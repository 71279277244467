<div [ngClass]="{'overview-trend-container': !dataLoaded}">
  <h6 *ngIf="!dataLoaded" class="overall-header">Overall Compliance Trend</h6>
  <app-multiline-trend [subHeadTitle]="'Overall Compliance Trend'" *ngIf="dataLoaded"
    [id]="'overAllComplianceColumnChart'" [hoverActive]="hoverActive" [showArea]="showArea" (error)="setError($event)"
    [showLegend]="showGraphLegend" [yAxisLabel]="yAxisLabel" [graphWidth]="graphWidth"
    [graphLinesData]="graphData"></app-multiline-trend>

  <div *ngIf="!dataLoaded && !error" class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div>

  <div *ngIf='error' class="error_handling">
    <app-error-message [selectedValue]="errorMessage"></app-error-message>
  </div>
  <div>