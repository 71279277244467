<section >
  <div class="container-fluid my-3">
    <div class="row">
      <div class="col-12">
        <div class="card chart-card2">
          <div class="card-body ">
            <h6 class="card-title">Tagging Across Target Types</h6>

            <div class="div-scroll2" *ngIf="!loading && !error">
              <div class="row flex-nowrap mb-4">

                <div class="col-3 text-center py-3 px-0 border-start" *ngFor="let data of taggingSummary">
                  <app-target-type-tagging-tile [awsResourceDetails]="data" class="flex"
                    (navigatePage)="navigatePage($event)"></app-target-type-tagging-tile>
                </div>
              </div>

            </div>

            <div [ngClass]="{'tagging-target-type-container': loading}" *ngIf="loading" class="loader-container">
              <div class="loader">
                <span></span>
              </div>
            </div>

            <div *ngIf='error && !loading' class="error_handling">
              <app-error-message [selectedValue]="errorMessage"></app-error-message>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>