
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
  providers: []
})
export class UserInfoComponent implements OnInit {

  constructor(private authenticateService: AuthService) { }

  @Input() firstName: string ='';

  @Output() closeInfoPop = new EventEmitter();

  ngOnInit() { }

  logout() {
    this.authenticateService.doLogout();
  }
}
