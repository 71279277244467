<section>
    <div class="container-fluid py-1">
      <div class="row">
        <div class="col-12">
          <div class="card tab-box">
            <div class="container-fluid mt-2 p-1">
              
              <div class="row">
                <div class="col-md-3 mb-4">
                  <h6 class="card-title">Role Details</h6>
                </div>
              </div>
              <div class="col-md-10 mx-auto">
                <div [hidden]="hideContent">                
                    <div class="row">
                      <div class="col-md-12 mb-4" *ngIf="isCreate">
                        <div class="heading-gray">
                          Name *                         
                        </div>
                        <input                          
                          class="input-field w-100" 
                          type="text" placeholder="Enter Role Name" name="rolesName"                        
                          [(ngModel)]="roles.roleName"                         
                          required>
                      </div>
                      <div
                        class="col-md-12 mb-4"
                        *ngIf="!isCreate">
                        <div class="heading-gray">
                          <label>Name</label>
                        </div>
                        <div class="text-value text-bold">{{roles.roleName}}</div>
                      </div>
                      <div class="col-md-12 mb-2">
                        <div class="heading-gray">
                          <label>Description *</label>
                        </div>
                        <textarea
                          class="input-field w-100"
                          rows="3"
                          placeholder="Enter Role Description" name="rolesDesc" [(ngModel)]="roles.description"></textarea>
                      </div>
                      <div class="col-md-12 mb-4">
                        <div>
                            <input type="checkbox" id="test5" name="isDisplayAssetGroupsEnabled" [checked]="roles.writePermission" [(ngModel)]="roles.writePermission">
                            <label for="test5" name="isDisplayAssetGroupsEnabled" class="bm30 ml-5">Write Permission</label>                           
                        </div>                       
                      </div>
                    </div>                
                
                <div class="col-md-4 mx-auto my-4 createsubmit">
                  <a class="btn btn-border me-2">
                    <button
                      class="submit-btn"
                      type="button"
                      (click)="navigateBack()">
                      <i class="bi bi-x fs-5"></i>
                      Cancel
                    </button>
                  </a>
                  <a
                    class="btn btn-border me-2 btn-green"
                    *ngIf="isCreate">
                    <button
                      class="submit-btn text-white"
                      (click)="createRole(roles)" [disabled]="roles.roleName===''">
                      <i class="bi bi-plus fs-5"></i>
                      Create
                    </button>
                  </a>
                  <a
                    class="btn btn-border me-2 btn-green"
                    *ngIf="!isCreate">
                    <button
                      class="submit-btn text-white"
                      (click)="updateRole(roles)" [disabled]="roles.roleName===''">
                      <i class="bi bi-check fs-5"></i>
                      Update
                    </button>
                  </a>
                </div>
                </div>
                <div
                  class="pacman-alert"
                  *ngIf="isRoleCreationUpdationFailed">
                  <div
                    class="sa-icon sa-error animateErrorIcon"
                    style="display: block;">
                    <span class="sa-x-mark animateXMark">                                          
                      <span class="sa-line sa-left"></span>                                    
                      <span class="sa-line sa-right"></span>
                      </span>
                  </div>
                  <div class="pacman-alert-title">{{failedTitle}}</div>
                  <div class="pacman-alert-message">
                    Role
                    <span class="highlight-pink">{{highlightName}}</span>
                    {{loadingContent}} failed!!!
                  </div>
                  <div class="pacman-alert-button">
                      <a class="btn btn-border">
                    <button
                      class="submit-btn cancelbut"
                      type="button"
                      (click)="closeErrorMessage()">
                      <span *ngIf="failedTitle!=='Loading Failed'"><i class="bi bi-x fs-5"></i> Close</span>
                      <span *ngIf="failedTitle==='Loading Failed'"><i class="bi bi-check fs-5"></i> Retry</span>
                    </button>
                  </a>
                  </div>
                </div>
                <div
                  class="pacman-alert"
                  *ngIf="isRoleCreationUpdationSuccess">
                  <div class="sa-icon sa-success animate">
                    <span class="sa-line sa-tip animateSuccessTip"></span>
                    <span class="sa-line sa-long animateSuccessLong"></span>
                    <div class="sa-placeholder"></div>
                    <div class="sa-fix"></div>
                  </div>
                  <div class="pacman-alert-title">{{successTitle}}</div>
                  <div class="pacman-alert-message">
                    Role
                    <span class="highlight-pink">{{highlightName}}</span>
                    has been successfully
                    <span *ngIf="isCreate">created</span>
                    <span *ngIf="!isCreate">updated</span>
                    !!!
                  </div>
                  <div class="pacman-alert-button">
                      <a class="btn btn-border">
                    <button
                      class="submit-btn cancelbut"
                      type="button"
                      (click)="navigateBack()">
                      <i class="bi bi-x fs-5"></i>
                      Close
                    </button>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  