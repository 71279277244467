
<section>
  <app-all-risks></app-all-risks>
</section>
<section class="my-2">
  <app-risk-severity></app-risk-severity>
</section>
<section>
  <app-open-risk></app-open-risk>
</section>
<section class="my-1">
  <div class="row w-100 m-0">
    <div class="col-lg-6 col-md-6 col-12 p-0 pr-20">
      <div class="card chart-card compliance-graph-section-height">
        <section>
          <app-functionwise-risk></app-functionwise-risk>
        </section>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 p-0">
      <div class="card chart-card compliance-graph-section-height mitigation">
        <section>
          <app-riskbymitigation></app-riskbymitigation>
        </section>
      </div>
    </div>
  </div>
  <div class="row w-100 m-0 m-t-30">
    <div class="col-lg-6 col-md-6 col-12 p-0 pr-20">
      <div class="card chart-card compliance-graph-section-height">
        <section>
          <app-riskbyaging></app-riskbyaging>
        </section>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12 p-0">
      <div class="card chart-card compliance-circle-section-height">
        <section>
          <app-riskowner></app-riskowner>
        </section>
      </div>
    </div>
  </div>
</section>
