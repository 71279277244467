
import {Injectable} from '@angular/core';
// @ts-ignore
import {Observable} from 'rxjs/Rx';
import { map } from 'rxjs/operators';

import {HttpService} from './http-response.service';
import {LoggerService} from './logger.service';
import {ErrorHandlingService} from './error-handling.service';

@Injectable()
export class CommonResponseService {

  constructor(private httpService: HttpService,
              private logger: LoggerService,
              private errorHandling: ErrorHandlingService) {
  }

  // @ts-ignore
  getData(dataUrl: any, dataMethod: any, dataPayload: any = {}, dataQuery: any = {}, headers = {}): Observable<any> {

    const url = dataUrl;
    const method = dataMethod;
    const payload = dataPayload;
    const queryParams: any = dataQuery;
    try {
      // used .pipe to work with map in angular v14
      return this.httpService.getHttpResponse(url, method, payload, queryParams, headers).pipe(
        map((response: any) => {
          return response
        })
      )
        // .map((response: any) => {
        //   return response
        // });
    } catch (error) {
      this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
}
