import { Observable } from 'rxjs/Rx';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from './../../../environments/environment';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';


@Injectable()
export class FetchResourcesService {

    constructor (
                 @Inject(HttpService) private httpService: HttpService,
                 private errorHandling: ErrorHandlingService) {}


    getAllResourceCounts(queryParams :any) :any {
        queryParams = {
            tenantId: 'UST_10001'
        }
        try {
            const url = environment.resourceCount.url;
            const method = environment.resourceCount.method;
            return this.httpService.getHttpResponse(url, method, {}, queryParams)
                    .map(response => {
                        return response;
                    })
                    .catch(error => {
                        return Observable.of(null);
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    getAllResourceCategories(queryParams :any) :any {
        try {
            const url = environment.resourceCategories.url;
            const method = environment.resourceCategories.method;
            return this.httpService.getHttpResponse(url, method, {}, queryParams)
                    .map(response => {
                        return response;
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    getRecommendations(queryParams :any) :any {
        try {
            const url = environment.recommendationStatus.url;
            const method = environment.recommendationStatus.method;
            return this.httpService.getHttpResponse(url, method, {}, queryParams)
                    .map(response => {
                        return response;
                    })
                    .catch(error => {
                        return Observable.of(null);
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    getResourceTypesAndCount(queryParams :any)  :any{
        try {
            const resourceType = this.getAllResourceCategories(queryParams);
            const resourceTypeCount = this.getAllResourceCounts(queryParams);
            const recommendations = this.getRecommendations(queryParams);
            const resourceTypeAndCountAndRecommendation :any = combineLatest(resourceType, resourceTypeCount, recommendations);
            return resourceTypeAndCountAndRecommendation;
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    dataCheck(data :any) {
        const APIStatus = this.errorHandling.checkAPIResponseStatus(data);
        if (!APIStatus.dataAvailble) {
            throw new Error('noDataAvailable');
        }
    }

}
