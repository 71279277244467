<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card tab-box">
          <div class="container-fluid mt-2 p-1">
            <div class="row">
              <div class="col-md-3 mb-4">
                <h6 class="card-title">Config Users</h6>
              </div>
            </div>
            <div class="col-md-10 mx-auto">
              <div class="floating-widgets-container">
                <ul class="conficlist">
                  <li class="widget-wrapper relative">                                                            
                    
                    
                    <div class="data-table-wrap relative">
                      <div class="sub-head">
                        <div class="flex flex-between flex-align-center">
                          <div class="sub-head-title capitalize flex flex-align-center after-load">
                            <div
                              class="header-text"
                              *ngIf="isCreate">
                              Role Details
                            </div>
                            <div
                              class="header-text"
                              *ngIf="!isCreate">
                              Role Name -
                              <span class="role-title">{{highlightName}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div [hidden]="hideContent">
                        <div class="data-content">
                          <div class="formcontainerwrapper">
                            <div class="formctn">
                              <div
                                class="attribute-contents asset-formctn"
                                *ngIf="!isCreate">
                                <div class="outer-div">
                                  <div class="list-select inner-div attrlist">
                                    <h1>Available Users</h1>
                                    <button
                                      class="move-btn move-btn-first"
                                      type="button"
                                      (click)="moveAllItemsToRight()"
                                      [disabled]="availableItems.length==0">
                                      <i class="bi bi-arrow-right"></i>
                                      <i class="bi bi-arrow-right"></i>
                                    </button>
                                    <button
                                      class="move-btn"
                                      type="button"
                                      (click)="moveItemToRight()"
                                      [disabled]="availChoosedItemsCount==0">
                                      <i class="bi bi-arrow-right"></i>
                                    </button>
                                    <input
                                      type="text"
                                      class="filter-box"
                                      placeholder="Filter Values"
                                      [(ngModel)]="searchAvailableUsersTerms"
                                      (ngModelChange)="searchAvailableUsers()">
                                    <ul>
                                      <li
                                        *ngFor="let availableItem of availableItems; let idx = index"
                                        (click)="onClickAvailableItem(idx, availableItem, availableItem.user)"
                                        [ngClass]="{selected: this.availChoosedItems[idx]}">{{availableItem.user}}</li>
                                      <div
                                        class="not-found"
                                        *ngIf="searchAvailableUsersTerms!='' && availableItems.length==0">
                                        <div class="not-found-image"></div>
                                        <div class="not-found-title">No values matching for :</div>
                                        <div class="highlight-pink not-found-title">
                                          <b>{{searchAvailableUsersTerms}}</b>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                  <div class="exchange list-select inner-div">
                                    <ul>
                                      <i class="bi bi-plus"></i>
                                    </ul>
                                  </div>

                                  <div class="list-select inner-div attrlist">
                                    <h1>Selected Users</h1>
                                    <button
                                      class="move-btn move-btn-first"
                                      type="button"
                                      (click)="moveAllItemsToLeft()"
                                      [disabled]="selectedItems.length==0">
                                      <i class="bi"></i>
                                      <i class="bi bi-arrow-left"></i>
                                    </button>
                                    <button
                                      class="move-btn"
                                      type="button"
                                      (click)="moveItemToLeft()"
                                      [disabled]="selectChoosedItemsCount==0">
                                      <i class="bi bi-arrow-left"></i>
                                    </button>
                                    <input
                                      class="filter-box"
                                      placeholder="Filter Values"
                                      [(ngModel)]="searchSelectedUsersTerms"
                                      (ngModelChange)="searchSelectedUsers()">
                                    <ul>
                                      <li
                                        *ngFor="let selectedItem of selectedItems; let idx = index"
                                        (click)="onClickSelectedItem(idx, selectedItem, selectedItem.user)"
                                        [ngClass]="{selected: this.selectChoosedItems[idx]}">{{selectedItem.user}}</li><div
                                        class="not-found"
                                        *ngIf="searchSelectedUsersTerms!=='' && selectedItems.length==0 && selectedItemsCopy.length!=0"><div class="not-found-image"></div><div class="not-found-title">No values matching for :</div><div class="highlight-pink not-found-title"><b>{{searchSelectedUsersTerms}}</b>
                                        </div>
                                      </div><div
                                        class="not-selected not-found"
                                        *ngIf="selectedItems.length==0 && selectedItemsCopy.length==0"><div class="not-selected-image"></div><div class="not-found-title">No users selected!!!</div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div><div class="data-table-paginator-wrap flex flex-center flex-align-center saveallbut createsubmit"><a class="btn btn-border me-2"><button
                              class="submit-btn"
                              type="button"
                              (click)="navigateBack()"><i class="bi bi-x fs-5"></i>
                              Cancel
                            </button>
                          </a><a
                            class="btn btn-border me-2 btn-green"
                            *ngIf="isCreate"><button
                              class="submit-btn text-white"
                              (click)="createRole(roles)"
                              [disabled]="roles.roleName===''"><i class="bi bi-plus fs-5"></i>
                              Create
                            </button>
                          </a><a
                            class="btn btn-border me-2 btn-green"
                            *ngIf="!isCreate"><button
                              class="submit-btn text-white"
                              (click)="configureUsers()"
                              [disabled]="roles.roleName===''"><i class="bi bi-check fs-5"></i>
                              Update
                            </button>
                          </a>
                        </div>
                      </div><div
                        class="pacman-alert"
                        *ngIf="isRoleCreationUpdationFailed"><div
                          class="sa-icon sa-error animateErrorIcon"
                          style="display: block;"><span class="sa-x-mark animateXMark">
                                    <span class="sa-line sa-left"></span>
                                <span class="sa-line sa-right"></span>
                                </span>
                        </div><div class="pacman-alert-title">{{failedTitle}}</div><div class="pacman-alert-message">
                          {{loadingContent}}<span class="highlight-pink">{{highlightName}}</span>
                          failed!!!
                        </div><div class="pacman-alert-button">
                          <a class="btn btn-border">
                            <button
                              class="submit-btn cancelbut"
                              type="button"
                              (click)="closeErrorMessage()">
                              <span *ngIf="failedTitle!=='Loading Failed'"><i class="bi bi-x fs-5"></i> Close</span>
                              <span *ngIf="failedTitle==='Loading Failed'"><i class="bi bi-check fs-5"></i> Retry</span>
                            </button>
                          </a>
                        </div>
                      </div><div
                        class="pacman-alert"
                        *ngIf="isRoleCreationUpdationSuccess"><div class="sa-icon sa-success animate"><span class="sa-line sa-tip animateSuccessTip"></span><span class="sa-line sa-long animateSuccessLong"></span><div class="sa-placeholder"></div><div class="sa-fix"></div>
                        </div><div class="pacman-alert-title">{{successTitle}}</div><div class="pacman-alert-message">
                          User Role<span class="highlight-pink">{{selectedRoleName}}</span>
                          has been successfully configured !!!
                        </div><div class="pacman-alert-button">
                            <a class="btn btn-border">
                            <button
                            class="submit-btn cancelbut"
                            type="button"
                            (click)="navigateBack()"><i class="bi bi-x"></i>
                            Close
                          </button>
                        </a>
                        </div>
                      </div><div
                        class="pacman-progress"
                        *ngIf="roleLoader">
                        <!-- <app-pacman-loader></app-pacman-loader> --><div class="title">Please Wait...</div><div class="pacman-alert-message">
                          User Role<span class="highlight-pink">{{highlightName}}</span>
                          {{loadingContent}} is in progress...
                        </div>
                      </div>
                    </div>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
