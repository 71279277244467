<div class="vulnerability-across-app-outer-wrapper flex flex-col relative">
  <h6 class="card-title p-20">Vulnerabilities Across Application  </h6>
    <section class=" vul-across-app-wrapper flex flex-col appblock" *ngIf = "showAsset">      
        <div class='flex-col flex relative right-padding' *ngIf='dataComing && !showLoader'>
            <div class="search-wrapper">
                
            </div>
            <div class="vul-app-wrapper">
                <app-listtable [tableData]="apiAppData"
                    [tableHeaderData]="tableHeaderData"
                    [orderByProperty]="{ property: 'S5', direction: -1 }"
                    [searchText]="returnedSearch" (error)="getErrorValues($event)" (navigatePage)="navigatePage($event)"></app-listtable>
            </div>
        </div>
    </section>
    <section class=" vul-across-app-wrapper flex flex-col appblock envblock"  *ngIf = "showEnv">
        <!-- <div class="sub-head">
                
        </div> -->
        <div class='flex-col flex relative' *ngIf='dataComing && !showLoader'>
            <div class="search-wrapper">
               
            </div>
            <div class="vul-app-wrapper">
                <app-listtable [tableData]="apiEnvData"
                    [tableHeaderData]="tableHeaderData"
                    [orderByProperty]="{ property: 'S5', direction: -1 }"
                    [searchText]="returnedSearch" (error)="getErrorValues($event)" (navigatePage)="navigatePageEnv($event)"></app-listtable>
            </div>
        </div>
    </section>
    <section class=" vul-across-app-wrapper flex flex-col appblock">
    <article class='center error_handling' *ngIf="!datacoming">
        <div class="error_msg">
            <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
        </div>
    </article>
  </section>
    <div *ngIf='showLoader' class="loader"></div>
</div>
