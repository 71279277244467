import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexMarkers, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';

export type coloumnChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  legend: ApexLegend;
};

@Component({
  selector: 'app-multiline-asset-trend',
  templateUrl: './multiline-asset-trend.component.html',
  styleUrls: ['./multiline-asset-trend.component.css']
})
export class MultilineAssetTrendComponent implements OnInit {

  @Input() id: any;
  @Input() graphWidth: any;
  @Input() graphHeight: any = 280;
  @Input() graphLinesData: any;
  @Input() yAxisLabel = '';
  @Input() xAxisLabel = '';
  @Input() showLegend = true;
  @Input() showArea = false;
  @Input() singlePercentLine = false;
  @Input() hoverActive = true;
  @Input() subHeadTitle = '';

  graphSeriesData: any = {};
  public complianceChartOptions: Partial<coloumnChartOptions> | any;

  constructor() {
    this.complianceChartOptions = {
      series: [],

      chart: {
        height: 350,
        type: 'area',
      },

      colors: ['#9fcb8d', '#277e71', '#a3748f', '#b6c3a3', '#DC8580'],

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },

      xaxis: {
        type: 'datetime',
        categories: [],
      },

      legend: {
        show: true,
        showForSingleSeries: true,
      },

      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },

      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0, 80],
        },
      },
    };
  }
  ngOnInit(): void {
    this.processGraphData(this.graphLinesData);
  }
  ngOnChanges(changes: SimpleChanges): void {}

  processGraphData(graphData: any) {
    if (this.id === 'cpuUtilization') {
      this.getAssetDetailsData(graphData);
    } else if (this.id === 'issuesHistory') {
      this.getPolicyViolationsHistoryData(graphData);
    } else if (this.id === 'taggingComplianceOverview') {
      this.getTaggingComplianceTrend(graphData);
    } else if (this.id === 'taggingAssetsOverview') {
      this.getComplianceAssets(graphData);
    } else if(this.id === 'complianceTrend'){
      this.getcomplianceTrendData(graphData);
    }else if(this.id === 'complianceAsset'){
      this.getComplianceAssetgraph(graphData);
    }
  }

  getAssetDetailsData(graphData:any) {
    const date: any[] = [];
    const value: any[] = [];

    const finalData = graphData[0].values.reduce((unique: any[], o: { date: any; }) => {
      if(!unique.some(obj => new Date(obj.date).getTime() === new Date (o.date).getTime())) {
        unique.push(o);
      }
      return unique;
    },[]);
    
  finalData.forEach((data: any) => {
      date.push(this.convertTime(data.date) || 0);
      value.push(data.value || 0);
    });

    this.graphSeriesData = {
      date: date,
      value: value,
    };
    
    this.complianceChartOptions.series = [
      {
        name: 'CPU Utilization',
        data: this.graphSeriesData.value,
      }
    ];
    
    this.complianceChartOptions.xaxis = {
      categories: this.graphSeriesData.date,
    };
    

    let yAxisHighst = [...value];
    let yAxisMax = Math.ceil(yAxisHighst.slice(-1)[0] / 100) * 100;
    this.complianceChartOptions.yaxis = {
      min: 0,
      max: yAxisMax,
      tickAmount: 4,
    };
  }

  getcomplianceTrendData(graphData:any) {
    const date: any[] = [];
    const value: any[] = [];

    const finalData = graphData[0].values.reduce((unique: any[], o: { date: any; }) => {
      if(!unique.some(obj => new Date(obj.date).getTime() === new Date (o.date).getTime())) {
        unique.push(o);
      }
      return unique;
    },[]);
    
  finalData.forEach((data: any) => {
      date.push(this.convertTime(data.date) || 0);
      value.push(data.value || 0);
    });

    this.graphSeriesData = {
      date: date,
      value: value,
    };
    
    this.complianceChartOptions.series = [
      {
        name: 'Compliance',
        data: this.graphSeriesData.value,
      }
    ];
    
    this.complianceChartOptions.xaxis = {
      categories: this.graphSeriesData.date,
    };
    

    let yAxisHighst = [...value];
    let yAxisMax = Math.ceil(yAxisHighst.slice(-1)[0] / 100) * 100;
    this.complianceChartOptions.yaxis = {
      min: 0,
      max: yAxisMax,
      tickAmount: 4,
    };
  }

  getComplianceAssetgraph(graphData: any) {
    let complianceGraphSeriesData;
    const date: any[] = [];
    const compliant: any[] = [];
    const total: any[] = [];

    const finalData = graphData[0].values.reduce((unique: any[], o: { date: any; }) => {
      if(!unique.some(obj => new Date(obj.date).getTime() === new Date (o.date).getTime())) {
        unique.push(o);
      }
      return unique;
  },[]);

  const finalDataAsset = graphData[1].values.reduce((unique: any[], o: { date: any; }) => {
    if(!unique.some(obj => new Date(obj.date).getTime() === new Date (o.date).getTime())) {
      unique.push(o);
    }
    return unique;
},[]);

  finalData.forEach((data: any, i: any) => {
      date.push(this.convertTime(data.date));
      compliant.push(data.value);
    });

    finalDataAsset.forEach((data: any, i: any) => {
      total.push(data.value);
    });
    complianceGraphSeriesData = {
      date: date,
      compliant: compliant,
      total: total,
    };

    this.complianceChartOptions.series = [
      {
        name: 'Compliant',
        data: complianceGraphSeriesData['compliant'],
      },
      {
        name: 'Total',
        data: complianceGraphSeriesData['total'],
      },
    ];
    let yAxisHighst = [...compliant, ...total];
    yAxisHighst.sort(function (a, b) {
      return a - b;
    });

    let yAxisMax = Math.ceil(yAxisHighst.slice(-1)[0] / 100) * 100;

    this.complianceChartOptions.xaxis = {
      categories: complianceGraphSeriesData.date,
    };

    this.complianceChartOptions.yaxis = {
      min: 0,
      max: yAxisMax,
      tickAmount: 4,
    };
  }

  getComplianceOverviewTrendData(graphData: any) {
    const date: any[] = [];
    const costOptimization: any[] = [];
    const governance: any[] = [];
    const security: any[] = [];
    const tagging: any[] = [];
    const overall: any[] = [];
    graphData.forEach((data: any, i: any) => {
      date.push(this.convertTime(data.date || 0));
      costOptimization.push(data.costOptimization || 0);
      governance.push(data.governance || 0);
      security.push(data.security || 0);
      tagging.push(data.tagging || 0);
      overall.push(data.overall || 0);
    });
    this.graphSeriesData = {
      date: date,
      costOptimization: costOptimization,
      governance: governance,
      security: security,
      tagging: tagging,
      overall: overall,
    };

    this.complianceChartOptions.series = [
      {
        name: 'Governance',
        data: this.graphSeriesData.governance,
      },
      {
        name: 'CostOptimization',
        data: this.graphSeriesData.costOptimization,
      },

      {
        name: 'Security',
        data: this.graphSeriesData.security,
      },
      {
        name: 'Tagged',
        data: this.graphSeriesData.tagging,
      },

      {
        name: 'Overall',
        data: this.graphSeriesData.overall,
      },
    ];

    this.complianceChartOptions.xaxis = {
      categories: this.graphSeriesData.date,
    };
  }

  getPolicyViolationsHistoryData(graphData: any) {
    const date: any[] = [];
    const high: any[] = [];
    const low: any[] = [];
    const medium: any[] = [];
    const critical: any[] = [];
    const Total: any[] = [];
    let graphYAxisSeries: any = [];
    graphData.forEach((data: any, i: any) => {
      date.push(this.convertTime(data.date) || 0);
      high.push(data.high || 0);
      low.push(data.low || 0);
      medium.push(data.medium || 0);
      critical.push(data.critical || 0);
      Total.push(data.total || 0);
    });

    let yAxisHighst = [...high, ...low, ...critical, ...medium, ...Total];
    yAxisHighst.sort(function (a, b) {
      return a - b;
    });

    let yAxisMax = Math.ceil(yAxisHighst.slice(-1)[0] / 100) * 100;

    this.graphSeriesData = {
      date,
      high,
      low,
      medium,
      critical,
      Total,
    };

    this.complianceChartOptions.yaxis = {
      min: 0,
      max: yAxisMax,
      tickAmount: 4,
    };

    // this.getYAxisData(1);
    this.complianceChartOptions.series = [
      {
        name: 'High',
        data: this.graphSeriesData.high,
      },
      {
        name: 'Low',
        data: this.graphSeriesData.low,
      },

      {
        name: 'Medium',
        data: this.graphSeriesData.medium,
      },
      {
        name: 'Critical',
        data: this.graphSeriesData.critical,
      },

      {
        name: 'Total',
        data: this.graphSeriesData.Total,
      },
    ];

    this.complianceChartOptions.xaxis = {
      categories: this.graphSeriesData.date,
    };
  }

  getTaggingComplianceTrend(graphData: any) {
    const date: any[] = [];
    const value: any[] = [];

    graphData[0].values.forEach((data: any) => {
      date.push(this.convertTime(data.date) || 0);
      value.push(data.value || 0);
    });

    this.complianceChartOptions.series = [
      {
        name: 'Compliance',
        data: value,
      },
    ];

    this.complianceChartOptions.xaxis = {
      categories: date,
    };
  }

  getComplianceAssets(graphData: any) {
    let complianceGraphSeriesData;
    const date: any[] = [];
    const compliant: any[] = [];
    const total: any[] = [];

    graphData[0].values.forEach((data: any, i: any) => {
      date.push(this.convertTime(data.date));
      compliant.push(data.value);
    });

    graphData[1].values.forEach((data: any, i: any) => {
      total.push(data.value);
    });
    complianceGraphSeriesData = {
      date: date,
      compliant: compliant,
      total: total,
    };

    this.complianceChartOptions.series = [
      {
        name: 'Compliant',
        data: complianceGraphSeriesData['compliant'],
      },
      {
        name: 'Total',
        data: complianceGraphSeriesData['total'],
      },
    ];

    let yAxisHighst = [...compliant, ...total];
    yAxisHighst.sort(function (a, b) {
      return a - b;
    });

    let yAxisMax = Math.ceil(yAxisHighst.slice(-1)[0] / 100) * 100;

    this.complianceChartOptions.xaxis = {
      categories: complianceGraphSeriesData.date,
    };

    this.complianceChartOptions.yaxis = {
      min: 0,
      max: yAxisMax,
      tickAmount: 4,
    };
  }

  convertTime(dateTime: any) {
    let date =
      new Date(dateTime).toString().split(' ')[1] +
      ' ' +
      new Date(dateTime).toString().split(' ')[2];
    return date;
  }

  // getYAxisData(option1: any, option2?:any , option3?: any, option4?:any, option5?:any ) {
  //   console.log(option1, option2)

  // }

}
