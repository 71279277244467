<div *ngIf="!contValue" class="loader-container">
  <div class="loader">
    <span></span>
  </div>
</div>
<div class="row" >
    <div class="col-md-10 col-10">
      <div
        id="barChart"
        class="riskage" *ngIf='contValue'>
        <div class="heading">
          <h3>Risk By Aging</h3>
        </div>
        <div class="label top-left">
          <div class="content">Severity</div>
        </div>
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [xaxis]="chartOptions.xaxis"
          [colors]="chartOptions.colors"
          [yaxis]="chartOptions.yaxis"
          [legend]="chartOptions.legend"></apx-chart>
        <p class="count">Count</p>
      </div>
    </div>
    <!-- <div class="col-md-2 col-2 text-end chart-value">
      <ul>
        <li >20</li>
        <li >80</li>
        <li >11</li>
        <li >12</li>
      </ul>
    </div> -->
    <div class="col-md-2 col-2 text-end chart-value">
        <ul>
          <li *ngFor="let val of series">{{ val }}%</li>
        </ul>
      </div>
  </div>
  