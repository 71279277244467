import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataCacheService } from '../../../core/services/data-cache.service';
import { LoggerService } from '../../../shared/services/logger.service';

@Component({
  selector: 'app-post-login-header',
  templateUrl: './post-login-header.component.html',
  styleUrls: ['./post-login-header.component.css'],
})
export class PostLoginHeaderComponent implements OnInit, OnDestroy {
    firstName: string = '';

  constructor(
    private dataCacheService: DataCacheService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    try {
      this.firstName = 'Guest';
      const detailsData = this.dataCacheService.getUserDetailsValue();
      const firstNameData = detailsData.getFirstName();
      if (firstNameData) {
        this.firstName = firstNameData;
      }
    } catch (error) {
      this.loggerService.log('error', 'JS Error' + error);
    }
  }

  ngOnDestroy(): void {}
}
