
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Feature modules */
import { SharedModule } from '../shared/shared.module';
import { PostLoginHeaderComponent } from './common/post-login-header/post-login-header.component';
import { ComplianceModule } from '../pacman-features/modules/compliance/compliance.module';
import { PostLoginAppRoutingModule } from './post-login-app-routing.module';
import { PostLoginAppComponent } from './post-login-app.component';
import { DomainGroupComponent } from './domain-group/domain-group.component';
import { DefaultAssetGroupComponent } from './default-asset-group/default-asset-group.component';
import { DomainDropdownComponent } from './common/domain-dropdown/domain-dropdown.component';
import { UserInfoComponent } from './common/user-info/user-info.component';
import { ContextualMenuComponent } from './common/contextual-menu/contextual-menu.component';
import { BreadcrumbComponent } from './common/breadcrumb/breadcrumb.component';
import { DownloadService } from '../shared/services/download.service';
import { ToastObservableService } from './common/services/toast-observable.service';
import { AssetsModule } from '../pacman-features/modules/assets/assets.module';
import { AwsResourceTypeSelectionService } from '../pacman-features/services/aws-resource-type-selection.service';
import { AdminModule } from '../pacman-features/modules/admin/admin.module';
import { ChangeDefaultAssetGroupComponent } from './change-default-asset-group/change-default-asset-group.component';
import { AssetGroupsComponent } from './asset-groups/asset-groups.component';
import { AssetGroupTabsComponent } from './common/asset-group-tabs/asset-group-tabs.component';
import { GrcModule } from '../pacman-features/modules/grc/grc.module';
import { NgxSelectModule } from 'ngx-select-ex';



@NgModule({
  imports: [
    PostLoginAppRoutingModule,
    CommonModule,
    SharedModule,
    ComplianceModule,
    AssetsModule,
    AdminModule,
    GrcModule,
    NgxSelectModule
  ],
  declarations: [
    PostLoginHeaderComponent,
    PostLoginAppComponent,
    DomainGroupComponent,
    DefaultAssetGroupComponent,
    DomainDropdownComponent,
    UserInfoComponent,
    ContextualMenuComponent,
    BreadcrumbComponent,
    ChangeDefaultAssetGroupComponent,
    AssetGroupsComponent,
    AssetGroupTabsComponent
  ],
  providers:[DownloadService, ToastObservableService, AwsResourceTypeSelectionService]

})
export class PostLoginAppModule {

  constructor (@Optional() @SkipSelf() parentModule: PostLoginAppModule) {
    if (parentModule) {
      throw new Error(
          'PostLoginAppModule is already loaded. Import it in the AppModule only');
    }
  }
}
