<div class="container-fluid">
    <div class="row">
      <div class="col-12">

        <div class="card tab-box dataTable-section-height">
          <!-- Loader -->
          <div *ngIf='errorValue == 0' class="loader-container">
            <div class="loader">
              <span></span>
            </div>
          </div>

          <div class='error_handling' *ngIf='errorValue == -1 && errorMessage'>
            <div class="error_msg">
              <app-error-message *ngIf='errorValue == -1 && errorMessage'
                [selectedValue]="errorMessage"></app-error-message>
            </div>
          </div>
          <!--Search Box and Download  -->
          <!-- <app-title-burger-head *ngIf="outerArr && outerArr.length > 0" [tableIdAppend]="tableIdAppend" (rowText)="emitRowText($event)" [popRows]="popRows"
            [showSearch]="searchableHeader" [subHeadTitle]="dataHead" (searchTxt)="searchCalled($event)"
            (enterPressed)="triggerSearch()"></app-title-burger-head> -->

          <!-- <app-table-tabs *ngIf="outerArr && outerArr.length > 0" (onSelectChange)="changeTabSelection($event)"
            [tabsData]="tabsData" [displayProperty]="rowAccessProperty">
            >
          </app-table-tabs> -->

          <!-- Data Table start here -->

          <div class="container-fluid mt-2 p-1 severity-status">
            
            <div class="row">
              <div class="col-md-3 "> <h6 class="card-title">Event Vulnerability Summary</h6> </div>  
           </div>
 <table datatable [dtOptions]="dtOptions" *ngIf="
                errorValue > 0 &&
                loaded &&
                filteredColumns.length > 0 &&
                sortArr.length > 0
              " id="datatable" class="table nowrap policy-table table-hover" width="100%" #datatable>
              <thead width="100%">
                <tr>
                  <th (click)='sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header);'
                    *ngFor="let header of filteredColumns; let i = index">
                    {{ header }}
                    <span [class.up-arr-shown]='sortArr[i].showUp == true'
                      [class.down-arr-shown]='sortArr[i].showUp == false' class="list-sortable-arrow relative"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of currentTableData;let i = index; ">
                  <td 
                    *ngFor="let column of filteredColumns; let i = index">
                    <a  class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" (click)='currentRowData = row; '>
                      {{row[column].text }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
              <!-- Table Pagination Strip -->
              <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
                <div class='checked-list' *ngIf='checkBox'>Selected: <span
                    class='checked-list-length'>{{checkedList.length}}</span>
                </div>
                <div class='pagination-strip'>
                  <div class='me-3'> Showing {{firstPaginator}} to <span
                      *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                      entries</span>
                  </div>
                  <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                      class="bi bi-chevron-left"></i>
                  </div>
                  <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                      [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            <!-- <div class="policy-count" *ngIf="currentTableData.length">
              Total of {{ currentTableData.length }} Events
            </div> -->
          </div>
          <!-- Data Table ends here -->

        </div>
      </div>

    </div>

  </div>
