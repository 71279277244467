<section>
  <div class="container-fluid my-3">  
    <div class="row">
      <div class="col-12">
        <div class="card chart-card2">
          <div class="card-body">
            <div class="gray-heading">  
              <div class="row p-1 mt-1">
                <div class="col-6">
                     <div class="row">
                     <div class="col"><h6>Creation Date :<span>{{violationData.violationCreatedDate}}</span></h6></div>
                  
                     </div>
                </div>
                <div class="col-6">
                  <div class="row text-end">
                    <div class="col"><h6>Last Modified Date :<span>{{violationData.violationModifiedDate}}</span></h6></div>
                    </div>
                </div>

              </div>

            </div>
             
             <div class="row">              
                 <div class="col-5 col-md-2 py-2 ps-3"><h6>Policy Violated :</h6></div>
                 <div class="col-7 col-md-10 py-2" (click)="navigateTo('policy knowledgebase details',violationData.ruleId)">{{violationData.policyViolated}}</div>

                 <div class="col-5 col-md-2 py-2 ps-3"><h6>Violation Reason :</h6></div>
                 <div class="col-7 col-md-10 py-2">{{violationData.violationReason}}</div>

                 <div class="col-5 col-md-2 py-2 ps-3"><h6>Resource ID :</h6></div>
                 <div class="col-7 col-md-10 py-2">{{violationData.resouceViolatedPolicy}}</div>
             </div>
          </div>
       </div>
    </div>
  </div>
 </div>
</section>