<div class="card chart-card compliance-graph-section-height">
  <!-- Loader -->
  <div *ngIf="!contValue" class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div>
  <div class="error_handling">
    <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
  </div>
  <div *ngIf='!seekdata' class="card-body">

    <h6 class="card-title" *ngIf='contValue'>
      Overall Compliance
      <span class="small float-right">Total <b>{{ overallPercentage }}%</b></span>
    </h6>
    <div class="row" *ngIf='contValue'>
      <div class="col-md-10 col-10">
        <!-- Bar Chart -->
        <div id="barChart">
          <apx-chart [grid]="chartOptions.grid" [series]="chartOptions.series" [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis"
            [colors]="chartOptions.colors" [legend]="chartOptions.legend" [yaxis]="chartOptions.yaxis"></apx-chart>
        </div>
        <!-- End Bar Chart -->
      </div>

      <div class="col-md-2 col-2 text-end chart-value">
        <ul>
          <li *ngFor="let val of series">{{ val }}%</li>
        </ul>
      </div>
    </div>
  </div>
</div>