
<div class="row">
    <div class="col-lg-12 col-md-12 col-12 ">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12 ">
         
          <div class="card chart-card compliance-graph-section-height" >
            <div class="card-body">
              <h6 class="card-title">Risks By Severity</h6>
              <div class="row">
                <div *ngIf="!contValue" class="loader-container" style="position: relative;">
                  <div class="loader" style="height: 220px;">
                    <span></span>
                  </div>
                </div>
                <div class="col-md-10 col-10" style="min-height: 215px;" *ngIf="contValue">
                  <!-- Bar Chart -->
                  <div id="barChart" style="width: 496px; height: 200px;">
                    <apx-chart [grid]="chartOptions.grid" [series]="chartOptions.series" [chart]="chartOptions.chart"
                      [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis"
                      [colors]="chartOptions.colors" [legend]="chartOptions.legend" [yaxis]="chartOptions.yaxis"></apx-chart>
                  </div>
                  <!-- End Bar Chart -->
                </div>
          
                <div class="col-md-2 col-2 text-end chart-value">
                  <ul>
                    <li *ngFor="let val of series">{{ val }}%</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12" >
          <app-risk-impact></app-risk-impact>
      </div>
      </div>
    </div>
  </div>