<section>
    <div class="container-fluid ">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box py-1">
                    <div class="container-fluid mt-2 py-4 mb-1 relative">
                        <div class="row"  [hidden]="hideContent">
                            <div class="col-md-10 mx-auto mt-4">
                                <div class="row">
                                    <div class="col-md-12 pt-4 ">
                                        <div class="text-bold text-center">
                                            <img class="my-4" src="assets/images/info-icon.svg">
                                            <h5> {{pageTitle}}</h5>
                                        </div>
                                    </div>
                                    <div class="col-md-10 py-4 my-4 text-center mx-auto">
                                        <div class="fs-6  mx-auto">
                                            Are you sure to <span class="lowercase">{{pageTitle}}</span> - <span class="highlight-pink">{{ruleId}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="col-md-4 mx-auto my-4 button-div">
                                        <button class="submit-btn btn-border me-2 cancelbut" type="button" (click)="navigateBack()"><i class="bi bi-x fs-5"></i>
                                            Cancel</button>
                                        <button class="submit-btn btn-green ms-2 greenbut disablebut" type="submit" (click)="enableDisableRule()">
                                            <i class="bi bi-plus fs-5"></i> {{action}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pacman-alert" *ngIf="isEnableDisableInvokeFailed">
                            <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                <span class="sa-x-mark animateXMark">
                                    <span class="sa-line sa-left"></span>
                                <span class="sa-line sa-right"></span>
                                </span>
                            </div>

                            <div class="pacman-alert-title">
                                <span *ngIf="action=='enable'; else elseBlock">
                                    Enabling Rule Failed
                                </span>
                                <ng-template #elseBlock>
                                    Disabling Rule Failed
                                </ng-template>
                            </div>
                            <div class="pacman-alert-message">
                                Unexpected error occurred while
                                <span *ngIf="action=='enable'; else elseBlock">
                                    enabling 
                                </span>
                                <ng-template #elseBlock>
                                    disabling
                                </ng-template>
                                rule - <span class="highlight-pink">{{ruleId}}</span>
                            </div>
                            <div class="pacman-alert-button">
                                <button class="btn btn-border me-2" type="button" (click)="closeErrorMessage()"><i class="fa fa-arrow-left"></i>  BACK</button>
                            </div>
                        </div>
                        <div class="pacman-alert" *ngIf="isEnableDisableInvokeSuccess">
                            <div class="sa-icon sa-success animate">
                                <span class="sa-line sa-tip animateSuccessTip"></span>
                                <span class="sa-line sa-long animateSuccessLong"></span>
                                <div class="sa-placeholder"></div>
                                <div class="sa-fix"></div>
                            </div>
                            <div class="pacman-alert-title">
                                <span *ngIf="action=='enable'; else elseBlock">
                                    {{pageTitle}}
                                </span>
                                <ng-template #elseBlock>
                                    {{pageTitle}}
                                </ng-template>
                            </div>
                            <div class="pacman-alert-message">
                                Rule <span class="highlight-pink">{{ruleId}}</span> has been successfully
                                <span *ngIf="action=='enable'; else elseBlock1">
                                    {{pageTitle}}
                                </span>
                                <ng-template #elseBlock1>
                                    {{pageTitle}}
                                </ng-template> !!!
                            </div>
                            <div class="pacman-alert-button">
                                <button class="submit-btn btn-border me-2" type="button" (click)="navigateBack()"><i class="fa fa-remove"></i> CLOSE</button>
                            </div>
                        </div>
                        <div class="pacman-progress" *ngIf="ruleLoader">
                            <!-- <app-pacman-loader></app-pacman-loader> -->
                            <div class="pacman-alert-title">
                                Please Wait...
                            </div>
                            <div class="pacman-alert-message">
                                <span *ngIf="action=='enable'; else elseBlock">
                                    Enabling
                                </span>
                                <ng-template #elseBlock>
                                    Disabling
                                </ng-template> rule - <span class="highlight-pink">{{ruleId}}</span> is under progress ...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>