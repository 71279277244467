<!-- <section class="patching-summary-wrapper">
	<div class="repeating-tiles flex flex-wrap">
		<ng-container *ngFor="let data of finalData.response; let i = index ">
			<div class="each-tile" *ngIf='data.value || data.value == 0'>
				<div class="wrapper" (click)="data.value > 0 && instructParentToNavigate(data)"
					[ngStyle]="{'cursor': data.value > 0 && data.styling.cursor }">
					<div *ngIf='data.value || data.value == 0' class="text" title="{{data.text}}">{{data.text}}
					</div>
					<div *ngIf='textValue == undefined' class="value nowrap-ellipsis" [style.border-bottom-color]="colors[i]"
						[style.color]="colors[i]" title="{{data.value | number}}"
						[ngClass]="{'link-text underline-link-num': data.value > 0 && data.link}">{{data.value |
						number}}
						<span class="percent" *ngIf='percent[i]'>%</span>
					</div>
					<div *ngIf='textValue == true && (data.value || data.value == 0)' class="value nowrap-ellipsis"
						[class.small]="text[i] == true" [class.flexClass]="data.image" [style.color]="colors[i]" title="{{data.value}}">
						<span class="image" *ngIf='data.image'><img class="image-wrapper" src="{{data.image}}"></span>
						<span class='nowrap-ellipsis text'>{{data.value}}</span><span class="percent" *ngIf='percent[i]'>%</span><span
							class="percent" *ngIf='outOf[i]'>/10</span>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</section> -->

<div class="row asset-details-div">
	<ng-container *ngFor="let data of finalData.response; let i = index ">
		<div class="each-tile" *ngIf='data.value || data.value == 0'>
	<div class="col-6 pe-0">
		<div class="wrapper" (click)="data.value > 0 && instructParentToNavigate(data)"
			[ngStyle]="{'cursor': data.value > 0 && data.styling.cursor }">
			<div class="row">
			<div *ngIf='data.value || data.value == 0' class="col-6 asset-label" title="{{data.text}}">{{data.text}}
			</div>
			<div *ngIf='textValue == undefined' class="value nowrap-ellipsis" [style.border-bottom-color]="colors[i]"
				[style.color]="colors[i]" title="{{data.value | number}}"
				[ngClass]="{'link-text underline-link-num': data.value > 0 && data.link}">{{data.value |
				number}}
				<span class="percent" *ngIf='percent[i]'>%</span>
			</div>
			<div *ngIf='textValue == true && (data.value || data.value == 0)' class="col-6 value nowrap-ellipsis"
				[class.small]="text[i] == true" [class.flexClass]="data.image" [style.color]="colors[i]" title="{{data.value}}">
				<span class="image" *ngIf='data.image'><img class="image-wrapper" src="{{data.image}}"></span>
				<span class='nowrap-ellipsis text'>{{data.value}}</span>
				<span class="percent" *ngIf='percent[i]'>%</span>
				<span class="percent" *ngIf='outOf[i]'>/10</span>
			</div>
			</div>
		</div>
	</div>
	<div class="col-6">
		<!-- <div id="graph2" class="donut-chart float-none mx-auto mb-0" data-amounts="50,50" data-fills="#E0E0E0,#01B27C"
			data-size="140" data-thickness="10">
			<div class="chart-label">50%</div>
		</div> -->
	</div>
</div>
</ng-container>
</div>