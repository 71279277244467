import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { PoliciesComponent } from './policies/policies.component';
import { AdminComponent } from './admin.component';
import { SharedModule } from "../../../shared/shared.module";
import { RulesComponent } from './rules/rules.component';
import { CreateRuleComponent } from './create-rule/create-rule.component';
import { EnableDisableRuleComponent } from './enable-disable-rule/enable-disable-rule.component';
import { UpdateRuleComponent } from './update-rule/update-rule.component';
import { InvokeRuleComponent } from './invoke-rule/invoke-rule.component';
import { JobExecutionManagerComponent } from './job-execution-manager/job-execution-manager.component';
import { TargetTypesComponent } from './asset-groups/target-types/target-types.component';
import { AssetGroupsComponent } from './asset-groups/asset-groups.component';
import { CreateJobExecutionManagerComponent } from './job-execution-manager/create-job-execution-manager/create-job-execution-manager.component';
import { UpdateJobExecutionManagerComponent } from './job-execution-manager/update-job-execution-manager/update-job-execution-manager.component';
import { CreateUpdateTargetTypesComponent } from './asset-groups/target-types/create-update-target-types/create-update-target-types.component';
import { NgxSelectModule } from 'ngx-select-ex';
// import { DatepickerOptions } from 'ng2-datepicker';
import { DomainComponent } from './domain/domain.component';
import { CreatePolicyComponent } from './create-policy/create-policy.component';
import { CreateDomainComponent } from './create-domain/create-domain.component';
import { FormsModule } from '@angular/forms';
import { CreateAssetGroupsComponent } from './asset-groups/create-asset-groups/create-asset-groups.component';
import { DeleteAssetGroupsComponent } from './asset-groups/delete-asset-groups/delete-asset-groups.component';
import { RolesComponent } from './roles/roles.component';
import { CreateUpdateRolesComponent } from './roles/create-update-roles/create-update-roles.component';
import { ConfigUsersComponent } from './roles/config-users/config-users.component';
import { RolesAllocationComponent } from './roles-allocation/roles-allocation.component';
import { StickyExceptionsComponent } from './sticky-exceptions/sticky-exceptions.component';
import { DeleteStickyExceptionsComponent } from './sticky-exceptions/delete-sticky-exceptions/delete-sticky-exceptions.component';
import { CreateStickyExceptionsComponent } from './sticky-exceptions/create-sticky-exceptions/create-sticky-exceptions.component';
// import { NgxDatesPickerModule } from 'ngx-dates-picker';

import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SystemManagementComponent } from './system-management/system-management.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ServicenowComponent } from './servicenow/servicenow.component';
import { ConfigManagementComponent } from './config-management/config-management.component';

@NgModule({
  declarations: [
    AdminComponent,
    PoliciesComponent,
    RulesComponent,
    CreateRuleComponent,
    EnableDisableRuleComponent,
    UpdateRuleComponent,
    InvokeRuleComponent,
    JobExecutionManagerComponent,
    TargetTypesComponent,
    AssetGroupsComponent,
    CreateJobExecutionManagerComponent,
    UpdateJobExecutionManagerComponent,
    CreateUpdateTargetTypesComponent,
    DomainComponent,
    CreatePolicyComponent,
    CreateDomainComponent,
    CreateAssetGroupsComponent,
    DeleteAssetGroupsComponent,
    RolesComponent,
    CreateUpdateRolesComponent,
    ConfigUsersComponent,
    RolesAllocationComponent,
    StickyExceptionsComponent,
    DeleteStickyExceptionsComponent,
    CreateStickyExceptionsComponent,
    SystemManagementComponent,
    ServicenowComponent,
    ConfigManagementComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    SelectDropDownModule,
    NgxSelectModule,
    FormsModule,
    AngularMyDatePickerModule
    // NgxDatesPickerModule
    
  ],

})
export class AdminModule { }
