import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  labels: any;
  legend: ApexLegend;
  colors: string[];
};
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { AutorefreshService } from '../../services/autorefresh.service';
import { GrcService } from '../../services/grc.service';

@Component({
  selector: 'app-riskowner',
  templateUrl: './riskowner.component.html',
  styleUrls: ['./riskowner.component.css']
})
export class RiskownerComponent implements OnInit {

  private riskDataUrl = environment.riskByOwner.url;
  private riskDataMethod = environment.riskByOwner.method;
  ownerData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToOwner: Subscription | any;
  contValue = false;
  loaded = false;
  constructor(private cdr: ChangeDetectorRef,private grcService:GrcService,
    private errorHandling: ErrorHandlingService) {
    this.chartOptions = {
      series: [0],
      chart: {
        height: 180,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false
              },
              value: {
                show: true,
                formatter: (val:any) => {
                  return val + ' tCO2e';
                }
              },
              total: {
                show: true,
                showAlways: false,
                formatter: (w:any) => {
                  return w.globals.seriesTotals.reduce((a:any, b:any) => {
                    return a + b
                  }, 0) + ' tCO2e'
                }
              }
            }
          }
        }
      },
      stroke: {
        width: 0,
        colors: ["#fff"]
      },
      colors: [
        '#fc6a59', 
        '#dab315',
        '#008699',
        '#d9434e',
        '#3f9234',                      
      ],
      labels: [''],
      dataLabels: {
        enabled: false,
        horizontalAlign: "left",
      },
      legend: {
        position: "left",
        horizontalAlign: "left",
        offsetX: 20
      },
    };
  }

  ngOnInit() {
    this.getByOwner();
  }
  getByOwner(): void {
    try {
      this.subscriptionToOwner = this.grcService
        .executeHttpAction(
          this.riskDataUrl,
          this.riskDataMethod, {}, {}
        )
        .subscribe(
          (response:any) => {
            try {
              this.contValue = true;
              this.loaded = true;
              this.ownerData = response[0];
              this.series = [];
              this.chartCategories = [];
              for (let i = 0; i < this.ownerData.length; i++) {
                const ownerItem = this.ownerData[i];
                  this.chartCategories.push(ownerItem.name +' '+ ownerItem.count);
                  this.series.push(ownerItem.count);
              }
              this.chartOptions.series = this.series;
              
              this.chartOptions.labels =this.chartCategories;
            } catch (e) {
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    this.series = [];
    this.chartCategories =[];
  }

}
