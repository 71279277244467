/**
 * @type Service
 * @desc Logger, this service is used to log errors, log info for debugging, log silent javascript errors.
 * @author Puneet Baser
 */


import {Injectable} from '@angular/core';

@Injectable()
export class LoggerService {

  constructor() {
  }

  log(level: any, message: any) {

    const timestamp = new Date().toISOString();

    if (level === 'error') {
      console.log(timestamp, 'ERROR \t', message);
      /*
       If required, add custom actions here; Such as send a mail, etc...
       */
    } else if (level === 'info') {
      console.log(timestamp, 'INFO  \t', message);
    } else if (level === 'debug') {
      console.log(timestamp, 'DEBUG  \t', message);
    } else {
      console.log(timestamp, level, '\t', message);
    }
  }
}
