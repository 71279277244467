
export const MESSAGES: any = {
  'errorMessages': {
    'apiResponseError': {
      'title': 'Uh-oh!',
      'description': 'Something went wrong while we were fetching your data.'
    },
    'noDataAvailable': {
      'title': '',
      'description': 'Doesn\'t look like any data is available'
    },
    'jsError': {
      'title': 'Uh-oh!',
      'description': 'Something went wrong.'
    },
    'patchingMessage': {
      'title': 'Great!',
      'description': 'All assets are compliant'
    },
    'certificateMessage': {
      'title': 'Great!',
      'description': 'No certificates are expiring for you in next 45 days'
    },
    'certificateTableMessage': {
      'title': '',
      'description': 'No certificates found in this asset group'
    },
    'vulnerabilityMessage': {
      'title': 'Great!',
      'description': 'No vulnerabilities found for the assets in this asset group'
    },
    'taggingMessage': {
      'title': 'Great!',
      'description': 'All assets are tagged for this asset group'
    },
    'policyDetailsTableMessage': {
      'title': 'Great!',
      'description': 'No open policy violations found for this asset group'
    },
    'patchingDataTableMessage': {
      'description': 'No instances found in this asset group'
    },
    'policyDetailsMessage': {
      'title': '',
      'description': 'None of the applications are associated with this policy'
    },
    'dataTableMessage': {
      'title': 'Ohh!',
      'description': 'No results found for this criteria'
    },
    'noDataforAssetGroup': {
      'title': 'Oops, It\'s deserted here!',
      'description': 'Doesn\'t look like any data is available for this asset group'
    },
    'policySummaryTableMessage': {
      'title': 'Ohh!',
      'description': 'None of the policies are associated in this asset group'
    },
    'taggingTargetMessage': {
      'title': '',
      'description': 'There are no assets in this asset group'
    },
    'violationMessage': {
      'title': 'Great!',
      'description': 'No Violations for this Resource ID'
    },
    'vulnerabilitiesMessage': {
      'title': 'Great!',
      'description': 'No Vulnerabilities for this Resource ID'
    },
    'awsNotificationMessage': {
      'title': '',
      'description': 'No AWS notifications found'
    },
    'noPullRequestsFound': {
      'title': '',
      'description': 'No Pull requests found for this criteria'
    },
    'noBranchesFound': {
      'title': '',
      'description': 'No Branches found for this criteria'
    },
    'noPolicyFound': {
      'title': '',
      'description': 'There are no active Policies for this Asset Type.'
    }
  }
};
