import { Component, ViewChild,OnInit, ChangeDetectorRef, Input, SimpleChanges, OnChanges } from "@angular/core";

import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexFill,
  ChartComponent
} from "ng-apexcharts";
import { Subscription } from "rxjs";
import { GrcService } from "src/app/pacman-features/services/grc.service";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-identity-chart',
  templateUrl: './identity-chart.component.html',
  styleUrls: ['./identity-chart.component.css']
})
export class IdentityChartComponent implements OnInit {
  private chartDataUrl = environment.partialchart.url;
  private chartDataMethod = environment.partialchart.method;
  partialData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToOwner: Subscription | any;
  @Input() actualvalue:number=0;
  @Input() status:any;
  chartcolorcode:string='#EE2219';
  @ViewChild('chart') chart: ChartComponent | undefined;
  constructor(private cdr: ChangeDetectorRef,private grcService:GrcService,
    private errorHandling: ErrorHandlingService) {
  
  
}
  ngOnInit(): void {
    if(this.actualvalue!=undefined && this.status!=undefined){
      this.generateChart()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['actualvalue'] || changes['status']) {      
      this.generateChart()
    }
  }

  updateChart() {
    
   
  }

  generateChart(){
    if(this.status=='Not aligned'){
      this.chartcolorcode='#EE2219';
    }else if(this.status=='Partially Aligned'){
      this.chartcolorcode='#DAB315';
    }else if(this.status=='Aligned'){
      this.chartcolorcode='#01B27C';
    }


    this.chartOptions = {
      series: [this.actualvalue],
      chart: {
        type: "radialBar",
        offsetY: -5,   
        width:200,
        height:250,           
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
         
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 3, // margin is in pixels
            // dropShadow: {
            //   enabled: true,
            //   top: 2,
            //   left: 0,
            //   opacity: 0.31,
            //   blur: 2
            // }
            value: {
              show: true,
          fontSize: '14px',
            },
          },
          dataLabels: {
            name: {
              show: false,        
            },
            value: {
              offsetY: 5,
              fontSize: "12px",
              formatter: function (val:any) {
                return (val/20).toFixed(2)
              }
            }
          }
        }
      },
      colors: [
        '#fc6a59',                               
      ],
      fill: {
        opacity: 0.8,
        type: 'solid',
        colors: [this.chartcolorcode]       
      },
      labels: ["Average Results"]
    };
  }

  updateColorCode(){
    if(this.status=='Not aligned'){
      this.chartcolorcode='#EE2219';
    }else if(this.status=='Partially Aligned'){
      this.chartcolorcode='#DAB315';
    }else if(this.status=='Aligned'){
      this.chartcolorcode='#01B27C';
    }
  }

}
