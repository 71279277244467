import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-asset-generic-summary',
  templateUrl: './asset-generic-summary.component.html',
  styleUrls: ['./asset-generic-summary.component.css']
})
export class AssetGenericSummaryComponent implements OnInit {

  @Input() data: any;
  @Input() colors: any;
  @Input() percent: any;
  @Input() headerText: any;
  @Output() navigatePage: EventEmitter<any> = new EventEmitter();
  @Input() textValue: any;
  @Input() text: any;
  @Input() outOf: any;
  dataValue: any;
  finalData: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    try {
      const DataChange = changes['data'];
      if (DataChange) {
        const cur = JSON.stringify(DataChange.currentValue);
        const prev = JSON.stringify(DataChange.previousValue);
        if (cur !== prev && this.data) {
          this.ngOnInit();
        }
      }
    } catch (error) {}
  }

  ngOnInit() {
    this.finalData = this.processData(this.data);
    console.log("Final: ", this.finalData)
  }

  isEven(n: any) {
    return n === parseFloat(n) ? !(n % 2) : void 0;
  }

  processData(data: any) {
    return data;
  }
  instructParentToNavigate(data: any) {
    this.navigatePage.emit(data);
  }

}
