<div [ngClass]="{'issue-trend-container': !dataLoaded}">
    <h6 *ngIf="!dataLoaded" class="trend-header">Trend of Number of Policy Violations</h6>
    <app-multiline-trend *ngIf="dataLoaded" [subHeadTitle]="'Trend of Number of Policy Violations'"
        [showArea]="showArea" [id]="'issuesHistory'" (error)="setError($event)" [showLegend]="showGraphLegend"
        [yAxisLabel]="yAxisLabel" [graphWidth]="graphWidth" [graphLinesData]="graphData"></app-multiline-trend>

    <!-- <div class="loader" *ngIf='!dataLoaded && !error'></div>
        <app-error-message *ngIf='error' [selectedValue]="errorMessage"></app-error-message> -->

    <div *ngIf="!dataLoaded && !error" class="loader-container">
        <div class="loader">
            <span></span>
        </div>
    </div>

    <div *ngIf='error' class="error_handling">
        <app-error-message [selectedValue]="errorMessage"></app-error-message>
    </div>
</div>


<!-- [ngStyle]="{'margin': dataLoaded ? '' : '13px'}"  -->