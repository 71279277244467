<app-post-login-header></app-post-login-header>
<app-contextual-menu [config]="config"></app-contextual-menu>

<!-- Main wrapper start -->
<main id="main" class="main my-container my-container active-cont">
  <!-- Collapsable button -->
  <a class="btn btn-sm border-0" id="menu-btn" (click)="toggleMenu()"
    ><i class="bi bi-list"></i
  ></a>

  <app-breadcrumb></app-breadcrumb>

  <section class="post-login-body flex flex-col">
    <router-outlet></router-outlet>
  </section>
</main>
