import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GrcRoutingModule } from './grc-routing.module';
import { RiskManagementComponent } from './risk-management/risk-management.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateRiskComponent } from './create-risk/create-risk.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { RiskImpactComponent } from '../../secondary-components/risk-impact/risk-impact.component';
import { RiskSeverityComponent } from '../../secondary-components/risk-severity/risk-severity.component';
import { AllRisksComponent } from '../../secondary-components/all-risks/all-risks.component';
import { OpenRiskComponent } from '../../secondary-components/open-risk/open-risk.component';
import { AutorefreshService } from '../../services/autorefresh.service';
import { GrcService } from '../../services/grc.service';
import { FunctionwiseRiskComponent } from '../../secondary-components/functionwise-risk/functionwise-risk.component';
import { RiskbyagingComponent } from '../../secondary-components/riskbyaging/riskbyaging.component';
import { RiskbymitigationComponent } from '../../secondary-components/riskbymitigation/riskbymitigation.component';
import { RiskownerComponent } from '../../secondary-components/riskowner/riskowner.component';
import { RiskcreateComponent } from './riskcreate/riskcreate.component';
import { UploadFileService } from '../../services/upload-file-service';
import { UpdateRiskComponent } from './update-risk/update-risk.component';
import { SecurityPostureAssessmentComponent } from './security-posture-assessment/security-posture-assessment.component';
import { OverallcompalaianceComponent } from './security-posture-assessment/overallcompalaiance/overallcompalaiance.component';
import { RiskscaleComponent } from './security-posture-assessment/riskscale/riskscale.component';
import { RiskrateComponent } from './security-posture-assessment/riskrate/riskrate.component';
import { RiskoverviewComponent } from './riskoverview/riskoverview.component';
import { MaturityGraphComponent } from './security-posture-assessment/maturity-graph/maturity-graph.component';
import { SecurityPiechartComponent } from './security-posture-assessment/security-piechart/security-piechart.component';
import { IdentityChartComponent } from './security-posture-assessment/identity-chart/identity-chart.component';


@NgModule({
  imports: [
    CommonModule,
    GrcRoutingModule,
    SharedModule,
    NgxSelectModule,
    
    SelectDropDownModule,
    FormsModule,
    AngularMyDatePickerModule
  ],
  declarations: [
    RiskManagementComponent,
    CreateRiskComponent,
    RiskImpactComponent,
    RiskSeverityComponent,
    AllRisksComponent,
    OpenRiskComponent,
    FunctionwiseRiskComponent,
    RiskbyagingComponent,
    RiskbymitigationComponent,
    RiskownerComponent,
    RiskcreateComponent,
    UpdateRiskComponent,
    SecurityPostureAssessmentComponent,
    OverallcompalaianceComponent,
    RiskscaleComponent,
    RiskrateComponent,
    RiskoverviewComponent,
    MaturityGraphComponent,
    SecurityPiechartComponent,
    IdentityChartComponent,
  ],
  providers: [
    GrcService,
    AutorefreshService,
    UploadFileService
  ]
})
export class GrcModule { }
