

import { Component, ViewEncapsulation } from '@angular/core';



@Component({
  selector: 'app-compliance-issues',
  templateUrl: './compliance-issues.component.html',
  styleUrls: ['./compliance-issues.component.css'],
  providers: [],
})

export class ComplianceIssuesComponent  {

}