<div [ngClass]="{'overview-trend-container': !dataLoaded}">
  <h6 *ngIf="!dataLoaded" class="compliance-trend-header">Compliance Trend</h6>
  <app-multiline-trend *ngIf="dataLoaded" [subHeadTitle]="'Compliance Trend'" [id]="'complianceTrend'" (error)="setError($event)"
    [showLegend]="showGraphLegend" [yAxisLabel]="yAxisLabel" [graphLinesData]="graphData">
  </app-multiline-trend>

  <!-- <div *ngIf="dataLoaded" class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div>
  
  <div *ngIf='!dataLoaded && !error' class="error_handling">
    <app-error-message *ngIf='error' [selectedValue]="errorMessage"></app-error-message>
  </div> -->

  <div *ngIf="!dataLoaded && !error" class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div>

  <div *ngIf='error' class="error_handling">
    <app-error-message [selectedValue]="errorMessage"></app-error-message>
  </div>
</div>
