import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AutorefreshService } from '../../services/autorefresh.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { Subscription } from 'rxjs/Subscription';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { HttpService } from 'src/app/shared/services/http-response.service';
import {environment} from "../../../../environments/environment";
import { Observable } from 'rxjs/Rx';
import { of } from 'rxjs';

@Component({
  selector: 'app-vulnerability-aging-graph',
  templateUrl: './vulnerability-aging-graph.component.html',
  styleUrls: ['./vulnerability-aging-graph.component.css'],
  providers: [ AutorefreshService, LoggerService, ErrorHandlingService]
})
export class VulnerabilityAgingGraphComponent implements OnInit {

  selectedAssetGroup!: string;
  // private errorMessage = 'apiResponseError';
  private dataSubscription: Subscription |any;
  private subscriptionToAssetGroup: Subscription |any;
  public errorMessage: any;
  errorValue = 0;
  public graphData: any = [];
  public legend_text: any;

  constructor(
  private assetGroupObservableService: AssetGroupObservableService,
  private logger: LoggerService,
  private errorHandling: ErrorHandlingService,
  private utilsService: UtilsService,
  @Inject(HttpService) private httpService: HttpService) {
    this.subscriptionToAssetGroup = this.assetGroupObservableService.getAssetGroup().subscribe(
      assetGroupName => {
          this.selectedAssetGroup = assetGroupName;
          this.updateComponent();
      });
   }

  ngOnInit() {
    this.updateComponent();
  }

  updateComponent() {
    this.getData();
  }

  getData() {

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    const payload = {};
    const queryParam = {
      'ag': this.selectedAssetGroup
    };
    this.errorValue = 0;

    const url = environment.vulnerabilityAgingSummary.url;
    const method = environment.vulnerabilityAgingSummary.method;

    this.dataSubscription = this.getHTTPData(url, method, payload, queryParam).subscribe(
      response => {
      try {
        if (this.utilsService.checkIfAPIReturnedDataIsEmpty(response)) {
          this.errorValue = -1;
          this.errorMessage = 'vulnerabilityMessage';
        } else {
          this.errorValue = 1;
          this.processGraphData(response);
        }

      } catch (e) {
        this.errorValue = -1;
        this.errorMessage = 'jsError';
        this.logger.log('error', e);
      }
    },
    error => {
      this.errorValue = -1;
      this.errorMessage = 'apiResponseError';
      this.logger.log('error', error);
    });
  }

  processGraphData(data:any): void {

    const barColor = ['#FFB00D', '#F75C03', '#D40325'];
    let i = 0;
      data.forEach((element:any) => {
        if (element['severity']) {
          element['x_domain'] = element['severity'];
          delete element['severity'];
        }
        if (element['days']) {
          element['y_domain'] = element['days'];
          delete element['days'];
        }
        element['bar_color'] = barColor[i];
        i++;
      });
      const legend = {
        y_axis: 'Age (days)',
        x_axis: 'Severities'
      };
      this.graphData = data;
      this.legend_text = legend;
  }

  onResize() {
    this.updateComponent();
  }

  // assign error values...

  getErrorValues(message?: any ): void {
    this.errorValue = -1;
    if (message) {
      this.errorMessage = message;
    }
  }

  getHTTPData(tableUrl:any, tableMethod:any, payload:any, queryParam:any): Observable<any> {

    const url = tableUrl;
    const method = tableMethod;
    const queryParams = queryParam;

    try {
        return this.httpService.getHttpResponse(url, method, payload, queryParams)
                .map(response => {
                    return response;
                });
    } catch (error) {
        this.errorHandling.handleJavascriptError(error);
        return of(undefined); 


    }

  }

  ngOnDestroy() {
    try {
      if (this.subscriptionToAssetGroup) {
        this.subscriptionToAssetGroup.unsubscribe();
      }
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}
