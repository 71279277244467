
export const QUARTER: any = {
  'quarterObj': {
    '1': {
      'fromMonth': '01',
      'toMonth': '03',
      'fromDay': '01',
      'toDay': '31'
    },
    '2': {
      'fromMonth': '04',
      'toMonth': '06',
      'fromDay': '01',
      'toDay': '30'
    },
    '3': {
      'fromMonth': '07',
      'toMonth': '09',
      'fromDay': '01',
      'toDay': '30'
    },
    '4': {
      'fromMonth': '10',
      'toMonth': '12',
      'fromDay': '01',
      'toDay': '31'
    }
  }

};
