
<section *ngIf="dataObjArray">
	<div class="container-fluid my-3" *ngIf='dataObjArray.length > 0'>
		<div class="row">
			<div class="col-12">
				<div class="card ">
					<div class="card-body mb-1">
						<div class="col">
							<h6>Attributes :</h6>
						</div>
						<div class="row">

							<ng-container *ngFor='let data of dataObjArray'>
							<div class="col-6 col-md-4 mb-3" *ngFor='let tiles of data.values'>
								<div *ngIf='tiles.value.length > 0'>
									<b>{{tiles.name}}</b><br>
									<div class="values-wrapper-value" *ngFor='let subTiles of tiles.value'>
										<div *ngIf="tiles.name==='EBS Volumes'">
											{{subTiles}}
										</div>
										<div *ngIf="tiles.name!=='EBS Volumes' && subTiles.href"
											class="nowrap-ellipsis uncategorizedWrapper" title="{{subTiles.href}}"
											[innerHTML]='subTiles.href'></div>
										<div *ngIf="tiles.name!=='EBS Volumes' && !subTiles.href"
											class="nowrap-ellipsis uncategorizedWrapper" title="{{subTiles}}"
											[innerHTML]='subTiles'></div>
									</div>
								</div>
							</div>
						</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>