<!-- All Policies Details -->
<section id='{{id}}'>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">

        <div class="card tab-box dataTable-section-height">
          <!-- Loader -->
          <div *ngIf='errorValue == 0' class="loader-container">
            <div class="loader">
              <span></span>
            </div>
          </div>

          <div class='error_handling' *ngIf='errorValue == -1 && errorMessage'>
            <div class="error_msg">
              <app-error-message *ngIf='errorValue == -1 && errorMessage'
                [selectedValue]="errorMessage"></app-error-message>
            </div>
          </div>
          <!--Search Box and Download  -->
          <!-- <app-title-burger-head *ngIf="outerArr && outerArr.length > 0" [tableIdAppend]="tableIdAppend" (rowText)="emitRowText($event)" [popRows]="popRows"
            [showSearch]="searchableHeader" [subHeadTitle]="dataHead" (searchTxt)="searchCalled($event)"
            (enterPressed)="triggerSearch()"></app-title-burger-head> -->

          <!-- <app-table-tabs *ngIf="outerArr && outerArr.length > 0" (onSelectChange)="changeTabSelection($event)"
            [tabsData]="tabsData" [displayProperty]="rowAccessProperty">
            >
          </app-table-tabs> -->

          <!-- Data Table start here -->

          <div class="container-fluid mt-2 p-1 severity-status">
            
            <div class="row">
              <div class="col-md-3 "> <h6 class="card-title">All Policies</h6> </div>  
              <div class="col-md-6"></div>   
              <div class="col-md-3 text-end crtbutoon"><a class="btn page-button" (click)="goToCreatePolicy()" ><i class="bi bi-plus text-white"></i>Create Policy</a></div>           
           </div>
            <table datatable [dtOptions]="dtOptions" *ngIf="
                errorValue > 0 &&
                loaded &&
                filteredColumns.length > 0 &&
                sortArr.length > 0
              " id="datatable" class="table nowrap policy-table table-hover" width="100%" #datatable>
              <thead width="100%">
                <tr>
                  <th (click)='sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header);'
                    *ngFor="let header of filteredColumns; let i = index">
                    {{ header }}
                    <span [class.up-arr-shown]='sortArr[i].showUp == true'
                      [class.down-arr-shown]='sortArr[i].showUp == false' class="list-sortable-arrow2 relative"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of currentTableData |searchFilter:searchPassed |orderBy :
                { property: headerColName, direction: direction , childProperty: 'valText'}; let i = index; ">
                  <td [ngClass]="{'sorting_1': row[column].colName === sortSelectedColomn}"
                    *ngFor="let column of filteredColumns">
                    <a *ngIf="row[column].colName==='Policy Id'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" (click)='currentRowData = row;tableRowClicked(row, i); goToDetails(currentRowData, column)'
                      [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}" data-bs-toggle="modal" data-bs-target="#modalForm">{{
                      row[column].text }}</a>
                    <a *ngIf="row[column].colName==='Actions'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" (click)='currentRowData = row; goToUpdatePolicy(currentRowData, column)'
                    [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}">{{
                    row[column].text }}</a>
                    <a *ngIf="row[column].colName!='Policy Id' && row[column].colName!='Actions'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" 
                    [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}">{{
                    row[column].text }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
              <!-- Table Pagination Strip -->
              <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
                <div class='checked-list' *ngIf='checkBox'>Selected: <span
                    class='checked-list-length'>{{checkedList.length}}</span>
                </div>
                <div class='pagination-strip'>
                  <div class='me-3'> Showing {{firstPaginator}} to <span
                      *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                      entries</span>
                  </div>
                  <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                      class="bi bi-chevron-left"></i>
                  </div>
                  <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                      [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            <!-- <div class="policy-count" *ngIf="currentTableData.length">
              Total of {{ currentTableData.length }} Policies
            </div> -->
          </div>
          <!-- Data Table ends here -->

        </div>
      </div>

    </div>

  </div>


</section>


<!-- Popup -->
<section>
  <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-dark text-white">
          <h5 class="modal-title" id="exampleModalLabel">Additional Details</h5>

          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">
            <i class="bi bi-x-lg text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3 pt-2">
              <table width="100%">                              
                <tr>
                  <td class="w-25">Policy ID :</td>
                  <td class="text-green">{{ rowObj["Policy Id"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-25">Policy Name :</td>
                  <td class="text-green">{{ rowObj["Policy Name"]?.text || null }}</td>
                </tr>   
                <tr>
                  <td class="w-25">Policy Url :</td>
                  <td>{{ rowObj["Policy Url"]?.text || null }}</td>
                </tr>       
                <tr>
                  <td class="w-25">Policy Version :</td>
                  <td>{{ rowObj["Policy Version"]?.text || null }}</td>
                </tr>  
                <tr>
                  <td class="w-25">Policy Description :</td>
                  <td>{{ rowObj["Policy Description"]?.text || null }}</td>
                </tr>                                    
                <tr>
                  <td class="w-25">Resolution :</td>
                  <td>{{ rowObj["resolution"]?.text || null }}</td>
                </tr>            
                <tr>
                  <td class="w-25">Created On :</td>
                  <td>{{ rowObj["Created Date"]?.text || null }}</td>
                </tr>

                <tr>
                  <td class="w-25">Modified On :</td>
                  <td>{{ rowObj["Modified Date"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-25">No of Rules :</td>
                  <td>{{ rowObj["No of Rules"]?.text || null }}</td>
                </tr>

                <tr>
                  <td class="w-25">Actions :</td>
                  <td>{{ rowObj["Actions"]?.text || null }}</td>
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>