<app-overall-vulnerabilities></app-overall-vulnerabilities>
<section>
    <app-vulnerabilities-compliance-trend ></app-vulnerabilities-compliance-trend>
</section>
<section class="across">
  <app-vulnerability-across-application></app-vulnerability-across-application>
</section>
<section>
  <app-vulnerability-summary-table  ></app-vulnerability-summary-table>
 </section>
 <section class="across">
  <app-vulnerability-aging-graph></app-vulnerability-aging-graph>
 </section>
<section>
    <app-vulnerability-assets-trend></app-vulnerability-assets-trend>
</section>


