<!-- <section class="policyviotable">
    <div class="container-fluid py-1">
      <div class="row">
        <div class="col-12">
          <div class="card tab-box">
            
            <div class="container-fluid mt-2 p-1 policy-violation dataTable-section-height">
              <h6>Risks by Likelihood</h6>
              <div #TABLE>
              <table datatable [dtOptions]="dtOptions" 
              *ngIf="errorValue > 0 && loaded && filteredColumns.length > 0 && sortArr.length > 0 " 
              id="policy-violation" class="table nowrap policy-violation-table table-hover" width="100%" #datatable>
                <thead>
                  <tr>
                    <th (click)="sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header)"
                    *ngFor="let header of filteredColumns; let i = index">
                      {{ header }}
                      <span [class.up-arr-shown]="sortArr[i].showUp == true"
                        [class.down-arr-shown]="sortArr[i].showUp == false" class="list-sortable-arrow"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                      let row of currentTableData
                        | searchFilter : searchPassed
                        | orderBy
                          : {
                              property: headerColName,
                              direction: direction,
                              childProperty: 'valText'
                            };
                      let i = index
                    ">
                    <td  [ngClass]="{
                        sorting_1: row[column]?.colName === sortSelectedColomn
                      }" *ngFor="let column of filteredColumns">
                      <a *ngIf='row[column]?.link && row[column]?.text'
                        (click)='currentRowData = row;goToDetails(currentRowData, column);(row[column].text === "Edit" || row[column].text === "Disable") ? $event.stopPropagation() :""'
                        class="table-trucate" data-bs-toggle="tooltip" title="{{ row[column]?.text }}"
                        [ngClass]="{ low: row[column]?.colName == 'Status' }">
                        <span [ngClass]="{
                            'low-dot':
                              row[column]?.text === 'medium' ||
                              row[column]?.text === 'low',
                            'high-dot':
                              row[column]?.text === 'high' ||
                              row[column]?.text === 'critical'
                          }" *ngIf="row[column]?.colName == 'Severity'">
                          <i class="bi bi-circle-fill"></i>
                        </span>
                        {{ row[column]?.text || "No Data" }}
                      </a>
                      <a *ngIf='!row[column]?.link' (click)='tableRowClicked(row, i)' data-bs-toggle="modal" data-bs-target="#modalForm"
                        class="table-trucate"  title="{{ row[column]?.text }}"
                        [ngClass]="{ low: row[column]?.colName == 'Status' }">
                        <span [ngClass]="{
                            'low-dot':
                              row[column]?.text === 'medium' ||
                              row[column]?.text === 'low',
                            'high-dot':
                              row[column]?.text === 'high' ||
                              row[column]?.text === 'critical'
                          }" *ngIf="row[column]?.colName == 'Severity'">
                          <i class="bi bi-circle-fill"></i>
                        </span>
                        {{ row[column]?.text || "No Data" }}
                      </a>
  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
              <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
                <div class='checked-list' *ngIf='checkBox'>Selected: <span
                    class='checked-list-length'>{{checkedList.length}}</span>
                </div>
                <div class='pagination-strip'>
                  <div class='me-3'> Showing {{firstPaginator}} to <span
                      *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                      entries</span>
                  </div>
                  <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                      class="bi bi-chevron-left"></i>
                  </div>
                  <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                      [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>.
          </div>
        </div>
      </div>
    </div>
  </section> -->

  
<div class="card chart-card">
  <div class="card-body mb-4 ">
    <div  *ngIf="!loaded" class="loader-container" style="position: relative;">
      <div class="loader" style="height: 220px;">
        <span></span>
      </div>
    </div>
    <div *ngIf="loaded" class="container-fluid mb-2 table-severity-div">
      <h6>Risks by Likelihood</h6>
      <table id="risk-severity-table" class="table nowrap table-hover risks-scroll" width="100%">
        <thead class="sticky-top top-0">
          <tr>
            <th>NAME</th>
            <th></th>
            <th>1-NEGLIGIBLE</th>
            <th>2-LOW</th>
            <th>3-MEDIUM</th>
            <th>4-HIGH</th>
            <th>5-VERY HIGH</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of this.rowCol; let i = index">
            <td>{{ row.charAt(0).toUpperCase() + row.slice(1) }}</td>
            <td *ngFor="let item of this.rowHeaders.riskImpactRating[row]">
              {{ item?.count}}
            </td>
            <td>{{this.impactTotal[i]}}
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td *ngFor="let severity of this.severityTotal">
              {{ severity }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
