<section>
    <div class="container-fluid">
        <div class="card main-div">
            <div class="col-12">
        <div class="flex flex-align-center toggle-wrap">
            <div (click)="contextChange('asset');" [class.active]="tabSelected == 'asset'" class="each-tab"> Asset-Specific </div>
            <!-- <div class="relative toggle-parent" (click)='contextChange(toggleSlider());'>
                <div class="absolute toggle-slider" [class.right]="tabSelected == 'general'"></div>
            </div> -->
            <div class="slider-div">
            <label class="creative-checkbox onOff">
                <input type="checkbox" (click)='contextChange(toggleSlider());' />
                <i></i>
            </label>
        </div>
            <div (click)="contextChange('general');" [class.active]="tabSelected == 'general'" class="each-tab"> General </div>
            <img class="help-icon pointer" (click)='callHelp();' src="../assets/icons/question.svg">

            <!-- <label class="creative-checkbox onOff">
                <input type="checkbox" >
                <i></i>
            </label> -->
        </div>
    </div>
    <div class="col-12">
        <div class="row card-group cardDiv">
            <div class="col-sm-6 col-md-6 col-lg-3 pb-3 tile-wrap" [class.hide]='summaryValue != 1'
                *ngFor='let tile of tilesObj; let i = index;'>
                <div class="card text-center each-tile" (click)='handleTileClick(tile, i);' [class.active]='tile.active'>
                    <div class="card-body">
                        <div class="flex flex-col">
                            <span class="tile-number">{{tile.value}}</span>
                            <span class="tile-txt">{{tile.title}}</span>
                            <span><img class="tile-img" src='{{tile.icon}}'></span>
                        </div>
                        <div class="tile-hover flex flex-align-center flex-center">
                            Click to {{tile.active ? 'remove' : 'add'}} filter
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf='summaryValue == 0' class="loader absolute loader-summary"></div>
            <div *ngIf='summaryValue == -1' class='absolute error-message'>Unable to fetch notifications summary!</div>
        </div>
        </div>
        </div>
    </div>
    <div class="notification-table">
    <app-notification-table
        [searchTextValues]='searchTxt'
        [errorValue]='errorValue'
        [parentName]="errorMsg"
        [allColumns]='allColumns'
        [outerArr]='outerArr'
        [totalRows]='totalRows'
        [firstPaginator]='firstPaginator'
        [lastPaginator]='lastPaginator'
        [columnWhiteList]="['event', 'Status', 'Affected Resources', 'Event Category', 'AWS Region', 'Start Time', 'End Time']"
        [currentPointer]='currentPointer'
        (previousPageCalled)='prevPg()'
        (nextPageCalled)='nextPg()'
        [searchableHeader]='true'
        [dataHead]="'Event Log'"
        (selectedRow)='goToDetails($event)'
        (searchRowTxt)='searchCalled($event)'
        [popRows]='popRows'
        (rowClickText)='handlePopClick($event)'
        (searchTriggerred)='callNewSearch()'
        [notificationHeader]='notificationHeader'>
    </app-notification-table>
</div>
</section>
