
import { Component, OnInit, Input, OnDestroy, AfterViewInit} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {DomainTypeObservableService} from "../../../core/services/domain-type-observable.service";
import {AssetGroupObservableService} from "../../../core/services/asset-group-observable.service";
import {WorkflowService} from "../../../core/services/workflow.service";
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  breadcrumbArray: any;
  breadcrumbLinks: any;
  breadcrumbPresent: any;
  asset: any = false;
  @Input() isCustomParentRoute: boolean =false;
  @Input() parentRouteName: any;


  private assetGroupSubscription: Subscription |any;
  private domainSubscription: Subscription |any;
  public agAndDomain :any = {};
  showHead = true;

  constructor(private assetGroupObservableService: AssetGroupObservableService,
              public router: Router,
              private workflowService: WorkflowService,
              private domainObservableService: DomainTypeObservableService,
              private logger: LoggerService,
              private breadCrumbService: BreadcrumbService) {

  }

  ngOnInit() {
    this.initializeSubscriptions();
    this.breadCrumbService.getBreadCrumbData().subscribe(data => {
      this.breadcrumbArray = data.breadcrumbArray;
      this.breadcrumbLinks = data.breadcrumbLinks;
      this.breadcrumbPresent = data.breadcrumbPresent;
      this.asset = data.asset;
    })

  }
  initializeSubscriptions() {
    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assetGroupName => {
      this.agAndDomain['ag'] = assetGroupName;
    });
    this.domainSubscription = this.domainObservableService.getDomainType().subscribe(domain => {
      this.agAndDomain['domain'] = domain;
    });
  }

  // loaderHeader() {
  //   this.router.events.forEach((event) => {
  //     if (event instanceof NavigationStart) {
  //       if (event['url'] == '/pl/grc/risk-management') {
  //         this.showHead = false;
  //       } else if (event['url'] == '/pl/grc/create-risk') {
  //         this.showHead = false;
  //       } else {
  //         this.showHead = true;
  //       }
  //     }
  //   });
  // }

  ngOnDestroy() {
      try {
        if (this.assetGroupSubscription) {
          this.assetGroupSubscription.unsubscribe();
        }
        if (this.domainSubscription) {
          this.domainSubscription.unsubscribe();
        }
      }catch (error) {
        this.logger.log('error', error);
      }
  }

  navigateRespective(index :any): any {
      if (this.asset === true) {
        this.router.navigate(['/pl/assets', this.breadcrumbLinks[index]], {queryParams: this.agAndDomain});
      } else if (this.isCustomParentRoute === true && this.parentRouteName !== undefined) {
        this.router.navigate(['/pl/' + this.parentRouteName, this.breadcrumbLinks[index]], {queryParams: this.agAndDomain});
      } else {
        this.router.navigate(['/pl/compliance', this.breadcrumbLinks[index]], {queryParams: this.agAndDomain});
      }
      this.workflowService.clearAllLevels();
  }
}
