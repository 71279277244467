<html lang="en">

  <body>
    <!-- ======= Header ======= -->

    <!-- ======= Sidebar ======= -->

    <!-- Main wrapper start -->
    <main
      id="main"
      class="main my-container my-container active-cont">
      <section>
        <div class="container-fluid main-heading">
          <div class="row">
            <div class="col-md-6 col-6 text-left mt-1">
              <h6>Cloud Security Mesh Dashboard</h6>
            </div>
            <div class="col-md-6 col-6 text-end mb-2"></div>
          </div>
        </div>
      </section>
      <!-- Risk Register Box Start -->
      <section>
        <div class="container-fluid mt-2">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-12 over-compliance-box">
              <div class="card">
                <div class="card-body">

                  <!-- Semi circle chart box begin -->
                  <div class="circle-container pb-1">

                    <h6 class="card-title text-start">Overall Compliance Score</h6>
                    <div class="chart-colors text-start">
                      <div class="red-chart-badge me-1"></div>
                      <span>0 - 49 Non Compliance</span>
                      <br>
                      <div class="yellow-chart-badge me-1"></div>
                      <span>50 - 79 Fair Compliance</span>
                      <br>
                      <div class="green-chart-badge me-1"></div>
                      <span>80 - 89 Substantial Compliance</span>
                      <br>
                      <div class="green2-chart-badge me-1"></div>
                      <span>90 - 100 Full Compliance</span>
                    </div>

                    <div class="chartbox">
                      <div class="pie-wrapper mt-5">
                        <div
                          class="arc"
                          data-value="80"></div>
                        <span class="score">65%</span>

                      </div>
                      <div class="targetbar"></div>
                      <div class="targetvalue">80%</div>
                      <div class="chartbox-start">0%</div>
                      <div class="chartbox-end">100%</div>
                    </div>
                  </div>
                  <!-- Semi circle chart box end -->

                </div>

              </div>
            </div>

            <div class="col-lg-7 col-md-12 col-12 over-compliance-box-right">
              <div class="card">
                <div class="card-body pb-2">

                  <div class="row">
                    <div class="col-lg-3 col-md-12 col-12 px-2">

                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">

                        <div class="small-circle-container pb-1">

                          <h6 class="card-title">Overall Risk Score</h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="90"></div>
                              <span class="score">47.5%</span>

                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>

                      </div>
                      <!-- Semi circle chart box end -->

                    </div>
                    <div class="col-lg-3 col-md-12 col-12 px-2">

                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">

                        <div class="small-circle-container pb-1">

                          <h6 class="card-title">Access Management </h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="100"></div>
                              <span class="score">100%</span>

                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->

                    </div>

                    <div class="col-lg-3 col-md-12 col-12 px-2">
                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">

                        <div class="small-circle-container pb-1">

                          <h6 class="card-title">Third Party Risk Mgmt </h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="66"></div>
                              <span class="score">66%</span>

                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->
                    </div>

                    <div class="col-lg-3 col-md-12 col-12 px-2">
                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">

                        <div class="small-circle-container pb-1">

                          <h6 class="card-title">Incident Mgmt. Compliance </h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="90"></div>
                              <span class="score">90%</span>

                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->
                    </div>

                    <div class="col-lg-3 col-md-12 col-12 px-2">
                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">

                        <div class="small-circle-container pb-1">

                          <h6 class="card-title">Audit Compliance </h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="60"></div>
                              <span class="score">60%</span>

                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->
                    </div>

                    <div class="col-lg-3 col-md-12 col-12 px-2">
                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">

                        <div class="small-circle-container pb-1">

                          <h6 class="card-title">BCP/DR Compliance </h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="30"></div>
                              <span class="score">30%</span>

                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->
                    </div>

                    <div class="col-lg-3 col-md-12 col-12 px-2">
                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">
                        <div class="small-circle-container pb-1">
                          <h6 class="card-title">Vulnerability Management</h6>
                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="55"></div>
                              <span class="score">55%</span>
                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->
                    </div>

                    <div class="col-lg-3 col-md-12 col-12 px-2">
                      <!-- Small Semi circle chart box begin -->
                      <div class="chartbox-container">
                        <div class="small-circle-container pb-1">
                          <h6 class="card-title">Security Awareness Mgmt </h6>

                          <div class="small-chartbox">
                            <div class="small-pie-wrapper mt-0">
                              <div
                                class="arc"
                                data-value="30"></div>
                              <span class="score">30%</span>
                            </div>
                            <div class="targetbar"></div>
                            <div class="targetvalue">80%</div>
                            <div class="small-chartbox-start">0%</div>
                            <div class="small-chartbox-end">100%</div>
                          </div>
                        </div>
                      </div>
                      <!-- Semi circle chart box end -->
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <!-- Row Ends -->
          </div>
        </div>

      </section>
      <!--  -->

      <section>
        <div class="container-fluid mt-1">
          <div class="row">
            <div class="col-lg-5 col-md-12 col-12 over-compliance-box">
              <div class="card chart-card">
                <div class="card-body">                 
                  <h6 class="card-title text-dark">
                    Top Risks List
                  </h6>
                  <div class="row">
                    <div class="col-md-10 col-10">

                      <!-- Bar Chart -->
                      <app-overallcompalaiance></app-overallcompalaiance>
                      <!-- Bar Chart script -->

                      <!-- End Bar Chart -->
                    </div>
                    <div class="col-md-2 col-2 text-end chart-value">
                      <ul>
                        <li>90%</li>
                        <li>70%</li>
                        <li>35%</li>
                        <li>85%</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 riskscale-box">
              <div class="card chart-card">
                <div class="card-body">
                  <h6 class="card-title text-dark">
                      Risk Scale
                    </h6>
                  <div class="row">
                    <div class="col-md-10 col-10">       
                        <app-riskscale></app-riskscale>              
                    </div>
                    <div class="col-md-2 col-2 text-end chart-value">
                      <ul>
                        <li>90%</li>
                        <li>70%</li>
                        <li>35%</li>
                        <li>45%</li>
                      </ul>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-lg-2 col-md-12 residual-risk-box">
              <div class="card chart-card">
                <h6 class="card-title riskrate text-dark">
                      Residual Risk Rating 
                    </h6>
                <app-riskrate></app-riskrate>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--  -->

      <!-- Risk Heat Map  and Open Risk Sections -->

      <section class="my-1">
        <div class="container-fluid mt-1">
          <div class="row">
            <!-- Heat Map Start -->
            <div class="col-lg-6 col-md-6 col-12 riskheatmap">
              <div class="card">
                <div class="card-body mb-0">
                  <div class="row">
                    <h6 class="card-title">Risk Heat Map</h6>

                    <div class="container pb-0">
                      <div class="riskheatmap pt-3">
                        <table class="table  w-100   text-center">

                          <tbody>
                            <tr>
                              <th scope="row">Severe</th>
                              <td class="bg-green border">20</td>
                              <td class="bg-yellow border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">50</td>

                            </tr>
                            <tr>
                              <th scope="row">Major</th>
                              <td class="bg-green border">100</td>
                              <td class="bg-yellow border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">50</td>

                            </tr>

                            <tr>
                              <th scope="row">Moderate</th>
                              <td class="bg-green border">30</td>
                              <td class="bg-yellow border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">50</td>

                            </tr>

                            <tr>
                              <th scope="row">Minor</th>
                              <td class="bg-green border">30</td>
                              <td class="bg-green border">30</td>
                              <td class="bg-yellow border">30</td>
                              <td class="bg-red2 border">30</td>
                              <td class="bg-red2 border">50</td>

                            </tr>
                            <tr>
                              <th scope="row">Insignificant</th>
                              <td class="bg-green border">30</td>
                              <td class="bg-green border">30</td>
                              <td class="bg-green border">30</td>
                              <td class="bg-red border">30</td>
                              <td class="bg-red border">50</td>

                            </tr>

                          </tbody>
                          <tfoot>
                            <tr>
                              <th></th>
                              <th>Rare</th>
                              <th>Unlikely</th>
                              <th>Moderate</th>
                              <th>Likely</th>
                              <th>Almost Certain</th>
                            </tr>
                          </tfoot>

                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- Heat Map Ends -->

            <!-- Open Risk Start -->
            <div class="col-lg-6 col-md-6 col-12 openrisk">
              <div class="card">
                <div class="card-body mb-0">
                  <div class="row mb-0">
                    <h6 class="card-title">Open Risks</h6>
                    <div class="container">
                      <div class="openrisktable">
                        <table class="table  w-100">
                          <thead>
                            <tr>
                              <th scope="col">Risk Owner Name</th>
                              <th
                                scope="col"
                                style="text-align: center;">
                                Risks Assigned
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class="bg-yellow border">Thomson Varghese</td>
                              <td class="bg-gray border">2</td>
                            </tr>
                            <tr>
                              <td class="bg-red border">Mike Thomas</td>
                              <td class="bg-gray border">8</td>
                            </tr>

                            <tr>
                              <td class="bg-red2 border">John Antony</td>
                              <td class="bg-gray border">10</td>
                            </tr>

                            <tr>
                              <td class="bg-green border">Simon George</td>
                              <td class="bg-gray border">10</td>
                            </tr>
                            <tr>
                              <td class="bg-green border">Dixon Varghese</td>
                              <td class="bg-gray border">10</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- Open Risk End -->

          </div>
        </div>
      </section>
    </main>
  </body>
</html>
