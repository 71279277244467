
import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable()
export class PolicyViolationSummaryService {

    constructor(
                private httpService: HttpService,
                private errorHandling: ErrorHandlingService) { }

    // @ts-ignore
  getData(Url :any, Method :any): Observable<any> {
        const url = Url;
        const method = Method;
        const payload = {};
        const queryParams = {};
        try {
            return this.httpService.getHttpResponse(url, method, payload, queryParams)
                    .map(response => {
                        try {
                            this.dataCheck(response);
                            return this.massageData(response);
                        } catch (error) {
                            this.errorHandling.handleJavascriptError(error);
                        }
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    dataCheck(data :any) {
        const APIStatus = this.errorHandling.checkAPIResponseStatus(data);
        if (!APIStatus.dataAvailble) {
            throw new Error('noDataAvailable');
        }
    }

    massageData(data :any): any {
        const dataValue = [];
        for (let i = 0; i < data.severityInfo.length; i++) {
            dataValue.push(data.severityInfo[i].count);
        }
        const dataObj :any= {
            'color': ['#d40325', '#f75c03', '#ffb00d', '#ffe00d',],
            'data': dataValue,
            'legend': ['Critical', 'High', 'Medium', 'Low'],
            'legendTextcolor': '#000',
            'totalCount': data.total,
            'link': true,
            'styling': {
            'cursor': 'pointer'
            }
         };
        return dataObj;
    }
}
