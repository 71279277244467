<div [ngClass]="{'tagging-asset-trend-container': !dataLoaded}">
  <h6 *ngIf="!dataLoaded" class="asset-header">Trend Of Total And Compliant Assets</h6>
    <app-multiline-trend *ngIf="dataLoaded" [id]="'taggingAssetsOverview'" [showArea]="false" (error)="setError($event)"
      [showLegend]="true" [yAxisLabel]="'Assets'" [graphWidth]="graphWidth" [subHeadTitle]="'Trend Of Total And Compliant Assets'"
      [graphLinesData]="graphData"></app-multiline-trend>
  <div *ngIf="!dataLoaded && !error" class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div>

  <div *ngIf='error' class="error_handling">
    <app-error-message [selectedValue]="errorMessage"></app-error-message>
  </div>
</div>