import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { RefactorFieldsService } from 'src/app/shared/services/refactor-fields.service';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../services/admin.service';
import { GrcService } from '../../services/grc.service';

@Component({
  selector: 'app-risk-impact',
  templateUrl: './risk-impact.component.html',
  styleUrls: ['./risk-impact.component.css']
})
export class RiskImpactComponent implements OnInit {

  breadcrumbArray: any = [''];
  breadcrumbLinks: any = [''];
  breadcrumbPresent: any = '';
  pageTitle: string = '';
  allSeverity: any = [];
  outerArr: any = [];
  dataLoaded: boolean = false;
  errorMessage: any;
  // showingArr: any = ['policyName', 'policyId', 'policyDesc'];
  allColumns: any = [];
  totalRows: number = 0;
  currentBucket: any = [];
  bucketNumber: number = 0;
  firstPaginator: number = 1;
  lastPaginator: number=0;
  currentPointer: number = 0;
  seekdata: boolean = false;
  showLoader: boolean = true;

  paginatorSize: number = 5;
  isLastPage : boolean =false; 
  isFirstPage : boolean =false; 
  totalPages : number=0;
  pageNumber : number = 0;

  searchTxt: string = '';
  dataTableData: any = [];
  tableDataLoaded: boolean = false;
  filters: any = [];
  searchCriteria: any;
  filterText: any = {};
  errorValue: number = 0;
  showGenericMessage: boolean = false;
  dataTableDesc: string = '';
  urlID: string = '';
  public labels: any;
  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  private previousUrl: any = '';
  urlToRedirect: any = '';
  private pageLevel = 0;
  public backButtonRequired  :any;
  mandatory: any;
  riskImpactdata: any = [];
  rowHeaders: any = [];
  private routeSubscription: Subscription|any;
  private getKeywords: Subscription |any;
  private previousUrlSubscription: Subscription|any;
  private downloadSubscription: Subscription |any;
  private riskImpactSubscription: Subscription |any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private refactorFieldsService: RefactorFieldsService,
    private workflowService: WorkflowService,
    private routerUtilityService: RouterUtilityService,
    private breadCurmbService: BreadcrumbService,
    private grcService:GrcService,
  ) {

    this.routerParam();
    this.updateComponent();
  }


  ngOnInit() {
    this.urlToRedirect = this.router.routerState.snapshot.url;
    this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(
      this.pageLevel
    );
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    this.getData();
  }

  // dataMarshalling(dataToMarshall:any) {
  //   let fullPolicies = [];
  //   for (var index = 0; index < dataToMarshall.length; index++) {
  //     let policyItem:PolicyItem={      
  //       createdDate:dataToMarshall[index][0],
  //       modifiedDate:dataToMarshall[index][1],
  //       resolution:dataToMarshall[index][2],
  //       policyDesc:dataToMarshall[index][3],
  //       policyId:dataToMarshall[index][4],
  //       policyUrl:dataToMarshall[index][5],
  //       policyVersion:dataToMarshall[index][6],      
  //       policyName:dataToMarshall[index][7],
  //       numberOfRules:dataToMarshall[index][8],
  //     };
  //     fullPolicies.push(policyItem);
  //   }
  //   return fullPolicies;

  // }


  nextPage() {
    try {
      if (!this.isLastPage) {
        this.pageNumber++;
        this.showLoader = true;
        this.getData();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  prevPage() {
    try {
      if (!this.isFirstPage) {
        this.pageNumber--;
        this.showLoader = true;
        this.getData();
      }
      
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
  
  getData() {
    try {
      if (this.riskImpactSubscription) {
        this.riskImpactSubscription.unsubscribe();
      }
      var queryParams:{ from: number; size: number} = {
        from: this.pageNumber, 
        size: this.paginatorSize
       };
      const riskImpactgUrl = environment.riskImpactRating.url;
      const riskImpactMethod = environment.riskImpactRating.method;
      this.errorValue = 0;
      this.riskImpactSubscription = this.grcService
        .executeHttpAction( riskImpactgUrl, riskImpactMethod, {}, queryParams)
        .subscribe(
          response => {
            // this.showGenericMessage = false;
            try {
              this.errorValue = 1;
              this.searchCriteria = undefined;
              this.tableDataLoaded = true;
              const data = response[0];
              this.rowHeaders = response[0];
              this.dataTableData = data;
              this.showLoader = false;
              // this.dataLoaded = true;
              if (data === undefined) {
                this.errorValue = -1;
                this.outerArr = [];
                this.allColumns = [];
                this.totalRows = 0;
              }
              if (data != undefined) {
                this.riskImpactdata = data;
                this.seekdata = false;
                this.totalRows = data.total;
                this.firstPaginator =
                  this.bucketNumber * this.paginatorSize + 1;
                this.lastPaginator =
                  this.bucketNumber * this.paginatorSize + this.paginatorSize;
                this.currentPointer = this.bucketNumber;
                if (this.lastPaginator > this.totalRows) {
                  this.lastPaginator = this.totalRows;
                }
                // const updatedResponse = this.massageData(this.riskImpactdata);
              //  const test = this.getNamesWithHighestCount(this.riskImpactdata)
                // this.currentBucket[this.bucketNumber] = updatedResponse;
                // this.processData(updatedResponse);
              }
            } catch (e) {
              this.errorValue = 0;
              this.errorValue = -1;
              this.outerArr = [];
              // this.dataLoaded = true;
              this.seekdata = true;
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          error => {
            // this.showGenericMessage = true;
            this.errorValue = -1;
            this.outerArr = [];
            // this.dataLoaded = true;
            this.seekdata = true;
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.showLoader = false;
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  /*
    * This function gets the urlparameter and queryObj 
    *based on that different apis are being hit with different queryparams
    */
  routerParam() {
    try {
      // this.filterText saves the queryparam
      let currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;

        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        delete this.queryParamsWithoutFilter['filter'];

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        this.urlID = this.FullQueryParams.TypeAsset;
        //check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  /**
   * This function get calls the keyword service before initializing
   * the filter array ,so that filter keynames are changed
   */

  updateComponent() {
    this.outerArr = [];
    this.searchTxt = '';
    this.currentBucket = [];
    this.bucketNumber = 0;
    this.firstPaginator = 1;
    this.showLoader = false;
    this.currentPointer = 0;
    this.dataTableData = [];
    this.tableDataLoaded = false;
    this.dataLoaded = false;
    this.seekdata = false;
    this.errorValue = 0;
    this.showGenericMessage = false;
    this.getData();
  }

  navigateBack() {
    try {
      this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  massageData(data :any) {    
    let refactoredService = this.refactorFieldsService;
    let newData:any = [];
    data.map(function(data:any) {
      let keysTobeChanged = Object.keys(data);
      let newObj:any = {};
      keysTobeChanged.forEach(element => {
        var elementnew =
          refactoredService.getDisplayNameForAKey(
            element
          ) || element;
        newObj = Object.assign(newObj, { [elementnew]: data[element] });
      });
      newData.push(newObj);
    });
    return newData;
  }

  processData(data:any) {
    try {
      var innerArr:any = {};
      var totalVariablesObj :any= {};
      var cellObj = {};
      var magenta = '#e20074';
      var green = '#26ba9d';
      var red = '#f2425f';
      var orange = '#ffb00d';
      var yellow = 'yellow';
      this.outerArr = [];
      var getData = data;

      if (getData.length) {
        var getCols:any = Object.keys(getData[0]);
      } else {
        this.seekdata = true;
      }

      for (var row = 0; row < getData.length; row++) {
        innerArr = {};
        for (var col = 0; col < getCols.length; col++) {
          // if (getCols[col].toLowerCase() == 'actions') {
          //   cellObj = {
          //     link: true,
          //     properties: {
          //       'text-shadow': '0.33px 0',
          //       'color': '#ed0295'
          //     },
          //     colName: getCols[col],
          //     hasPreImg: false,
          //     valText: 'Edit',
          //     imgLink: '',
          //     text: 'Edit',
          //     statusProp: {
          //       'color': '#ed0295'
          //     }
          //   };
          // } else {
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: getCols[col],
              hasPreImg: false,
              imgLink: '',
              text: getData[row][getCols[col]],
              valText: getData[row][getCols[col]]
            };
          // }
          innerArr[getCols[col]] = cellObj;
          totalVariablesObj[getCols[col]] = '';
        }
        this.outerArr.push(innerArr);
      }
      if (this.outerArr.length > getData.length) {
        var halfLength = this.outerArr.length / 2;
        this.outerArr = this.outerArr.splice(halfLength);
      }
      this.allColumns = Object.keys(totalVariablesObj);
      this.allColumns = ['Name','', '1 - Negligible', '2 - Low', '3 - Medium', '4 - High', '5 Very High', 'Total'];
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToCreatePolicy() {
    try {
      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      this.router.navigate(['../create-policy'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {
        }
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToDetails(row:any) {
    if(row.col === 'Actions') {
      try {
       this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
        this.router.navigate(['../create-policy'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: {
            policyId : row.row['Policy Id'].text
          }
        });
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.logger.log('error', error);
      }
    }
  }

  searchCalled(search:any) {
    this.searchTxt = search;
  }

  callNewSearch() {
    this.bucketNumber = 0;
    this.currentBucket = [];
    this.getData();
  }

  ngOnDestroy() {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }
      if (this.previousUrlSubscription) {
        this.previousUrlSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}
