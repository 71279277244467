<div class="container-fluid py-1">
    <div class="row">
        <div class="row">
            <div class="col-md-3 mb-4">
                <h6 class="card-title"></h6>
            </div>
        </div>
    </div>
    <div class="floating-widgets-container ">
        <section>
            <div>
                <div class="widget-wrapper relative ">
                    <div class="data-table-wrap relative ">
                        <div class="sub-head ">
                            <div class="row">
                                <div class="d-flex">
                                    <h6>{{stepTitle}} </h6>
                                    <div class="ms-auto" *ngIf="!isCreate && stepIndex==3 && showWidget">
                                        <ngx-select #targetType class="select-width" name="targetType" [(ngModel)]="targetTypeValue"
                                            [allowClear]="true" [items]="remainingTargetTypes" placeholder="Select Target Type" required>
                                        </ngx-select>
                                        <button class="btn add-btn" [disabled]="targetTypeValue=='' || targetTypeValue.length==0"
                                            (click)="addTagetType(targetTypeValue)">+ Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-stepper-horizontal orange">
                            <div class="md-step active">
                                <div class="md-step-circle"><span class="fa fa-arrows"><img
                                            src="/assets/icons/groups.svg"></span></div>
                                <div class="md-step-title">Group Details</div>
                                <div class="md-step-bar-left"></div>
                                <div class="md-step-bar-right" [ngClass]="{'md-step-active': (stepIndex>0)}"></div>
                            </div>
                            <div class="md-step" [ngClass]="{'active': (stepIndex>0)}" *ngIf="isCreate">
                                <div class="md-step-circle"><span class="fa fa-tasks"><img
                                            src="/assets/icons/domain.svg"></span></div>
                                <div class="md-step-title">Domain Details</div>
                                <div class="md-step-bar-left" [ngClass]="{'md-step-active': (stepIndex>0)}"></div>
                                <div class="md-step-bar-right" [ngClass]="{'md-step-active': (stepIndex>1)}"></div>
                            </div>
                            <div class="md-step" [ngClass]="{'active': (stepIndex>1)}" *ngIf="isCreate">
                                <div class="md-step-circle"><span class="fa fa-circle"><img
                                    src="/assets/icons/target.svg"></span></div>
                                <div class="md-step-title">Target Details</div>
                                <div class="md-step-bar-left" [ngClass]="{'md-step-active': (stepIndex>1)}"></div>
                                <div class="md-step-bar-right" [ngClass]="{'md-step-active': (stepIndex>2)}"></div>
                            </div>
                            <div class="md-step" [ngClass]="{'active': (stepIndex>2)}">
                                <div class="md-step-circle"><span class="fa fa-tasks"><img
                                    src="/assets/icons/attribute.svg"></span></div>
                                <div class="md-step-title">Attribute Details</div>
                                <div class="md-step-bar-left" [ngClass]="{'md-step-active': (stepIndex>2)}"></div>
                                <div class=" md-step-bar-right "></div>
                            </div>
                        </div>
                        <div [hidden]="hideContent">
                            <div class="data-content group-details">
                                <div class="pacman-progress" [hidden]="!assetLoader">
                                    <div class="title">
                                        Please Wait...
                                    </div>
                                    <div class="pacman-alert-message">
                                        <span class="highlight-pink">{{assetLoaderTitle}}</span> is been loading...
                                    </div>
                                </div>
                                <div class="pacman-progress pacman-alert" [hidden]="!assetLoaderFailure">
                                    <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                        <span class="sa-x-mark animateXMark">
                                            <span class="sa-line sa-left"></span>
                                            <span class="sa-line sa-right"></span>
                                        </span>
                                    </div>
                                    <div class="title">
                                        Loading Failed!!!
                                    </div>

                                    <div class="pacman-alert-message">
                                        Failed in loading <span class="highlight-pink small">{{assetLoaderTitle}}</span>
                                        ...
                                    </div>
                                    <button *ngIf="isCreate" class="sa-x-btn" type="button"
                                        (click)="closeAssetErrorMessage()">
                                        <div><i class="fa fa-remove"></i> Close</div>
                                    </button>
                                    <button *ngIf="!isCreate" class="sa-x-btn" type="button" (click)="navigateBack()">
                                        <div><i class="fa fa-remove"></i> Close</div>
                                    </button>
                                </div>
                                <div class="formcontainerwrapper" [hidden]="pageContent[0].hide">
                                    <div class="asset-formctn">
                                        <div class="formctnleft">
                                            <div class="input-box group-div" *ngIf="!isCreate">
                                                <div class="heading-gray">Group Name</div>
                                                <div class="text-value">{{groupName}}</div>
                                            </div>
                                            <div class="input-form" *ngIf="isCreate">
                                                <div class="heading-gray">Group Name *
                                                    <span *ngIf="isGroupNameValid==0" class="not-available">(Not Available)</span>
                                                    <span *ngIf="isGroupNameValid==1" class="available">(Available)</span>
                                                </div>
                                                <input type="text" name="groupName" class="input-field" placeholder="Enter the Group Name" [(ngModel)]="assetForm.groupName" (input)="isGroupNameAvailable($any($event).target.value)" autocomplete="off">
                                            </div>
                                            
                                            <div class="input-box">
                                                <div class="heading-gray">Display Name *</div>
                                                <input type="text" name="displayName" class="input-field" placeholder="Enter the Display Name" [(ngModel)]="assetForm.displayName" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="formctnright">
                                            <div class="input-box">
                                                <div class="heading-gray">Type *</div>
                                                <input type="text" name="type" class="input-field" placeholder="Enter Type" [(ngModel)]="assetForm.type" autocomplete="off">
                                            </div>
                                            <div class="bm10 input-box">
                                                <div class="heading-gray">Created By *</div>
                                                <input type="text" name="createdBy" class="input-field" placeholder="Enter Created By" [(ngModel)]="assetForm.createdBy" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="formctn">
                                            <div class="bm10 input-box">
                                                <div class="heading-gray">Description</div>
                                                    <textarea name="description" rows="4" class="input-field textarea" placeholder="Enter Group Description" [(ngModel)]="assetForm.description"></textarea>
                                            </div>
                                            <div class="input-box">
                                                <input type="checkbox" id="test5" name="isDisplayAssetGroupsEnabled" [checked]="assetForm.visible" [(ngModel)]="assetForm.visible">
                                                <label for="test5" name="isDisplayAssetGroupsEnabled" class="bm30">Display Asset Groups</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="formcontainerwrapper" *ngIf="!pageContent[1].hide ">
                                    <div class="attribute-contents asset-formctn">
                                        <div class="outer-div">
                                            <div class="list-select inner-div attrlist">
                                                <h1>Available Domains</h1>
                                                <button class="move-btn move-btn-first" type="button" (click)="moveAllItemsToRight()" [disabled]="availableItems.length==0"><i class="bi-arrow-right"></i><i class="bi-arrow-right"></i></button>
                                                <button class="move-btn" type="button" (click)="moveItemToRight()" [disabled]="availChoosedItemsCount==0"><i class="bi-arrow-right"></i></button>
                                                <input type="text" class="filter-box" placeholder="Filter Values" [(ngModel)]="searchAvailableDomainTerms" (ngModelChange)="searchAvailableDomains()">
                                                <ul>
                                                    <li *ngFor="let availableItem of availableItems; let idx = index" (click)="onClickAvailableItem(idx, availableItem, availableItem.domainName)" [ngClass]="{selected: this.availChoosedItems[idx]}">{{availableItem.domainName}}</li>
                                                    <div class="not-found" *ngIf="searchAvailableDomainTerms!='' && availableItems.length==0">
                                                        <div class="not-found-image"></div>
                                                        <div class="not-found-title">
                                                            No values matching for :
                                                        </div>
                                                        <div class="highlight-pink not-found-title">
                                                            <b>{{searchAvailableDomainTerms}}</b>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div class="exchange list-select inner-div emptydiv">
                                                <ul>
                                                    <i class="fa fa-exchange"></i>
                                                </ul>
                                            </div>

                                            <div class="list-select inner-div attrlist">
                                                <h1>Selected Domains</h1>
                                                <button class="move-btn move-btn-first" type="button" (click)="moveAllItemsToLeft()" [disabled]="selectedItems.length==0"><i class="bi-arrow-left"></i><i class="bi-arrow-left"></i></button>
                                                <button class="move-btn" type="button" (click)="moveItemToLeft()" [disabled]="selectChoosedItemsCount==0"><i class="bi-arrow-left"></i></button>
                                                <input class="filter-box" placeholder="Filter Values" [(ngModel)]="searchSelectedDomainTerms" (ngModelChange)="searchSelectedDomains()">
                                                <ul>
                                                    <li *ngFor="let selectedItem of selectedItems; let idx = index" (click)="onClickSelectedItem(idx, selectedItem, selectedItem.domainName)" [ngClass]="{selected: this.selectChoosedItems[idx]}">{{selectedItem.domainName}}</li>
                                                    <div class="not-found" *ngIf="searchSelectedDomainTerms!=='' && selectedItems.length==0 && selectedItemsCopy.length!=0">
                                                        <div class="not-found-image"></div>
                                                        <div class="not-found-title">
                                                            No values matching for :
                                                        </div>
                                                        <div class="highlight-pink not-found-title">
                                                            <b>{{searchSelectedDomainTerms}}</b>
                                                        </div>
                                                    </div>
                                                    <div class="not-selected not-found" *ngIf="selectedItems.length==0 && selectedItemsCopy.length==0">
                                                        <div class="not-selected-image"></div>
                                                        <div class="not-found-title">
                                                            No domains selected!!!
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="formcontainerwrapper" *ngIf="!pageContent[2].hide ">
                                    <div class="attribute-contents asset-formctn">
                                        <div class="outer-div">
                                            <div class="list-select inner-div attrlist">
                                                <h1>Available Targets</h1>
                                                <button class="move-btn move-btn-first" type="button" (click)="moveTdAllItemsToRight()" [disabled]="availableTdItems.length==0"><i class="bi-arrow-right"></i><i class="bi-arrow-right"></i></button>
                                                <button class="move-btn" type="button" (click)="moveTdItemToRight()" [disabled]="availTdChoosedItemsCount==0"><i class="bi-arrow-right"></i></button>
                                                <input type="text" class="filter-box" placeholder="Filter Values" [(ngModel)]="searchAvailableTargetTerms" (ngModelChange)="searchAvailableTargets()">
                                                <ul>
                                                    <li *ngFor="let availableTdItem of availableTdItems; let idx = index" (click)="onClickAvailableTdItem(idx, availableTdItem, availableTdItem.targetName)" [ngClass]="{selected: this.availTdChoosedItems[idx]}">{{availableTdItem.targetName}}</li>
                                                    <div class="not-found" *ngIf="searchAvailableTargetTerms!='' && availableTdItems.length==0">
                                                        <div class="not-found-image"></div>
                                                        <div class="not-found-title">
                                                            No values matching for :
                                                        </div>
                                                        <div class="highlight-pink not-found-title">
                                                            <b>{{searchAvailableTargetTerms}}</b>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div class="exchange list-select inner-div">
                                                <ul>
                                                    <i class="fa fa-exchange"></i>
                                                </ul>
                                            </div>

                                            <div class="list-select inner-div attrlist">
                                                <h1>Selected Targets</h1>
                                                <button class="move-btn move-btn-first" type="button" (click)="moveTdAllItemsToLeft()" [disabled]="selectedTdItems.length==0"><i class="bi-arrow-left"></i><i class="bi-arrow-left"></i></button>
                                                <button class="move-btn" type="button" (click)="moveTdItemToLeft()" [disabled]="selectTdChoosedItemsCount==0"><i class="bi-arrow-left"></i></button>
                                                <input class="filter-box" placeholder="Filter Values" [(ngModel)]="searchSelectedTargetTerms" (ngModelChange)="searchSelectedTargets()">
                                                <ul>
                                                    <li *ngFor="let selectedTdItem of selectedTdItems; let idx = index" (click)="onClickSelectedTdItem(idx, selectedTdItem, selectedTdItem.targetName)" [ngClass]="{selected: this.selectTdChoosedItems[idx]}">{{selectedTdItem.targetName}}</li>
                                                    <div class="not-found" *ngIf="searchSelectedTargetTerms!=='' && selectedTdItems.length==0 && selectedTdItemsCopy.length!=0">
                                                        <div class="not-found-image"></div>
                                                        <div class="not-found-title">
                                                            No values matching for :
                                                        </div>
                                                        <div class="highlight-pink not-found-title">
                                                            <b>{{searchSelectedTargetTerms}}</b>
                                                        </div>
                                                    </div>
                                                    <div class="not-selected not-found" *ngIf="selectedTdItems.length==0 && selectedTdItemsCopy.length==0">
                                                        <div class="not-selected-image"></div>
                                                        <div class="not-found-title">
                                                            No targets selected!!!
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="formcontainerwrapper" *ngIf="!pageContent[3].hide ">
                                    <div class="attribute-contents asset-formctn">
                                        <div class="outer-div">
                                            <div class="inner-div attrlist" *ngFor="let attributeDetail of allAttributeDetails; let idx = index;">
                                                <h1>{{attributeDetail.targetName}}</h1>
                                                <div class="attrspacer"></div>
                                                <h3 class="added" *ngIf="!attributeDetail.includeAll">{{attributeDetail.attributes.length}}</h3>
                                                <h3 class="added" *ngIf="attributeDetail.includeAll">{{attributeDetail.allAttributesName.length}}</h3>
                                                <h2 class="text">Attributes added</h2>
                                                <div class="btnctn"><button class="attrbutton" (click)="openAttributeConfigure(attributeDetail, idx)" [disabled]="attributeDetail.includeAll" data-bs-toggle="modal" data-bs-target="#modalForm">CONFIG</button></div>
                                                <div class="include-all-checkbox btnctn">
                                                    <input type="checkbox" id="{{attributeDetail.targetName}}" (change)="includeAllAttributes(attributeDetail, idx)" name="{{attributeDetail.targetName}}" [checked]="attributeDetail.includeAll">
                                                    <label for="{{attributeDetail.targetName}}">Include All</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="attributes-not-found pacman-alert"
                                        *ngIf="allAttributeDetails.length==0">
                                        <div class="not-found-image"></div>
                                        <div class="attributes-not-found-title">
                                            Target Types not found for :
                                        </div>
                                        <div class="highlight-pink attributes-not-found-title">
                                            <b>{{searchTerm}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="data-table-paginator-wrap flex flex-center flex-align-center createsubmit">
                                <a class="btn btn-border me-2 btn-green">
                                    <button class="submit-btn" (click)="prevStep()" [disabled]="!(stepIndex>0) || assetLoader"><i
                                            class="bi-arrow-left fs-5"></i>
                                        Prev</button>
                                </a>
                                <a class="btn btn-border me-2 btn-green" *ngIf="stepIndex<3">
                                    <button class="submit-btn " (click)="nextStep()" [disabled]="isStepDisabled(stepIndex) || assetLoader">Next
                                        <i class="bi-arrow-right fs-5"></i></button>
                                </a>
                                <a class="btn btn-border me-2 btn-green" *ngIf="stepIndex==3 && isCreate">
                                    <button class="submit-btn " (click)="create(assetForm)"><i
                                            class="bi bi-plus fs-5"></i> SUBMIT</button>
                                </a>
                                <a class="btn btn-border me-2 btn-green" *ngIf="stepIndex==3 && !isCreate">
                                    <button class="submit-btn " (click)="update(assetForm)"><i
                                            class="bi bi-check fs-5"></i> Update</button>
                                </a>
                                <a class="btn btn-border me-2 cancelbutton">
                                    <button class="submit-btn " type="button" (click)="navigateBack()"><i
                                            class="bi bi-x fs-5"></i> Cancel</button>
                                </a>
                            </div>
                        </div>
                        <div class="pacman-alert" *ngIf="isAssetGroupFailed">
                            <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                <span class="sa-x-mark animateXMark">
                                    <span class="sa-line sa-left"></span>
                                <span class="sa-line sa-right"></span>
                                </span>
                            </div>
                            <div class="pacman-alert-message">
                                {{failedTitleStart}} <span class="highlight-pink">{{highlightedText}}</span> {{failedTitleEnd}}
                            </div>
                            <div class="pacman-alert-button">
                                <a class="btn btn-border">
                                <button class="submit-btn mt-2" type="button" (click)="closeErrorMessage()"><i class="fa fa-remove"></i> Close</button></a>
                            </div>
                        </div>
                        <div class="pacman-alert" *ngIf="isAssetGroupSuccess">
                            <div class="sa-icon sa-success animate">
                                <span class="sa-line sa-tip animateSuccessTip"></span>
                                <span class="sa-line sa-long animateSuccessLong"></span>
                                <div class="sa-placeholder"></div>
                                <div class="sa-fix"></div>
                            </div>
                            <div class="pacman-alert-message">
                                {{successTitleStart}} <span class="highlight-pink">{{highlightedText}}</span> {{successTitleEnd}}
                            </div>
                            <div class="pacman-alert-button">
                                <a class="btn btn-border">
                                <button class="submit-btn mt-2" type="button" (click)="navigateBack()"><i class="fa fa-remove"></i> Close</button></a>
                            </div>
                        </div>
                        <div class="pacman-progress" *ngIf="assetGroupLoader">
                            <div class="title">
                                Please Wait...
                            </div>
                            <div class="pacman-alert-message">
                                {{progressText}} <span class="highlight-pink">{{highlightedText}}</span> is in progress...
                            </div>
                        </div>
                        <section>
                            <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                            <div class="modal-dialog">
                                <div class="modal-content ">
                                    <div class="modal-header bg-dark text-white">
                                        <h5 class="modal-title" id="exampleModalLabel">Configure Attributes</h5>
                                        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close" (click)="closeAttributeConfigure()"><i class="bi bi-x-lg text-white"></i></button>
                                    </div>
                                    <div class="modal-body">
                                            <div class="mb-3 pt-2">
                                              <div class="heading-gray">
                                                <div class="row">
                                                  <div class="col-5 pe-1">Select Name :
                                                    <ngx-select #attributeNameElement [allowClear]="true" name="attributeName" [(ngModel)]="attributeName" (select)='getTargetTypeAttributeValues($event)' [items]="selectedAttributeDetails" placeholder="Select Name" required>
                                                    </ngx-select>
                                                    </div>
                                                  <div class="col-5"> Select Value :
                                                    <ngx-select #attributeValueElement [allowClear]="true" name="attributeValue" [(ngModel)]="attributeValue" (select)='selectAttributes($event)' (input)="typedAttributes($any($event).target.value)" [items]="targetTypeAttributeValues" placeholder="Select Value"
                                                    required>
                                                </ngx-select>
                                                </div>
                                                <div class="col-2 px-0 pt-3 add-div">
                                                    <button class="btn add-btn"
                                                        [disabled]="attributeValue=='' || attributeName.length==0 || isAttributeAlreadyAdded !== -1"
                                                        (click)="addAttributes(attributeName, attributeValue)"><i class="fa fa-plus"></i>Add</button>
                                                </div>
                                                 </div>
                                                 <div class="row px-2">
                                                   <div class="col-12  border rounded-2 mt-2 px-0 ms-1 me-1">                    
                                                    <table class="table table-hover px-0 table-text">
                                                      <thead>
                                                    <tr>
                                                      <th>Attribute Name</th>
                                                      <th scope="col">Attribute Value</th>
                                                      <th scope="col">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody *ngIf="selectedIndex!==-1">
                                                        <!-- <div *ngFor="let attribute of allAttributeDetails[selectedIndex].attributes; let idx = index"> -->
                                                            <tr *ngFor="let attribute of allAttributeDetails[selectedIndex].attributes; let idx = index">
                                                                <td>{{attribute.name}}</td>
                                                                <td>{{attribute.value}}</td>
                                                                <td><a (click)="deleteAttributes(attribute.name, idx)">Delete</a>
                                                                </td>
                                                            </tr>
                                                        <!-- </div> -->
                                                    </tbody>
                                                    </table>
                                                      </div>
                                                 </div>            
                                               </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          </section>
                        <!-- <div [@fadeInOut]="state" class="overlay" (click)="closeAttributeConfigure()"></div>
                        <div class="side-bar-window configure-attributes" [@slideInOut]="menuState">
                            <div class="data-table-wrap relative">
                                <div class="sub-head">
                                    <div class="flex flex-between flex-align-center ">
                                        <div class="sub-head-title capitalize flex flex-align-center after-load ">
                                            <div class="header-text ">Configure Attributes</div>
                                        </div>
                                        <div class="popup">
                                            <a class="close fa fa-times-circle" (click)="closeAttributeConfigure()"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="data-content-attr data-content">
                                    <div class="">
                                        <div class="asset-formctn">
                                            <div class="formctn">
                                                <div class="input-box">
                                                    <ul class="parameters">
                                                        <label>Select Name :</label>
                                                        <ngx-select #attributeNameElement [allowClear]="true" name="attributeName" [(ngModel)]="attributeName" (select)='getTargetTypeAttributeValues($event)' [items]="selectedAttributeDetails" placeholder="Select Name" required>
                                                        </ngx-select>
                                                    </ul>
                                                    <ul class="parameters">
                                                        <label>Select Value : 
                                                            <span *ngIf="isAttributeAlreadyAdded==0" class="not-available">(Already Added)</span>
                                                            <span *ngIf="isAttributeAlreadyAdded==1" class="available"></span>
                                                        </label>
                                                        <ngx-select #attributeValueElement [allowClear]="true" name="attributeValue" [(ngModel)]="attributeValue" (select)='selectAttributes($event)' (input)="typedAttributes($any($event).target.value)" [items]="targetTypeAttributeValues" placeholder="Select Value"
                                                            required>
                                                        </ngx-select>
                                                    </ul>
                                                    <button class="tmbutton btm30 ib" [disabled]="attributeValue=='' || attributeName.length==0 || isAttributeAlreadyAdded !== -1" (click)="addAttributes(attributeName, attributeValue)">+ Add</button>
                                                </div>
                                            </div>
                                            <div class="data-table-content">
                                                <div class='data-table-head-outer'>
                                                    <div class="data-table-head flex flex-around flex-align-center">
                                                        <div class="head-cells table-cells">
                                                            Attribute Name
                                                            <span class="relative"></span>
                                                        </div>
                                                        <div class="head-cells table-cells">
                                                            Attribute Value
                                                            <span class="relative"></span>
                                                        </div>
                                                        <div class="head-cells table-cells">
                                                            Action
                                                            <span class="relative"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class='data-table-inner-wrap relative' *ngIf="selectedIndex!==-1">
                                                    <div currentTable class="data-table-inner-content data-table-current">
                                                        <div class="data-table-rows flex flex-around flex-align-center" *ngFor="let attribute of allAttributeDetails[selectedIndex].attributes; let idx = index">
                                                            <div class="row-cells table-cells flex flex-align-center">
                                                                <div class="column-wrapper">
                                                                    <div class='nowrap-ellipsis'>
                                                                        {{attribute.name}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row-cells table-cells flex flex-align-center">
                                                                <div class="column-wrapper">
                                                                    <div class='nowrap-ellipsis'>
                                                                        {{attribute.value}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row-cells table-cells flex flex-align-center">

                                                                <div class="column-wrapper">
                                                                    <div class='nowrap-ellipsis'>
                                                                        <a (click)="deleteAttributes(attribute.name, idx)">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
