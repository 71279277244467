import {CommonModule} from "@angular/common";
import {AssetsRoutingModule} from "./assets-routing.module";
import {SharedModule} from "../../../shared/shared.module";
import {AssetsComponent} from "./assets.component";
import {AssetListComponent} from "./asset-list/asset-list.component";
import { NgModule } from "@angular/core";
import { AwsResourceDetailsComponent } from "../../secondary-components/aws-resource-details/aws-resource-details.component";
import { AssetDashboardComponent } from "./asset-dashboard/asset-dashboard.component";
import { ViewAllResourcesComponent } from "../../secondary-components/view-all-resources/view-all-resources.component";
import { InventoryContainerComponent } from "../../secondary-components/inventory-container/inventory-container.component";
import {MultilineChartComponent} from "../../secondary-components/multiline-chart/multiline-chart.component";
import { AssetDetailsComponent } from "./asset-details/asset-details.component";
import { PacmanPolicyViolationsComponent } from "../../secondary-components/pacman-policy-violations/pacman-policy-violations.component";
import { AttributeComponent } from "../../secondary-components/attribute/attribute.component";
import { AssetSummaryComponent } from "../../secondary-components/asset-summary/asset-summary.component";
import { AssetListDetailSummaryComponent } from "../../secondary-components/asset-list-detail-summary/asset-list-detail-summary.component";
import { HostVulnerabilitiesComponent } from "../../secondary-components/host-vulnerabilities/host-vulnerabilities.component";
import { AccessGroupsComponent } from "../../secondary-components/access-groups/access-groups.component";
import { OpenPortsComponent } from "../../secondary-components/open-ports/open-ports.component";
import { InstalledSoftwaresComponent } from "../../secondary-components/installed-softwares/installed-softwares.component";
import { AssetContentsComponent } from "../../secondary-components/asset-contents/asset-contents.component";
import { MultilineAssetTrendComponent } from "../../secondary-components/multiline-asset-trend/multiline-asset-trend.component";


@NgModule({
  imports: [
     CommonModule,
    AssetsRoutingModule,
    SharedModule,

  ],
  declarations: [
    AssetsComponent,
    AssetDashboardComponent,
    AwsResourceDetailsComponent,
    ViewAllResourcesComponent,
    AssetListComponent,
    InventoryContainerComponent,
    MultilineChartComponent,
    AssetDetailsComponent,
    PacmanPolicyViolationsComponent,
    AttributeComponent,
    AssetSummaryComponent,
    AssetListDetailSummaryComponent,
    HostVulnerabilitiesComponent,
    AccessGroupsComponent,
    OpenPortsComponent,
    InstalledSoftwaresComponent,
    AssetContentsComponent,
    MultilineAssetTrendComponent
  ]
})
export class AssetsModule { }
