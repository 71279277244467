import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ComplianceIssuesComponent } from "../../secondary-components/compliance-issues/compliance-issues.component";
import { ComplianceOverviewTrendComponent } from "../../secondary-components/compliance-overview-trend/compliance-overview-trend.component";
import { IssuesCategoryComponent } from "../../secondary-components/issues-category/issues-category.component";
import { IssuesTrendHistoryComponent } from "../../secondary-components/issues-trend-history/issues-trend-history.component";
import { MultilineTrendComponent } from "../../secondary-components/multiline-trend/multiline-trend.component";
import { OverallComplianceComponent } from "../../secondary-components/overall-compliance/overall-compliance.component";
import { OverviewTaggingComponent } from "../../secondary-components/overview-tagging/overview-tagging.component";
import { OverviewVulnerabilitiesComponent } from "../../secondary-components/overview-vulnerabilities/overview-vulnerabilities.component";
import { PacmanIssuesComponent } from "../../secondary-components/pacman-issues/pacman-issues.component";
import { SelectComplianceDropdown } from "../../services/select-compliance-dropdown.service";
import { CloudNotificationsComponent } from "./cloud-notifications/cloud-notifications.component";
import { ComplianceDashboardComponent } from "./compliance-dashboard/compliance-dashboard.component";
import { ComplianceRoutingModule } from "./compliance-routing.module";
import { ComplianceComponent } from "./compliance.component";
import { IssueListingComponent } from "./issue-listing/issue-listing.component";
import { PolicyKnowledgebaseComponent } from "./policy-knowledgebase/policy-knowledgebase.component";
import { RecommendationsComponent } from "./recommendations/recommendations.component";
import { TaggingComplianceComponent } from "./tagging-compliance/tagging-compliance.component";
import { TaggingSummaryComponent } from "../../secondary-components/tagging-summary/tagging-summary.component";
import { TaggingAcrossTargetTypeComponent } from "../../secondary-components/tagging-across-target-type/tagging-across-target-type.component";
import { TargetTypeTaggingTileComponent } from "../../secondary-components/target-type-tagging-tile/target-type-tagging-tile.component";
import { TaggingComplianceTrendComponent } from "../../secondary-components/tagging-compliance-trend/tagging-compliance-trend.component";
import { TaggingAssetsTrendComponent } from "../../secondary-components/tagging-assets-trend/tagging-assets-trend.component";
import { TotalTagComplianceComponent } from "../../secondary-components/total-tag-compliance/total-tag-compliance.component";
import { ListTableComponent } from "../../secondary-components/list-table/list-table.component";
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { AllPolicyViolationsComponent } from "../../secondary-components/all-policy-violations/all-policy-violations.component";
import { DataTablesModule } from "angular-datatables";
import { PolicyTrendComponent } from "../../secondary-components/policy-trend/policy-trend.component";
import { PolicyAssetsTrendComponent } from "../../secondary-components/policy-assets-trend/policy-assets-trend.component";
import { PolicyContentSliderComponent } from "../../secondary-components/policy-content-slider/policy-content-slider.component";
import { PolicySummaryComponent } from "../../secondary-components/policy-summary/policy-summary.component";
import { PolicyKnowledgebaseDetailsComponent } from "./policy-knowledgebase-details/policy-knowledgebase-details.component";
import { PolicyViolationDescComponent } from "../../secondary-components/policy-violation-desc/policy-violation-desc.component";
import { IssueDetailsComponent } from "./issue-details/issue-details.component";
import { VulnerabilitiesComplianceComponent } from "./vulnerabilities-compliance/vulnerabilities-compliance.component";
import { TaggingInstancesTableComponent } from "../../secondary-components/tagging-instances-table/tagging-instances-table.component";
import { VulnerabilitiesComplianceTrendComponent } from "../../secondary-components/vulnerabilities-compliance-trend/vulnerabilities-compliance-trend.component";
import { VulnerabilityAgingGraphComponent } from "../../secondary-components/vulnerability-aging-graph/vulnerability-aging-graph.component";
import { VulnerabilityAssetsTrendComponent } from "../../secondary-components/vulnerability-assets-trend/vulnerability-assets-trend.component";
import { OverallVulnerabilitiesComponent } from "../../secondary-components/overall-vulnerabilities/overall-vulnerabilities.component";
import { VulnerabilityAcrossApplicationComponent } from "../../secondary-components/vulnerability-across-application/vulnerability-across-application.component";
import { VulnerabilitySummaryTableComponent } from "../../secondary-components/vulnerability-summary-table/vulnerability-summary-table.component";
import { VulnerabilityAcrossApplicationService } from "src/app/shared/services/vulnerability-across-application.service";
import { AutorefreshService } from "../../services/autorefresh.service";
import { RecommandCategoryComponent } from "../../secondary-components/recommand-category/recommand-category.component";
import { ListtableComponent } from "../../secondary-components/listtable/listtable.component";



@NgModule({
  imports: [
    CommonModule,
    ComplianceRoutingModule,
    SharedModule,
    DataTablesModule,
  ],
  declarations: [
    ComplianceComponent,
    ComplianceDashboardComponent,
    PolicyKnowledgebaseComponent,
    IssueListingComponent,
    RecommendationsComponent,
    ComplianceComponent,
    PolicyViolationDescComponent,
    IssueDetailsComponent,
    CloudNotificationsComponent,
    TaggingComplianceComponent,
    TotalTagComplianceComponent,
    ListTableComponent,
    OverallComplianceComponent,
    OverallVulnerabilitiesComponent,
    PolicyKnowledgebaseDetailsComponent,
    ComplianceIssuesComponent,
    PacmanIssuesComponent,
    IssuesCategoryComponent,
    OverviewTaggingComponent,
    OverviewVulnerabilitiesComponent,
    ComplianceOverviewTrendComponent,
    MultilineTrendComponent,
    IssuesTrendHistoryComponent,
    TaggingSummaryComponent,
    TaggingAcrossTargetTypeComponent,
    TargetTypeTaggingTileComponent,
    TaggingComplianceTrendComponent,
    TaggingAssetsTrendComponent,
    PolicyDetailsComponent,
    AllPolicyViolationsComponent,
    PolicyTrendComponent,
    PolicyAssetsTrendComponent,
    PolicyContentSliderComponent,
    PolicySummaryComponent,
    VulnerabilitiesComplianceComponent,
    TaggingInstancesTableComponent,    
    VulnerabilitiesComplianceTrendComponent,
    VulnerabilityAgingGraphComponent,
    VulnerabilityAssetsTrendComponent,
    VulnerabilityAcrossApplicationComponent ,
    VulnerabilitySummaryTableComponent,
    RecommandCategoryComponent,
    ListtableComponent
  ],
  providers: [
    SelectComplianceDropdown,
    VulnerabilityAcrossApplicationService,
    AutorefreshService
  ]
})
export class ComplianceModule {}
