import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { environment } from './../../../../../../environments/environment';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UtilsService } from '../../../../../shared/services/utils.service';
import { LoggerService } from '../../../../../shared/services/logger.service';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { FilterManagementService } from '../../../../../shared/services/filter-management.service';
import { WorkflowService } from '../../../../../core/services/workflow.service';
import { RouterUtilityService } from '../../../../../shared/services/router-utility.service';
import { AdminService } from '../../../../services/all-admin.service';
import { NgForm } from '@angular/forms';
import { UploadFileService } from '../../../../services/upload-file-service';
import { ErrorHandlingService } from '../../../../../shared/services/error-handling.service';
import { AdminUtilityService } from '../../commons/utility-service';
import { NgxSelectComponent } from 'ngx-select-ex';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-admin-update-job-execution-manager',
  templateUrl: './update-job-execution-manager.component.html',
  styleUrls: ['./update-job-execution-manager.component.css'],
  providers: [
    LoggerService,
    ErrorHandlingService,
    UploadFileService,
    AdminService,
    AdminUtilityService
  ]
})
export class UpdateJobExecutionManagerComponent implements OnInit, OnDestroy {
  @ViewChild('targetType') targetTypeSelectComponent: NgxSelectComponent | any;
  @ViewChild('jobFrequencyMonthDay') jobFrequencyMonthDayComponent: NgxSelectComponent | any;
  pageTitle = 'Update Job Execution Manager';
  allJobNames = [];
  breadcrumbArray = ['Admin', ''];
  breadcrumbLinks = ['policies', 'job-execution-manager'];
  breadcrumbPresent: any = 'Update Job Execution Manager';
  outerArr = [];
  dataLoaded = false;
  isCreate: any;
  jobId = '';
  errorMessage: any;
  showingArr = ['policyName', 'policyId', 'policyDesc'];
  allColumns = [];
  totalRows= 0;
  currentBucket = [];
  bucketNumber= 0;
  firstPaginator= 1;
  lastPaginator: any;
  currentPointer= 0;
  seekdata = false;
  showLoader = true;
  jobDetailsLoader = true;
  hideContent = false;
  hideMainContent = false;
  allMonthDays: any = [];
  allEnvironments: any = [];
  allJobParams: any = [];
  loadingContent = '';
  isJobCreationUpdationFailed = false;
  isJobCreationUpdationSuccess = false;
  jobLoaderFailure = false;
  isFileChanged = false;

  paginatorSize= 25;
  isLastPage: any;
  isFirstPage: any;
  totalPages: any;
  pageNumber= 0;

  allJobParamKeys: any = [];
  allEnvParamKeys: any = [];
  searchTxt: any = '';
  dataTableData: any = [];
  jobDetails: any = {};
  jobFrequency: any;
  jobFrequencyDay: any;
  jobFrequencyMonth: any;
  jobFrequencyMonths: any;
  jobFrequencyDays: any;
  weekName: any;
  jobFrequencyModeValue: any;
  initVals = [];
  tableDataLoaded = false;
  filters = [];
  searchCriteria: any;
  filterText = {};
  errorValue= 0;
  showGenericMessage = false;
  dataTableDesc = '';
  urlID = '';

  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  urlToRedirect = '';
  mandatory: any;
  parametersInput = { jobKey: '', jobValue: '', envKey: '', envValue: '' };
  allFrequencies:any = ['Daily', 'Hourly', 'Minutes', 'Monthly', 'Weekly'];
  allMonths = [
    { text: 'January', id: 0 },
    { text: 'February', id: 1 },
    { text: 'March', id: 2 },
    { text: 'April', id: 3 },
    { text: 'May', id: 4 },
    { text: 'June', id: 5 },
    { text: 'July', id: 6 },
    { text: 'August', id: 7 },
    { text: 'September', id: 8 },
    { text: 'October', id: 9 },
    { text: 'November', id: 10 },
    { text: 'December', id: 11 }
  ];
  isAlexaKeywordValid = -1;
  jobJarFile: any;
  currentFileUpload: File | any;
  selectedFiles: FileList | any;
  jobLoader = false;
  isJobSuccess = false;
  isJobFailed = false;

  jobType = 'jar';
  selectedFrequency = '';
  jobName: any;
  jobJarFileName = '';

  isFilterRquiredOnPage = false;
  appliedFilters: any = {
    queryParamsWithoutFilter: {}, /* Stores the query parameter ibject without filter */
    pageLevelAppliedFilters: {} /* Stores the query parameter ibject without filter */
  };
  filterArray = []; /* Stores the page applied filter array */

  public labels: any;
  private previousUrl = '';
  private pageLevel = 0;
  public backButtonRequired: any;
  private routeSubscription: Subscription;
  private previousUrlSubscription: Subscription | any;
  tenantId = 'UST_10001';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private uploadService: UploadFileService,
    private filterManagementService: FilterManagementService,
    private workflowService: WorkflowService,
    private routerUtilityService: RouterUtilityService,
    private adminService: AdminService,
    private adminUtilityService: AdminUtilityService,
    private breadCurmbService: BreadcrumbService
  ) {
    /* Check route parameter */
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
    // Fetch the required params from this object.
    });
    this.routerParam();
    this.updateComponent();
  }

  ngOnInit() {
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    this.urlToRedirect = this.router.routerState.snapshot.url;
    this.breadcrumbPresent = 'Update Job Execution Manager';
    this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(
      this.pageLevel
    );
  }

  nextPage() {
    try {
      if (!this.isLastPage) {
        this.pageNumber++;
        this.showLoader = true;
        // this.getPolicyDetails();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  prevPage() {
    try {
      if (!this.isFirstPage) {
        this.pageNumber--;
        this.showLoader = true;
        // this.getPolicyDetails();
      }

    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  updateJob(form: NgForm) {   
    this.hideMainContent = true;
    this.jobLoader = true;
    this.buildUpdateJobModel(form.value);
  }


  private buildUpdateJobModel(jobForm: any) {
    const existingJobModel = Object();
    this.jobName = jobForm.jobName;
    existingJobModel.jobName = this.jobId;
    existingJobModel.jobDesc = jobForm.jobDesc;
    existingJobModel.jobFrequency = this.buildRuleFrequencyCronJob(jobForm);
    existingJobModel.jobType = 'Manage Rule';
    existingJobModel.jobParams = this.buildJobParams();
    existingJobModel.jobExecutable = this.jobJarFileName;
    existingJobModel.isFileChanged = this.isFileChanged;
    existingJobModel.tenantId = this.tenantId;

    if (this.isFileChanged) {
      this.currentFileUpload = this.selectedFiles.item(0);
    } else {
      this.currentFileUpload = null;
    }

    const url = environment.updateJob.url;
    const method = environment.updateJob.method;
    this.uploadService.pushFileToStorage(url, method, this.currentFileUpload, existingJobModel).subscribe((event: any) => {
      this.jobLoader = false;
      this.isJobSuccess = true;
    },
    (error: any) => {
      this.isJobFailed = true;
      this.showGenericMessage = true;
      this.errorValue = -1;
      this.outerArr = [];
      this.dataLoaded = true;
      this.seekdata = true;
      this.errorMessage = 'apiResponseError';
      this.showLoader = false;
      this.jobLoader = false;
    });
  }

  buildJobParams() {
    const jobParms = Object();
    jobParms.params = this.allJobParams;
    jobParms.environmentVariables = this.allEnvironments;
    return JSON.stringify(jobParms);
  }

  getRuleRestUrl(jobForm: any) {
    const jobType = jobForm.jobType;
    if (jobType === 'Serverless') {
      return jobForm.jobRestUrl;
    } else {
      return '';
    }
  }

  buildRuleFrequencyCronJob(jobForm: any) {
    const selectedFrequencyType = jobForm.jobFrequency;
    const cronDetails = Object();
    cronDetails.interval = selectedFrequencyType;
    if (selectedFrequencyType === 'Yearly') {
      cronDetails.day = jobForm.jobFrequencyMonth[0].id;
      cronDetails.month = (jobForm.jobFrequencyMonth[0].id + 1);
    } else if (selectedFrequencyType === 'Monthly') {
      cronDetails.duration = parseInt(jobForm.jobFrequencyMonths, 10);
      cronDetails.day = parseInt(jobForm.jobFrequencyDays, 10);
    } else if (selectedFrequencyType === 'Weekly') {
      cronDetails.week = jobForm.weekName;
    } else {
      cronDetails.duration = parseInt(jobForm.jobFrequencyModeValue, 10);
    }

    return this.generateExpression(cronDetails);
  }

  generateExpression(cronDetails: any) {

    const getCronExpression = function (cronObjIns: any) {
      if (cronObjIns === undefined || cronObjIns === null) {
        return undefined;
      } else {
        const cronObjFields = ['minutes', 'hours', 'dayOfMonth', 'month', 'dayOfWeek'];
        let cronExpression = cronObjIns.minutes;
        for (let index = 1; index < cronObjFields.length; index++) {
          cronExpression = cronExpression + ' ' + cronObjIns[cronObjFields[index]];
        }
        return cronExpression;
      }
    };

    const isValid = function (cronValidity: any) {
      if (cronValidity.minutes && cronValidity.hours && cronValidity.dayOfMonth && cronValidity.month && cronValidity.dayOfWeek && cronValidity.year) {
        return true;
      }
      return false;
    };

    let cronObj = {};
    if (cronDetails.interval === 'Minutes') {
      cronObj = {
        minutes: '0/' + cronDetails.duration,
        hours: '*',
        dayOfMonth: '*',
        month: '*',
        dayOfWeek: '?',
        year: '*'
      };
    } else if (cronDetails.interval === 'Hourly') {
      cronObj = {
        minutes: '0',
        hours: '*/' + cronDetails.duration,
        dayOfMonth: '*',
        month: '*',
        dayOfWeek: '*'
        // year: '*'
      };
    } else if (cronDetails.interval === 'Daily') {
      cronObj = {
        minutes: '0',
        hours: '0',
        dayOfMonth: '1/' + cronDetails.duration,
        month: '*',
        dayOfWeek: '?',
        year: '*'
      };
    } else if (cronDetails.interval === 'Weekly') {
      cronObj = {
        minutes: '0',
        hours: '0',
        dayOfMonth: '?',
        month: '*',
        dayOfWeek: cronDetails.week,
        year: '*'
      };
    } else if (cronDetails.interval === 'Monthly') {
      cronObj = {
        minutes: '0',
        hours: '0',
        dayOfMonth: cronDetails.day,
        month: '1/' + cronDetails.duration,
        dayOfWeek: '?',
        year: '*'
      };
    }
    return getCronExpression(cronObj);
  }

  onJarFileChange(event: any) {
    this.selectedFiles = event.target.files;
    this.jobJarFileName = this.selectedFiles[0].name;
    const extension = this.jobJarFileName.substring(this.jobJarFileName.lastIndexOf('.') + 1);
    if (extension !== 'jar') {
      this.removeJarFileName();
    }
    this.isFileChanged = true;
  }

  removeJarFileName() {
    this.jobJarFileName = '';
    this.jobJarFile = '';
    this.isFileChanged = true;
  }

  closeErrorMessage() {
    this.isJobFailed = false;
    this.hideMainContent = false;
    this.hideContent = false;
  }

  openJarFileBrowser(event: any) {
    const element: HTMLElement = document.getElementById('selectJarFile') as HTMLElement;
    element.click();
  }

  addEnvironmentParameters(parametersInput: any, isEncrypted: any) {
    if (parametersInput.envKey.trim() !== '' && parametersInput.envValue.trim() !== '') {
      this.allEnvironments.push({ name: parametersInput.envKey.trim(), value: parametersInput.envValue.trim(), encrypt: isEncrypted.checked });
      this.allEnvParamKeys.push(parametersInput.envKey.trim());
      parametersInput.envKey = '';
      parametersInput.envValue = '';
      isEncrypted.checked = false;
    }
  }

  addJobParameters(parametersInput: any, isEncrypted: any) {
    if (parametersInput.jobKey.trim() !== '' && parametersInput.jobValue.trim() !== '') {
      this.allJobParams.push({ key: parametersInput.jobKey.trim(), value: parametersInput.jobValue.trim(), encrypt: isEncrypted.checked });
      this.allJobParamKeys.push(parametersInput.jobKey.trim());
      parametersInput.jobKey = '';
      parametersInput.jobValue = '';
      isEncrypted.checked = false;
    }
  }

  removeJobParameters(index: number): void {
    this.allJobParamKeys.splice(index, 1);
    this.allJobParams.splice(index, 1);
  }

  removeEnvironmentParameters(index: number): void {
    this.allEnvParamKeys.splice(index, 1);
    this.allEnvironments.splice(index, 1);
  }


  getJobDetails() {
    this.hideContent = true;
    this.jobLoader = false;
    this.jobDetailsLoader = true;
    this.loadingContent = 'loading';
    this.isJobCreationUpdationFailed = false;
    this.isJobCreationUpdationSuccess = false;
    const url = environment.jobDetailsById.url;
    const method = environment.jobDetailsById.method;
    this.adminService.executeHttpAction(url, method, {}, {jobId: this.jobId}).subscribe((jobDetailsResponse: any) => {
      this.jobDetailsLoader = false;
      this.hideContent = false;
      this.jobDetails = jobDetailsResponse[0];
      if(jobDetailsResponse[0].jobParams) {
        const jobParams = JSON.parse(jobDetailsResponse[0].jobParams);
        this.allEnvironments = jobParams.environmentVariables;
        this.allJobParams = jobParams.params;
        this.jobDetails['jobDesc'] = jobParams.jobDesc;
      }
      this.jobJarFileName = this.jobDetails['jobExecutable'];
      const frequencyforEdit = this.adminUtilityService.decodeCronExpression(this.jobDetails['jobFrequency']);
      // this.jobFrequency = [{ 'text': frequencyforEdit.interval, 'id': frequencyforEdit.interval }];
      this.jobFrequency = frequencyforEdit.interval;
      this.onSelectFrequency(frequencyforEdit.interval);

      if (frequencyforEdit.interval.toLowerCase() === 'yearly') {
        this.jobFrequencyDay = [{ text: frequencyforEdit.day, id: frequencyforEdit.day }];
        this.jobFrequencyMonth = [this.allMonths[parseInt(frequencyforEdit.month, 10) - 1]];
      } else if (frequencyforEdit.interval.toLowerCase() === 'monthly') {
        this.jobFrequencyMonths = frequencyforEdit.duration;
        this.jobFrequencyDays = frequencyforEdit.day;
      } else if (frequencyforEdit.interval.toLowerCase() === 'weekly') {
        this.weekName = frequencyforEdit.week;
      } else {
        this.jobFrequencyModeValue = frequencyforEdit.duration;
      }
    },
      (error: any) => {
        this.jobDetailsLoader = false;
        this.loadingContent = 'loading';
        this.jobLoaderFailure = false;
      });
  }

  onSelectFrequency(frequencyType: any) {
    this.selectedFrequency = frequencyType;
  }

  onSelectFrequencyMonth(selectedMonth: any) {
    this.jobFrequencyMonthDayComponent.placeholder = 'Select Day';
    if (this.jobFrequencyMonthDayComponent.active) {
      this.jobFrequencyMonthDayComponent.active.length = 0;
    }
    const monthDays = [];
    const daysCount = this.getNumberOfDays(selectedMonth.id);
    for (let dayNo = 1; dayNo <= daysCount; dayNo++) {
      monthDays.push({ id: dayNo, text: dayNo.toString() });
    }
    this.allMonthDays = monthDays;
    this.jobFrequencyMonthDayComponent.items = monthDays;
  }


  getNumberOfDays = function (month: any) {
    const year = new Date().getFullYear();
    const isLeap = ((year % 4) === 0 && ((year % 100) !== 0 || (year % 400) === 0));
    return [31, (isLeap ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  };

  /*
    * This function gets the urlparameter and queryObj
    *based on that different apis are being hit with different queryparams
   */

   routerParam() {
    try {

      const currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);

      if (currentQueryParams) {
        this.appliedFilters.queryParamsWithoutFilter = JSON.parse(JSON.stringify(currentQueryParams));
        this.jobId = this.appliedFilters.queryParamsWithoutFilter['jobId'];
        this.getJobDetails();
        delete this.appliedFilters.queryParamsWithoutFilter['filter'];
        this.appliedFilters.pageLevelAppliedFilters = this.utils.processFilterObj(currentQueryParams);
        this.filterArray = this.filterManagementService.getFilterArray(this.appliedFilters.pageLevelAppliedFilters);
      }
    } catch (error) {
      this.errorMessage = 'jsError';
      this.logger.log('error', error);
    }
  }

  updateUrlWithNewFilters(filterArr: any) {
    this.appliedFilters.pageLevelAppliedFilters = this.utils.arrayToObject(
        this.filterArray,
        'filterkey',
        'value'
    ); // <-- TO update the queryparam which is passed in the filter of the api
    this.appliedFilters.pageLevelAppliedFilters = this.utils.makeFilterObj(this.appliedFilters.pageLevelAppliedFilters);

    /**
     * To change the url
     * with the deleted filter value along with the other existing paramter(ex-->tv:true)
     */

    const updatedFilters = Object.assign(
        this.appliedFilters.pageLevelAppliedFilters,
        this.appliedFilters.queryParamsWithoutFilter
    );

    /*
     Update url with new filters
     */

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: updatedFilters
    }).then(success => {
      this.routerParam();
    });
  }

  /**
   * This function get calls the keyword service before initializing
   * the filter array ,so that filter keynames are changed
   */

  updateComponent() {
    this.outerArr = [];
    this.searchTxt = '';
    this.currentBucket = [];
    this.bucketNumber = 0;
    this.firstPaginator = 1;
    this.showLoader = true;
    this.jobDetailsLoader = true;
    this.currentPointer = 0;
    this.dataTableData = [];
    this.tableDataLoaded = false;
    this.dataLoaded = false;
    this.seekdata = false;
    this.errorValue = 0;
    this.showGenericMessage = false;
  }

  navigateBack() {
    this.router.navigate(['../admin-job-execution-manager'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
      }
    });
  }

  goToCreatePolicy() {
    try {
      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      this.router.navigate(['../create-edit-policy'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {
        }
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }
      if (this.previousUrlSubscription) {
        this.previousUrlSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}
