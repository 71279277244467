import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { AdminService } from 'src/app/pacman-features/services/admin.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { RefactorFieldsService } from 'src/app/shared/services/refactor-fields.service';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Subscription } from 'rxjs/Subscription';
import { environment } from './../../../../../environments/environment';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import * as moment from 'moment';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
  providers: [
    LoggerService,
    ErrorHandlingService,
    AdminService
  ]
})
export class RolesComponent implements OnInit {
 
  allPolicies = [];
  breadcrumbArray: any = ['Admin'];
  breadcrumbLinks: any = ['roles'];
  breadcrumbPresent: any = 'Roles';
  pageTitle: string = 'Roles';
  outerArr: any = [];
  dataLoaded = false;
  errorMessage:any;
  showingArr = ['policyName', 'policyId', 'policyDesc'];
  allColumns:any = [];
  totalRows = 0;
  currentBucket = [];
  bucketNumber = 0;
  firstPaginator = 1;
  lastPaginator:any;
  currentPointer = 0;
  seekdata = false;
  showLoader = true;

  paginatorSize = 25;
  isLastPage: boolean =false;
  isFirstPage: boolean =false;
  totalPages: number =0;
  pageNumber = 0;

  searchTxt = '';
  dataTableData = [];
  tableDataLoaded = false;
  filters = [];
  searchCriteria:any;
  filterText = {};
  errorValue = 0;
  showGenericMessage = false;
  dataTableDesc = '';
  urlID = '';
  public labels:any;
  FullQueryParams:any;
  queryParamsWithoutFilter:any;
  private previousUrl = '';
  urlToRedirect = '';
  private pageLevel = 0;
  public backButtonRequired:any;
  mandatory:any;
  private routeSubscription: Subscription|any;
  private getKeywords: Subscription |any;
  private previousUrlSubscription: Subscription|any;
  private downloadSubscription: Subscription |any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private refactorFieldsService: RefactorFieldsService,
    private workflowService: WorkflowService,
    private routerUtilityService: RouterUtilityService,
    private adminService: AdminService,
    private breadCurmbService: BreadcrumbService
  ) {

    this.routerParam();
    this.updateComponent();
  }


  ngOnInit() {
    this.urlToRedirect = this.router.routerState.snapshot.url;
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(
      this.pageLevel
    );
  }

  nextPage() {
    try {
      if (!this.isLastPage) {
        this.pageNumber++;
        this.showLoader = true;
        this.getPolicyDetails();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  prevPage() {
    try {
      if (!this.isFirstPage) {
        this.pageNumber--;
        this.showLoader = true;
        this.getPolicyDetails();
      }

    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  getPolicyDetails() {
    const url = environment.roles.url;
    const method = environment.roles.method;

    var queryParams:{ page: number; size: number; searchTerm?: any } = {
      page: this.pageNumber, 
      size: this.paginatorSize
     };
    
    if(this.searchTxt !== undefined && this.searchTxt!== '') {
      queryParams.searchTerm = this.searchTxt;
    }

    this.adminService.executeHttpAction(url, method, {}, queryParams).subscribe(reponse => {
      this.showLoader = false;
      if (reponse[0].content !== undefined) {
        this.allPolicies = reponse[0].content;
        this.errorValue = 1;
        this.searchCriteria = undefined;
        const data = reponse[0];
        this.tableDataLoaded = true;
        this.dataTableData = reponse[0].content;
        this.dataLoaded = true;
        if (reponse[0].content.length === 0) {
          this.errorValue = -1;
          this.outerArr = [];
          this.allColumns = [];
        }

        if (data.content.length > 0) {
          this.isLastPage = data.last;
          this.isFirstPage = data.first;
          this.totalPages = data.totalPages;
          this.pageNumber = data.number;

          this.seekdata = false;

          this.totalRows = data.totalElements;

          this.firstPaginator = data.number * this.paginatorSize + 1;
          this.lastPaginator = data.number * this.paginatorSize + this.paginatorSize;

          this.currentPointer = data.number;

          if (this.lastPaginator > this.totalRows) {
            this.lastPaginator = this.totalRows;
          }
          const updatedResponse = this.massageData(data.content);
          this.processData(updatedResponse);
        }
      }
    },
      error => {
        this.showGenericMessage = true;
        this.errorValue = -1;
        this.outerArr = [];
        this.dataLoaded = true;
        this.seekdata = true;
        this.errorMessage = 'apiResponseError';
        this.showLoader = false;
      });
  }

  /*
    * This function gets the urlparameter and queryObj
    *based on that different apis are being hit with different queryparams
    */
  routerParam() {
    try {
      // this.filterText saves the queryparam
      const currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;

        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        delete this.queryParamsWithoutFilter['filter'];

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        this.urlID = this.FullQueryParams.TypeAsset;
        // check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  /**
   * This function get calls the keyword service before initializing
   * the filter array ,so that filter keynames are changed
   */

  updateComponent() {
    this.outerArr = [];
    this.searchTxt = '';
    this.currentBucket = [];
    this.bucketNumber = 0;
    this.firstPaginator = 1;
    this.showLoader = true;
    this.currentPointer = 0;
    this.dataTableData = [];
    this.tableDataLoaded = false;
    this.dataLoaded = false;
    this.seekdata = false;
    this.errorValue = 0;
    this.showGenericMessage = false;
    this.getPolicyDetails();
  }

  navigateBack() {
    try {
      this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  massageData(data:any) {
    const refactoredService = this.refactorFieldsService;
    const newData:any = [];
    const formattedFilters = data.map(function (datam:any) {
      const keysTobeChanged = Object.keys(datam);
      let newObj:any = {};
      keysTobeChanged.forEach(element => {
        const elementnew =
          refactoredService.getDisplayNameForAKey(
            element
          ) || element;
        newObj = Object.assign(newObj, { [elementnew]: datam[element] });
      });
      newObj['Actions'] = '';
      newObj['Allocations'] = '';
      newData.push(newObj);
    });
    return newData;
  }

  processData(data: any) {
    try {
      var innerArr: any = {};
      var totalVariablesObj: any = {};
      let cellObj = {};
      this.outerArr = [];
      const getData = data;
      // let getCols:any;
      if (getData.length) {
        var getCols:any = Object.keys(getData[0]);
      } else {
        this.seekdata = true;
      }

      for (let row = 0; row < getData.length; row++) {
        innerArr = {};
        for (let col = 0; col < getCols.length; col++) {
          if (getCols[col].toLowerCase() === 'actions') {
            const dropDownItems = [ 'Edit Role', 'Config Users'];
            cellObj = {
              properties: {
                'text-shadow': '0.33px 0',
                'color': '#ed0295'
              },
              colName: getCols[col],
              hasPreImg: false,
              imgLink: '',
              dropDownEnabled: true,
              dropDownItems: dropDownItems,
              statusProp: {
                'color': '#ed0295'
              }
            };
          } else if (getCols[col].toLowerCase() === 'allocations') {
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: getCols[col],
              hasPreImg: false,
              imgLink: '',
              text: getData[row]['Users'].length,
              valText: getData[row]['Users'].length
            };
          } else if (getCols[col].toLowerCase() === 'modified date' || getCols[col].toLowerCase() === 'created date') {
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: moment(getCols[col]).format('DD/MM/YYYY'),
              hasPreImg: false,
              imgLink: '',
              text: moment(getData[row][getCols[col]]).format('DD/MM/YYYY'),
              valText: moment(getData[row][getCols[col]]).format('DD/MM/YYYY')
            };
          } else {
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: getCols[col],
              hasPreImg: false,
              imgLink: '',
              text: getData[row][getCols[col]],
              valText: getData[row][getCols[col]]
            };
          }
          innerArr[getCols[col]] = cellObj;
          totalVariablesObj[getCols[col]] = '';
        }
        this.outerArr.push(innerArr);
      }
      if (this.outerArr.length > getData.length) {
        const halfLength = this.outerArr.length / 2;
        this.outerArr = this.outerArr.splice(halfLength);
      }
      this.allColumns = Object.keys(totalVariablesObj);
      this.allColumns = ['Role Name', 'Created By', 'Allocations', 'Users', 'Actions'];
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToCreatRoles() {
    try {
      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      this.router.navigate(['../create-update-roles'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {
        }
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToViewUserRoles() {
    try {
      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      this.router.navigate(['../roles-allocation'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {
        }
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
  goToDetails(row:any) {
    if (row.col === 'Edit Role') {
      try {
        this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
        this.router.navigate(['../create-update-roles'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: {
            roleId: row.row['Role Id'].text,
            roleName: row.row['Role Name'].text
          }
        });
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.logger.log('error', error);
      }
    }

    if (row.col === 'Config Users') {
      try {
        this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
        this.router.navigate(['../config-users'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: {
            roleId: row.row['Role Id'].text,
            roleName: row.row['Role Name'].text
          }
        });
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.logger.log('error', error);
      }
    }
  }

  searchCalled(search:any) {
    this.searchTxt = search;
  }

  callNewSearch() {
    this.bucketNumber = 0;
    this.currentBucket = [];
    this.pageNumber = 0;
    this.paginatorSize = 25;
    this.getPolicyDetails();
  }

  ngOnDestroy() {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }
      if (this.previousUrlSubscription) {
        this.previousUrlSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}
