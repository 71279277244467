import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { GrcService } from 'src/app/pacman-features/services/grc.service';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { UploadFileService } from 'src/app/pacman-features/services/upload-file-service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { DomainTypeObservableService } from 'src/app/core/services/domain-type-observable.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { DataCacheService } from 'src/app/core/services/data-cache.service';
import { Subscription } from 'rxjs';
import { WorkflowService } from 'src/app/core/services/workflow.service';
@Component({
  selector: 'app-riskcreate',
  templateUrl: './riskcreate.component.html',
  styleUrls: ['./riskcreate.component.css']
})

export class RiskcreateComponent implements OnInit {

  constructor( private grcService:GrcService, 
    private uploadService: UploadFileService,
     public router: Router,
     private breadCurmbService: BreadcrumbService,
    private routerUtilityService: RouterUtilityService,
    private activatedRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private assetGroupObservableService: AssetGroupObservableService,
    private domainObservableService: DomainTypeObservableService,
    private loggerService: LoggerService,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private dataCacheService: DataCacheService,
    ) { }
  public hasMissingDescriptions:boolean=false;
  public hasDescriptions:boolean=false;
  public currentFileUpload: File | any;
  @ViewChild('closebutton') closebutton:any;
  breadcrumbArray: any = ['GRC'];
  breadcrumbLinks: any = ['riskcreate'];
  breadcrumbPresent: any = 'Add Risk';
  pageTitle: string = 'Add Risk ';
  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  filterText: any = {};
  provider:any = [];
  mandatory: any;
  errorMessage: any;
  urlID: string = '';
  public agAndDomain :any= {};
  private assetGroupSubscription: Subscription |any;
  private domainSubscription: Subscription |any;

  ngOnInit(): void {
    this.subscribeToAgAndDomainChange();
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)   
  }
  date = new Date();
  dateToday = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate();
  
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',    
  };
  expiryDate: any;
  getDateData(date: any): any {    
    this.expiryDate = date.singleDate.formatted;
    
  }
  dateOfRisk: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  subscribeToAgAndDomainChange() {
    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assetGroup => {
      this.agAndDomain['ag'] = assetGroup;
    });
    this.domainSubscription = this.domainObservableService.getDomainType().subscribe(domain => {
      this.agAndDomain['domain'] = domain;
      this.getProvider();
    });
  }

  
  getProvider() {
    /* Store the recently viewed asset list in stringify format */
    try {
    let recentList = '';
    recentList = this.dataCacheService.getRecentlyViewedAssetGroups();
    if (recentList) {
      const currentAGDetails = JSON.parse(recentList).filter((element:any) => element.ag === this.agAndDomain['ag']);
      this.provider = this.fetchprovider(currentAGDetails);
     }
    } catch (error) {
      this.loggerService.log('error', error);
    }
  }



  fetchprovider(assetGroupObject :any) {
    const provider :any = [];
    if (assetGroupObject.length && assetGroupObject[0].providers) {
      assetGroupObject[0].providers.forEach((element:any) => {
        provider.push(element.provider);
      });
    }
    return provider;
}

  public uploadData(e:any) {
    const bulkRiskModels: any[] = [];
    const target: DataTransfer = <DataTransfer>(<unknown>e.target);
    
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data:any = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      for (const record of data) {
        
        if (record['Risk Description'] === undefined || record['Risk Description'] === null) {
          this.hasMissingDescriptions = true;
          break;
        }else{
        const newRiskModel =  Object(); // Create a new object for each iteration
        newRiskModel.riskReferenceId = record['Risk ID'];
        newRiskModel.affectedFunctions = record['recorecordrd'];
        newRiskModel.status = record['status'];
        newRiskModel.riskCategory = record['Risk Category'];
        newRiskModel.riskMitigationStatus = record['Risk Mitigation Status'];
        newRiskModel.riskDescription =record['Risk Description'];
        newRiskModel.riskTreatment = record['Risk Treatment'];
        newRiskModel.mitigationstatus = record['Risk Mitigation Status'];
        newRiskModel.riskOwner = record['Risk Owner'];
        newRiskModel.mitigationPlan = record['Mitigation Plan'];
        newRiskModel.department = record['department'];
        newRiskModel.service = record['service'];
        // // ... (add other properties as needed)
        newRiskModel.dateOfRisk = record['Date of Risk']; // Optional chaining for dateOfRisk
        newRiskModel.riskProbability = record['Risk Probability or Likelihood'];
        newRiskModel.riskImpact = record['Risk Impact'];
        newRiskModel.riskValue = record['Risk Value'];
        newRiskModel.residualRiskLikelihood = record['Residual Risk Likelihood'];
        newRiskModel.residualImpact = record['Residual Impact'];
        newRiskModel.residualRiskRating = record['Residual Risk Rating'];

        bulkRiskModels.push(newRiskModel); 

        }
        

      }
      if(!this.hasMissingDescriptions){       
          const url = environment.AddRisk.url;
          const method = environment.AddRisk.method;
          this.currentFileUpload=target.files[0];
          const newRiskModel = Object();
          // newRiskModel.Name = 'Risk';
          // newRiskModel.Desc = 'Description';
          this.uploadService.pushFileToStorage(url, method, this.currentFileUpload, newRiskModel).subscribe((event: any) => {
            this.closebutton.nativeElement.click();      
            this.hasDescriptions = true;    
          },
          (error: any) => {
           
          });

          // this.grcService.executeHttpAction(url, method, impotyRiskModel,).subscribe((event: any) => {
          
          // },
          // (error: any) => {

          // });  
      }
      console.log(this.hasMissingDescriptions);
    };
  }
  routelink(){     
    try {
      // debugger;
      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      this.router.navigate(["/pl/grc/create-risk"], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: {
        }
      });
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log("error", error);
    }

  }
  exportAsExcel() {
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, 'Riskfilled.xlsx');
  } 

  closeAlert(){
    this.hasMissingDescriptions=false;
  }
  closeAlert1(){
    this.hasDescriptions=false;
    this.router.navigate(["/pl/grc/risk-management"])
  }
  
}
