import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { of } from 'rxjs';
import { RefactorFieldsService } from 'src/app/shared/services/refactor-fields.service';

@Injectable()
export class GrcService {

  constructor(@Inject(HttpService) private refactorFieldsService: RefactorFieldsService,
  private httpService: HttpService) { }
 
   executeHttpAction(Url:any, Method :any, payload :any, queryParams = {}): Observable<any> {
     const url = Url;
     const method = Method;
     return Observable.combineLatest(this.httpService.getHttpResponse(url, method, payload, queryParams)
     .map(response => this.massageData(response,'') )
     .catch(this.handleError)
    );
    //  try {
    //      return Observable.combineLatest(this.httpService.getHttpResponse(url, method, payload, queryParams)
    //          .map(response => this.massageData(response) )
    //          .catch(this.handleError)
    //      );
    //  } catch (error) {
    //      this.handleError(error);
    // //  }
   }
   handleError(error: any): Promise<any> {
     return Promise.reject(error.message || error);
   }
 
   massageData(data:any, noMassage:any): any {
     return data;
   }
}
