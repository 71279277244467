
import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable()
export class ComplianceCategoriesService {

    dataArray: any = {};
    constructor( @Inject(HttpService) private httpService: HttpService,
        private errorHandling: ErrorHandlingService) {}
    getData(queryParams :any, category :any, categoryUrl :any, categoryMethod :any) :any {
        const url = categoryUrl;
        const method = categoryMethod;
        const payload :any = {};
        const query :any = queryParams;
        try {
            return Observable.combineLatest(
            this.httpService.getHttpResponse(url, method, payload, query)
            .map(response => this.massageData(response, category) )
            );
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    massageData(data :any, category :any): any {
        this.dataArray[category] = data;
        this.dataArray[category].loaded = true;
        return this.dataArray;
    }

}



