

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs/ReplaySubject';

@Injectable()

export class RecentlyViewedObservableService {

    private subject = new ReplaySubject<string>(0);

    constructor() {
    }

    updateRecentAssets (recentList :any) {
        this.subject.next(recentList);
    }

    getRecentAssets(): Observable<any> {
        return this.subject.asObservable();
    }

}
