<section>
    <div (click)='outsideClick($event, loginForm)' class="modal fade" id="modalForm" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content p-4 ">
            <!-- Loader -->
            <div *ngIf="loginPending" class="loader-container">
                <div class="loader">
                    <span></span>
                </div>
             </div>
                <div class="modal-header mt-4 ms-4">
                    <h5 class="modal-title" id="exampleModalLabel">Sign in</h5>

                    <button type="button" #closeModel (click)='closeSidemenu(loginForm)' class="btn-close"
                        data-bs-dismiss="modal" aria-label="Close"></button>

                </div>
                <div class="modal-body ms-4">
                    <!-- <h6>New User? <a href="#">Sign Up</a> </h6> -->
                    <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                        <div class="mb-3 pt-4">
                            <label class="form-label">Email ID *</label>
                            <input type="text" class="form-control" id="username" name="username" ngModel required
                                placeholder="Username" />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Password *</label>
                            <input type="password" [type]="show ? 'text' : 'password'" class="form-control"
                                id="password-field" name="password" ngModel required placeholder="Password" />
                            <!-- <span toggle="#password-field" class="bi bi-eye field-icon toggle-password"
                                (click)="showPassword()"></span> -->

                                <span (click)="showicon()" class="field-icon toggle-password">
                                    <i class="bi bi-eye" aria-hidden="true" *ngIf="!show"></i>
                                    <i class="bi bi-eye-slash" aria-hidden="true" *ngIf="show"></i>
                                </span>
                        </div>
                        <p *ngIf='throwError'>
                            <span class="mb-3 login-validation-msg">Authentication Failed. Please check your
                                credentials.</span>
                        </p>
                        <div class="modal-footer border-0 px-0">

                            <button [disabled]='loginPending' type="submit"
                                class="btn signin-button w-100 ">Submit</button>

                                <!-- <button (click)="redirectToLoginPage()" type="btn"
                                class="btn signin-button">Login with Keycloak</button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>