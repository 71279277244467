  <!-- Page Title -->
  <div class="pagetitle" *ngIf="(!router.url.includes('/pl/grc/risk-management')) && (!router.url.includes('/pl/grc/security-assessment')) && (!router.url.includes('/pl/grc/riskoverview'))">
    <nav style="--bs-breadcrumb-divider: ''">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let data of breadcrumbArray; let i = index">
        <a (click)="navigateRespective(i)" >{{data}}</a>
        </li>

        <li class="breadcrumb-item active ps-1">
          <i class="bi bi-chevron-right"></i> {{breadcrumbPresent}}
        </li>
      </ol>
    </nav>
  </div>
  <!-- End Page Title -->


    <!-- Heading - Search - Selection box -->
  <section *ngIf="(!router.url.includes('/pl/grc/risk-management')) && (!router.url.includes('/pl/grc/security-assessment')) && (!router.url.includes('/pl/grc/riskoverview'))">
    <div class="container-fluid main-heading">
      <div class="row">
        <div class="col-md-5 col-6 text-left heading-right-gap brdr">
            <h6>{{breadcrumbPresent}}</h6>
        </div>
        <div class="col-md-4 col-6 search-box brdr" *ngIf="!router.url.includes('/pl/grc/riskcreate')">
          <div class="search-bar" *ngIf="(!router.url.includes('/pl/grc/create-risk')) && (!router.url.includes('/pl/grc/update-risk'))">
            <form
              class="search-form d-flex align-items-center"
              method="POST"
              action="#">
              <input
                type="text"
                name="query"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i class="bi bi-search"></i>
              </button>
            </form>
          </div>
        </div>
        <div class="col-md-3 col-12 col action-platform-dropdown brdr" *ngIf="!router.url.includes('/pl/grc/riskcreate')">
          <div class="row" *ngIf="!router.url.includes('/pl/grc/create-risk') && (!router.url.includes('/pl/grc/update-risk'))">
            <div class="col-lg-6 col-md-6 col-6 pe-1">
              <app-default-asset-group></app-default-asset-group>
            </div>

            <div class="col-lg-6 col-md-6 col-6">
               <app-domain-group></app-domain-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section *ngIf="router.url.includes('/pl/grc/risk-management')"> 
    <div class="container-fluid grc-heading">
      <div class="row">
        <div class="col-md-6 col-6 text-left mt-1"><h6>Risk Management</h6></div>
        
        <div class="col-md-6 col-6 text-end mb-2">
          <a class="btn page-button float-end" routerLink='/pl/grc/riskcreate' [queryParams]="agAndDomain" >
            <i class="bi bi-plus-lg text-white mt-2 ps-0"></i> New Risk </a>
      </div>
      </div>
    </div>
  </section>
  