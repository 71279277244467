

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { of } from 'rxjs';

 
 @Injectable()
 export class AdminService {

   constructor(@Inject(HttpService) private httpService: HttpService) { }
 
   executeHttpAction(policyUrl  :any, policyMethod :any, payload :any, queryParams = {}): Observable<any> {
     const url = policyUrl;
     const method = policyMethod;
     return Observable.combineLatest(this.httpService.getHttpResponse(url, method, payload, queryParams)
     .map(response => this.massageData(response) )
     .catch(this.handleError)
    );
    //  try {
    //      return Observable.combineLatest(this.httpService.getHttpResponse(url, method, payload, queryParams)
    //          .map(response => this.massageData(response) )
    //          .catch(this.handleError)
    //      );
    //  } catch (error) {
    //      this.handleError(error);
    // //  }
   }
 
   handleError(error: any): Promise<any> {
     return Promise.reject(error.message || error);
   }
 
   massageData(data:any): any {
     return data;
   }
 }
 