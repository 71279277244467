import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpService } from '../../shared/services/http-response.service';

@Injectable()
export class AdminService {

  constructor(@Inject(HttpService) private httpService: HttpService) { }

  executeHttpAction(policyUrl: any, policyMethod: any, payload: any, queryParams: any): Observable<any> {
    const url = policyUrl;
     const method = policyMethod;
     return Observable.combineLatest(this.httpService.getHttpResponse(url, method, payload, queryParams)
     .map(response => this.massageData(response) )
     .catch(this.handleError)
    );
  }

  handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  massageData(data: any): any {
    return data;
  }
}
