// import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingPageModule } from './landing-page/landing-page.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { PostLoginAppModule } from './post-login-app/post-login-app.module';
// import { environment } from 'src/environments/environment';
// import { Starter } from './core/services/starter.service';


// const initializeApplication = (srv: Starter) => {
//   return () => srv.start();
// };
@NgModule({
  declarations: [
    AppComponent,    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LandingPageModule,
    HttpClientModule,
    AppRoutingModule,
    LandingPageModule,
    PostLoginAppModule,
    CoreModule
  ],
  providers: [ 
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApplication,
    //   deps: [Starter],
    //   multi: true,
    // },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
