<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">

        <div class="card tab-box">
          <!-- Search Box and Download -->

          <!-- Table Container start here -->
          <div class="container-fluid mt-2 p-1">

            <div class="row">
              <div class="col-md-3 mb-4">
                <h6 class="card-title">Policy Details</h6>
              </div>
            </div>
            <div class="row">
              <form
                (ngSubmit)="createOrUpdatePolicy(policyForm)"
                #policyForm="ngForm"
                [hidden]="hideContent">
                <div class="col-md-10 mx-auto">
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">
                        Policy ID *
                        <span
                          *ngIf="isPolicyIdValid==0"
                          class="not-available">(Not Available)</span>
                        <span
                          *ngIf="isPolicyIdValid==1"
                          class="available">(Available)</span>
                      </div>
                      <div *ngIf="!isCreate">
                        <div class="policy-text-value highlight-pink">PacMan_{{policyName}}_{{policyVersion}}</div>
                      </div>
                      <div *ngIf="isCreate">
                        <div class="policy-text-value text-bold">PacMan_{{policyName}}_{{policyVersion}}</div>
                      </div>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Policy Version</div>
                      <div class="policy-text-value text-bold">{{policyVersion}}</div>
                    </div>

                    <div
                      class="col-md-6 mb-4"
                      *ngIf="isCreate">
                      <div class="heading-gray">Policy Name *</div>
                      <input
                        type="text"
                        class="input-field"
                        placeholder="Enter Policy Name"
                        name="policyName"
                        [(ngModel)]="policyName"
                        (input)="isPolicyIdAvailable($any($event.target).value)"
                        required>
                    </div>

                    <div
                      *ngIf="!isCreate"
                      class="col-md-6 mb-4">
                      <div class="text-wrapper-header">Policy Name</div>
                      <div class="policy-text-value">{{policyName}}</div>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Policy URL</div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="input-field"
                        placeholder="Enter Policy URL"
                        name="policyUrl"
                        [(ngModel)]="policyUrl">
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Resolution</div>
                      <textarea
                        class="input-field"
                        rows="3"
                        placeholder="Enter Policy Resolution"
                        name="policyResolution"
                        [(ngModel)]="policyResolution"></textarea>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Description *</div>
                      <textarea
                        class="input-field"
                        rows="3"
                        placeholder="Enter Policy Description"
                        name="policyDesc"
                        [(ngModel)]="policyDesc" required></textarea>
                    </div>
                    <div class="col-md-4 mx-auto my-4 createsubmit">
                      <!-- <a href="#" class="btn btn-border me-2"><i class="bi bi-x fs-5"></i> Cancel</a>
                     <a href="#" class="btn btn-green ms-2"><i class="bi bi-plus fs-5"></i> Create</a> -->
                      <a class="btn btn-border me-2">
                        <button
                          class="submit-btn"
                          type="button"
                          (click)="navigateBack()">
                          <i class="bi bi-x fs-5"></i>
                          Cancel
                        </button>
                      </a>
                      <a class="btn btn-border me-2 btn-green">
                        <button
                          class="submit-btn"
                          type="submit" [disabled]="policyForm.form.invalid || isPolicyIdValid<1">
                          <span *ngIf="isCreate"><i class="bi bi-plus fs-5"></i> Create</span>
                          <span *ngIf="!isCreate"><i class="bi bi-check fs-5"></i> Update</span>
                        </button>
                      </a>
                    </div>

                  </div>
                </div>
              </form>
              <div
                class="pacman-alert"
                *ngIf="isPolicyCreationFailed">
                <div
                  class="sa-icon sa-error animateErrorIcon"
                  style="display: block;">
                  <span class="sa-x-mark animateXMark">
                                    
                    <span class="sa-line sa-left"></span>
                                
                    <span class="sa-line sa-right"></span>
                                </span>
                </div>

                <div class="pacman-alert-message">{{failedTitle}}</div>
                <div class="pacman-alert-button">
                  <a class="btn btn-border">
                    <button
                      class="submit-btn"
                      type="button"
                      (click)="closeErrorMessage()">
                      <i class="bi bi-x fs-5"></i>
                      Close
                    </button> 
                  </a>                 
                </div>
              </div>
              <div
                class="pacman-alert"
                *ngIf="isPolicyCreationSuccess">
                <div class="sa-icon sa-success animate">
                  <span class="sa-line sa-tip animateSuccessTip"></span>
                  <span class="sa-line sa-long animateSuccessLong"></span>
                  <div class="sa-placeholder"></div>
                  <div class="sa-fix"></div>
                </div>

                <div class="pacman-alert-title">{{successTitle}}</div>
                <div class="pacman-alert-message">
                  <span class="highlight-pink">{{this.policyId}}</span>
                  has been successfully {{successSubTitle}} !!!
                </div>
                <div class="pacman-alert-button">
                  <a class="btn btn-border">
                  <button
                    class="submit-btn"
                    type="button"
                    (click)="navigateBack()">
                    <i class="bi bi-x"></i>
                    Close
                  </button>
                </a>
                </div>
              </div>
            </div>

          </div>
          <!-- Table Container ends here -->
        </div>
      </div>
    </div>
  </div>
</section>
