 <section class="list-table-wrapper">
    <ul class="flex flex-col list-table-inner-wrapper relative" *ngIf="tableBodyData">
        <li class="flex list-table-each-list list-table-header-cont">
            <ul class="flex list-table-value list-table-header">
                <li class="list-table-header-values list-table-value-each capitalize center" *ngFor="let tableHead of tableHeaderData;" title = {{tableHead}}>
                    {{tableHead}}
                </li>
            </ul>
        </li>
        <!-- <li class="flex list-table-each-list" *ngFor="let tableValue of (tableBodyData | searchFilter: searchText) | orderBySum: (orderByConfig ? orderByConfig : {}) | orderBy: (orderByProperty ? orderByProperty : {}) ;let i = index " title = {{tableValue.application}}> -->
        <li class="flex list-table-each-list" *ngFor="let tableValue of (tableBodyData | searchFilter: searchText) | orderBy: (orderByProperty ? orderByProperty : {}) ;let i = index " title = {{tableValue.application}}>
            <div class="list-table-elem-name">
                {{tableValue.AppName}}
            </div>
            <ul class="flex list-table-value">
                <li class="list-table-count-each count-color nowrap-ellipsis center pointer" *ngFor="let tableRow of tableValue.AppDetails; let j = index " title = {{tableRow.count}} (click)="tableRow.count > 0 && instructParentToNavigate(tableValue.AppName,tableHeaderData[j+1])" [ngClass]="{'link-text pointer underline-link-num': tableRow.count > 0 && !tableRow.noLink}">
                    {{tableRow.count}}
                </li>
            </ul>
        </li>
        <div class="list-table-divider"></div>
    </ul>
</section>