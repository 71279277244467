<!-- <div class="tagging-instances-container relative">
    <div class="tagging-instances-content relative"> -->
        <app-recommendations-table [searchTextValues]='searchTxt'
                        [errorValue]='errorValue'
                        [parentName]='errorMessage'
                        [showGenericMessage]='showGenericMessage'
                        [allColumns]='allColumns'
                        [outerArr]='outerArr'
                        [totalRows]='totalRows'
                        [firstPaginator]='firstPaginator'
                        [lastPaginator]='lastPaginator'
                        [currentPointer]='currentPointer'
                        (previousPageCalled)='prevPg()'
                        (nextPageCalled)='nextPg()'
                        [searchableHeader]='true'
                        [dataHead]="selectedTab.displayName"
                        (searchRowTxt)='searchCalled($event)'
                        (searchTriggerred)='callNewSearch()'
                        (rowClickText)='handlePopClick($event)'
                        (selectedRow)='goToDetails($event)'
                        [headerColName]='selectedTab.category == "cost_optimizing" ? "potential monthly savings" : "recommended" '
                        [direction]='-1'
                        [popRows]='popRows'
                        [tableIdAppend]="'9'"
                        [columnWhiteList]="columnWhiteList">
                    </app-recommendations-table>
    <!-- </div>
</div> -->