        <div class="row">
      <div class="col-md-6 px-2">
        <div class="card pichartbox p-3">
          <div class="row">
            <div class="col-6 tagging-box pe-0">
              <h4>{{tableBodyData[0].AppName}}</h4>
              <div class="div-content">
              <div class="violet-badge"></div> <span> Tagged {{tableBodyData[0].tagged}}</span> <br>
              <div class="geeen2-badge"></div> <span> Untagged {{tableBodyData[0].untagged}}</span> <br>
            </div>
            </div>
            <div class="col-6">
              <app-circle-chart key="#graph2" value="{{tableBodyData[0].tagged}}, {{tableBodyData[0].untagged}}" [result]="result"></app-circle-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card pichartbox p-3">
          <div class="row">
            <div class="col-6 tagging-box pe-0">
              <h4>{{tableBodyData[1].AppName}}</h4>
              <div class="div-content">
              <div class="violet-badge"></div> <span> Tagged {{tableBodyData[1].tagged}}</span> <br>
              <div class="geeen2-badge"></div> <span> Untagged {{tableBodyData[1].untagged}}</span> <br>
            </div>
            </div>
            <div class="col-6">
              <app-circle-chart-graph key="#graph3" value="{{tableBodyData[1].tagged}}, {{tableBodyData[1].untagged}}" [dataResult]="dataResult"></app-circle-chart-graph>
            </div>
          </div>
        </div>
      </div>

      </div>