import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthService } from './services/auth.service';
import { DataCacheService } from './services/data-cache.service';
import { AssetGroupObservableService } from './services/asset-group-observable.service';
import { OnPremAuthenticationService } from './services/onprem-authentication.service';
import { AdalService } from './services/adal.service';
import { AuthSessionStorageService } from './services/auth-session-storage.service';
import { CommonModule } from '@angular/common';
import { WorkflowService } from './services/workflow.service';
import { DomainTypeObservableService } from './services/domain-type-observable.service';
import { AssetTilesService } from './services/asset-tiles.service';
import {PermissionGuardService} from './services/permission-guard.service';
import {RecentlyViewedObservableService} from './services/recently-viewed-observable.service';
import { DomainMappingService } from './services/domain-mapping.service';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { MenuService } from './services/menu.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BreadcrumbService } from './services/breadcrumb.service';

@NgModule({
    declarations: [ ],
    imports: [
        CommonModule,
    ],
    providers: [
        WorkflowService,
        AuthService,
        DataCacheService,
        AssetGroupObservableService,
        OnPremAuthenticationService,
        AuthService,
        AdalService,
        AuthSessionStorageService,
        DomainTypeObservableService,
        AssetTilesService,
        DomainMappingService,
        MenuService,
        PermissionGuardService,
        RecentlyViewedObservableService,
        BreadcrumbService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptorService,
          multi: true
        }
    ],
  })
  export class CoreModule { 
    constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
          throw new Error(
              'CoreModule is already loaded. Import it in the AppModule only');
        }
      }
  }