<app-overlay (click)='closeBox();'></app-overlay>
<div class='confirmation-box fixed'>
  <div class="static-confirmation flex flex-col flex-center flex-align-center" *ngIf="!transactionInProgress">
    <img class="delete-icon absolute" (click)='closeBox();' src='../assets/png/delete.png'>
    <div class='flex flex-col flex-center flex-align-center'>
      <div class="conf-text bold">Confirmation</div>
      <div class="conf-text">{{text}}</div>
 
      <div class="loader" *ngIf = "errorValue == 0"></div>
      <div class="flex flex-col flex-center buttons-wrap" *ngIf = "errorValue == 1">
        <app-button class="app-button mr-20" (click)='confirmBox();'>Continue</app-button>
        <app-button [type]="'secondary'" class="app-button" (click)='closeBox();'>Cancel</app-button>
      </div>
      <div class="flex flex-col flex-center buttons-wrap" *ngIf = "errorValue == -1">
        <app-button  class="app-button" (click)='closeBox();'>Close</app-button>
      </div>
    </div>
  </div>
  <div class='confirmation-transaction' *ngIf="transactionInProgress">
      <div class='loader' *ngIf='!transactionResponse'></div>
      <div *ngIf="transactionResponse" class="full-page-center">
          <app-loader-msg [ifCancelRequired]='false' [response]="transactionResponse" (actionClick)="takeActionPostTransaction($event)"></app-loader-msg>
      </div>
  </div>
</div>
