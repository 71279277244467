

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { ReplaySubject } from 'rxjs';
import { DataCacheService } from './data-cache.service';

@Injectable()

export class AssetGroupObservableService {

    private subject = new ReplaySubject<string>(0);

    private updateTriggerStatus :any;

    constructor(private dataCacheService: DataCacheService) {
    }

    updateAssetGroup (groupName: string) {
        const previousAssetGroup = this.dataCacheService.getCurrentSelectedAssetGroup();
        const shouldNotUpdate = (previousAssetGroup === groupName && this.updateTriggerStatus) ? true : false;

        // Pass data only when there is valid asset group.
        if (groupName && !shouldNotUpdate) {
            this.dataCacheService.setCurrentSelectedAssetGroup(groupName);
            this.subject.next(groupName);
            this.updateTriggerStatus = true;
        }
    }

    getAssetGroup(): Observable<any> {
        return this.subject.asObservable();
    }

}
