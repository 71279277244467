import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GRC_MODULE } from '../../../shared/constants/routes';

const routes: Routes = GRC_MODULE;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrcRoutingModule { }
