import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {DataCacheService} from './core/services/data-cache.service';
import {RouterUtilityService} from './shared/services/router-utility.service';
import {AdalService} from './core/services/adal.service';
import {CONFIGURATIONS} from "../config/configurations";
import {LoggerService} from "./shared/services/logger.service";

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'app';

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private loggerService: LoggerService,
              private dataStore: DataCacheService,
              private routerService: RouterUtilityService,
              private adalService: AdalService) {

    if (CONFIGURATIONS.optional.auth.AUTH_TYPE === 'azuresso') {
      adalService.init(CONFIGURATIONS.optional.auth.adConfig);
    }
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

}