import { Component, Input, OnInit,ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};
import { Subscription } from 'rxjs';
import { GrcService } from 'src/app/pacman-features/services/grc.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-maturity-graph',
  templateUrl: './maturity-graph.component.html',
  styleUrls: ['./maturity-graph.component.css']
})
export class MaturityGraphComponent implements OnInit {

  private MaturitylevelUrl = environment.Maturitylevel.url;
  private MaturitylevelMethod = environment.Maturitylevel.method;
  agingData: any = [];
  series: any[] = [];
  policyseries: any[] = [];
  practiceseries: any[] = [];
  processseries: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  selectedDomain:string='all';
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToAging: Subscription | any;
  public columnWidth:string='90%';
  chart: any;
  contValue = false;
  loaded = false;
  constructor(private grcService:GrcService,
    private errorHandling: ErrorHandlingService) {
    this.chartOptions = {
      series: [
        {
          name: "Policy  Maturity",
          color:"#01B27C",
          data:[0]
          // data: [2,3,2,2,4,3,2,3,3,2,4,4,2,3,4,5,3,4,3]
        },
        {
          name: "Practice Maturity",
          color:"#006E74",
          data:[0]
          // data: [3,2,3,2,3,3,2,1,3,2,4,4,2,3,4,3,3,3,4]
        },
        {
          name: "Process Maturity",
          color:"#0097AC",
          data:[0]
          // data: [4,1,4,2,4,1,2,1,3,3,2,4,4,2,4,1,3,2,1]
        }
      ],
      chart: {
        type: 'bar',
        height: 500
      },
      plotOptions: {
        bar: {          
          dataLabels: {
            position: 'top',         
            },  
          columnWidth: this.columnWidth,
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -1,
        // offsetY: 30,
        style: {
          fontSize: '11px',
          colors: ['#000']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Information Security', 'Security Operations', 'Cryptography', 'Security Awareness', 'Communication Security', 
        'Access Control', 'Incident Management', 'Vulnerability Management', 'Asset Management','Third party risk management', 
        'Reporting Compliance','System Acquisition','External/Internal Audit','Human Resource', 'Physical and Environmental', 
        'Regulatory Compliance', 'Risk Management', 'Supplier Relationship', 'BCPDR' ],
      },
      yaxis: {            
      show: true,
      showAlways: false,
      showForNullSeries: true,
      seriesName: undefined,
      opposite: false,
      reversed: false,
      logarithmic: false,
      logBase: 10,
      tickAmount: undefined,   
      stepSize: 1,
      min:0,
      max:5,
      forceNiceScale: true,
      floating: false,
      decimalsInFloat: false,
      },
      fill: {
        opacity: 1
      },    
      tooltip: {
        y: {
          formatter: function(val:any) {
            return + val ;
          }
        }
      }
    };
  }
  ngOnInit() {
    this.getAllMaturitylevel();
  }

  getAllMaturitylevel(domain:any='all'): void {
    try {
      this.contValue = false;
      this.subscriptionToAging = this.grcService
        .executeHttpAction(
          this.MaturitylevelUrl+'?domain='+domain,
          this.MaturitylevelMethod, {}, {}
        )
        .subscribe(
          (response:any) => {
            this.contValue = true;
            this.loaded = true;
            try {
              this.agingData = response[0];
              this.policyseries = [];
              this.practiceseries = [];
              this.processseries = [];
              this.chartCategories = [];
              const keysArray = Object.keys(this.agingData);
              const agingdatacount = keysArray.length;

              for (const [nodeName, nodeData] of Object.entries(this.agingData)) {
                // console.log(`Node: ${nodeName}`);
                this.chartCategories.push(nodeName);
                this.policyseries.push((nodeData as any).policyMaturity);
                this.practiceseries.push((nodeData as any).practiceMaturity);
                this.processseries.push((nodeData as any).processMaturity);
              }
              this.chartOptions.series = [
                {
                  name: "Policy Maturity",
                  color:"#01B27C",
                  data:this.policyseries
                },
                {
                  name: "Practice Maturity",
                  color:"#006E74",
                  data:this.practiceseries
                },
                {
                  name: "Process Maturity",
                  color:"#0097AC",
                  data:this.processseries
                }
              ];

              this.chartOptions.xaxis = {
                categories: this.chartCategories,
              };
              let newchartColumn:string='90%'
              console.log(domain)
              if (domain != 'All' && domain != 'all' ) {
                newchartColumn='6%';                                
              }else{
                newchartColumn='90%';                                
              }
              this.chartOptions.plotOptions={
                bar:{
                  dataLabels: {
                    position: 'top',         
                    },
                  columnWidth:newchartColumn
                }
              }; 

            } catch (e) {
              
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {8
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    this.series = [];
    this.chartCategories =[];
    
  }

}

