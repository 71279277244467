import { Component, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ChartComponent } from 'ng-apexcharts';
import { environment } from 'src/environments/environment';
import { GrcService } from '../../services/grc.service';

@Component({
  selector: 'app-all-risks',
  templateUrl: './all-risks.component.html',
  styleUrls: ['./all-risks.component.css']
})
export class AllRisksComponent implements OnInit {

  @ViewChild('barChart')
  barChart!: ChartComponent;
  allCardsData:any = [];
  criticalRisk: any;
  unassignedRisk:any;
  noMitigationPlan:any;
  totalRisks:any;
  errorMessage: any;
  contValue = false;
  loaded = false;
  constructor( private grcService:GrcService) { }

  ngOnInit() {
    this.getRiskOverview();
  }

  getRiskOverview() {
    const url = environment.riskOverview.url;
    const method = environment.riskOverview.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((response: any) => {
      this.allCardsData = response[0];
      console.log('ALL Risk ', this.allCardsData)
      this.criticalRisk = this.allCardsData[0];
      this.unassignedRisk = this.allCardsData[1];
      this.noMitigationPlan = this.allCardsData[2];
      this.totalRisks = this.allCardsData[3];
      this.contValue = true;
      setTimeout(() => {
        this.drawDonut('#graph1')
        this.drawDonut('#graph5')
        this.drawDonut('#graph3')
        this.drawDonut('#graph4')
      }, 10);
     
      // this.loaded = true;
    },
      (error: any) => {
        this.allCardsData = [];
        this.errorMessage = 'apiResponseError';
      });
  } 

  drawDonut(elem: any){
    let node = document.querySelector(elem);
    let width = document.querySelector(elem)?.getAttribute("data-size") ?? 54;
    let height = document.querySelector(elem)?.getAttribute("data-size") ?? 54;
    let thickness = document.querySelector(elem)?.getAttribute("data-thickness") ?? 8;
    // let thickness = node.getAttribute("data-thickness") || 8;
    // let duration = node.getAttribute('data-duration') || 450;
    let duration = document.querySelector(elem)?.getAttribute("data-duration") ?? 450;
    let delay = document.querySelector(elem)?.getAttribute("data-delay") ?? 100;
    // let delay = node.getAttribute('data-delay') || 100;
    let amounts = node.getAttribute("data-amounts").split(",");
    // let amounts = value.split(",").map(Number);
    // let fills = node.getAttribute("data-fills").split(",");
    let fills = document.querySelector(elem)?.getAttribute("data-fills").split(",");
    
    let radius = Math.min(width, height) / 3;
    let pie = d3.pie().sort(null);
    
    var svg = d3.select(elem).append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 3 + "," + height / 3 + ")")
      
    let arc:any = d3.arc()
        .innerRadius(radius - thickness)
        .outerRadius(radius);
    
    svg.selectAll("path")
      .data(pie(amounts))
      .enter()
      .append("path")
      .style("fill", function(d, i) { return fills[i]; })
      .attr("d", arc)
        .transition()
      .delay(delay)
      .duration(duration)
      .call(arcTween);
    
    function arcTween(transition: { attrTween: (arg0: string, arg1: (d: d3.DefaultArcObject) => (t: number) => string | null) => void; }) {
      transition.attrTween("d", function(d: d3.DefaultArcObject) {
        var interpolate = d3.interpolate(d.startAngle, d.endAngle);
        return function(t: number) {
          d.endAngle = interpolate(t);
          return arc(d);
        };
      });
    }
  }

}
