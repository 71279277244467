import { Component, ViewChild,OnInit } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-riskscale',
  templateUrl: './riskscale.component.html',
  styleUrls: ['./riskscale.component.css']
})
export class RiskscaleComponent implements OnInit {

  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToAging: Subscription | any;

  constructor() {
    this.chartOptions = {
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },    
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      xaxis: {
        categories: ['Vulnerabilities','User Permissions', 'Dormant Accounts','Physical Security']
      },   
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 2,
      },
      series: [
        {
          name: 'basic',
          data:[90,70,35,45]
        },
      ],
  
      legend: {
        position: 'right',
        verticalAlign: 'top',
      },
  
      colors: ['#FC6A59','#006E74', '#DAB315','#EE2219'],
  
      chart: {
        type: 'bar',
        height: 150,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          barHeight: '40%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
    };
  }
  ngOnInit(): void {
    
  }
}


