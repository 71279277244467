
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-config-history-dropdown',
  templateUrl: './config-history-dropdown.component.html',
  styleUrls: ['./config-history-dropdown.component.css'],
  host: {
    '(document:click)': 'onClickOutside($event)'
  }
})
export class ConfigHistoryDropdownComponent implements OnInit {
  @ViewChild('closebutton') closebutton:any;
  constructor(
    private eref: ElementRef
  ) {}
  @Output() emitClose = new EventEmitter();
  @Input() items: any;
  @Input() placeholder:any;
  @Input() firstDD:any;
  @Input() activeTile:any;
  @Input() errorValue:any;
  @Input() errorMessage :any;
  @Output() selection = new EventEmitter();
  @Output() closeDropdown = new EventEmitter();
  tiles:any = [];
  showDropdown = false;

  ngOnInit() {
    this.processData(this.items);
    
  }

  public selected(value:any, index:any): void {
    this.selection.emit({'value': value, 'activeIndex': index});
  }

  processData(items:any) {
    this.tiles = items;
  }

  onClickOutside(event:any) {
    
  }
  public onSave() {
    this.closebutton.nativeElement.click();
  }
  closeBox() {
    this.closeDropdown.emit(false);
  }
  
}
