import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable()
export class TaggingComplianceService {
    constructor( @Inject(HttpService) private httpService: HttpService,
                private errorHandler: ErrorHandlingService) { }

    // @ts-ignore
  getTaggingCompliance(queryParams :any, taggingComplianceUrl :any, taggingComplianceMethod :any): Observable<any> {
        const url = taggingComplianceUrl;
        const method = taggingComplianceMethod;
        const payload = {};
        try {
          return Observable.combineLatest(
            this.httpService.getHttpResponse(url, method, payload, queryParams)
            .map(response => {
                try {
                    return this.massageData(response);
                } catch (error) {
                    this.errorHandler.handleJavascriptError(error);
                }
            })
          );
        } catch (error) {
            this.errorHandler.handleJavascriptError(error);
        }
    }

    // @ts-ignore
  getTaggingSummaryByTargetType(payload :any, taggingSummaryUrl :any, taggingSummaryMethod :any): Observable<any> {
        const queryParams = {};
        try {
          return this.httpService.getHttpResponse(taggingSummaryUrl, taggingSummaryMethod, payload, queryParams)
            .map((response :any) => {
                try {
                    const data = response['data'].response;
                    data.sort(function(a :any, b :any) {
                        return b.untagged - a.untagged;     // For descending order
                    });
                    return data;
                } catch (error) {
                    this.errorHandler.handleJavascriptError(error);
                }
            });
        } catch (error) {
            this.errorHandler.handleJavascriptError(error);
        }
    }

    massageData(data :any): any {
        const finalObj :any= {};
        const tempData = data.response.untaggedList.slice();
        const finalData :any = [];
        const appTaggingStatus :any = [];
        const headerData :any = [];

        /* tableheaderdata is for table header */
        headerData.push('TagName');
        tempData.forEach((element:any) => {
            const complianceInstance = [
                {title: 'topBlank', val: (100 - (element.compliancePercentage || 100) ) },
                {title: element.name, val: (element.compliancePercentage || 100)},
                {title: 'leftBlank', val: 100}
               ];
            const taggingStatus = {
                'AppName' : element.name,
                'AppDetails': [
                    {'CountType' : 'untagged', 'count' : parseInt(element.untagged, 10)},
                    {'CountType' : 'tagged', 'count' : parseInt(element.tagged, 10)}
                ]
            };
            finalData.push(complianceInstance);
            appTaggingStatus.push(taggingStatus);
        });
        appTaggingStatus[0].AppDetails.forEach((element :any , index :any) => {
            headerData.push(element.CountType);
        });
        appTaggingStatus.forEach((element:any) => {
            element.AppDetails.forEach((details:any) => {
                element[details.CountType] = details.count;
            });
        });
        finalObj['data'] = finalData;
        finalObj['percent'] = data.response.overallCompliance;
        finalObj['taggingStatus'] = {'header' : headerData, 'data' : appTaggingStatus};
        return finalObj;
    }
}
