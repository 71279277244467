<div id="chart">
    <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [fill]="chartOptions.fill"   
    [labels]="chartOptions.labels"
    [legend]="chartOptions.legend"
    [colors]="chartOptions.colors"
  ></apx-chart>
    </div>