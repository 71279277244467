<section *ngIf="tagsArray.length > 0">
	<!-- <div class="container-fluid my-3">   -->
	  <!-- <div class="row">
		<div class="col-12"> -->
		  <div class="card chart-card2">
			<div class="card-body">
				<h6>Tags</h6>
			  <!-- <div class="gray-heading mb-2">  
				<div class="row p-1 mt-1">
					<div class="col"><h6>Target Entity Mandatory Tags :</h6></div>
				</div>
  
			  </div> -->
  
			  <ng-container  *ngIf="tagsArray.length > 0">
				<div class="tag text-center" title="{{data.value}}" *ngFor='let data of tagsArray'>
					{{data.name}}: {{data.value}}
				</div>
			  </ng-container>
  
			</div>
		 </div>
	  <!-- </div>
	</div> -->
   <!-- </div> -->
  </section>