
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';

@Injectable()

export class SelectComplianceDropdown {
    private subject = new ReplaySubject<any>(0);

    updateCompliance(dropDownObj: any) {
        if (dropDownObj) {
            this.subject.next(dropDownObj);
        }
    }
    getCompliance(): Observable<any> {
        return this.subject.asObservable();
    }
}
