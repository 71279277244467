import { Component, OnDestroy, OnInit,ViewChild,ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { AssetGroupObservableService } from "src/app/core/services/asset-group-observable.service";
import { BreadcrumbService } from "src/app/core/services/breadcrumb.service";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { IssueFilterService } from "src/app/pacman-features/services/issue-filter.service";
import { SelectComplianceDropdown } from "src/app/pacman-features/services/select-compliance-dropdown.service";
import { LoggerService } from "src/app/shared/services/logger.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import {Subscription} from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
@Component({
  selector: 'app-vulnerabilities-compliance',
  templateUrl: './vulnerabilities-compliance.component.html',
  styleUrls: ['./vulnerabilities-compliance.component.css'],
  providers: [
    IssueFilterService,
    LoggerService
  ]

})
export class VulnerabilitiesComplianceComponent implements OnInit, OnDestroy {
  breadcrumbArray: any = ['Compliance'];
  breadcrumbLinks: any = ['compliance-dashboard'];
  breadcrumbPresent: any = 'Vulnerabilities';
  finalData: any;
  @ViewChild('widgetContainer') widgetContainer: ElementRef | any;

  pageTitle = 'Vulnerabilities';
  widgetWidth: number=0;
  widgetHeight: number=0;

  /*variables for breadcrumb data*/
  complianceDropdowns: any = [];
  searchDropdownData: any = {};
  selectedDD = '';
  currentObj: any = {};
  subscriptionToAssetGroup: Subscription;
  selectedAssetGroup: string="";
  selectedComplianceDropdown: any;
  filterTypeOptions = [];
  filterTypeLabels:any = [];
  currentFilterType:any;
  filterTagOptions:any = [];
  filterTagLabels:any = [];
  filters = [];
  outerArr: any = [];
  private filterTypesSubscription: Subscription |any;

  constructor(
    private assetGroupObservableService: AssetGroupObservableService,
    private selectComplianceDropdown: SelectComplianceDropdown,
    private router: Router,
    private utils: UtilsService,
    private issueFilterService: IssueFilterService,
    private logger: LoggerService,
    private workflowService: WorkflowService,
    private breadCurmbService: BreadcrumbService
  ) {
    this.subscriptionToAssetGroup = this.assetGroupObservableService
      .getAssetGroup()
      .subscribe(assetGroupName => {
        this.selectedAssetGroup = assetGroupName;
        this.deleteFilters();
        this.getFilters();
      });
  }

  ngOnInit() {
    try {
      this.breadcrumbPresent = 'Vulnerabilities';
      this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent,false)
      this.widgetWidth = parseInt(
        window
          .getComputedStyle(this.widgetContainer.nativeElement, null)
          .getPropertyValue('width'),
        10
      );
      // this.workflowService.clearAllLevels();
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  getFilters() {
    const queryParams = {
      filterId: 2
    };
    const issueFilterUrl = environment.issueFilter.url;
    const issueFilterMethod = environment.issueFilter.method;
    this.filterTypesSubscription = this.issueFilterService
      .getFilters(queryParams, issueFilterUrl, issueFilterMethod)
      .subscribe(response => {
        this.filterTypeLabels = _.map(response[0].response, 'optionName');
        this.filterTypeOptions = response[0].response;
      });
  }

  changeFilterType(value:any) {
    this.currentFilterType = _.find(this.filterTypeOptions, {
      optionName: value.id
    });
    this.filterTypesSubscription = this.issueFilterService
      .getFilters(
        {
          ag: this.selectedAssetGroup
        },
        environment.base +
          this.utils.getParamsFromUrlSnippet(this.currentFilterType.optionURL)
            .url,
        'GET'
      )
      .subscribe(response => {
        this.filterTagOptions = response[0].response;
        this.filterTagLabels = _.map(response[0].response, 'name');
      });
  }

  changeFilterTags(value:any) {
    if (this.currentFilterType) {
      const filterTag = _.find(this.filterTagOptions, { name: value.id });
      this.utils.addOrReplaceElement(
        this.filters,
        {
          key: this.currentFilterType.optionName,
          value: filterTag.id,        
          filterkey: this.currentFilterType.optionValue.trim(),
          compareKey: this.currentFilterType.optionValue.toLowerCase().trim()
        },
        (el: any) => {
          return (
            el.compareKey ===
            this.currentFilterType.optionValue.toLowerCase().trim()
          );
        }
      );
      this.selectComplianceDropdown.updateCompliance(
        this.utils.arrayToObject(this.filters, 'filterkey', 'value')
      );
      this.filterTagOptions = [];
      this.filterTagLabels = [];
      this.currentFilterType = null;
    }
    this.utils.clickClearDropdown();
  }

  deleteFilters(event?:any) {
    try {
      if (!event) {
        this.filters = [];
      } else {
        if (event.clearAll) {
          this.filters = [];
        } else {
          this.filters.splice(event.index, 1);
        }
        this.selectComplianceDropdown.updateCompliance(
          this.utils.arrayToObject(this.filters, 'filterkey', 'value')
        );
      }
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.filterTypesSubscription) {
        this.filterTypesSubscription.unsubscribe();
      }
      if (this.subscriptionToAssetGroup) {
        this.subscriptionToAssetGroup.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }

  
}