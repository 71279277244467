<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <app-policy-content-slider [pageLevel]="pageLevel" [ruleID]="ruleID" *ngIf="setRuleIdObtained == true"></app-policy-content-slider>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <app-policy-summary [ruleID]="ruleID" [pageLevel]="pageLevel" *ngIf="setRuleIdObtained == true"></app-policy-summary>
</section>
<section>
  <app-policy-trend [ruleID]="ruleID" *ngIf="setRuleIdObtained == true"></app-policy-trend>
</section>
<section>
  <app-policy-assets-trend [ruleID]="ruleID" *ngIf="setRuleIdObtained == true"></app-policy-assets-trend>
</section>
<section>
  <app-all-policy-violations [ruleID]="ruleID" *ngIf="setRuleIdObtained == true"></app-all-policy-violations>
</section>
