<div class="card chart-card issues-graph-section-height">
  <!-- Loader -->
  <div *ngIf='!loaded' class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div>
  <div class="error_handling">
    <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
  </div>
  <div class="card-body paman-title" *ngIf='!seekdata && loaded'>

      <h6 class="card-title"> 
        <a (click)="navigateTo()">Policy Violations </a>          
        <span class="small pt-1">
        <span (click)="navigateToCritical()">Critical <b>{{pacmanIssues.severity[0].critical}}</b></span>
        <span (click)="navigateToTotal()">Total Violation <b>{{pacmanIssues.totalIssues}}</b> / </span>    
      </span>                  
      </h6>
      <div class="row" *ngIf='!seekdata && loaded'>
        <div class="col-md-10 col-10">
          <!-- Bar Chart -->
          <div id="barChart2">
            <apx-chart [grid]="chartOptions.grid" [series]="chartOptions.series" [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
              [xaxis]="chartOptions.xaxis" [colors]="chartOptions.colors" [legend]="chartOptions.legend"
              [yaxis]="chartOptions.yaxis"></apx-chart>
          </div>
          <!-- End Bar Chart -->
        </div>
        <div class="col-md-2 col-2 text-end chart-value">
          <ul>
            <li *ngFor="let val of series ">{{val}}%</li>
          </ul>
        </div>
      </div>
  </div>

  <!-- <div>
    <div class="error_handling">
      <app-error-message *ngIf='error' [selectedValue]="errorMessage"></app-error-message>
    </div>
  </div> -->

</div>

