import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as d3 from 'd3';

@Component({
  selector: 'app-compliant-circle-chart',
  templateUrl: './compliant-circle-chart.component.html',
  styleUrls: ['./compliant-circle-chart.component.css'],
})
export class CompliantCircleChartComponent implements OnInit {
  @Input() cardsValue: any;

  ngOnInit(): void {
    console.log("Circle Data: ", this.cardsValue)
    this.drawDonut('#compliantGraph');
  }

  drawDonut(elem: any) {
    let node = document.querySelector(elem);
    let width = node.getAttribute('data-size') || 54;
    let height = node.getAttribute('data-size') || 54;
    let thickness = node.getAttribute('data-thickness') || 8;
    let duration = node.getAttribute('data-duration') || 450;
    let delay = node.getAttribute('data-delay') || 100;
    let amounts = node.getAttribute('data-amounts').split(',');
    let fills = node.getAttribute('data-fills').split(',');

    let radius = Math.min(width, height) / 3;
    let pie = d3.pie().sort(null);

    var svg = d3
      .select(elem)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 3 + ',' + height / 3 + ')');

    let arc: any = d3
      .arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    svg
      .selectAll('path')
      .data(pie(amounts))
      .enter()
      .append('path')
      .style('fill', function (d, i) {
        return fills[i];
      })
      .attr('d', arc)
      .transition()
      .delay(delay)
      .duration(duration)
      .call(arcTween);

    function arcTween(transition: {
      attrTween: (
        arg0: string,
        arg1: (d: d3.DefaultArcObject) => (t: number) => string | null
      ) => void;
    }) {
      transition.attrTween('d', function (d: d3.DefaultArcObject) {
        var interpolate = d3.interpolate(d.startAngle, d.endAngle);
        return function (t: number) {
          d.endAngle = interpolate(t);
          return arc(d);
        };
      });
    }
  }
}
