<div [ngClass]="{'overview-trend-container': !dataLoaded}">
    <app-multiline-trend *ngIf="dataLoaded" [subHeadTitle]="'Trend Of Total And Compliant Assets'" [id]="'complianceAsset'" (error)="setError($event)"
      [showLegend]="showGraphLegend" [yAxisLabel]="yAxisLabel" [graphLinesData]="graphData">
    </app-multiline-trend>
  
    <div *ngIf="!dataLoaded && !error" class="loader-container">
      <div class="loader">
        <span></span>
      </div>
    </div>
  
    <div *ngIf='error' class="error_handling">
      <app-error-message [selectedValue]="errorMessage"></app-error-message>
    </div>
  </div>