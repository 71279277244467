
import {Injectable} from '@angular/core';
import {MAPPING} from "../constants/field-display-name-mapping";

@Injectable()
export class RefactorFieldsService {
  constructor() {
  }

  getMappingObject() {
    return MAPPING;
  }

  getDisplayNameForAKey(key: any) {
    let mappingElement: string = MAPPING[key];
    return mappingElement;
  }
}