<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box">
                    <div class="container-fluid mt-2 p-1">
                        <div class="row">
                            <div class="col-md-3 mb-4">
                                <h6 class="card-title">Job Execution Manager Details </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11 mx-auto ps-5">
                            <form (ngSubmit)="updateJob(jobForm)" #jobForm="ngForm" [hidden]="hideMainContent">
                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Job Name*</div>
                                        <input class="form-control input-field" disabled type="text" name="query"
                                            placeholder="Enter Job Name" title="" value="{{jobDetails['jobName']}}" />
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Job Frequency*</div>
                                        <ngx-select name="jobFrequency" [(ngModel)]="jobFrequency" (select)='onSelectFrequency($event)' [allowClear]="true" [items]="allFrequencies" placeholder="Select Frequency" required>
                                        </ngx-select>
                                        <div id="other-div" class="frequency-div" *ngIf="selectedFrequency==='Daily'">
                                            <label class="border-line">Every
                                                <input type="text" autocomplete="off" name="jobFrequencyModeValue" [(ngModel)]="jobFrequencyModeValue"
                                                    class="period" appOnlyNumber required />
                                                Day(s)
                                            </label>
                                        </div>
                                        <div id="other-div2" class="frequency-div" *ngIf="selectedFrequency==='Hourly'">
                                            <label class="border-line">Every
                                                <input type="text" autocomplete="off" name="jobFrequencyModeValue" [(ngModel)]="jobFrequencyModeValue"
                                                    class="period" [maxlength]="2" appOnlyNumber required />
                                                Hour(s)
                                            </label>
                                        </div>
                                        <div id="other-div3" class="frequency-div" *ngIf="selectedFrequency==='Minutes'">
                                            <label class="border-line">Every
                                                <input type="text" autocomplete="off" name="jobFrequencyModeValue" [(ngModel)]="jobFrequencyModeValue" class="period" [maxlength]="2" appOnlyNumber required/>
                                                 Minute(s)
                                            </label>
                                        </div>
                                        <div id="other-div4" class="frequency-div" style="width:20px;" *ngIf="selectedFrequency==='Monthly'">
                                            <label class="border-line">
                                                <table style="width:200px;">
                                                    <tr>
                                                        <td>Day  <input type="text" autocomplete="off" name="jobFrequencyDays" [(ngModel)]="jobFrequencyDays" class="period" appOnlyNumber required /> of
                                                            every </td>
                                                        <td><input type="text" autocomplete="off" name="jobFrequencyMonths" [(ngModel)]="jobFrequencyMonths" class="period" appOnlyNumber required /> month(s)
                                                        </td>
                                                    </tr>
                                                </table>
                                            </label>
                                        </div>
                                        <div id="other-div5" class="frequency-div" style="width:90%;" *ngIf="selectedFrequency==='Weekly'">
                                            <label>
                                                <div class="d-inline">
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="mon" value="MON" name="weekName" class="form-check-input" checked="checked"
                                                            [(ngModel)]="weekName" required />
                                                        <label class="form-check-label" for="inlineRadio1">Mon</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="tue" value="TUE" name="weekName" class="form-check-input" [(ngModel)]="weekName" required />
                                                        <label class="form-check-label" for="inlineRadio2">Tue</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="wed" value="WED" name="weekName" class="form-check-input" [(ngModel)]="weekName" required />
                                                        <label class="form-check-label" for="inlineRadio3">Wed</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="thu" value="THU" name="weekName" class="form-check-input" [(ngModel)]="weekName" required />
                                                        <label class="form-check-label" for="inlineRadio4">Thu</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="fri" value="FRI" name="weekName" class="form-check-input" [(ngModel)]="weekName" required />
                                                        <label class="form-check-label" for="inlineRadio5">Fri</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="sat" value="SAT" name="weekName" class="form-check-input" [(ngModel)]="weekName" required />
                                                        <label class="form-check-label" for="inlineRadio6">Sat</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="sun" value="SUN" name="weekName" class="form-check-input" [(ngModel)]="weekName" required>
                                                        <label class="form-check-label" for="inlineRadio7">Sun</label>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div id="other-div6" class="frequency-div" *ngIf="selectedFrequency==='Yearly'">
                                            <label class="border-line">Every
                                                <ngx-select name="jobFrequencyMonth" [(ngModel)]="jobFrequencyMonth" (select)='onSelectFrequencyMonth($event)' [allowClear]="true" [items]="allMonths" placeholder="Select Month" required>
                                                </ngx-select>
                                                <ngx-select name="jobFrequencyDay" [(ngModel)]="jobFrequencyDay" [allowClear]="true" [items]="allMonthDays" placeholder="Select Day" required>
                                                </ngx-select>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Job Description *</div>
                                        <textarea name="jobDesc" [(ngModel)]="jobDetails['jobDesc']" class="input-field jobtext" placeholder="Enter Job Description"
                                            required></textarea>
                                    </div> -->

                                    

                                    <!-- <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Job Type</div>
                                        <div class="w-75">
                                            <div class="form-check form-check-inline">
                                                <input type="radio" id="federated-rule" value="jar" name="jobType" class="form-check-input" checked="checked"
                                                    [(ngModel)]="jobType">
                                                <label class="form-check-label" for="inlineRadio1">Executable
                                                    Jar</label>
                                            </div>
                                        </div>

                                        <div *ngIf="jobType=='jar'">
                                            <div class="selected-file">{{jobJarFileName}} <a (click)="removeJarFileName();" *ngIf="jobJarFileName!=''"><i class="bi bi-x remove-icon"></i></a></div>
                                            <div class="heading-gray">Job Executable *</div>
                                            <div class="file btn btn-upload" *ngIf="jobJarFileName==''">
                                                <a (click)="openJarFileBrowser($event)"><i class="bi bi-upload"></i> Upload JAR
                                                    file</a>
                                                    <input type="file" id="selectJarFile" class="hide" (change)="onJarFileChange($event)" name="jobJarFile" [(ngModel)]="jobJarFile" *ngIf="jobJarFileName==''" required />
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Job Parameters
                                            <div class="row">
                                                <div class="col-3 pe-1"> 
                                                    <input type="text" autocomplete="off" class="form-control input-field-small" placeholder="Enter Key" name="jobParamKey" [(ngModel)]="parametersInput.jobKey"/>
                                                </div>
                                                <div class="col-3"> 
                                                        <input type="text" autocomplete="off"class="form-control input-field-small" placeholder="Enter Value" name="jobParamValue" [(ngModel)]="parametersInput.jobValue"/>
                                                    </div>
                                                <div class="col-2 px-0  pt-2">
                                                    <div class="form-check">
                                                            <input type="checkbox" class="form-check-input" #isJobParamEncrypted id="encryptjobparam"/>
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Encrypt
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-2 px-0 ">
                                                    <!-- <a href="#" class="btn add-btn"><i
                                                            class="bi bi-plus fs-4 ps-0"></i><span>Add</span></a> -->
                                                            <button class="btn add-btn" [disabled]="!(parametersInput.jobKey&&parametersInput.jobValue&&allJobParamKeys.indexOf(parametersInput.jobKey.trim())==-1)" (click)="addJobParameters(parametersInput, isJobParamEncrypted)"> + Add</button>
                                                </div>
                                            </div>
                                            <div class="row px-2">
                                                <div class="col-10  border rounded-2 mt-2 px-0 ms-1" *ngIf="allJobParams.length==0">
                                                    <div class=" text-center py-5">No Environment Parameters Added</div>
                                                </div>
                                                <div class="col-10  border rounded-2 mt-2 px-0 ms-1" *ngIf="allJobParams.length!==0">
                                                    <table class="table table-hover px-0 table-text">
                                                        <thead>
                                                            <tr>
                                                                <th>Key</th>
                                                                <th scope="col">Value </th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let jobParams of allJobParams; let jobParamsIndex = index;">
                                                            <tr>
                                                                <td>{{jobParams.key}}</td>
                                                                <td *ngIf="!jobParams.encrypt">{{jobParams.value}}</td>
                                                                <td *ngIf="jobParams.encrypt"> XXXX <span class="highlight-pink">(Encrypted)</span></td>
                                                                <td><a (click)="removeJobParameters(jobParamsIndex)">X</a></td>
                                                            </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Environment Parameters
                                            <div class="row">
                                                <div class="col-3 pe-1">
                                                    <input type="text" autocomplete="off" class="form-control input-field-small" placeholder="Enter Key"
                                                        name="envParamKey" [(ngModel)]="parametersInput.envKey" />
                                                </div>
                                                <div class="col-3">
                                                    <input type="text" autocomplete="off" class="form-control input-field-small" placeholder="Enter Value"
                                                        name="envParamValue" [(ngModel)]="parametersInput.envValue" />
                                                </div>
                                                <div class="col-2 px-0  pt-2">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" #isEnvParamEncrypted id="encryptenvparam">
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Encrypt
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-2 px-0 ">
                                                    <!-- <a href="#" class="btn add-btn"><i
                                                            class="bi bi-plus fs-4 ps-0"></i><span>Add</span></a> -->
                                                            <button class="btn add-btn" [disabled]="!(parametersInput.envKey&&parametersInput.envValue&&allEnvParamKeys.indexOf(parametersInput.envKey.trim())==-1)" (click)="addEnvironmentParameters(parametersInput, isEnvParamEncrypted)"> + Add</button>
                                                </div>
                                            </div>
                                            <div class="row px-2">
                                                <div class="col-10  border rounded-2 mt-2 px-0 ms-1" *ngIf="allEnvironments.length==0">
                                                    <div class=" text-center py-5">No Environment Parameters Added</div>
                                                </div>
                                                <div class="col-10  border rounded-2 mt-2 px-0 ms-1" *ngIf="allEnvironments.length!==0">
                                                    <table class="table table-hover px-0 table-text">
                                                        <thead>
                                                            <tr>
                                                                <th>Key</th>
                                                                <th scope="col">Value </th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let envParams of allEnvironments; let ruleEnvParamIndex = index;">
                                                            <tr>
                                                                <td>{{envParams.name}}</td>
                                                                <td *ngIf="!envParams.encrypt">{{envParams.value}}</td>
                                                                <td *ngIf="envParams.encrypt"> XXXX <span class="highlight-pink">(Encrypted)</span></td>
                                                                <td><a (click)="removeEnvironmentParameters(ruleEnvParamIndex)">X</a></td>
                                                            </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Job Description *</div>
                                        <textarea name="jobDesc" [(ngModel)]="jobDetails['jobDesc']" class="input-field jobtext" placeholder="Enter Job Description"
                                            required></textarea>
                                    </div>
                                    <div class="col-12">
                                        <div class="col-md-4 mx-auto my-4 createsubmit">                                                                                      
                                                 <a class="btn btn-border me-2">
                                                    <button
                                                      class="submit-btn"
                                                      type="button"
                                                      (click)="navigateBack()">
                                                      <i class="bi bi-x fs-5"></i>
                                                      Cancel
                                                    </button>
                                                  </a>                                       
                                                <a class="btn btn-border me-2 btn-green">
                                                    <button
                                                        class="submit-btn text-white"                                                            
                                                        [disabled]="(jobForm.form.invalid)">
                                                        <i class="bi bi-check fs-5"></i>
                                                        Update
                                                    </button>
                                                </a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="pacman-alert" *ngIf="isJobFailed">
                                <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                    <span class="sa-x-mark animateXMark">
                                            <span class="sa-line sa-left"></span>
                                    <span class="sa-line sa-right"></span>
                                    </span>
                                </div>
                                <div class="pacman-alert-title">
                                    Job Configuring Failed
                                </div>
                                <div class="pacman-alert-message">
                                    Failed in configuring the job <span class="highlight-pink">{{jobId}}</span> !!!
                                </div>
                                <div class="pacman-alert-button">                                    
                                    <a class="btn btn-border me-2">
                                        <button
                                          class="submit-btn retybutton cancelbut"
                                          type="button"
                                          (click)="closeErrorMessage()">
                                          <i class="bi bi-x fs-5"></i>
                                          Cancel
                                        </button>
                                      </a>
                                </div>
                            </div>
                            <div class="pacman-alert" *ngIf="isJobSuccess">
                                <div class="sa-icon sa-success animate">
                                    <span class="sa-line sa-tip animateSuccessTip"></span>
                                    <span class="sa-line sa-long animateSuccessLong"></span>
                                    <div class="sa-placeholder"></div>
                                    <div class="sa-fix"></div>
                                </div>
                                <div class="pacman-alert-title">
                                    Successfully Configured
                                </div>
                                <div class="pacman-alert-message">
                                    Job <span class="highlight-pink">{{jobId}}</span> has been successfully configured !!!
                                </div>
                                <div class="pacman-alert-button">
                                    <a class="btn btn-border me-2">
                                        <button
                                          class="submit-btn retybutton"
                                          type="button"
                                          (click)="navigateBack()">
                                          <i class="bi bi-x fs-5"></i>
                                          Cancel
                                        </button>
                                      </a>  
                                </div>
                            </div>
                            </div>
                        </div>
                    </div><!-- Container ends here -->
                </div>
            </div>
        </div>
    </div>
</section>