import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexGrid,
  ApexYAxis
} from "ng-apexcharts";

import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { PacmanIssuesService } from '../../services/pacman-issues.service';
import { AssetGroupObservableService } from '../../../core/services/asset-group-observable.service';
import { AutorefreshService } from '../../services/autorefresh.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { WorkflowService } from '../../../core/services/workflow.service';
import { RefactorFieldsService } from '../../../shared/services/refactor-fields.service';
import { DomainTypeObservableService } from '../../../core/services/domain-type-observable.service';
import {environment} from "../../../../environments/environment";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  yaxis: ApexYAxis
};

@Component({
  selector: 'app-pacman-issues',
  templateUrl: './pacman-issues.component.html',
  styleUrls: ['./pacman-issues.component.css'],
  providers: [ ErrorHandlingService, PacmanIssuesService, AutorefreshService]
})

export class PacmanIssuesComponent implements OnInit, OnDestroy {

  @ViewChild('countval') varcount: ElementRef |any;
  pacmanIssues: any;
  subscriptionToAssetGroup: Subscription;
  subscriptionDomain: Subscription;
  selectedAssetGroup: string ='';
  errorMessages:any;
  dataSubscriber:any;
  durationParams: any;
  autoRefresh: boolean =false;
  errorMessage: any;
  error = false;
  pacmanCategories: any = [];
  selectedDomain: any;
  loaded: boolean=false;
  seekdata = false;
  routeTo = 'issue-listing';



  series: any[] = [];

  private autorefreshInterval:any;

  public chartOptions: Partial<ChartOptions> | any;



  constructor(private pacmanIssuesService: PacmanIssuesService,
              private assetGroupObservableService: AssetGroupObservableService,
              private autorefreshService: AutorefreshService,
              private errorHandling: ErrorHandlingService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private utils: UtilsService,
              private workflowService: WorkflowService,
              private refactorFieldsService: RefactorFieldsService,
              private domainObservableService: DomainTypeObservableService) {

              this.subscriptionToAssetGroup = this.assetGroupObservableService.getAssetGroup().subscribe(
                assetGroupName => {
                    this.selectedAssetGroup = assetGroupName;
              });

              this.subscriptionDomain = this.domainObservableService.getDomainType().subscribe(domain => {
                   this.selectedDomain = domain;
                   this.updateComponent();
              });

              this.chartOptions = {
                grid: {
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                xaxis: {
                  categories: ['Security', 'Cost Optimization', 'Governance', 'tagging']
                },
                yaxis: {
                  min: 0,
                  max: 100,
                  tickAmount: 2,
                },
                series: [
                  {
                    data: [],
                  },
                ],
          
                legend: {
                  show: false,
                },
          
                colors: ['#FC6A59', '#006E74', '#881E87', '#D9434E'],
          
                chart: {
                  type: 'bar',
                  height: 150,
                  toolbar: {
                    show: false
                  }
                },
                plotOptions: {
                  bar: {
                    barHeight: '40%',
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                      position: 'bottom',
                    },
                  },
                },
              };
   }

   ngOnInit() {
        this.durationParams = this.autorefreshService.getDuration();
        this.durationParams = parseInt(this.durationParams, 10);
        this.autoRefresh = this.autorefreshService.autoRefresh;

        const afterLoad = this;
        if (this.autoRefresh !== undefined) {
            if ((this.autoRefresh === true ) || (this.autoRefresh.toString() === 'true')) {
                this.autorefreshInterval = setInterval(() => {
                    afterLoad.getPacmanIssues();
                }, this.durationParams);
            }
        }
    }

    navigateTo() {
        this.workflowService.addRouterSnapshotToLevel(
          this.router.routerState.snapshot.root
        );
        this.router.navigate(['../', this.routeTo], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge'
        });
    }

    navigateToCritical() {

      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      if (this.routeTo !== undefined) {
        const eachParams = {'severity.keyword': 'critical', 'include_exempt': 'no'};
        const newParams = this.utils.makeFilterObj(eachParams);
        this.router.navigate(['../', this.routeTo], {relativeTo: this.activatedRoute, queryParams: newParams, queryParamsHandling: 'merge'});
      }
    }

    navigateToTotal() {
      this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
      if (this.routeTo !== undefined) {
        const eachParams = {'include_exempt': 'no'};
        const newParams = this.utils.makeFilterObj(eachParams);
        this.router.navigate(['../', this.routeTo], {relativeTo: this.activatedRoute, queryParams: newParams, queryParamsHandling: 'merge'});
      }
    }

    /* Function to repaint component */
    updateComponent() {
        /* All functions variables which are required to be set for component to be reloaded should go here */
        if (this.dataSubscriber) {
          this.dataSubscriber.unsubscribe();
        }
        this.loaded = false;
        this.error = false;
        this.getData();
    }

    /* Function to get Data */
    getData() {

        /* All functions to get data should go here */
        this.getPacmanIssues();
    }

    getPacmanIssues() {

      if (this.dataSubscriber) { this.dataSubscriber.unsubscribe(); }

      const queryParams = {
          'ag': this.selectedAssetGroup,
          'domain': this.selectedDomain,
          'tenantId': 'UST_10001'
      };

      const pacmanIssuesUrl = environment.pacmanIssues.url;
      const pacmanIssuesMethod = environment.pacmanIssues.method;
      this.seekdata = false;
      try {
        this.dataSubscriber = this.pacmanIssuesService.getData(queryParams, pacmanIssuesUrl, pacmanIssuesMethod).subscribe(
        response => {
          try {
            this.pacmanIssues = response;
            if (this.pacmanIssues.length === 0) {
              this.seekdata = true;
              this.errorMessage = 'noDataAvailable';
            } else {
              this.seekdata = false;
              this.pacmanIssues = response;
            }
            this.pacmanCategories = [];
            this.series = [];

            const dataObj: any = {
              'Security': 0,
              'Cost Optimization': 0,
              'Governance': 0,
              'tagging': 0,
            };

            for (let i = 0; i < this.pacmanIssues.category.length; i++) {
              const obj = {
                displayName:
                  this.refactorFieldsService.getDisplayNameForAKey(
                    Object.keys(this.pacmanIssues.category[i])[0].toLowerCase()
                  ) || Object.keys(this.pacmanIssues.category[i])[0],
                key: Object.keys(this.pacmanIssues.category[i])[0],
                value:
                  this.pacmanIssues.category[i][
                    Object.keys(this.pacmanIssues.category[i])[0]
                  ],
              };

              dataObj[obj.displayName] = obj.value
              this.pacmanCategories.push(obj.displayName);
              this.series.push(obj.value);
            }

            // update chart data
            //TODO: Need to confirmation what to do when we dont have data to show on garph
            // if (!this.series.length) {
            //   this.series = [0,0,0,0]
            // }

            // if (!this.pacmanCategories.length) {
            //   this.pacmanCategories = ['Security', 'Cost Optimization', 'Governance', 'Tagging']
            // }

   
            this.series = Object.values(dataObj)
            this.pacmanCategories = Object.keys(dataObj)

            this.chartOptions.series = [
              {
                data: this.series,
              },
            ];

            this.chartOptions.xaxis = {
              categories: this.pacmanCategories,
            };

            this.loaded = true;


          } catch (e) {
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
              this.seekdata = true;
              this.getErrorValues();
          }
        },
        error => {
          this.errorMessage = error;
          this.seekdata = true;
          this.getErrorValues();
        });
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.getErrorValues();
      }
   }

   // assign error values...

   getErrorValues(): void {
    this.loaded = true;
    this.error = true;
    this.seekdata = true;
   }

   ngOnDestroy() {
    try {
      if (this.subscriptionToAssetGroup) { this.subscriptionToAssetGroup.unsubscribe(); }
      if (this.dataSubscriber) {this.dataSubscriber.unsubscribe(); }
      if (this.subscriptionDomain) {this.subscriptionDomain.unsubscribe(); }
      clearInterval(this.autorefreshInterval);
    } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.getErrorValues();
    }
  }

}