<div class="card tab-box">
        <app-asset-data-table [searchTextValues]=searchTxt [errorValue]='errorValue'
                [parentName]="'patchingDataTableMessage'" [showGenericMessage]='showGenericMessage'
                [allColumns]='allColumns' [outerArr]='outerArr' [heightValue]="'27.4em'" [searchableHeader]='true'
                [dataHead]="'Associated Policies'" [popRows]='popRows' [totalRows]='totalRows'
                [firstPaginator]='firstPaginator' [tableIdAppend]="'1'" [lastPaginator]='lastPaginator'
                [currentPointer]='currentPointer' (previousPageCalled)='prevPg()' (nextPageCalled)='nextPg()'
                (searchRowTxt)='searchCalled($event)' (searchTriggerred)='callNewSearch()'
                (selectedRow)=' goToDetails($event)' [columnWhiteList]="[ 'Policy Name', 'Last Scanned', 'frequency']">
        </app-asset-data-table>
        <div class="error_msg">
                <app-error-message *ngIf='errorValue === 1 && dataTableData.length === 0' [selectedValue]="errorMessage"></app-error-message>
            </div>
</div>