import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-asset-contents',
  templateUrl: './asset-contents.component.html',
  styleUrls: ['./asset-contents.component.css']
})
export class AssetContentsComponent implements OnInit {

  @Input() resource: any;

  constructor() { }

  ngOnInit() {
  }

}
