
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';

@Injectable()

export class AwsResourceTypeSelectionService {
    private subject = new ReplaySubject<any>();
    private allResources = new ReplaySubject<any>();

    awsResourceSelected(resource: string) {
        this.subject.next(resource);
    }

    allAwsResourcesForAssetGroup(allAwsResources: {}) {
        this.allResources.next(allAwsResources);
    }

    getAllAwsResources(): Observable<any> {
        return this.allResources.asObservable();
    }

    getSelectedResource(): Observable<any> {
        return this.subject.asObservable();
    }
}
