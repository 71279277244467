<app-sticy-table
  [searchTextValues]="searchTxt"
  [showGenericMessage]="showGenericMessage"
  [errorValue]="errorValue"
  [parentName]="'taggingTargetMessage'"
  [showingArr]="showingArr"
  [outerArr]="outerArr"
  [searchableHeader]="true"
  [allColumns]="allColumns"
  [dataHead]="'All Sticky Exceptions'"
  (selectedRow)="goToDetails($event)"
  [firstPaginator]="firstPaginator"
  [lastPaginator]="lastPaginator"
  [totalRows]="totalRows"
  [totalPages]='totalPages' 
  [pageNumber]='pageNumber'
  [currentPointer]="currentPointer"
  (searchRowTxt)="searchCalled($event)"
  (previousPageCalled)="prevPage()"
  (nextPageCalled)="nextPage()"
  (searchTriggerred)="callNewSearch()">
</app-sticy-table>
