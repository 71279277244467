
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-table-tabs',
  templateUrl: './table-tabs.component.html',
  styleUrls: ['./table-tabs.component.css']
})
export class TableTabsComponent implements OnInit {
  constructor() {}

  @Input() tabsData :any = [];
  @Input() displayProperty :any = null;
  @Input() selected :any = null;
  @Output() onSelectChange = new EventEmitter();

  ngOnInit() {}

  selectTab(tab :any , index :any) {
    if (tab === this.selected) {
      return;
    }
    let direction;
    const prevIndex = !!this.selected
      ? _.findIndex(this.tabsData, (tabData :any) => {
          return (
            tabData[this.displayProperty] === this.selected[this.displayProperty]
          );
        }) + 1
      : 0;
    direction = index < prevIndex ? 'left' : 'right';

    this.selected = tab;
    this.onSelectChange.emit({
      tab: this.selected,
      index: index,
      direction: direction
    });
  }

  tabSelected(tab :any) {
    if (!this.selected) {
      return false;
    }
    return this.selected[this.displayProperty] === tab[this.displayProperty];
  }
}
