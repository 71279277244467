
import { Injectable } from '@angular/core';
import { DataCacheService } from './data-cache.service';
// @ts-ignore
import CryptoJS from 'crypto-js';


@Injectable()
export class AuthSessionStorageService {
  constructor(private dataStore: DataCacheService) {}

  saveUserDetails(fetchedResult :any) {
    this.dataStore.setCurrentUserLoginDetails(JSON.stringify(fetchedResult));
    this.dataStore.setUserDefaultAssetGroup(fetchedResult.userInfo.defaultAssetGroup);

    let email = this.dataStore.getUserDetailsValue() ? this.dataStore.getUserDetailsValue().getEmail() : 'guestUser';

    if (!email) {
      email = 'guestUser';
    }
    const emailHashed = (CryptoJS.SHA256(email)).toString();
    this.dataStore.setHashedIdOfUser(emailHashed);

    /* Below local storage key is added and removed to
    transfer storage to other tabs on successful login */
    localStorage.setItem('loginsuccess', '.');
    localStorage.removeItem('loginsuccess');
  }


}
