

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable()

export class AssetCostService {
    constructor(@Inject(HttpService) private httpService: HttpService,
                private errorHandling: ErrorHandlingService) {}

    // @ts-ignore
  getData(Url :string, Method :any): Observable<any> {
        const url = Url;
        const method = Method;
        const payload :any = {};
        const queryParams :any = {};
        const costArray :any= [];
        try {
            return this.httpService.getHttpResponse(url, method, payload, queryParams)
                    .map(response => {
                        if (Object.keys(response).length > 0) {
                            for (let i = 0; i < Object.keys(response).length; i++) {
                                costArray.push({
                                    name: Object.keys(response)[i],
                                    value: '$' + this.toInt(Object.values(response)[i]) // adding USD symbol to cost
                                });
                            }
                        }
                        return costArray;
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }

    }

    // round off value and cast to integer
    toInt (n :any) {
        return Math.round(Number(n));
    }
}
