
// @ts-ignore
import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import {DataCacheService} from './data-cache.service';
import {AssetGroupObservableService} from './asset-group-observable.service';
import {LoggerService} from '../../shared/services/logger.service';
import { DomainTypeObservableService } from './domain-type-observable.service';
import {environment} from "../../../environments/environment";

@Injectable()
export class AssetTilesService {

    constructor(@Inject(HttpService) private httpService: HttpService,
                private errorHandlingService: ErrorHandlingService,
                private dataStore: DataCacheService,
                private assetGroupObservableService: AssetGroupObservableService,
                private logger: LoggerService,
                private domainTypeObservableService: DomainTypeObservableService) { }

    // @ts-ignore
  getAssetdetailTiles(queryParams :any, assetDetailUrl :any, assetDetailMethod :any): Observable<any> {
        const url = assetDetailUrl;
        const method = assetDetailMethod;
        const payload = {};

        try {
          return Observable.combineLatest(
            this.httpService.getHttpResponse(url, method, payload, queryParams)
            .map((response :any) => this.massageData(response) )
          );
        } catch (error) {
            this.errorHandlingService.handleJavascriptError(error);
        }
    }

    // @ts-ignore
  getAssetTiles(assetUrl :any, assetMethod :any): Observable<any> {

        const url = assetUrl;
        const method = assetMethod;
        const payload = {};
        try {
          return Observable.combineLatest(
            this.httpService.getHttpResponse(url, method, payload)
            .map((response :any) => this.massageData(response) )
          );
        } catch (error) {
            this.errorHandlingService.handleJavascriptError(error);
        }
    }

    // @ts-ignore
  updateDefaultAssetGroupForUser(url :any, method :any, groupName :any, userId :any): Observable<any> {
        try {
            const payload = {
                'defaultAssetGroup': groupName,
                'userId': userId
            };
            return this.httpService.getHttpResponse(url, method, payload)
                   .map((response :any) => response);
        } catch (error) {
            this.errorHandlingService.handleJavascriptError(error);
        }
    }

    massageData(data :any): any {
        return data;
    }

    // @ts-ignore
  getAssetGroupDisplayName(assetGroupName :any) :any {
        try {
            return new Observable((observer :any) => {
                try {
                    const assetGroupListSessionStored = this.dataStore.getListOfAssetGroups();
                    if (!assetGroupListSessionStored) {
                        this.getAssetGroupList().subscribe((response :any) => {
                            const assetGroupObject = this.filterAssetGroupObjectFromList(assetGroupName, response);
                            const assetGroupDisplayName = assetGroupObject ? assetGroupObject.displayname : assetGroupName;
                            observer.next(assetGroupDisplayName);
                            observer.complete();
                        });
                    } else {
                        /* If list is availbe then get asset group display name, domain list from a matchign asset group name */
                        const assetGroupList = JSON.parse(assetGroupListSessionStored);
                        const assetGroupObject = this.filterAssetGroupObjectFromList(assetGroupName, assetGroupList);
                        const assetGroupDisplayName = assetGroupObject ? assetGroupObject.displayname : assetGroupName;
                        observer.next(assetGroupDisplayName);
                        observer.complete();
                    }
                } catch (error) {
                    this.logger.log('error', 'js error - ' + error);
                }
            });
        } catch (error) {
            this.logger.log('error', 'js error - ' + error);
        }
    }

    filterAssetGroupObjectFromList(assetGroupName :any, assetGroupList :any) {
        try {
            let isAgPresent = false;
            let assetGroupObject;
            const filteredArray = assetGroupList.filter((element :any) => element.name === assetGroupName);

            if (filteredArray.length) {
                isAgPresent = true;
                assetGroupObject = filteredArray[0];
            }

            return assetGroupObject;
        } catch (error) {
            this.logger.log('error', 'js error - ' + error);
        }
    }

    // @ts-ignore
  getAssetGroupList() :Observable<any> {
        try {
            return new Observable((observer :any) => {
                const assetUrl = environment.assetTiles.url;
                const assetMethod = environment.assetTiles.method;

                this.getAssetTiles(assetUrl, assetMethod).subscribe(
                  (response:any) => {
                        const assetGroupList = response[0];
                        if (assetGroupList) {
                            /* Store the list in stringify format */
                            this.dataStore.setListOfAssetGroups(JSON.stringify(assetGroupList));
                            observer.next(assetGroupList);
                            observer.complete();
                        }
                    },
                  (error :any) => {
                        observer.next([]);
                        observer.complete();
                        this.logger.log('error', error);
                    }
                );
            });
        } catch (error) {
            this.logger.log('error', 'js error - ' + error);
        }
    }

    // @ts-ignore
  getListOfDomainsAssociatedWithAssetGroup(assetGroupName :any) :any {
        try {
            return new Observable((observer :any) => {
                let listOfDomains = this.dataStore.getCurrentSelectedDomainList() ? this.dataStore.getCurrentSelectedDomainList() : '';
                if (listOfDomains) {
                    observer.next(listOfDomains);
                    observer.complete();
                } else {
                    this.getAssetGroupList().subscribe((response :any) => {
                        const assetGroupObject = this.filterAssetGroupObjectFromList(assetGroupName, response);
                        listOfDomains = assetGroupObject.domains ? assetGroupObject.domains : [];
                        this.domainTypeObservableService.updateListOfDomains(listOfDomains.join('~'));
                        observer.next(listOfDomains.join('~'));
                        observer.complete();
                    });
                }
            });
        } catch (error) {
            this.logger.log('error', 'js error - ' + error);
        }
    }
}
