<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box">
                    <div class="container-fluid mt-2 p-1">
                        <div class="row">
                            <div class="col-md-3 mb-4">
                                <h6 class="card-title">Additional Rule Parameters</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11 mx-auto py-4 ps-5" [hidden]="hideContent">
                                <div class="row mb-4">
                                    <div class="col-md-12 mb-4">
                                        <h6 class="card-title">Provide additional Rule parameters to invoke rule : <span>{{ruleId}}</span></h6><br>
                                        <div class="heading-gray">Rule Parameters
                                            <div class="row">
                                                <div class="col-3 pe-1">
                                                    <input type="text" #ruleParamKey class="form-control input-field-small" placeholder="Enter Key Name"
                                                        name="ruleParamKey" ngModel />
                                                </div>
                                                <div class="col-3">
                                                    <input type="text" #ruleParamValue class="form-control input-field-small" placeholder="Enter Value"
                                                        name="ruleParamValue" ngModel>
                                                </div>
                                                <div class="col-2 px-0  pt-2">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" #isRuleParamEncrypted id="encryptruleparam">
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Encrypt
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-2 px-0 ">
                                                    <button class="btn add-btn" [disabled]="!(ruleParamValue.value&&ruleParamKey.value)"
                                                        (click)="addOptionalRuleParameters(ruleParamKey, ruleParamValue, isRuleParamEncrypted)">+ Add</button>
                                                </div>
                                            </div>
                                            <div class="row px-2">
                                                <div class="col-12  border rounded-2 mt-2 px-0 ms-1"  *ngIf="allOptionalRuleParams.length==0">
                                                    <div class=" text-center py-5">No Optional Rule Parameters Added</div>
                                                </div>
                                                <div class="col-10  border rounded-2 mt-2 px-0 ms-1" *ngIf="allOptionalRuleParams.length!==0">
                                                    <table class="table table-hover px-0 table-text">
                                                        <thead>
                                                            <tr>
                                                                <th>Key</th>
                                                                <th scope="col">Value </th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let ruleParams of allOptionalRuleParams;let ruleIndex = index">
                                                            <tr>
                                                                <td>{{ruleParams.key}}</td>
                                                                <td *ngIf="!ruleParams.encrypt">{{ruleParams.value}}</td>
                                                                <td *ngIf="ruleParams.encrypt"> XXXX <span class="highlight-pink">(Encrypted)</span></td>
                                                                <td><a (click)="removeOptionalRuleParameters(ruleIndex)">X</a></td>
                                                            </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>                                    
                                    <div class="col-12">
                                        <div class="col-md-4 mx-auto my-4 button-div">
                                            <button class="submit-btn btn-border me-2 cancelbut" type="button" (click)="navigateBack()"><i class="bi bi-x fs-5"></i>
                                                Cancel</button>
                                            <button class="submit-btn btn-green ms-2 greenbut" type="submit" (click)="invokeRule()"><i class="bi bi-plus fs-5"></i> Invoke</button>
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pacman-alert" *ngIf="isRuleInvokeFailed">
                                <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                    <span class="sa-x-mark animateXMark">
                                        <span class="sa-line sa-left"></span>
                                    <span class="sa-line sa-right"></span>
                                    </span>
                                </div>
    
                                <div class="pacman-alert-message">
                                    Rule Invocation Failed !!!
                                </div>
                                <div class="pacman-alert-button">
                                    <button class="submit-btn btn-border me-2 cancelbut" type="button" (click)="closeErrorMessage()"><i class="fa fa-remove"></i> Close</button>
                                </div>
                            </div>
                            <div class="pacman-alert" *ngIf="isRuleInvokeSuccess">
                                <div class="sa-icon sa-success animate">
                                    <span class="sa-line sa-tip animateSuccessTip"></span>
                                    <span class="sa-line sa-long animateSuccessLong"></span>
                                    <div class="sa-placeholder"></div>
                                    <div class="sa-fix"></div>
                                </div>
    
                                <div class="pacman-alert-title">
                                    Rule Invoked
                                </div>
                                <div class="pacman-alert-message">
                                    <span class="highlight-pink">{{ruleId}}</span> has been successfully invoked !!!
                                    <div>Your Invocation ID is: <span class="highlight-pink"><b class="invocationId">{{invocationId}}</b></span></div>
                                </div>
                                <div class="pacman-alert-button">
                                    <button class="submit-btn btn-border me-2 cancelbut" type="button" (click)="navigateBack()"><i class="fa fa-remove"></i> Close</button>
                                </div>
                            </div>
                            <div class="pacman-progress" *ngIf="ruleLoader">
                                <div class="title">
                                    Please Wait...
                                </div>
                                <div class="pacman-alert-message">
                                    <span class="highlight-pink">{{ruleId}}</span> rule is been invoking...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>