import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { AutorefreshService } from '../../services/autorefresh.service';
import { GrcService } from '../../services/grc.service';
@Component({
  selector: 'app-functionwise-risk',
  templateUrl: './functionwise-risk.component.html',
  styleUrls: ['./functionwise-risk.component.css']
})
export class FunctionwiseRiskComponent implements OnInit {

  private riskDataUrl = environment.functionWiseRisk.url;
  private riskDataMethod = environment.functionWiseRisk.method;
  functionData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToFunction: Subscription | any;
  public totalseveritycount:number[]=[];
  contValue = false;
  loaded = false;
  constructor(private grcService:GrcService,
    private errorHandling: ErrorHandlingService) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar", 
        height: 250,
        stacked: true,
          
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,                  
          dataLabels: {                    
            total: {
              enabled: false,    
              offsetY: 8,  
              offsetX: 15, 
              textAnchor: 'end',
              style: {
                fontSize: '12px',
                fontWeight: 500,                
              }
            }
          }
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        // categories:['Human Resource Security', 'Operation Security', 'Asset Management', 'Security Awareness', 'System Acquisition, Development and Maintenance','Cryptography', 'Communication Security', 'Information Security Incident Management', 'Physical and Environmental Security','Business Continuity & Disaster Recovery'],
        labels: {
          y: {
            formatter: function(val: string) {
              return val + "K";
            }
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(val: string) {
            return val + "K";
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        offsetX: 40
      },
      colors: ['#0097ac','#01b27c','#006e74','#dab315','#fc6a59', '#d9434e'],
      dataLabels: { 
        enabled: false,
      },
    };
  }
  ngOnInit() {
    this.getByFunction();
  }

  getByFunction() {
    try {
      this.subscriptionToFunction = this.grcService
        .executeHttpAction(
          this.riskDataUrl,
          this.riskDataMethod, {}, {}
        )
        .subscribe(
          (response: any) => {
            this.contValue = true;
            this.loaded = true;
            try {
              const functionWiseRisks = response[0]['functionWiseRisks'];
  
              const desiredSeverityOrder = ["Blank", "1-Negligible", "2-Low", "3-Medium", "4-High", "5-Very High"];
  
              const seriesData = desiredSeverityOrder.map(severityName => ({
                name: severityName,
                data: functionWiseRisks.map((fwr:any) => {
                  const severity = fwr.severities.find((s:any) => s.name === severityName);
                  return severity ? severity.count : 0;
                })
              }));
  
              const categories = functionWiseRisks.map((fwr:any) => fwr.functionName);
              this.totalseveritycount = functionWiseRisks.map((data:any) => data.totalSeverityCount);
  
              this.chartOptions.series = seriesData;
              this.chartOptions.xaxis = {
                categories: categories,
              };
  
            } catch (e) {
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }
  }
}
