import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
 import {DomainTypeObservableService} from "../../../core/services/domain-type-observable.service";
 import {WorkflowService} from "../../../core/services/workflow.service";
 import {AssetGroupObservableService} from "../../../core/services/asset-group-observable.service";
 import {Subscription} from "rxjs/Subscription";
 import {DataCacheService} from "../../../core/services/data-cache.service";
import { MenuService } from 'src/app/core/services/menu.service';
import { DomainMappingService } from 'src/app/core/services/domain-mapping.service';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { PermissionGuardService } from 'src/app/core/services/permission-guard.service';

@Component({
  selector: 'app-contextual-menu',
  templateUrl: './contextual-menu.component.html',
  styleUrls: ['./contextual-menu.component.css'],
  providers: []
})
export class ContextualMenuComponent implements OnInit, OnChanges, OnDestroy {

  @Input() config : any;

  private selectedDomainName = '';
  listOfContextualMenuItems: any[] =[];
  private subscriptionToDomainType: Subscription |any;
  private domainSubscription: Subscription |any;

   assetListDomainList: Subscription |any;
   adminListDomainList: Subscription |any;
   grcDomainList: Subscription |any;
  public agAndDomain :any= {};
  addClass: boolean =false;

  provider:any = [];
  private assetGroupSubscription: Subscription |any;
  adminAccess = false; // check for admin access

  constructor(private domainMappingService: DomainMappingService,
    private loggerService: LoggerService,
    private domainObservableService: DomainTypeObservableService,
    private dataCacheService: DataCacheService,
    private router: Router,
    private menuService: MenuService,
    private assetGroupObservableService: AssetGroupObservableService,
    private permissions: PermissionGuardService,
    ) {
  }

  ngOnInit(): void {
    this.updateMenuListOnDomainUpdate();
    this.subscribeToDomainType();
    this.assetListDomain();
    this.adminListDomain();
    this.subscribeToAgAndDomainChange();

    this.adminAccess = this.permissions.checkAdminPermission();

    this.menuService.currentMessage.subscribe(toggle => {
      this.addClass = !this.addClass; 
    })
  }


  subscribeToAgAndDomainChange() {
    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assetGroup => {
      this.agAndDomain['ag'] = assetGroup;
    });
    this.domainSubscription = this.domainObservableService.getDomainType().subscribe(domain => {
      this.agAndDomain['domain'] = domain;
      this.getProvider();
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
  }


  getProvider() {
    /* Store the recently viewed asset list in stringify format */
    try {
    let recentList = '';
    recentList = this.dataCacheService.getRecentlyViewedAssetGroups();
    if (recentList) {
      const currentAGDetails = JSON.parse(recentList).filter((element:any) => element.ag === this.agAndDomain['ag']);
      this.provider = this.fetchprovider(currentAGDetails);
     }
    } catch (error) {
      this.loggerService.log('error', error);
    }
  }



  fetchprovider(assetGroupObject :any) {
    const provider :any = [];
    if (assetGroupObject.length && assetGroupObject[0].providers) {
      assetGroupObject[0].providers.forEach((element:any) => {
        provider.push(element.provider);
      });
    }
    return provider;
}

ngOnDestroy() {
  try {
    this.assetGroupSubscription.unsubscribe();
    this.domainSubscription.unsubscribe();
  } catch (error) {
    this.loggerService.log('error', 'js error - ' + error);
  }
}

  subscribeToDomainType() {
    try {
      this.subscriptionToDomainType = this.domainObservableService
          .getDomainType()
          .subscribe(DomainName => {
            if (DomainName) {
              this.selectedDomainName = DomainName;
              this.updateMenuListOnDomainUpdate();
              this.assetListDomain();
              this.adminListDomain();            
            }
          });
    } catch (error) {
      this.loggerService.log('error', error);
    }
  }

  updateMenuListOnDomainUpdate() {
    try {
      const moduleName = 'compliance';
      const domainName = this.selectedDomainName || '';
      this.listOfContextualMenuItems = this.domainMappingService.getDashboardsApplicableForADomain(domainName, moduleName);     
    } catch (error) {
      this.loggerService.log('JS Error ', error);
    }
  }
  

  assetListDomain() {
    try {
      const moduleName = 'assets';
      const domainName = this.selectedDomainName || '';
      this.assetListDomainList = this.domainMappingService.getDashboardsApplicableForADomain(domainName, moduleName);      
    } catch (error) {
      this.loggerService.log('JS Error ', error);
    }
  }

  adminListDomain() {
    try {
      const moduleName = 'admin';
      const domainName = this.selectedDomainName || '';
      this.adminListDomainList = this.domainMappingService.getDashboardsApplicableForADomain(domainName, moduleName);            
    } catch (error) {
      this.loggerService.log('JS Error ', error);
    }
  }

  grcDomain() {
    try {
      const moduleName = 'grc';
      const domainName = this.selectedDomainName || '';
      this.grcDomainList = this.domainMappingService.getDashboardsApplicableForADomain(domainName, moduleName);            
    } catch (error) {
      this.loggerService.log('JS Error ', error);
    }
  }

}
