import { Injectable } from '@angular/core';
import { HttpService } from './http-response.service';
import { ErrorHandlingService } from './error-handling.service';
import { Observable } from 'rxjs/Rx';
import { of } from 'rxjs';

@Injectable()
export class VulnerabilityAcrossApplicationService {

  constructor(
    private httpService: HttpService,
    private errorHandling: ErrorHandlingService) { }

getData(queryParams:any, VulnerabilityAcrossApplicationUrl:any, VulnerabilityAcrossApplicationMethod:any): Observable<any> {

const url = VulnerabilityAcrossApplicationUrl;
const method = VulnerabilityAcrossApplicationMethod;
const payload = {};
try {
return this.httpService.getHttpResponse(url, method, payload, queryParams)
        .map(response => {
            this.dataCheck(response);
            return this.massageData(response);
        });
} catch (error) {
this.errorHandling.handleJavascriptError(error);
return of(undefined); 
}
}

getpolicyEnv(queryParams:any, PolicyAcrossApplicationUrl:any, PolicyAcrossApplicationMethod:any): Observable<any> {
const url = PolicyAcrossApplicationUrl;
const method = PolicyAcrossApplicationMethod;
const payload = {};
try {
return this.httpService.getHttpResponse(url, method, payload, queryParams)
        .map(response => {
            this.dataCheck(response);
            return this.massageData(response);
        });
} catch (error) {
this.errorHandling.handleJavascriptError(error);
return of(undefined); 
}
}

dataCheck(data:any) {
const APIStatus = this.errorHandling.checkAPIResponseStatus(data);
if (!APIStatus.dataAvailble) {
throw new Error('noDataAvailable');
}
}

massageData(data:any): any {
/**
* here goes the massaging the data to a desired format
* the format is described in mockdata */
const tablebodyData = data.response;
const jsonObjOuter:any = [];
tablebodyData.forEach((elementOut:any , indexOut:any) => {
const eachElement = elementOut;
let appName;
const jsonObjInner:any = [];
eachElement.severityinfo.forEach((elementInside:any , indexInside:any) => {
// if count value is 0 add nodata
if (elementInside.count === undefined) {
  elementInside.count = 'nodata';
}
jsonObjInner.push({
  'count' : elementInside.count,
  'severity' : elementInside.severity,
});
});
    /**
   * this is the final object for table
   */

  if (elementOut.application === undefined) {
    appName = elementOut.environment;
  } else {
    appName = elementOut.application;
  }
/**
* sort the json obj based on which column has to be shown first
*/
jsonObjInner.sort(function(a:any, b:any) {
const x = a.severity < b.severity ? -1 : 1;
return x;
});
/**
* this is the final object for table
*/
jsonObjOuter.push({
'AppName' : appName,
'S3' : jsonObjInner[0].count,
'S4' : jsonObjInner[1].count,
'S5' : jsonObjInner[2].count,
'AppDetails' : [{
  'CountType' : jsonObjInner[0].severity,
  'count' : jsonObjInner[0].count
},
{
  'CountType' : jsonObjInner[1].severity,
  'count' : jsonObjInner[1].count
},
{
  'CountType' : jsonObjInner[2].severity,
  'count' : jsonObjInner[2].count
}]
});
});
jsonObjOuter.forEach((element:any) => {
element.AppDetails.forEach((details:any) => {
    element[details.CountType] = details.count;
});
});
return jsonObjOuter;
}
}
