<!-- <app-overlay (click)='closeModal();'></app-overlay> -->
<div class="fixed flex flex-col generic-modal">
    <!-- <div class="absolute close-img" (click)='closeModal();'>
      <img src='../assets/png/delete.png'>
    </div> -->
    <div class="title-txt no-shink">{{title}}</div>
    <div class="main-container">
        <ng-content></ng-content>
    </div>
</div>

