<div class="card pichartbox p-3" *ngIf="finalData && finalData.response">
  <div class="row">
    <div class="col-6 tagging-box pe-0">
      <h4>All Assets</h4>
      <div *ngFor="let data of finalData.response" class="badge-div">
        <div [ngClass]="{'orange-badge': data.text === 'Untagged' ,
                          'geeen-badge': data.text === 'Total Assets',
                          'gray-badge': data.text === 'Tagged'}">
        </div>
        <span *ngIf="data.text !== 'Compliant' && data.value !== ''"> {{data.text}} &nbsp; {{data.value}}</span> <br>
      </div>
    </div>
    <div class="col-6">
            <app-circle-graph [complaint]="complaint"></app-circle-graph>
    </div>
  </div>
</div>
