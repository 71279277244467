import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexGrid,
  ApexYAxis
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  yaxis: ApexYAxis
};
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { AutorefreshService } from '../../services/autorefresh.service';
import { GrcService } from '../../services/grc.service';
@Component({
  selector: 'app-riskbyaging',
  templateUrl: './riskbyaging.component.html',
  styleUrls: ['./riskbyaging.component.css']
})
export class RiskbyagingComponent implements OnInit {

  private riskDataUrl = environment.riskByAging.url;
  private riskDataMethod = environment.riskByAging.method;
  agingData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToAging: Subscription | any;
  contValue = false;
  loaded = false;
  constructor(private grcService:GrcService,
    private errorHandling: ErrorHandlingService) { this.chartOptions = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Negligible Pending above 6 month','Low Pending above 6 month','Medium Pending above 6 months','High Pending above 6 month','Very High Pending above 6 month']
    }, 
      
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 2,
    },
    series: [
      {
        name: 'basic',
        data: [0,0, 0,0],
      },
    ],

    legend: {
      show: false,
    },

    colors: ['#dab315','#d9434e','#fc6a59', '#01b27c'],

    chart: {
      type: 'bar',
      height: 180,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        barHeight: '40%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
  };}

  ngOnInit() {
    this.getByAging();
  }
  getByAging(): void {
    try {
      this.subscriptionToAging = this.grcService
        .executeHttpAction(
          this.riskDataUrl,
          this.riskDataMethod, {}, {}
        )
        .subscribe(
          (response:any) => {
            this.contValue = true;
              this.loaded = true;
            try {
              this.agingData = response[0];
              this.series = [];
              this.chartCategories = [];
        
              for (let i = 0; i < this.agingData.length; i++) {
                const agingItem = this.agingData[i];
                  this.chartCategories.push(agingItem.name);
                  this.series.push(agingItem.count);
              }
              this.chartOptions.series = [
                {
                  data: this.series,
                },
              ];

              this.chartOptions.xaxis = {
                categories: this.chartCategories,
              };
              
            } catch (e) {
              
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {8
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    this.series = [];
    this.chartCategories =[];
  }

}
