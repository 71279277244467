<app-compliance-issues></app-compliance-issues>
<app-data-table
  (noScrollDetected)="noMinHeight = true"
  [searchTextValues]="searchTxt"
  [errorValue]="errorValue"
  [parentName]="'policySummaryTableMessage'"
  [showGenericMessage]="showGenericMessage"
  [showingArr]="showingArr"
  [outerArr]="outerArr"
  [heightValue]="'calc(70vh - 14em)'"
  [searchableHeader]="true"
  [allColumns]="allColumns"
  (selectedRow)="goToDetails($event)"
  [dataHead]="'Policy Compliance Overview'"
  [firstPaginator]="firstPaginator"
  [lastPaginator]="lastPaginator"
  [popRows]="popRows"
  [totalRows]="totalRows"
  [currentPointer]="currentPointer"
  (searchRowTxt)="searchCalled($event)"
  (searchTriggerred)="callNewSearch()"
  (previousPageCalled)="prevPg()"
  (tabSelected)="tabValChange($event)"
  [headerColName]="'Policy Severity'"
  (nextPageCalled)="nextPg()"
  [direction]="-1"
  [tabFilterProperty]="'Rule Category'"
  [columnWhiteList]="[ 'compliance', 'Policy Title', 'Compliance %', 'Policy Severity',   'Last Scanned']"
  [paginatorAbsent]="true"
  [popRows]="popRows"
  (rowClickText)="handlePopClick($event)"
  [searchPassed]="searchPassed"
  [regulatoryData]="regulatoryData"
  [id]="'overviewDataTable'"
>
</app-data-table>

<app-compliance-overview-trend></app-compliance-overview-trend>
<app-issues-trend-history></app-issues-trend-history>
