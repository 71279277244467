<!--
 *Copyright 2018 T Mobile, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); You may not use
 * this file except in compliance with the License. A copy of the License is located at
 * 
 * http://www.apache.org/licenses/LICENSE-2.0
 * 
 * or in the "license" file accompanying this file. This file is distributed on
 * an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or
 * implied. See the License for the specific language governing permissions and
 * limitations under the License.
 -->

 <div class="installed-container relative">
    <div class="installed-content relative">
        <app-asset-data-table [searchTextValues]='searchTxt'
                        [errorValue]='errorValue'
                        [parentName]="'patchingDataTableMessage'"
                        [showGenericMessage]='showGenericMessage'
                        [allColumns]='allColumns'
                        [outerArr]='outerArr'
                        [heightValue]="'27.4em'"
                        [searchableHeader]='true'
                        [dataHead]="'Installed Softwares'"
                        [totalRows]='totalRows'
                        [tableIdAppend]="'4'"
                        [firstPaginator]='firstPaginator'
                        [lastPaginator]='lastPaginator'
                        [currentPointer]='currentPointer'
                        (previousPageCalled)='prevPg()'
                        (nextPageCalled)='nextPg()'
                        (searchRowTxt)='searchCalled($event)'
                        (searchTriggerred)='callNewSearch()'>
        </app-asset-data-table>
    </div>
</div>