import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { DataCacheService } from 'src/app/core/services/data-cache.service';
import { DomainTypeObservableService } from 'src/app/core/services/domain-type-observable.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-risk-management',
  templateUrl: './risk-management.component.html',
  styleUrls: ['./risk-management.component.css']
})
export class RiskManagementComponent implements OnInit {
  breadcrumbArray: any = ['GRC'];
  breadcrumbLinks: any = ['risk-management'];
  breadcrumbPresent: any = 'Risk-Management';
  pageTitle: string = 'Risk Mangement';
  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  filterText: any = {};
  provider:any = [];
  mandatory: any;
  errorMessage: any;
  urlID: string = '';
  public agAndDomain :any= {};
  private assetGroupSubscription: Subscription |any;
  private domainSubscription: Subscription |any;

  constructor(
    private breadCurmbService: BreadcrumbService,
    private routerUtilityService: RouterUtilityService,
    private activatedRoute: ActivatedRoute,
    private assetGroupObservableService: AssetGroupObservableService,
    private domainObservableService: DomainTypeObservableService,
    private router: Router,
    private loggerService: LoggerService,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private dataCacheService: DataCacheService,

  ) { 
    }

  ngOnInit(): void {
    this.subscribeToAgAndDomainChange();
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    this.routerParam();
  }

  subscribeToAgAndDomainChange() {
    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assetGroup => {
      this.agAndDomain['ag'] = assetGroup;
    });
    this.domainSubscription = this.domainObservableService.getDomainType().subscribe(domain => {
      this.agAndDomain['domain'] = domain;
      this.getProvider();
    });
  }

  
  getProvider() {
    /* Store the recently viewed asset list in stringify format */
    try {
    let recentList = '';
    recentList = this.dataCacheService.getRecentlyViewedAssetGroups();
    if (recentList) {
      const currentAGDetails = JSON.parse(recentList).filter((element:any) => element.ag === this.agAndDomain['ag']);
      this.provider = this.fetchprovider(currentAGDetails);
     }
    } catch (error) {
      this.loggerService.log('error', error);
    }
  }



  fetchprovider(assetGroupObject :any) {
    const provider :any = [];
    if (assetGroupObject.length && assetGroupObject[0].providers) {
      assetGroupObject[0].providers.forEach((element:any) => {
        provider.push(element.provider);
      });
    }
    return provider;
}
   /*
    * This function gets the urlparameter and queryObj 
    *based on that different apis are being hit with different queryparams
    */
    routerParam() {
      try {
        // this.filterText saves the queryparam
        let currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
        if (currentQueryParams) {
  
          this.FullQueryParams = currentQueryParams;
  
          this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
          delete this.queryParamsWithoutFilter['filter'];
  
          /**
           * The below code is added to get URLparameter and queryparameter
           * when the page loads ,only then this function runs and hits the api with the
           * filterText obj processed through processFilterObj function
           */
          this.filterText = this.utils.processFilterObj(
            this.FullQueryParams
          );
  
          this.urlID = this.FullQueryParams.TypeAsset;
          //check for mandatory filters.
          if (this.FullQueryParams.mandatory) {
            this.mandatory = this.FullQueryParams.mandatory;
          }
  
        }
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.logger.log('error', error);
      }
    }
  
}
