import { Component, OnInit } from '@angular/core';
import { UploadFileService } from 'src/app/pacman-features/services/upload-file-service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { AdminService } from 'src/app/pacman-features/services/admin.service';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { environment } from '../../../../../../environments/environment';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-delete-sticky-exceptions',
  templateUrl: './delete-sticky-exceptions.component.html',
  styleUrls: ['./delete-sticky-exceptions.component.css'],
  providers: [
    LoggerService,
    ErrorHandlingService,
    UploadFileService,
    AdminService
  ]
})
export class DeleteStickyExceptionsComponent implements OnInit {
  pageTitle: String = 'Delete Sticky Exceptions';
  breadcrumbArray: any = ['Admin', 'Sticy-Exceptions'];
  breadcrumbLinks: any = ['sticky-exceptions', 'delete-sticky-exceptions'];
  breadcrumbPresent: any = 'Delete Sticky Exceptions';   
  outerArr: any = [];
  filters: any = [];
  isExceptionDeletionFailed: boolean = false;
  isExceptionDeletionSuccess: boolean = false;
  ruleContentLoader: boolean = true;
  exceptionLoader: boolean = false;
  invocationId: String = '';
  showLoader: boolean = true;
  errorMessage: any;

  hideContent: boolean = false;

  filterText: any = {};
  errorValue: number = 0;
  urlID: String = '';
  exceptionName: string = '';
  groupName: string = '';

  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  urlToRedirect: any = '';
  mandatory: any;

  public labels: any;
  private previousUrl: any = '';
  private pageLevel = 0;
  public backButtonRequired: any;
  private routeSubscription: Subscription | undefined;
  private getKeywords: Subscription| undefined;
  private previousUrlSubscription: Subscription| undefined;
  private systemStatusSubscription: Subscription | undefined;
  private downloadSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private workflowService: WorkflowService,
    private routerUtilityService: RouterUtilityService,
    private adminService: AdminService,
    private breadCurmbService: BreadcrumbService,
  ) {

    this.routerParam();
    this.updateComponent();
  }

  ngOnInit() {
    this.urlToRedirect = this.router.routerState.snapshot.url;
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
    this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(
      this.pageLevel
    );
  }
  
  deleteException() {
    this.hideContent = true;
    this.exceptionLoader = true;
    let url = environment.deleteStickyException.url; 
    let method = environment.deleteStickyException.method; 
    this.adminService.executeHttpAction(url, method, {exceptionName: this.exceptionName, groupName: this.groupName}, {}).subscribe(reponse => {
      this.exceptionLoader = false;
      this.isExceptionDeletionSuccess = true;
    },
    error => {
      this.isExceptionDeletionFailed = true;
      this.exceptionLoader = false;
    })
  }

  closeErrorMessage() {
    this.isExceptionDeletionFailed = false;
    this.hideContent = false;
  }
  /*
    * This function gets the urlparameter and queryObj 
    *based on that different apis are being hit with different queryparams
    */
  routerParam() {
    try {
      // this.filterText saves the queryparam
      let currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;
        this.exceptionName = this.FullQueryParams.exceptionName;
        this.groupName = this.FullQueryParams.groupName;
        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        delete this.queryParamsWithoutFilter['filter'];

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        this.urlID = this.FullQueryParams.TypeAsset;
        //check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  /**
   * This function get calls the keyword service before initializing
   * the filter array ,so that filter keynames are changed
   */

  updateComponent() {
    this.outerArr = [];
    this.showLoader = true;
    this.errorValue = 0;
  }

  navigateBack() {
    try {
      this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }
      if (this.previousUrlSubscription) {
        this.previousUrlSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}
