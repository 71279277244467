
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()

export class ToastObservableService {
    private subject = new Subject;

    postMessage (msg: string, duration?:any) {
        if (msg) {
            const obj = {
                'msg': msg,
                'duration': duration
            };
            this.subject.next(obj);
        }
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

}
