import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApexChart, ApexFill, ApexNonAxisChartSeries, ApexPlotOptions } from 'ng-apexcharts';
import { Subscription } from "rxjs";
import { GrcService } from 'src/app/pacman-features/services/grc.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
};

@Component({
  selector: 'app-security-piechart',
  templateUrl: './security-piechart.component.html',
  styleUrls: ['./security-piechart.component.css']
})
export class SecurityPiechartComponent implements OnInit {

  private chartDataUrl = environment.partialchart.url;
  private chartDataMethod = environment.partialchart.method;
  partialData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  identity_value:number=0;
  detect_value:number=0;
  protect_value:number=0;
  recover_value:number=0;
  respond_value:number=0;
  identity_status:any;
  detect_status:any;
  protect_status:any;
  recover_status:any;
  respond_status:any;
  contValue = false;
  loaded = false;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToOwner: Subscription | any;
  constructor(private cdr: ChangeDetectorRef,private grcService:GrcService,
    private errorHandling: ErrorHandlingService) { }


  ngOnInit(): void {
    this.getByOwner();
  }
  getByOwner(domain:string='all'): void {
    try {
      this.contValue = false;
      this.subscriptionToOwner = this.grcService
        .executeHttpAction(
          this.chartDataUrl+'?domain='+domain,
          this.chartDataMethod, {}, {}
        )
        .subscribe( 
          (response:any) => {
            this.contValue = true;
            this.loaded = true;
            try {
              this.partialData = response[0];
              
              if(this.partialData['IDENTIFY (ID)']!=undefined){
                this.identity_value=this.partialData['IDENTIFY (ID)'].averagePracticeMaturity*20;
                this.identity_status=this.partialData['IDENTIFY (ID)'].alignment;
              }else{
                this.identity_value=0;
                this.identity_status='Not aligned';
              }
              if(this.partialData['PROTECT (PR)']!=undefined){
                this.protect_value=this.partialData['PROTECT (PR)'].averagePracticeMaturity*20;
                this.protect_status=this.partialData['PROTECT (PR)'].alignment;
              }else{
                this.protect_value=0;
                this.protect_status='Not aligned';
              }
              if(this.partialData['RECOVER (RC)']!=undefined){
                this.recover_value=this.partialData['RECOVER (RC)'].averagePracticeMaturity*20;
                this.recover_status=this.partialData['RECOVER (RC)'].alignment;
              }else{
                this.recover_value=0;
                this.recover_status='Not aligned';
              }
              if(this.partialData['RESPOND (RS)']!=undefined){
                this.respond_value=this.partialData['RESPOND (RS)'].averagePracticeMaturity*20;
                this.respond_status=this.partialData['RESPOND (RS)'].alignment;
              }else{
                this.respond_value=0;
                this.respond_status='Not aligned';
              }
              if(this.partialData['DETECT (DE)']!=undefined){
                this.detect_value=this.partialData['DETECT (DE)'].averagePracticeMaturity*20;
                this.detect_status=this.partialData['DETECT (DE)'].alignment;
              }else{
                this.detect_value=0;
                this.detect_status='Not aligned';
              }
              
              // this.series = [];
              // this.chartCategories = [];
              // for (let i = 0; i < this.partialData.length; i++) {
              //   const ownerItem = this.partialData[i];
              //     this.chartCategories.push(ownerItem.name +' '+ ownerItem.count);
              //     this.series.push(ownerItem.count);
              // }
              // this.chartOptions.series = this.series;
              
              // this.chartOptions.labels =this.chartCategories;
            } catch (e) {
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    // this.series = [];
    // this.chartCategories =[];
  }
}
