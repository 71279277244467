import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-listtable',
  templateUrl: './listtable.component.html',
  styleUrls: ['./listtable.component.css']
})
export class ListtableComponent implements OnInit {

  @Input() tableData: any;
    @Input() tableHeaderData: any;
    @Input() searchText: any = '';
    @Input() orderByConfig:any;
    @Input() orderByProperty:any;
    @Output() error: EventEmitter<any> = new EventEmitter();
    @Output() navigatePage: EventEmitter<any> = new EventEmitter();
    public tableBodyData: any;

    constructor() {
    }

    ngOnInit() {
        this.finalTableData();
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
          const graphDataChange = changes['tableData'];
          if (graphDataChange) {
            const cur  = JSON.stringify(graphDataChange.currentValue);
            const prev = JSON.stringify(graphDataChange.previousValue);
            if ((cur !== prev) && (this.tableData)) {
                this.finalTableData();
            }
          }
        } catch (error) {
          this.error.emit('jsError');
        }
      }

    finalTableData() {
        this.tableBodyData = this.tableData;
    }

    addTest(object:any) {
        if (object) {
            let count = 0;
            for (const i of object.severityinfo) {
                count += object.severityinfo[i].count;
            }
            return count;
        }else{
          return false;
        }
    }
    instructParentToNavigate (appName:any, colName:any ) {
        const emitData = {
            'Rowname': appName,
            'Colname': colName
        };
        this.navigatePage.emit(emitData);
    }

}
