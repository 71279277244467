import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from './../../../environments/environment';
import { HttpService } from '../../shared/services/http-response.service';
import { LoggerService } from '../../shared/services/logger.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable()
export class IssuesHistoryService {

    constructor (
                 @Inject(HttpService) private httpService: HttpService,
                 private logger: LoggerService,
                 private errorHandling: ErrorHandlingService) { }

    private combinedData: any = [];
    // @ts-ignore
  getData(url :string, method :string, payload :any, queryParameters :any): Observable<any> {
        try {
            return this.httpService.getHttpResponse(url, method, payload, queryParameters)
                .map(response => {
                    if (method === 'POST') {
                        return this.massagePostResponse(response);
                    } else {
                        return this.massageGetResponse(response);
                    }
                });
        } catch (error) {
            this.logger.log('error', error);
            this.errorHandling.handleJavascriptError(error);
        }
    }

    getSeverity(queryParameters :any) :any {
        const url = environment.severity.url;
        const method = environment.severity.method;
        try {
            return this.httpService.getHttpResponse(url, method, {}, queryParameters)
                    .map(response => {
                        return response;
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    massageGetResponse(data :any) {
        const apiResponse = Object.assign({}, data);
        const values :any = [];
        let formattedObject :any = {};
        const allDates = Object.keys(apiResponse).sort();
        for (let i = 0; i < allDates.length; i++) {
            // Additional property 'zero-value' being added to keep track of zero values, as the zero values are replaced
            // with 1 during plotting graph with a log axis (as [log 0]  is infinity)
            const obj = {
                'date' : new Date(allDates[i]),
                'value' : apiResponse[allDates[i]],
                'zero-value' : apiResponse[allDates[i]] === 0
            };
            values.push(obj);
        }
        formattedObject = {
            'key'    : 'total',
            'values' : values
        };
        return formattedObject;
    }

    massagePostResponse(data :any) {
        const finalData :any= [];
        let apiResponse = data.data.response['issues_info'];
        if (data.data.response['issues_info'] && apiResponse.constructor.name === 'Array') {
                apiResponse =  apiResponse.sort(function(a :any, b :any){
                a = new Date(a.date);
                b = new Date(b.date);
                const x = a < b ? -1 : 1;
                return x;
            });

        //     const types = Object.keys(apiResponse[0]);
        //     types.splice(types.indexOf('date'), 1);
        //     types.forEach(type => {
        //         const values :any = [];
        //         let formattedObject = {};
        //         apiResponse.forEach((details:any) => {
        //             const obj = {
        //                 'date' : new Date(details['date']),
        //                 'value': details[type],
        //                 'zero-value': details[type] === 0
        //             };
        //             values.push(obj);
        //         });
        //         formattedObject = {
        //             'key'    : type,
        //             'values' : values
        //         };
        //         if (type.toLowerCase() !== 'overall' && type.toLowerCase() !== 'total') {
        //             if (type.toLowerCase() !== 'security' && type.toLowerCase() !== 'governance') {
        //                 finalData.unshift(formattedObject);
        //             }
        //         } else {
        //             finalData.push(formattedObject);
        //         }
        //     });
        }
        return apiResponse;
    }

}

