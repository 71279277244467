<div class="col-lg-12 col-md-12 col-12">
  <div class="row">
    <div class="col-md-3 col-lg-3 pb-3"  *ngIf="!contValue" >
      <div class="card p-3" style="height: 140px;">
        <div class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      </div>
    </div>
      <div class="col-md-3 col-lg-3 pb-3" *ngIf="contValue">
        <div class="card p-3">
          <div class="row">
            <div class="col-5 risk-box pt-5">
              <h4>Critical Risks</h4>
            </div>
            <div class="col-7">
              <div
                id="graph1"
                class="donut-chart"
                data-amounts="80"
                data-fills="#D9434E,#01B27C"
                data-size="140"
                data-thickness="50">
                <div class="chart-label text-white">{{this.criticalRisk?.count}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  <div class="col-md-3 col-lg-3 pb-3"  *ngIf="!contValue">
        <div class="card p-3" style="height: 140px;">
          <div class="loader-container">
            <div class="loader">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    <div class="col-md-3 col-lg-3 pb-3"  *ngIf="contValue">
      <div class="card  p-3">
        <div class="row">
          <div class="col-5 risk-box pt-5">
            <h4>Un-Assigned Risk</h4>
          </div>
          <div class="col-7">
            <div
              id="graph5"
              class="donut-chart"
              data-amounts="80"
              data-fills="#008699,#01B27C"
              data-size="140"
              data-thickness="50">
              <div class="chart-label text-white">{{this.unassignedRisk?.count}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 pb-3"  *ngIf="!contValue">
      <div class="card p-3" style="height: 140px;">
        <div class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 pb-3"  *ngIf="contValue">
      <div class="card  p-3" >
        <div class="row">
          <div class="col-5 risk-box pt-5 pe-0">
            <h4>Risks with no
            Mitigation Plan</h4>
          </div>
          <div class="col-7">
            <div
              id="graph3"
              class="donut-chart"
              data-amounts="80"
              data-fills="#01B27C,#01B27C"
              data-size="140"
              data-thickness="50">
              <div class="chart-label text-white">{{this.noMitigationPlan?.count}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 pb-3"  *ngIf="!contValue">
      <div class="card p-3" style="height: 140px;">
        <div class="loader-container">
          <div class="loader">
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 pb-3" *ngIf="contValue">
      <div class="card  p-3" >
        <div class="row">
          <div class="col-5 risk-box pt-5">
            <h4>Total Risks</h4>
          </div>
          <div class="col-7">
            <div
              id="graph4"
              class="donut-chart"
              data-amounts="80"
              data-fills="#FC6A59,#01B27C"
              data-size="140"
              data-thickness="50">
              <div class="chart-label text-white">{{this.totalRisks?.count}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
