 import { Injectable, Inject } from '@angular/core';
 import { Observable } from 'rxjs/Rx';
 import 'rxjs/add/operator/toPromise';
 import { HttpService } from '../../shared/services/http-response.service';
 import { ErrorHandlingService } from '../../shared/services/error-handling.service';
 
 @Injectable()
 export class IssueListingService {
     constructor(
                 @Inject(HttpService) private httpService: HttpService,
                 private errorHandling: ErrorHandlingService) {}
 
     getData(listingPayload: any, listingUrl: any, listingMethod: any, dataQuery:{}): Observable<any> {
 
        //  try {
             const url = listingUrl;
             const method = listingMethod;
             const payload = listingPayload;
             const queryParams = dataQuery;
             return Observable.combineLatest(
                 this.httpService.getHttpResponse(url, method, payload, queryParams)
                 .map((response: any) => {
                     try {
                         this.dataCheck(response);
                         return response['data'];
                     } catch (error) {
                         this.errorHandling.handleJavascriptError(error);
                     }
                 })
             );
        //  } catch (error) {
        //      this.errorHandling.handleJavascriptError(error);
        //  }
     }
 
     dataCheck(data: any) {
         const APIStatus = this.errorHandling.checkAPIResponseStatus(data);
         if (!APIStatus.dataAvailble) {
             throw new Error('noDataAvailable');
         }
     }
 
     handleError(error: any): Observable<any> {
         return Observable.throw(error.message || error);
     }
 
 }
 