<section>
   <div class="card tab-box">
    <div class="row">
        <div class="col-md-6 header-div">
            <!-- <h6 class="card-title">All Assets</h6> -->
        </div>
        <div class="col-md-6 searchable-div">
            <div class="filter-type-div">
                <app-searchable-dropdown *ngIf='filterTypeLabels.length' [firstDD]='true'
                    (selection)='changeFilterType($event)' [placeholder]="'Select filter type'" [items]='filterTypeLabels'>
                </app-searchable-dropdown>
            </div>
            <div class="filter-tag-div">
                <app-searchable-dropdown (selection)='changeFilterTags($event)' [placeholder]="'Search filter tags'"
                    [items]='filterTagLabels'></app-searchable-dropdown>
            </div>
        </div>
        <div class='floating-widgets-filter-wrapper' *ngIf='filters.length'>
            <app-filtered-selector (deleteAllFilters)="deleteFilters($event)" (deleteFilter)="deleteFilters($event)"
                [(filteredArray)]="filters" [(mandatoryFilter)]="mandatory"></app-filtered-selector>
        </div>
    </div>
    <app-asset-data-table [searchTextValues]=searchTxt [showGenericMessage]='showGenericMessage'
        [errorValue]='errorValue' [parentName]="'taggingTargetMessage'" [showingArr]='showingArr' [outerArr]='outerArr'
        [searchableHeader]='true' [allColumns]='allColumns' [dataHead]="'All Assets'"
        (selectedRow)='goToDetails($event)' [firstPaginator]='firstPaginator' [lastPaginator]='lastPaginator'
        [totalRows]='totalRows' [currentPointer]='currentPointer' (searchRowTxt)='searchCalled($event)'
        (previousPageCalled)='prevPg()' (nextPageCalled)='nextPg()' (searchTriggerred)='callNewSearch()'
        [popRows]='popRows' (rowClickText)='handlePopClick($event)'>
    </app-asset-data-table>
    <div class="data-table-desc">{{dataTableDesc}}</div>
</div>
</section>