<div [ngClass]="{'tagging-compliance-trend-container': !dataLoaded}">
    <h6 *ngIf="!dataLoaded" class="tagging-header">Tagging Compliance Trend</h6>
        <app-multiline-trend *ngIf="dataLoaded" [id]="'taggingComplianceOverview'" [singlePercentLine]="singlePercentLine"
            [showArea]="showArea" (error)="setError($event)" [showLegend]="showGraphLegend" [yAxisLabel]="yAxisLabel"
            [subHeadTitle]="'Tagging Compliance Trend'" [graphWidth]="graphWidth"
            [graphLinesData]="graphData"></app-multiline-trend>

    <div *ngIf="!dataLoaded && !error" class="loader-container">
        <div class="loader">
            <span></span>
        </div>
    </div>

    <div *ngIf='error' class="error_handling">
        <app-error-message [selectedValue]="errorMessage"></app-error-message>
    </div>
</div>