<div *ngIf="!contValue" class="loader-container" style="position: relative; top: 200px;">
  <div class="loader" style="height: 500px;">
    <span></span>
  </div>
</div>
<div *ngIf='contValue'>
<h6 class="texttittle">Maturity Level For Individual ISMS Function</h6>
<div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [yaxis]="chartOptions.yaxis"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
      [tooltip]="chartOptions.tooltip"
      [xaxis]="chartOptions.xaxis"
    ></apx-chart>
  </div>
  <h6 class="texttittle">ISMS Category</h6>
</div>
