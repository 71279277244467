<!-- All Policies Details -->
<section id='{{id}}'>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card tab-box dataTable-section-height">
            <div *ngIf='errorValue == 0' class="loader-container">
              <div class="loader">
                <span></span>
              </div>
            </div>
            <div class='error_handling' *ngIf='errorValue == -1 && errorMessage'>
              <div class="error_msg">
                <app-error-message *ngIf='errorValue == -1 && errorMessage'
                  [selectedValue]="errorMessage"></app-error-message>
              </div>
            </div>
            <app-title-burger-head *ngIf="outerArr && outerArr.length > 0" [tableIdAppend]="tableIdAppend" (rowText)="emitRowText($event)" [popRows]="popRows"
              [showSearch]="searchableHeader" [subHeadTitle]="dataHead" (searchTxt)="searchCalled($event)"
              (enterPressed)="triggerSearch()"></app-title-burger-head>
  
            <app-table-tabs *ngIf="outerArr && outerArr.length > 0" (onSelectChange)="changeTabSelection($event)"
              [tabsData]="tabsData" [displayProperty]="rowAccessProperty">
              >
            </app-table-tabs>
  
          <div *ngIf="regulatoryData">
            <div *ngIf="outerArr && outerArr.length > 0 && showRegulatoryCards && regulatoryData.HIPAA"
              class="tab-content div-scroll" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-home-tab"
                tabindex="0">
                <div class="row flex-nowrap main-div div-scroll">
                  <!-- Box Start  -->
                  <div class="col-sm-3 col-3 inner-box">
                    <div class="tab-inner-box">
                      <!-- <div class="tab-inner-box-count">
                        Total Count 390
                        <span>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault"> </label>
                          </div>
                        </span>
                      </div> -->
                      <div class="row">
                        <p class="me-2 mt-0">Compliance Overview</p>
                        <div class="col-6 text-center">                        
                          <div class="secondbox">
                            <div
                            id="graph6" class="overview-circle-chart" data-amounts="20,80" data-fills="#01b27c,#e0e0e0" data-size="130"
                            data-thickness="8">
                              <div class="chart-label invisible">0</div>
                            </div>
                          </div>  
                          <app-circle-chart key="#graph6"
                          value="{{regulatoryData.total.compliantPercentage}}"></app-circle-chart>                                                
                            <span class="totpercent me-2 w-75">{{regulatoryData.total.compliantPercentage}}%</span>                      
                        </div>
                        <div class="col-6">
                          <div class="row p-top">
                            <div class="col-7 px-0 mb-2">Applicable</div>
                            <div class="col-5 text-end fw-bold">{{regulatoryData.total.Applicable}}</div>
  
                            <div class="col-7 px-0 mb-2">Passed</div>
                            <div class="col-5 text-green text-end fw-bold">{{regulatoryData.total.passed}}</div>
  
                            <div class="col-7 px-0 mb-2">Failed</div>
                            <div class="col-5 text-red text-end fw-bold">{{regulatoryData.total.failed}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Box End  -->
  
                  <!-- Box Start  -->
                  <div class="col-xs-3 col-3 inner-box" (click)="complianceCardClicked('hipaa')">
                    <div class="tab-inner-box">
                      <div class="tab-inner-box-count">
                        <!-- 97 Insights -->
                        <!-- <span>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault"> </label>
                          </div>
                        </span> -->
                      </div>
                      <div class="row">
                        <div class="col-4 text-center px-1 pt-0">
                          <img src="/assets/icons/hippa.svg" width="62" />
                          <p>HIPAA<br />
                            <span>{{regulatoryData.HIPAA.compliantPercentage}}%</span>
                          </p>
                        </div>
                        <div class="col-8 border-start">
                          <div class="row p-top">
                            <div class="col-8 pl-1 mb-2">Applicable</div>
                            <div class="col-4 text-end">{{regulatoryData.HIPAA.Applicable}}</div>
                            <div class="col-8 pl-1 mb-2">Passed</div>
                            <div class="col-4 text-green text-end">{{regulatoryData.HIPAA.passed}}</div>
                            <div class="col-8 pl-1 mb-2">Failed</div>
                            <div class="col-4 text-red text-end">{{regulatoryData.HIPAA.failed}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Box End  -->
  
                  <!-- Box Start  -->
                  <div class="col-sm-3 col-3 inner-box" (click)="complianceCardClicked('gdpr')">
                    <div class="tab-inner-box">
                      <div class="tab-inner-box-count">
                        <!-- 97 Insights -->
                        <!-- <span>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault"> </label>
                          </div>
                        </span> -->
                      </div>
                      <div class="row">
                        <div class="col-4 text-center px-1 pt-0">
                          <img src="/assets/icons/gdpr.svg" width="62" />
                          <p>GDPR<br />
                            <span>{{regulatoryData.GDPR.compliantPercentage}}%</span>
                          </p>
                        </div>
                        <div class="col-8 border-start">
                          <div class="row p-top">
                            <div class="col-8 pl-1 mb-2">Applicable</div>
                            <div class="col-4 text-end">{{regulatoryData.GDPR.Applicable}}</div>
                            <div class="col-8 pl-1 mb-2">Passed</div>
                            <div class="col-4 text-green text-end">{{regulatoryData.GDPR.passed}}</div>
                            <div class="col-8 pl-1 mb-2">Failed</div>
                            <div class="col-4 text-red text-end">{{regulatoryData.GDPR.failed}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Box End  -->
  
                  <!-- Box Start  -->
                  <div class="col-sm-3 col-3 inner-box" (click)="complianceCardClicked('soc2')">
                    <div class="tab-inner-box">
                      <div class="tab-inner-box-count">
                        <!-- 97 Insights -->
                        <!-- <span>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault"> </label>
                          </div>
                        </span> -->
                      </div>
                      <div class="row">
                        <div class="col-4 text-center px-1 pt-0">
                          <img src="/assets/icons/soc2.svg" width="62">
                          <p>SOC2<br /><span>{{regulatoryData.SOC2.compliantPercentage}}%</span></p>
                        </div>
                        <div class="col-8 border-start">
                          <div class="row p-top">
                            <div class="col-8 pl-1 mb-2">Applicable</div>
                            <div class="col-4 text-end">{{regulatoryData.SOC2.Applicable}}</div>
                            <div class="col-8 pl-1 mb-2">Passed</div>
                            <div class="col-4 text-green text-end">{{regulatoryData.SOC2.passed}}</div>
                            <div class="col-8 pl-1 mb-2">Failed</div>
                            <div class="col-4 text-red text-end">{{regulatoryData.SOC2.failed}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Box End  -->
  
                  <!-- Box Start  -->
                  <div class="col-sm-3 col-3 inner-box">
                    <div class="tab-inner-box">
                      <div class="tab-inner-box-count">
                      </div>
                      <div class="row">
                        <div class="col-4 text-center px-1 pt-0">
                          <img src="/assets/icons/pci.svg" width="62">
                          <p>PCI<br /><span>{{regulatoryData.PCI.compliantPercentage}}%</span></p>
                        </div>
                        <div class="col-8 border-start">
                          <div class="row p-top">
                            <div class="col-8 pl-1 mb-2">Applicable</div>
                            <div class="col-4 text-end">{{regulatoryData.PCI.Applicable}}</div>
                            <div class="col-8 pl-1 mb-2">Passed</div>
                            <div class="col-4 text-green text-end">{{regulatoryData.PCI.passed}}</div>
                            <div class="col-8 pl-1 mb-2">Failed</div>
                            <div class="col-4 text-red text-end">{{regulatoryData.PCI.failed}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Box Start  -->
                  <div class="col-sm-3 col-3 inner-box">
                    <div class="tab-inner-box">
                      <div class="tab-inner-box-count">
                      </div>
                      <div class="row">
                        <div class="col-4 text-center px-1 pt-0">
                          <img src="/assets/images/iso.PNG" width="62">
                          <p>ISO<br /><span>{{regulatoryData.ISO.compliantPercentage}}%</span></p>
                        </div>
                        <div class="col-8 border-start">
                          <div class="row p-top">
                            <div class="col-8 pl-1 mb-2">Applicable</div>
                            <div class="col-4 text-end">{{regulatoryData.ISO.Applicable}}</div>
                            <div class="col-8 pl-1 mb-2">Passed</div>
                            <div class="col-4 text-green text-end">{{regulatoryData.ISO.passed}}</div>
                            <div class="col-8 pl-1 mb-2">Failed</div>
                            <div class="col-4 text-red text-end">{{regulatoryData.ISO.failed}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Box End  -->
                </div>
              </div>
            </div>
          </div>
            <!-- Tab Conent Ends  -->
            <!-- Data Table start here -->
  
            <div class="container-fluid mt-2 p-1 severity-status">
              <div class="policy-count" *ngIf="currentTableData.length">
                Total of {{ currentTableData.length }} Policies
              </div>
  
              <table datatable [dtOptions]="dtOptions" *ngIf="
                  errorValue > 0 &&
                  loaded &&
                  filteredColumns.length > 0 &&
                  sortArr.length > 0
                " id="datatable" class="table nowrap policy-table table-hover" width="100%" #datatable>
                <thead width="100%">
                  <tr>
                    <th (click)='sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header);'
                      *ngFor="let header of filteredColumns; let i = index">
                      {{ header }}
                      <span [class.up-arr-shown]='sortArr[i].showUp == true'
                        [class.down-arr-shown]='sortArr[i].showUp == false' class="list-sortable-arrow1 relative"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of currentTableData |searchFilter:searchPassed |orderBy :
                  { property: headerColName, direction: direction , childProperty: 'valText'}; let i = index; ">
                    <td [ngClass]="{'sorting_1': row[column].colName === sortSelectedColomn}"
                      *ngFor="let column of filteredColumns">
                      <a class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" 
                        [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}">{{
                        row[column].text }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Data Table ends here -->
  
          </div>
        </div>
  
      </div>
  
    </div>
  
  
  </section>