
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { Observable } from 'rxjs/Observable';
import { DataCacheService } from './data-cache.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../shared/services/logger.service';
import {AuthSessionStorageService} from './auth-session-storage.service';

@Injectable()
export class OnPremAuthenticationService {
  constructor(
    private httpService: HttpService,
    private dataStore: DataCacheService,
    private loggerService: LoggerService,
    private authSessionStorageService: AuthSessionStorageService
  ) {}

  handleError(error: any): Observable<any> {
    return Observable.throw(error.message || error);
  }

  logout() {
    const logoutUrl = environment.logout.url;
    const logoutMethod = environment.logout.method;

        // // used .pipe to work with map in angular v14
        // const logoutObserver = this.httpService
        // .getHttpResponse(logoutUrl, logoutMethod, {}, {}).pipe(
        //   map((response :any) => {
        //     return response;
        //   }) , catchError((error :any) => this.handleError(error))
        // )
        // // .map((response :any) => {
        // //   return response;
        // // })
        // // .catch((error :any) => this.handleError(error));

    const logoutObserver = this.httpService
      .getHttpResponse(logoutUrl, logoutMethod, {}, {})
      .map((response :any) => {
        return response;
      })
      .catch((error :any) => {
        return this.handleError(error)
      });

    logoutObserver.subscribe(
      (response :any) => {
        this.loggerService.log('info', 'Logged out successfully');
        window.location.replace('/home'); // Reloading the page, to reset the observable sujects
      },
      (error :any) => {
        this.loggerService.log('error', error);
        window.location.replace('/home'); // Reloading the page, to reset the observable sujects
      }
    );
  }

  formatUsernameWithoutDomain(username :any) {
    const regex = /^(gsm1900\/|gsm1900\\)/gi;
    return username.replace(regex, '');
  }

  isAuthenticated() {
    const isAuthenticated = (this.dataStore.getUserDetailsValue() && this.dataStore.getUserDetailsValue().isAuthenticated());
    // Return true if authenticated. Return false if user is not authenticated.
    return isAuthenticated;
    // return this.utilityService.strToBool(token);
  }

  /* Deprecated: this function is not being used anymore */
  getRedirectUrl() {
     return this.dataStore.getRedirectUrl();
  }

  massageAndStoreUserDetails(userDetails :any) {
    this.authSessionStorageService.saveUserDetails(userDetails);
  }
}
