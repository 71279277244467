import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.css']
})
export class GenericModalComponent implements OnInit {

  constructor() { }

  @Input() title: any;

  @Output() emitClose = new EventEmitter();

  ngOnInit() {
  }

  closeModal() {
    this.emitClose.emit();
  }

}
