<section>
  <div
    *ngIf="showLoader"
    class="card dataTable-section-height">
    <div class="loader-container">
      <div class="loader">
        <span></span>
      </div>
    </div>
    <div
      *ngIf="!datacoming"
      class="d-flex align-items-center"
      style="min-height:50vh">
      <div class="w-100 text-center">
        <div>
          <div>
            <app-error-message
              *ngIf="seekdata == true"
              [selectedValue]="errorMessage"></app-error-message>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid my-1 mianDiv"
    *ngIf="dataComing && !showLoader">
    <div class="row">
      <div class="col-12 policyknowledge">
        <div class="card chart-card2">
          <div class="card-body p-4">
            <h5>{{displayName}}: </h5>
            <h6 class="d-inline">Description : </h6>
            {{ruleDescription}}
            <div class="d-flex">
              <div class="col-7 px-3">
                <ng-container *ngFor="let data of policyDesc">
                  <div class="col-5 col-md-5 py-3 ps-0">{{data.key}}</div>
                  <div class="col-3 col-md-3 py-3">
                    <span [ngClass]="{
                                    'low':
                                    data.value === 'low',
                                    'high':
                                    data.value === 'high' || data.value === 'critical',
                                      'medium': data.value === 'medium'
                                  }">{{data.value}}</span>
                  </div>
                  <hr>
                </ng-container>
              </div>
              <div
                class="col-5 mt-4 px-0"
                *ngIf="resolution.length > 0">
                <p>Resolution</p>
                <p>To remediate this issue:</p>
                <p *ngFor="let data of resolution; let i = index">{{i+1}} ) {{data}}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
