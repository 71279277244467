import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  constructor() {
  }

  @Input() disabled = false;
  @Input() type:any;
  @Output() onClickInner = new EventEmitter();

  onButtonClick() {
      if (!this.disabled) {
          this.onClickInner.emit();
      }
  }
}
