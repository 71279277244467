import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexGrid,
  ApexYAxis
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  yaxis: ApexYAxis
};
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { AutorefreshService } from '../../services/autorefresh.service';
import { GrcService } from '../../services/grc.service';

@Component({
  selector: 'app-riskbymitigation',
  templateUrl: './riskbymitigation.component.html',
  styleUrls: ['./riskbymitigation.component.css']
})
export class RiskbymitigationComponent implements OnInit {

  private riskDataUrl = environment.riskByMitigation.url;
  private riskDataMethod = environment.riskByMitigation.method;
  mitigationData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToMitigation: Subscription | any;
  contValue = false;
  loaded = false;
  constructor(private grcService:GrcService,
    private errorHandling: ErrorHandlingService) { this.chartOptions = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },    
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    xaxis: {
      categories: ['Open','In-Progress', 'Closed']
    },   
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 2,
    },
    series: [
      {
        name: 'basic',
        data:[0,0,0,]
      },
    ],

    legend: {
      position: 'right',
      verticalAlign: 'top',
    },

    colors: ['#01b27c','#dab315', '#d9434e'],

    chart: {
      type: 'bar',
      height: 150,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        barHeight: '40%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
  };
    
  }

  ngOnInit() {
    this.getByMitigation();
  }
  getByMitigation(): void {
    try {
      this.subscriptionToMitigation = this.grcService
        .executeHttpAction(
          this.riskDataUrl,
          this.riskDataMethod, {}, {}
        )
        .subscribe(
          (response:any) => {
            this.contValue = true;
            this.loaded = true;
            try {
              this.mitigationData = response[0];
              this.series = [];
              this.chartCategories = [];
        
              for (let i = 0; i < this.mitigationData.length; i++) {
                const mitigationItem = this.mitigationData[i];
                  this.chartCategories.push(mitigationItem.name);
                  this.series.push(mitigationItem.count);
              }
              this.chartOptions.series = [
                {
                  data: this.series,
                },
              ];

              this.chartOptions.xaxis = {
                categories: this.chartCategories,
              };
            } catch (e) {
              
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {8
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    this.series = [];
    this.chartCategories =[];
  }
}
