
import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Injectable()
export class UpdateRecentAGService {

    constructor(
                @Inject(HttpService) private httpService: HttpService,
                private errorHandlingService: ErrorHandlingService) { }

    // @ts-ignore
  updateRecentlyViewedAG(queryParams:any, UpdateRecentAGUrl:any, UpdateRecentAGMethod:any): Observable<any> {
        const url = UpdateRecentAGUrl;
        const method = UpdateRecentAGMethod;
        const payload = {};

         try {
            return this.httpService.getHttpResponse(url, method, payload, queryParams)
                    .map(response => {
                        return this.massageData(response);
                    });
        } catch (error) {
            this.errorHandlingService.handleJavascriptError(error);
        }
    }

    massageData(data:any): any {
        return data;
    }


    handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
