import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../shared/services/http-response.service';


@Injectable()
export class MultilineChartService {

    constructor (
                 @Inject(HttpService) private httpService: HttpService) { }



    private combinedData: any = [];

    // @ts-ignore
  getDataDiffNew(queryParameters :any): Observable<any> {
        const MultilineChartNewUrl = environment.MultilineChartNew.url;
        const method = environment.MultilineChartNew.method;
        const payload = {};
        try {
            const allObservables: Observable<any>[] = [];
                const queryParams = {};
                allObservables.push(
                    this.httpService.getHttpResponse(MultilineChartNewUrl, method, payload, queryParameters)
                        .map(response => this.massageResponseNew(response))
                        .catch(error => this.handleCombiningError(error))
                );
            return allObservables.length > 0 ? Observable.combineLatest(allObservables) : Observable.of([]);
        } catch (error) {
            this.handleError(error);
        }    }

    massageResponse(data :any ) {
        const apiResponse = data;
        return apiResponse;
    }

    massageResponseNew(data :any) {
        const apiResponse = data.trend;
        return apiResponse;
    }

    handleCombiningError(error: any): Observable<any> {
        let errorMessage = 'Error while processing response';
        if (error._body !== undefined) {
            errorMessage = JSON.parse(error._body).message;
        }
        if (errorMessage === 'no data found') {
            return Observable.of(this.massageResponse([]));
        } else {
            return Observable.throw(error.message || error);
        }
    }

    handleError(error: any): Observable<any> {
        return Observable.throw(error.message || error);
    }
    handlePromiseError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
