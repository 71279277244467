import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';



@Injectable()
export class ComplianceOverviewService {

    constructor (
                 @Inject(HttpService) private httpService: HttpService,
                 private errorHandling: ErrorHandlingService) { }


    private combinedData: any = [];

    // @ts-ignore
  getDailyData(url :any, method :any, payload :any, queryParameters = {}): Observable<any> {

        try {
            return this.httpService.getHttpResponse(url, method, payload, queryParameters)
                        .map((response:any) => this.massageDailyResponse(response['data'].response));
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    // @ts-ignore
  getWeeklyData(url :string, method :any, queryParameters :any): Observable<any> {

        let payload :any = {};
        const queryParams :any = {};

        try {
            payload = {
                'ag': queryParameters.ag,
                'from': queryParameters.from,
                'filters': {}
            };

            return this.httpService.getHttpResponse(url, method, payload, queryParams)
                        .map((response:any) => this.massageWeeklyResponse(response['data'].response));
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    dataCheck(data :any) {
        const APIStatus = this.errorHandling.checkAPIResponseStatus(data);
        if (!APIStatus.dataAvailble) {
            throw new Error('noDataAvailable');
        }
    }

    massageDailyResponse(data :any) {

        // datacheck function is added by Trinanjan on 10.03.2018 to check for empty data
        this.dataCheck(data);

        // *************************************************************** //
        const finalData  :any= [];
        const apiResponse = data['compliance_info'];
        // if (apiResponse.length) {
        //     const types = Object.keys(apiResponse[0]);
        //     types.splice(types.indexOf('date'), 1);
        //     types.forEach(type => {
        //         const values :any = [];
        //         let formattedObject = {};
        //         apiResponse.forEach((details:any) => {
        //             console.log(details)

        //             const obj = {
        //                 'date' : new Date(details['date']),
        //                 'value': details[type],
        //                 'zero-value': details[type] === 0 ? true : false
        //             };
        //             values.push(obj);
        //         });
        //         formattedObject = {
        //             'key'    : type,
        //             'values' : values
        //         };
        //         if (type.toLowerCase() !== 'overall' && type.toLowerCase() !== 'total') {
        //             finalData.unshift(formattedObject);
        //         } else {
        //             finalData.push(formattedObject);
        //         }
        //     });
        // }

        return apiResponse;

    }

    massageWeeklyResponse (data :any) {
        // datacheck function is added by Trinanjan on 10.03.2018 to check for empty data
        this.dataCheck(data);
        // *************************************************************** //
        const finalData :any = [];
        const currentData = data.compliance_trend;
        const types = Object.keys(currentData[0]['compliance_info'][0]);
        types.splice(types.indexOf('date'), 1);
        types.forEach(type => {
            let formattedObject = {};
            const values :any = [];
            currentData.forEach((weeklyData:any) => {
                const apiResponse = weeklyData['compliance_info'];
                apiResponse.forEach((details:any) => {
                    const obj = {
                        'date' : new Date(details['date']),
                        'value': details[type],
                        'zero-value': details[type] === 0 ? true : false
                    };
                    values.push(obj);
                });
            });
            formattedObject = {
                'key'    : type,
                'values' : values
            };
            if (type.toLowerCase() !== 'overall' && type.toLowerCase() !== 'total') {
                finalData.unshift(formattedObject);
            } else {
                finalData.push(formattedObject);
            }
        });
        return finalData;
    }

}
