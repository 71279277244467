
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggerService } from '../../../shared/services/logger.service';

@Component({
  selector: 'app-domain-dropdown',
  templateUrl: './domain-dropdown.component.html',
  styleUrls: ['./domain-dropdown.component.css']
})

export class DomainDropdownComponent implements OnInit {
  /*
   * This component is used for the domain dropdown
   * Onclick of the dropdown data is send to the calling component by event emmiter
   */

  @Input() dropdownDataStr: string ='';
  @Output() onChange = new EventEmitter();

  dropdownData: any = [];

  constructor(
    private logger: LoggerService
  ) {

  }

  ngOnInit() {
    // domaindata is stored in the session storage seperated by tilda
    try {
      this.dropdownData = this.dropdownDataStr.split('~');
    } catch (error) {
      this.logger.log('error', error);
    }
  }

  /*
   * This function sends cilcked domain dropdown to the calling component
   */
  applyActive: any;
  sendClickedValue(data :any, index :any) {

    try {
      const emitdata = {
        value: data,
        index: index
      };
      this.onChange.emit(emitdata.value);
    } catch (error) {
      this.logger.log('error', error);
    }
  }
}
