
import {EventEmitter, Output, Pipe, PipeTransform} from '@angular/core';
import {LoggerService} from '../services/logger.service';

@Pipe({name: 'searchFilter'})
export class SearchFilterPipe implements PipeTransform {
  @Output() pipeError = new EventEmitter();

  constructor(private loggerService: LoggerService) {
  }

  transform(input: any, searchQuery: any): any {
    try {
      return input.filter((item: any) => {
        for (const key in item) {
          if (
            (
              '' +
              JSON.stringify(item[key])
                .toString()
                .toLowerCase()
            ).includes(searchQuery.toString().toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    } catch (error) {
      this.loggerService.log('infor', 'error in pipe' + error);
      return false;
    }
  }
}
