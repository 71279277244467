<div *ngIf="!contValue" class="loader-container">
  <div class="loader">
    <span></span>
  </div>
</div>
<div class="row">
    <div class="col-md-10 col-10">
      <div id="chart"  *ngIf='contValue'>
        <div class="heading">
          <h3>Function Wise Risk</h3>
        </div>
        <div class="label top-left">
          <div class="content">Function</div>
        </div>        
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [xaxis]="chartOptions.xaxis"
          [stroke]="chartOptions.stroke"
          [fill]="chartOptions.fill"
          [tooltip]="chartOptions.tooltip"
          [legend]="chartOptions.legend"
          [colors]="chartOptions.colors"></apx-chart>
          <p class="count">Count</p>
      </div>
  
    </div>
    <div class="col-md-2 col-2 text-end chart-value2">
      <ul>
        <li *ngFor="let val of totalseveritycount">{{ val }}</li>
      </ul>
    </div>
    <!-- <div class="col-md-2 col-2 text-end chart-value">
        <ul>
          <li *ngFor="let val of totalseveritycount">{{ val }}%</li>
        </ul>
      </div> -->
  </div>
  