<div class="container-fluid pt-3">
  <div class="row card-group">
    <div class="col-sm-6 col-md-6 col-lg-3 pb-3" *ngFor="let data of finalData.response">
      <div class="card text-center mb-3 summaryDiv">
        <div class="loader-container" *ngIf="!loaderFlag">
          <div class="loader">
            <span></span>
          </div>
        </div>
        <div class="card-body" *ngIf="loaderFlag">
          <h6 class="card-title" *ngIf="data.text != 'failed'">{{ data.text }}</h6>
          <h6 class="card-title" *ngIf="data.text === 'failed'">Failed</h6>
          <h3 class="card-text" *ngIf="data.text === 'Compliant'">{{ data.value }}%</h3>
          <h3 class="card-text" *ngIf="data.text === 'Assets Scanned'">{{ data.value }}</h3>
          <h3 class="card-text text-success" *ngIf="data.text === 'Passed'">{{ data.value }}</h3>
          <h3 class="card-text text-danger" *ngIf="data.text === 'failed'">{{ data.value }}</h3>
        </div>
      </div>
    </div>
  </div>
</div>