<!-- Small Semi circle chart box begin -->
<section class="halfpiechart">
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="card cardbox">         
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-2-spa col-md-12 col-12 px-2">                                                    
                <div class="chartbox-title">{{identity_status}}</div>
                <div class="chartbox-container">    
                  <div *ngIf="!contValue" class="mid-circle-container pb-1 loader-container" style="position: relative; top:50px">
                    <div class="loader">
                      <span></span>
                    </div>
                  </div>
                  <div *ngIf="contValue" class="mid-circle-container pb-1">
                    <h6 class="card-title">Identity</h6>
                    <div class="mid-chartbox">  
                      <app-identity-chart [actualvalue]="identity_value" [status]="identity_status" ></app-identity-chart> 
                      <div class="targetbar"></div>                 
                      <div class="targetvalue">4.00</div>
                      <div class="mid-chartbox-start">0.00</div>
                      <div class="mid-chartbox-end">5.00</div>
                    </div>
                  </div>
                </div>
                <!-- Semi circle chart box end -->
              </div>
              <div class="col-lg-2-spa col-md-12 col-12 px-2">

                <!-- Small Semi circle chart box begin -->
                <div class="chartbox-title">{{protect_status}}</div>
                <div class="chartbox-container">
                  <div *ngIf="!contValue" class="mid-circle-container pb-1 loader-container" style="position: relative; top:50px">
                    <div class="loader">
                      <span></span>
                    </div>
                  </div>
                  <div *ngIf="contValue" class="mid-circle-container pb-1">
                    <h6 class="card-title">Protect</h6>
                    <div class="mid-chartbox">
                      <div class=" mt-0">
                        <app-identity-chart [actualvalue]="protect_value" [status]="protect_status"></app-identity-chart>  

                      </div>
                      <div class="targetbar"></div>
                      <div class="targetvalue">4.00</div>
                      <div class="mid-chartbox-start">0.00</div>
                      <div class="mid-chartbox-end">5.00</div>
                    </div>
                  </div>
                </div>
                <!-- Semi circle chart box end -->

              </div>

              <div class="col-lg-2-spa col-md-12 col-12 px-2">
                <!-- Small Semi circle chart box begin -->
                <div class="chartbox-title">{{respond_status}}</div>
                <div class="chartbox-container">
                  <div *ngIf="!contValue" class="mid-circle-container pb-1 loader-container" style="position: relative; top:50px">
                    <div class="loader">
                      <span></span>
                    </div>
                  </div>
                  <div *ngIf="contValue" class="mid-circle-container pb-1">

                    <h6 class="card-title">Respond </h6>

                    <div class="mid-chartbox">
                      <div class="mt-0">
                        <app-identity-chart [actualvalue]="respond_value" [status]="respond_status"></app-identity-chart> 

                      </div>
                      <div class="targetbar"></div>
                      <div class="targetvalue">4.00</div>
                      <div class="mid-chartbox-start">0.00</div>
                      <div class="mid-chartbox-end">5.00</div>
                    </div>
                  </div>
                </div>
                <!-- Semi circle chart box end -->
              </div>

              <div class="col-lg-2-spa col-md-12 col-12 px-2 mr-0">
                <!-- Small Semi circle chart box begin -->
                <div class="chartbox-title">{{detect_status}}</div>
                <div class="chartbox-container">
                  <div  *ngIf="!contValue" class="mid-circle-container pb-1 loader-container" style="position: relative; top:50px">
                    <div class="loader">
                      <span></span>
                    </div>
                  </div>
                  <div *ngIf="contValue" class="mid-circle-container pb-1">

                    <h6 class="card-title">Detect </h6>

                    <div class="mid-chartbox chartbox">
                      <div class="mt-0">
                        <app-identity-chart [actualvalue]="detect_value" [status]="detect_status"></app-identity-chart> 

                      </div>
                      <div class="targetbar"></div>
                      <div class="targetvalue">4.00</div>
                      <div class="mid-chartbox-start">0.00</div>
                      <div class="mid-chartbox-end">5.00</div>
                    </div>
                  </div>
                </div>
                <!-- Semi circle chart box end -->
              </div>

              <div class="col-lg-2-spa col-md-12 col-12 px-2 ml-0">
                <!-- Small Semi circle chart box begin -->
                <div class="chartbox-title">{{recover_status}}</div>
                <div class="chartbox-container">
                  <div *ngIf="!contValue" class="mid-circle-container pb-1 loader-container" style="position: relative; top:50px">
                    <div class="loader">
                      <span></span>
                    </div>
                  </div>
                  <div *ngIf="contValue" class="mid-circle-container pb-1">

                    <h6 class="card-title">Recover </h6>

                    <div class="mid-chartbox">
                      <div class=" mt-0">
                        <app-identity-chart [actualvalue]="recover_value" [status]="recover_status"></app-identity-chart> 

                      </div>
                      <div class="targetbar"></div>
                      <div class="targetvalue">4.00</div>
                      <div class="mid-chartbox-start">0.00</div>
                      <div class="mid-chartbox-end">5.00</div>

                    </div>
                  </div>
                </div>
                <!-- Semi circle chart box end -->
              </div>
            </div>

            <div class="row" *ngIf="contValue">
              <div class="col-md-12 mx-auto text-center my-4">
                <div class="mb-1">
                  <div class="red-badge mx-auto"></div>
                  <b class="pl-10">Not Aligned</b>
                  - (AVERAGE value of Practice Maturity >= 1.00 & < 2 )
                  <div class="yellow-badge ms-4"></div>
                  <b class="pl-10">Partially Aligned</b>
                  - (AVERAGE value of Practice Maturity >= 2.00 & < 4.00 )
                </div>

                <div class="green-badge ms-4"></div>
                <b class="pl-10">Aligned</b>
                - (AVERAGE value of Practice Maturity >= 4.00)
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row Ends -->
    </div>
  </div>

</section>
<!--  -->
