import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // this.router.navigate(['/pl/compliance/compliance-dashboard'])
    // debugger;
    const loginUrl = '/pl/compliance/compliance-dashboard?ag=aws';
    this.router.navigateByUrl(loginUrl).then(result => {
   console.log('result from home page component')
   console.log(result)
  },
  error => {
    console.log('error from home page component')
    console.log(error)
    // this.loggerService.log('error', 'Error navigating to login - ' + error);
  });
  }

  goTo(location: string): void {
    window.location.hash = '';
    window.location.hash = location;
  }

  login() {
    console.log('workinr')
    // this.router.navigate(['home/login'])
  }

}
