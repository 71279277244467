import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { AssetGroupObservableService } from '../../../../core/services/asset-group-observable.service';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../shared/services/logger.service';
import { WorkflowService } from '../../../../core/services/workflow.service';
import { SelectComplianceDropdown } from '../../../services/select-compliance-dropdown.service';

@Component({
  selector: 'app-tagging-compliance',
  templateUrl: './tagging-compliance.component.html',
  styleUrls: ['./tagging-compliance.component.css']
})
export class TaggingComplianceComponent implements OnInit, OnDestroy {
  breadcrumbArray: any = ['Compliance'];
  breadcrumbLinks: any = ['compliance-dashboard'];
  breadcrumbPresent: any = 'Tagging Compliance';

  complianceDropdowns: any = [];
  searchDropdownData: any = {};
  selectedDD = '';
  currentObj: any = {};
  filterArr: any = [];
  subscriptionToAssetGroup: Subscription;
  selectedAssetGroup: string = '';
  selectedComplianceDropdown: any;
  public pageLevel = 0;
  public backButtonRequired: any;

  constructor(
    private breadCurmbService: BreadcrumbService,
    private assetGroupObservableService: AssetGroupObservableService,
    private selectComplianceDropdown: SelectComplianceDropdown,
    private router: Router,
    private logger: LoggerService,
    private workflowService: WorkflowService
  ) {
    this.subscriptionToAssetGroup = this.assetGroupObservableService
      .getAssetGroup()
      .subscribe((assetGroupName) => {
        this.backButtonRequired =
          this.workflowService.checkIfFlowExistsCurrently(this.pageLevel);
        this.selectedAssetGroup = assetGroupName;
      });
    this.selectComplianceDropdown
      .getCompliance()
      .subscribe((complianceName) => {
        this.selectedComplianceDropdown = complianceName;
      });
  }

  ngOnInit(): void {
    this.breadCurmbService.changeBreadCrumbData(
      this.breadcrumbArray,
      this.breadcrumbLinks,
      this.breadcrumbPresent,
      false
    );
  }
  ngOnDestroy(): void {}
}
