 <div class="policy-cont-slider-wrapper relative" [ngClass]="{'wrapper-shadow': !dataComing}">
    <div class="policy-cont-slider-inner-wrapper" *ngIf='dataComing && !showLoader'>
        <app-content-slider [title] = "title" [titleDesc] = "titleDesc" (navigatePage)="navigateToPlolicy($event)" ></app-content-slider>
    </div>
    <div *ngIf="showLoader" class="loader-container">
      <div class="loader">
        <span></span>
      </div>
    </div>
    <div *ngIf='!dataComing' class="error_handling">
      <app-error-message *ngIf='seekdata == true' [selectedValue]="errorMessage"></app-error-message>
    </div>
  </div>