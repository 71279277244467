
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { AssetGroupObservableService } from '../../../core/services/asset-group-observable.service';
import { WorkflowService } from '../../../core/services/workflow.service';

@Component({
  selector: 'app-issues-category',
  templateUrl: './issues-category.component.html',
  styleUrls: ['./issues-category.component.css']
})

export class IssuesCategoryComponent implements OnInit, OnDestroy {

  selectedAssetGroup: string =''
  errorMessages: string ='';

  @Input() categoriesVal:any;
  @Input() imgSrc: string =''

  @Input() routeTo:any;
  private assetGroupSubscription: Subscription;
  @Input() pageLevel: number=0;
  private urlToRedirect:any;

  constructor(private router: Router,
              private assetGroupObservableService: AssetGroupObservableService, private activatedRoute: ActivatedRoute, private workflowService: WorkflowService) {

    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(
                assetGroupName => {
                    this.selectedAssetGroup = assetGroupName;
                  });
  }

  ngOnInit() {
    this.urlToRedirect = this.router.routerState.snapshot.url;
  }


  ngOnDestroy() {
    try {
      if (this.assetGroupSubscription) {
        this.assetGroupSubscription.unsubscribe();
      }
    } catch (e) {
    }
  }

}
