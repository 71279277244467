<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card tab-box">
          <div class="container-fluid mt-2 p-1">
            
            <div class="row">
              <div class="col-md-3 mb-4">
                <h6 class="card-title">Domain Details</h6>
              </div>
            </div>
            <div class="col-md-10 mx-auto">
              <div [hidden]="hideContent">                
                  <div class="row">
                    <div class="col-md-6 mb-4" *ngIf="isCreate">
                      <div class="heading-gray">
                        Name *
                        <span
                          *ngIf="isDomainNameValid==0"
                          class="not-available">(Not Available)</span>
                        <span
                          *ngIf="isDomainNameValid==1"
                          class="available">(Available)</span>
                      </div>
                      <input
                        type="text"
                        class="input-field"
                        placeholder="Enter Domain Name"
                        name="domainName"
                        [(ngModel)]="domain.name"
                        (input)="isDomainNameAvailable($any($event).target.value)"
                        required>
                    </div>
                    <div
                      class="col-md-6 mb-4"
                      *ngIf="!isCreate">
                      <div class="heading-gray">
                        <label>Name</label>
                      </div>
                      <div class="text-value text-bold">{{domain.name}}</div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">
                        <label>Description *</label>
                      </div>
                      <textarea
                        class="input-field"
                        rows="3"
                        placeholder="Enter Domain Description"
                        name="domainDesc"
                        [(ngModel)]="domain.desc"></textarea>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">
                        <label>Configuration *</label>
                      </div>
                      <textarea
                        class="input-field"
                        rows="3"
                        placeholder="Enter Domain Configuration"
                        name="domainConfig"
                        [(ngModel)]="domain.config"></textarea>
                    </div>
                  </div>                
              
              <div class="text-center my-4 createsubmit">
                <a class="btn btn-border me-2">
                  <button
                    class="submit-btn"
                    type="button"
                    (click)="navigateBack()">
                    <i class="bi bi-x fs-5"></i>
                    Cancel
                  </button>
                </a>
                <a
                  class="btn btn-border me-2 btn-green"
                  *ngIf="isCreate">
                  <button
                    class="submit-btn text-white"
                    (click)="createDomain(domain)"
                    [disabled]="domain.name.trim()==='' || domain.desc.trim()==='' || domain.config.trim()==='' || isDomainNameValid<1">
                    <i class="bi bi-plus fs-5"></i>
                    Create
                  </button>
                </a>
                <a
                  class="btn btn-border me-2 btn-green"
                  *ngIf="!isCreate">
                  <button
                    class="submit-btn text-white"
                    (click)="updateDomain(domain)"
                    [disabled]="domain.name.trim()==='' || domain.desc.trim()==='' || domain.config.trim()===''">
                    <i class="bi bi-check fs-5"></i>
                    Update
                  </button>
                </a>
              </div>
              </div>
              <div
                class="pacman-alert"
                *ngIf="isDomainCreationUpdationFailed">
                <div
                  class="sa-icon sa-error animateErrorIcon"
                  style="display: block;">
                  <span class="sa-x-mark animateXMark">                                          
                    <span class="sa-line sa-left"></span>                                    
                    <span class="sa-line sa-right"></span>
                    </span>
                </div>
                <div class="pacman-alert-title">{{failedTitle}}</div>
                <div class="pacman-alert-message">
                  Domain
                  <span class="highlight-pink">{{domainName}}</span>
                  {{loadingContent}} failed!!!
                </div>
                <div class="pacman-alert-button">
                    <a class="btn btn-border">
                  <button
                    class="submit-btn"
                    type="button"
                    (click)="closeErrorMessage()">
                    <span *ngIf="failedTitle!=='Loading Failed'"><i class="bi bi-x fs-5"></i> Close</span>
                    <span *ngIf="failedTitle==='Loading Failed'"><i class="bi bi-check fs-5"></i> Retry</span>
                  </button>
                </a>
                </div>
              </div>
              <div
                class="pacman-alert"
                *ngIf="isDomainCreationUpdationSuccess">
                <div class="sa-icon sa-success animate">
                  <span class="sa-line sa-tip animateSuccessTip"></span>
                  <span class="sa-line sa-long animateSuccessLong"></span>
                  <div class="sa-placeholder"></div>
                  <div class="sa-fix"></div>
                </div>

                <div class="pacman-alert-title">{{successTitle}}</div>
                <div class="pacman-alert-message">
                  Domain
                  <span class="highlight-pink">{{domainName}}</span>
                  has been successfully
                  <span *ngIf="isCreate">created</span>
                  <span *ngIf="!isCreate">updated</span>
                  !!!
                </div>
                <div class="pacman-alert-button">
                    <a class="btn btn-border">
                  <button
                    class="submit-btn closebut"
                    type="button"
                    (click)="navigateBack()">
                    <i class="bi bi-x fs-5"></i>
                    Close
                  </button>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
