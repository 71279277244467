
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-generic-summary',
  templateUrl: './generic-summary.component.html',
  styleUrls: ['./generic-summary.component.css']
})
export class GenericSummaryComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() colors: any;
  @Input() percent: any;
  @Input() headerText: any;
  @Output() navigatePage: EventEmitter<any> = new EventEmitter();
  @Input() textValue: any;
  @Input() text: any;
  @Input() outOf: any;
  dataValue: any;
  finalData: any;
  totalAssets: any;
  complaint: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    try {
      const DataChange = changes['data'];
      if (DataChange) {
        const cur = JSON.stringify(DataChange.currentValue);
        const prev = JSON.stringify(DataChange.previousValue);
        if (cur !== prev && this.data) {
          this.ngOnInit();
        }
      }
    } catch (error) {}
  }

  ngOnInit() {
    this.finalData = this.processData(this.data);

    
    if(this.finalData && this.finalData.response) {
      this.finalData.response.forEach((element: any , index: any) => {
        if(element.text === "Total Assets") {
          this.totalAssets = element;
          this.complaint = this.finalData.response[0];
           this.finalData.response;
          //  console.log("Data: ", this.finalData.response.splice(index, 1))
        }
      });
    }
  }

  // calculatePercentage(data: any) {

  // }

  isEven(n:any) {
    return n === parseFloat(n) ? !(n % 2) : void 0;
  }

  processData(data :any) {
    return data;
  }
  instructParentToNavigate(data:any) {
    this.navigatePage.emit(data);
  }
}
