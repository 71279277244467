
import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { DataCacheService } from '../../../../core/services/data-cache.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { ErrorHandlingService } from '../../../../shared/services/error-handling.service';
import { WorkflowService } from '../../../../core/services/workflow.service';
import { AssetGroupObservableService } from '../../../../core/services/asset-group-observable.service';
import {CONFIGURATIONS} from "../../../../../config/configurations";
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';



@Component({
  selector: 'app-asset-dashboard',
  templateUrl: './asset-dashboard.component.html',
  styleUrls: ['./asset-dashboard.component.css'],
})

export class AssetDashboardComponent implements OnInit, OnDestroy {

  breadcrumbArray: any = ['Assets'];
  breadcrumbLinks: any = ['Asset-Dashboard'];
  breadcrumbPresent: any = 'Asset Dashboard';

  pageTitle = 'Overview';
  showNotif = false;
  beepCount = 0;
  public errorMessage: any;
  urlToRedirect: any = '';
  public pageLevel = 0;
  public backButtonRequired :any;
  public config :any;
  public oss :any;

  constructor(
    private dataStore: DataCacheService,
    private router: Router,
    private logger: LoggerService,
    private workflowService: WorkflowService,
    private assetGroupObservableService: AssetGroupObservableService,
    private breadCurmbService: BreadcrumbService
  ) {
    this.config = CONFIGURATIONS;

    this.oss = this.config && this.config.optional && this.config.optional.general && this.config.optional.general.OSS;

    this.getAssetGroup();
  }

  ngOnInit() {
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
  }

  getAssetGroup() {
    this.assetGroupObservableService.getAssetGroup().subscribe(
      assetGroupName => {
          this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(this.pageLevel);
    });
  }

  ngOnDestroy() {
    try {
      this.dataStore.set('urlToRedirect', this.urlToRedirect);
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}