
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ComplianceCategoriesService } from '../../services/compliance-categories.service';
import { Subscription } from 'rxjs/Subscription';
import { AutorefreshService } from '../../services/autorefresh.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { DomainTypeObservableService } from '../../../core/services/domain-type-observable.service';
import { DataCacheService } from '../../../core/services/data-cache.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-overview-vulnerabilities',
  templateUrl: './overview-vulnerabilities.component.html',
  styleUrls: ['./overview-vulnerabilities.component.css'],
  providers: [ComplianceCategoriesService, AutorefreshService, LoggerService, ErrorHandlingService]
})
export class OverviewVulnerabilitiesComponent implements OnInit, OnDestroy {


  private selectedAssetGroup: string ='';
  subscriptionToOverviewVulnerabilities: Subscription |any;
  subscriptionDomain: Subscription;
  errorMessages: string ='';
  passingData: any;
  hideLoader = false;
  showError = false;
  error: any = {
    Vulnerabilities: false
  };
  complianceCategoriesFinal: any = {
    Vulnerabilities: {output : {}}
  };
  selectedDomain: any;
  complianceData: any =  [
    {
        'categoriesHeader': 'Vulnerabilities',
        'categoriesTitle': 'Vulnerabilities',
        'categoriesSubTitle': 'across',
        'categoriesPostTitle': 'hosts'
    }
  ];

  durationParams: any;
  autoRefresh: boolean =false;

   autorefreshInterval :any;
  @Input() pageLevel: number=0;

  constructor(
              private complianceCategoriesService: ComplianceCategoriesService,
              private errorHandling: ErrorHandlingService,
              private logger: LoggerService,
              private autorefreshService: AutorefreshService,
              private domainObservableService: DomainTypeObservableService,
              private dataCacheService: DataCacheService) {

      this.subscriptionDomain = this.domainObservableService.getDomainType().subscribe(domain => {
           this.selectedDomain = domain;
           this.selectedAssetGroup = this.dataCacheService.getCurrentSelectedAssetGroup();
           this.updateComponent();
      });
  }

  ngOnInit() {
    try {
      this.durationParams = this.autorefreshService.getDuration();
      this.durationParams = parseInt(this.durationParams, 10);
      this.autoRefresh = this.autorefreshService.autoRefresh;

      const afterLoad = this;
      if (this.autoRefresh !== undefined) {
        if ((this.autoRefresh === true ) || (this.autoRefresh.toString() === 'true')) {

          this.autorefreshInterval = setInterval(function() {
            afterLoad.getComplianceData();
          }, this.durationParams);
        }
      }

    } catch (error) {
      this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  updateComponent() {
    try {
      this.showError = false;
      this.hideLoader = false;
      if (this.subscriptionToOverviewVulnerabilities) {
        this.subscriptionToOverviewVulnerabilities.unsubscribe();
      }
      this.error = {
          Vulnerabilities: false
      };
      this.getComplianceData();
    } catch (error) {
      this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }


  getComplianceCategories(category :any): void {
    try {
      const afterLoad = this;

      const queryParams = {
        'ag': this.selectedAssetGroup,
        'domain': this.selectedDomain
      };

      // @ts-ignore
      const categoryUrl = environment.complianceCategories.complianceCategoriesData[category].url;

      // @ts-ignore
      const categoryMethod = environment.complianceCategories.complianceCategoriesData[category].method;

      const categoryType = category;

        this.subscriptionToOverviewVulnerabilities = this.complianceCategoriesService.getData(queryParams, categoryType, categoryUrl, categoryMethod).subscribe(
          (response:any) => {
            this.showError = false;
            this.complianceCategoriesFinal = response[0];

            this.error[categoryType] = false;
            this.hideLoader = true;

            this.complianceCategoriesFinal[categoryType].loaded = false;
            const x = this;

            setTimeout(function(){
                x.complianceCategoriesFinal[categoryType].loaded = true;
            }, 500);
          },
          (error:any) => {
            this.showError = true;
            this.errorMessages = 'apiResponseError';
             this.hideLoader = true;
            this.error[categoryType] = true;
          });
    } catch (error) {
      this.showError = true;
      this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  getComplianceData() {
    for ( let i = 0; i < this.complianceData.length; i++ ) {
        this.getComplianceCategories(this.complianceData[i].categoriesHeader);
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptionDomain.unsubscribe();
      this.subscriptionToOverviewVulnerabilities.unsubscribe();
      clearInterval(this.autorefreshInterval);
    } catch (error) {
      this.logger.log('error', error);
    }
  }

 }


