import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef,Output,EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ComplianceOverviewService } from '../../services/compliance-overview.service';
import { AutorefreshService } from '../../services/autorefresh.service';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';

import { LoggerService } from 'src/app/shared/services/logger.service';
import { SelectComplianceDropdown } from '../../services/select-compliance-dropdown.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import {RefactorFieldsService} from "src/app/shared/services/refactor-fields.service";
import {environment} from "../../../../environments/environment";
import { CommonResponseService } from 'src/app/shared/services/common-response.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-vulnerability-summary-table',
  templateUrl: './vulnerability-summary-table.component.html',
  styleUrls: ['./vulnerability-summary-table.component.css'],
  providers: [CommonResponseService]
})
export class VulnerabilitySummaryTableComponent implements OnInit,OnDestroy {
  selectedAssetGroup!: string;
  errorMessage = 'apiResponseError';

  getContextMenuItems: any;
  gridApi: any;
  gridColumnApi: any;
  columns: any = [];
  initComplete = false;
  showtable = false;

  gridOptions: any;
  private subscriptionToAssetGroup: Subscription;
  private dataSubscription!: Subscription;

  @Output() errorOccurred = new EventEmitter();

  errorValue = 0;
//new variables
 
@Input() dataHead :any ;
@Input() searchableHeader :boolean= false;
@Input() heightValue :any;
@Input() outerArr: any;
@Input() showingArr: any = [];
allColumns: any = [];
@Input() totalRows: any;
@Input() firstPaginator: number =0;
@Input() lastPaginator: number=0;
@Input() currentPointer: number=0;
@Input() headerColName = '';
@Input() direction = 0;
@Input() paginatorAbsent = false;
@Input() tabFilterProperty :any;
@Input() cbModel :any;
@Input() checkBox :any;
@Input() columnWhiteList :any;
@Input() buttonsArr :any;
@Input() searchTextValues = '';
@Input() popRows: any;
@Input() parentName: any;
@Input() buttonColumn :any;
@Input() showGenericMessage: any;
@Input() tableIdAppend: any;
@Input() searchPassed = '';
@Input() checkedList :any= [];
@Output() selectedRow = new EventEmitter<any>();
@Output() menuClick = new EventEmitter<any>();
@Output() searchRowTxt = new EventEmitter<any>();
@Output() noScrollDetected = new EventEmitter<any>();
@Output() searchTriggerred = new EventEmitter<any>();
@Output() previousPageCalled = new EventEmitter<any>();
@Output() tabSelected = new EventEmitter<any>();
@Output() nextPageCalled = new EventEmitter<any>();
@Output() cbClicked = new EventEmitter<any>();
@Output() rowClickText = new EventEmitter<string>();
@Output() emitPaginator =new EventEmitter<any>();

@Input() regulatoryData:any ;
@Input() id:any;

showRegulatoryCards: boolean = true;
regulatoryCompliance: any;
dtOptions: DataTables.Settings = {};
loaded = false;
sortArr: any = [];
indexSelected: number =0;
    rowDetails: any = [];
    rowIndex = -1;
    noMinHeight = false;
    rowObj: any = {};
    searchVal = '';
    sortSelectedColomn = '';

    seekdata = false;
    showError = false;
    scrollEnabled = false;
     rowAccessProperty = 'text';
    private allTableData :any;
     tabsData :any;
    public filteredColumns :any = [];
    public animationState :any;
    public previousTableData:any = [];
    public currentTableData:any = [];
  currentRowData: any;

  constructor(  private commonResponseService: CommonResponseService,
          private assetGroupObservableService: AssetGroupObservableService,
          private logger: LoggerService,
          private refactorFieldsService: RefactorFieldsService, 
          private utils: UtilsService ) {

    this.gridOptions = <any>{};
    this.gridOptions.columnDefs = [];

    this.gridOptions.rowData = [];
        this.getContextMenuItems = function getContextMenuItems(params:any) {
            const result = [
              'toolPanel',
              'separator',
              'copy',
              'separator',
              'csvExport',
              'separator',
              'autoSizeAll',
              'resetColumns'
            ];
            return result;
        };

    this.subscriptionToAssetGroup = this.assetGroupObservableService.getAssetGroup().subscribe(
      assetGroupName => {
          this.showtable = false;
          this.selectedAssetGroup = assetGroupName;
          setTimeout(() => {
            this.showtable = true;
            this.updateComponent();
          }, 10);
      });

  }

  ngOnInit() {
    
    this.getData();
    this.dtOptions = {
      scrollY: '200x',
      scrollCollapse: true,
      paging:false,
      scrollX:true,
      searching: true,
      info: false,
      ordering: false
    }; 
    this.noMinHeight = false;
    this.seekdata = false;
    
  }

  restrictShownColumns(columnNames :any) {
    if (columnNames) {
        const list = _.filter(columnNames, (whiteListedColumn) => {
            return _.find(this.allColumns, (column) => {
                return whiteListedColumn.toLowerCase() === column.toLowerCase();
            });
        });
        this.filteredColumns = list;
    } else {
        this.filteredColumns = this.allColumns;
    }
}



headerClicked(index :any, header :any) {
  this.rowIndex = -1;
    for (let i = 0; i < this.sortArr.length; i++) {
        if (i !== index) {
            this.sortArr[i].showUp = undefined;
            this.sortArr[i].direction = 0;
        } else {
            if (this.sortArr[i].direction === 0) {
                this.sortArr[i].direction = 1;
            } else {
                this.sortArr[i].direction = this.sortArr[i].direction * -1;
            }
            this.direction = this.sortArr[i].direction;
        }
    }
    this.indexSelected = index;
    this.headerColName = header;

    // for sort selected cloumn 
    this.sortSelectedColomn = header;
}

  updateComponent() {
      this.errorValue = 0;
      this.getData();
  }

  getData() {

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    const payload = {};
    const queryParam = {
      'ag': this.selectedAssetGroup
    };
    this.errorValue = 0;
    const tableUrl = environment.vulnerabilitySummary.url;
    const tableMethod = environment.vulnerabilitySummary.method;
    this.errorValue = 0;

    this.dataSubscription = this.commonResponseService.getData(tableUrl, tableMethod, payload, queryParam).subscribe(
      response => {

        try {
          if (this.utils.checkIfAPIReturnedDataIsEmpty(response.distribution)) {
            this.errorOccurred.emit();
            this.errorValue = -1;
            this.errorMessage = 'vulnerabilityMessage';
          } else {
            this.loaded = true;
            this.errorValue = 1;
            this.processData(response.distribution.severityinfo);
          }
        } catch (e) {
            this.errorOccurred.emit();
            this.errorValue = -1;
            this.errorMessage = 'jsError';
            this.logger.log('error', e);
        }
    },
    error => {
      this.errorOccurred.emit();
      this.errorValue = -1;
      this.errorMessage = 'apiResponseError';
      this.logger.log('error', error);
     });
  }

  downloadCsv() {
    this.gridApi.exportDataAsCsv({fileName: 'Vulnerability Summary.csv'});
  }

  processData(data:any) {
    try {
      var innerArr:any = {};
      var totalVariablesObj :any= {};
      var cellObj = {};
      var magenta = '#e20074';
      var green = '#26ba9d';
      var red = '#f2425f';
      var orange = '#ffb00d';
      var yellow = 'yellow';
      this.outerArr = [];
      var getData = data;

      if (getData.length) {
        var getCols:any = Object.keys(getData[0]);
      } else {
        this.seekdata = true;
      }

      for (var row = 0; row < getData.length; row++) {
        innerArr = {};
        for (var col = 0; col < getCols.length; col++) {
          if (getCols[col].toLowerCase() == 'actions') {
            cellObj = {
              link: true,
              properties: {
                'text-shadow': '0.33px 0',
                'color': '#ed0295'
              },
              colName: getCols[col],
              hasPreImg: false,
              valText: 'Edit',
              imgLink: '',
              text: 'Edit',
              statusProp: {
                'color': '#ed0295'
              }
            };
          } else {
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: getCols[col],
              hasPreImg: false,
              imgLink: '',
              text: getData[row][getCols[col]],
              valText: getData[row][getCols[col]]
            };
          }
          innerArr[getCols[col]] = cellObj;
          totalVariablesObj[getCols[col]] = '';
        }
        this.outerArr.push(innerArr);
      }
      if (this.outerArr.length > getData.length) {
        var halfLength = this.outerArr.length / 2;
        this.outerArr = this.outerArr.splice(halfLength);
      }
      this.allColumns = Object.keys(totalVariablesObj);
      this.allColumns = ['severity','uniqueVulnCount', 'appCount', 'hostCount', 'vulnInstanceCount','count','severitylevel'];      
      if ((this.outerArr !== undefined) && (this.outerArr.length !== 0)) {
        this.currentTableData = this.outerArr;
        this.allTableData = this.outerArr;
        if (this.tabFilterProperty) {
            this.tabsData = _(this.allTableData)
                .map((row: any) => {
                    return row[this.tabFilterProperty];
                })
                .filter((row) => {
                    return !!row[this.rowAccessProperty];
                })
                .uniqBy((row) => {
                    return row[this.rowAccessProperty];
                })
                .value();
  
            for (let index = 0; index < this.tabsData.length; index++ ) {
                this.tabsData[index]['displayName'] = this.refactorFieldsService.getDisplayNameForAKey(this.tabsData[index]['text'].toLowerCase()) || this.tabsData[index]['text'];
            }
        }
        this.restrictShownColumns(this.columnWhiteList);
        for (let column_index = 0; column_index < this.allColumns.length; column_index++) {
            this.sortArr[column_index] = {
                'showUp': undefined,
                'direction': 0
            };
        }
        this.loaded = true;
    } else {
        this.seekdata = true;
        this.showError = true;
  
        if (this.showGenericMessage === true) {
            this.errorMessage = 'apiResponseError';
        } else {
  
            if (this.searchTextValues === '') {
                this.errorMessage = this.parentName;
            } else {
                this.errorMessage = 'dataTableMessage';
            }
        }
    }
    } catch (error) {
      // this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  onresize() {
    if (this.columns.length < 6 && this.columns.length > 0) {
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 3);
    } else {
      this.autoSizeAll();
    }
  }
  

  onGridReady(params:any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  autoSizeAll() {
    const allColumnIds:any = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function(column:any) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }

  ngOnDestroy() {
    try {
      if (this.subscriptionToAssetGroup) {
        this.subscriptionToAssetGroup.unsubscribe();
      }
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
  prevPage() {
    if (this.currentPointer > 0) {
        this.previousPageCalled.emit();
    }
}

nextPage() {
    if (this.lastPaginator < this.totalRows) {
        this.nextPageCalled.emit();
    }
}
}
