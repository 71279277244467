<div class="issue-trend-wrapper flex flex-col">
  <div class="sub-head">
    <h6 class="card-title p-20">Vulnerabilities Aging Graphs  </h6>
   </div>  
   <div *ngIf='errorValue > 0' class="flex flex-col flex-grow" #widget id="agingBarGraph">
       <app-bar-chart class="flex flex-col flex-grow" (error)="getErrorValues($event)" [chartContId] = "'agingBarGraph'" [graphData] = "graphData" [legend_text]="legend_text" *ngIf = "graphData"></app-bar-chart>
   </div>    
   <div class='loader' *ngIf='errorValue==0'></div>      
     <div class="error-msg-block center error_handling" *ngIf='errorValue == -1'>
         <app-error-message *ngIf='errorValue == -1' [selectedValue]="errorMessage"></app-error-message>
   </div>    
</div>