<!-- <div *ngIf='errorValue == 0' class="loader-container">
    <div class="loader">
      <span></span>
    </div>
  </div> -->
 <div class="sticky-exceptions-wrapper floating-widgets-section flex flex-col issue-listing-wrapper" [class.filter-absent]='filters.length == 0' >     
    <div class="floating-widgets-container ">
    <div class="data-table-wrap relative">
                    <h6 class="title-text" *ngIf="isCreate">Service now mapping details </h6>
                    <h6 class="title-text" *ngIf="!isCreate">Service now mapping details -</h6>
                      <div [hidden]="hideContent">
                          <div class="data-content">
                              <div class="formcontainerwrapper">
                                  <div class="formctn">
                                      <div class="attribute-contents asset-formctn pb-4" >
                                          <div class="outer-div">
                                              <div class="list-select inner-div attrlist">
                                                  <h1>Available Policy Details</h1>
                                                  <button class="move-btn move-btn-first" type="button" (click)="moveAllItemsToRight()" [disabled]="availableItems.length==0"><i class="bi-arrow-right"></i><i class="bi-arrow-right"></i></button>
                                                  <button class="move-btn" type="button" (click)="moveItemToRight()" [disabled]="availChoosedItemsCount==0"><i class="bi-arrow-right"></i></button>
                                                  <input type="text" class="filter-box" placeholder="Filter Values" [(ngModel)]="searchAvailableUsersTerms" (ngModelChange)="searchAvailableUsers()">
                                                  <ul>
                                                      <li *ngFor="let availableItem of availableItems; let idx = index" (click)="onClickAvailableItem(idx, availableItem, availableItem)" [ngClass]="{selected: this.availChoosedItems[idx]}">{{availableItem}}</li>
                                                      <div class="not-found" *ngIf="searchAvailableUsersTerms!='' && availableItems.length==0">
                                                          <div class="not-found-image"></div>
                                                          <div class="not-found-title">
                                                              No values matching for :
                                                          </div>
                                                          <div class="highlight-pink not-found-title">
                                                              <b>{{searchAvailableUsersTerms}}</b>
                                                          </div>
                                                      </div>
                                                  </ul>
                                              </div>
                                              <div class="exchange list-select inner-div emptydiv">
                                                  <ul>
                                                      <i class="fa fa-exchange"></i>
                                                  </ul>
                                              </div>

                                              <div class="list-select inner-div attrlist">
                                                  <h1>Selected Policy Details</h1>
                                                  <button class="move-btn move-btn-first" type="button" (click)="moveAllItemsToLeft()" [disabled]="selectedItems.length==0"><i class="bi-arrow-left"></i><i class="bi-arrow-left"></i></button>
                                                  <button class="move-btn" type="button" (click)="moveItemToLeft()" [disabled]="selectChoosedItemsCount==0"><i class="bi-arrow-left"></i></button>
                                                  <input class="filter-box" placeholder="Filter Values" [(ngModel)]="searchSelectedUsersTerms" (ngModelChange)="searchSelectedUsers()">
                                                  <ul>
                                                      <li *ngFor="let selectedItem of selectedItems; let idx = index" (click)="onClickSelectedItem(idx, selectedItem, selectedItem)" [ngClass]="{selected: this.selectChoosedItems[idx]}">{{selectedItem}}</li>
                                                      <div class="not-found" *ngIf="searchSelectedUsersTerms!=='' && selectedItems.length==0 && selectedItemsCopy.length!=0">
                                                          <div class="not-found-image"></div>
                                                          <div class="not-found-title">
                                                              No values matching for :
                                                          </div>
                                                          <div class="highlight-pink not-found-title">
                                                              <b>{{searchSelectedUsersTerms}}</b>
                                                          </div>
                                                      </div>
                                                      <div class="not-selected not-found" *ngIf="selectedItems.length==0 && selectedItemsCopy.length==0">
                                                          <div class="not-selected-image"></div>
                                                          <div class="not-found-title">
                                                              No policies selected!!!
                                                          </div>
                                                      </div>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="button-div">
                              <a class="btn btn-border me-2 btn-green" >
                                  <button class="update-btn" (click)="configureServicenow()" ><i class="bi bi-check fs-5"></i> Update</button>
                              </a>
                              <a class="btn btn-border me-2">
                                  <button class="cancel-btn" type="button" (click)="navigateBack()"><i class="bi bi-x fs-5"></i> Cancel</button>
                              </a>
                            </div>
                      </div>

                      <div class="pacman-alert" *ngIf="isRoleCreationUpdationFailed">
                          <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                              <span class="sa-x-mark animateXMark">
                                  <span class="sa-line sa-left"></span>
                              <span class="sa-line sa-right"></span>
                              </span>
                          </div>
                          <div class="pacman-alert-title">
                              {{failedTitle}}
                          </div>
                          <div class="pacman-alert-message">
                              {{loadingContent}} <span class="highlight-pink">{{highlightName}}</span> failed!!!
                          </div>
                          <div class="pacman-alert-button">
                              <button class="btn-border" type="button" (click)="closeErrorMessage()"><span *ngIf="failedTitle!=='Loading Failed'"><i class="fa fa-remove"></i> Close</span> <span *ngIf="failedTitle==='Loading Failed'"><i class="fa fa-refresh"></i> Retry</span></button>
                          </div>
                      </div>
                      <div class="pacman-alert" *ngIf="isRoleCreationUpdationSuccess">
                          <div class="sa-icon sa-success animate">
                              <span class="sa-line sa-tip animateSuccessTip"></span>
                              <span class="sa-line sa-long animateSuccessLong"></span>
                              <div class="sa-placeholder"></div>
                              <div class="sa-fix"></div>
                          </div>

                          <div class="pacman-alert-title">
                              {{successTitle}}
                          </div>
                          <div class="pacman-alert-message">
                              User Role <span class="highlight-pink">{{selectedRoleName}}</span> has been successfully configured !!!
                          </div>
                          <div class="pacman-alert-button">
                              <button class="submit-btn close" type="button" (click)="closeErrorMessage()"><i class="fa fa-remove"></i> Close</button>
                          </div>
                      </div>
                      <div class="pacman-progress" *ngIf="roleLoader">
                          <div class="title">
                              Please Wait...
                          </div>
                          <div class="pacman-alert-message">
                              User Role <span class="highlight-pink">{{highlightName}}</span> {{loadingContent}} is in progress...
                          </div>
                      </div>
                  </div>
              <!-- </li> -->
          <!-- </ul> -->
  </div>
</div>

<!-- <section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box">
                    <div class="container-fluid mt-2 p-1 my-5">
                        <div class="row">
                            <div class="col-md-3 mb-4 pb-4">
                                <h6 class="card-title">Service Now Mapping Details -
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 mx-auto ps-5 py-5">
                                <div class="row mb-4">
                                    <div class="col-md-6 mb-4">
                                        <div class="text-bold"><b>Left Box</b></div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="text-bold"><b>Right Box</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->