import { Component, OnInit, ViewChild,Output, EventEmitter, Input  } from '@angular/core';
import { NgxSelectComponent } from 'ngx-select-ex';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { GrcService } from 'src/app/pacman-features/services/grc.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { UploadFileService } from 'src/app/pacman-features/services/upload-file-service';
import { environment } from 'src/environments/environment';
import { IAngularMyDpOptions } from 'angular-mydatepicker';

@Component({
  selector: 'app-create-risk',
  templateUrl: './create-risk.component.html',
  styleUrls: ['./create-risk.component.css'],
  providers:[GrcService,LoggerService,
    ErrorHandlingService,
    UploadFileService,]
})
export class CreateRiskComponent implements OnInit {
  @ViewChild('targetType') targetTypeSelectComponent: NgxSelectComponent | any;
  @ViewChild('targetTypeRuleSelect') targetTypeRuleSelectComponent: NgxSelectComponent | any;
  ruleLoader = false;
  @Input() label?: string; // Optional label for the select
  @Output() selectedValueChange1 = new EventEmitter<number>();
  breadcrumbArray: any = ['Grc', 'risk-management'];
  breadcrumbLinks: any = ['risk-management', 'create-risk'];
  breadcrumbPresent: any = 'Create Risk Register';
  pageTitle: string = 'Create Risk Register';
  // showingArr = ['affectedFunctions'];
  affectedFunctions: any = [];
  riskCategory:any=[];
  riskProbability:any=[];
  riskImpact:any=[];
  riskValue:any=[];
  riskId:any=[];
  riskTreatment:any=[];
  status:any=[];
  mitigationstatus:any=[];
  riskOwner:any=[];
  riskMitigationStatus:any=[];
  residualRiskLikelihood:any=[];
  residualImpact:any=[];
  residualRiskRating:any=[];
  riskDescription="";
  mitigationPlan="";
  department="";
  service="";
  remarks="";
  isRiskValid:boolean=false;
  errorMessage: any;
  options = [1, 2, 3, 4, 5];
  options1 = [1, 2, 3, 4, 5];
  options2 = [1, 2, 3, 4, 5];
  statusvalues = ['open','closed'];
  statusvalues2 = ['Open','Closed'];
  selectedValue: number | undefined;
  selectedValue1: number | undefined;
  selectedValue2:number| undefined;
  selectedValue3:number| undefined;
  selectedValue4:number| undefined;
  selectedValue5:number| undefined;
  selectedValue6:number| undefined;
  selectedValue7:number| undefined;
  selectedValue8:number| undefined;
  product: number| undefined;
  isRiskCreationFailed:boolean=false;
  riskLoader:boolean=false;
  isRiskCreationSuccess:boolean=false;
  successTitle: String = '';
  failedTitle: string = '';
  successSubTitle: String = '';
  loadingContent: string = '';
  highlightName: string = '';
  hideContent: boolean = false;
  isCreate: boolean = false;
  date = new Date();
  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  urlToRedirect: any = '';
  mandatory: any;
  filterText: any = {};
  errorValue: number = 0;
  urlID: String = '';
  ruleId: String = '';
  dateToday = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate();
  plannedResolutionDate: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  actualDateOfResolution: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  dateOfRisk: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',    
  };
  today = new Date();
   
  myDpOptions2: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',
    disableSince: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() + 1,
    },
    disableUntil: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() - 1,
    },
    
  };

  riskTreatmentValue: boolean = false;

  constructor(
    private breadCurmbService: BreadcrumbService,
    private grcService:GrcService,
    private workflowService: WorkflowService,
    private router: Router,
    private routerUtilityService: RouterUtilityService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private utils: UtilsService,
  ) {
    this.getRiskRegisterID();
    this.getAllAffectedFunctions();
    this.getAllriskCategory();  
    this.getAllriskTreatment();
    this.getAllstatus();
    this.getAllriskOwner();   
    this.getAllmitigationstatus();   
   }

  ngOnInit(): void {
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)    
  }
  
  
  createNewRisk(form: NgForm) {
    this.hideContent = true;
    // this.ruleLoader = true;
    const newRiskModel = this.buildCreateRiskeModel(form.value);
  }
  getRiskRegisterID() {
    const url = environment.getRiskId.url;
    const method = environment.getRiskId.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      this.riskId = reponse[0];
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskId = '';
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }

  private buildCreateRiskeModel(riskForm: any) {
    const newRiskModel = Object();
    this.isCreate=true;
    newRiskModel.riskReferenceId = this.riskId;
    newRiskModel.affectedFunctions = riskForm.affectedFunctions;
    newRiskModel.status = riskForm.status;
    newRiskModel.riskCategory = riskForm.riskCategory;
    newRiskModel.riskMitigationStatus = riskForm.riskMitigationStatus;
    newRiskModel.riskDescription = riskForm.riskDescription;
    newRiskModel.riskTreatment = riskForm.riskTreatment;
    newRiskModel.mitigationstatus = riskForm.mitigationstatus;
    newRiskModel.riskOwner = riskForm.riskOwner;
    newRiskModel.mitigationPlan = riskForm.mitigationPlan;
    newRiskModel.department = riskForm.department;
    newRiskModel.service = riskForm.service;
    // newRiskModel.actualDateOfResolution = riskForm.actualDateOfResolution.singleDate.formatted;
    newRiskModel.dateOfRisk = riskForm.dateOfRisk.singleDate.formatted;
    newRiskModel.plannedResolutionDate = riskForm.plannedResolutionDate.singleDate.formatted;
    newRiskModel.riskProbability = riskForm.riskProbability;
    newRiskModel.riskImpact = riskForm.riskImpact;
    newRiskModel.riskValue = riskForm.riskValue;
    newRiskModel.residualRiskLikelihood = riskForm.residualRiskLikelihood;
    newRiskModel.residualImpact = riskForm.residualImpact;
    newRiskModel.residualRiskRating = riskForm.residualRiskRating;
    const url = environment.createRisk.url;
    const method = environment.createRisk.method;
    console.log(newRiskModel)
    this.grcService.executeHttpAction(url, method, newRiskModel,).subscribe((event: any) => {
      this.riskLoader = false;
      this.isRiskCreationSuccess = true;
    },
    (error: any) => {
      this.isRiskCreationFailed = true;
      this.riskLoader = false;
    });
  }
  onSelectaffectedFunctions(onSelectaffectedFunctions:any){

  }
  onSelectriskCategory(onSelectriskCategory:any){} 
  onSelectriskTreatment(onSelectriskTreatment:any){
    if(onSelectriskTreatment === 'Mitigation'){
      this.riskTreatmentValue = true;
    }else {
      this.riskTreatmentValue = false;
    }
  }
  onSelectriskOwner(onSelectriskOwner:any){}
  onSelectstatus(onSelectriskOwner:any){}
  onSelectmitigationstatus(onSelectriskOwner:any){}
  onSelectriskMitigationStatus(onSelectriskMitigationStatus:any){} 
  onSelectresidualImpact(onSelectresidualImpact:any){}
  onSelectresidualRiskRating(onSelectresidualRiskRating:any){}
  onSelectionChange1() {
    this.selectedValueChange1.emit(this.selectedValue1);   
  }
  onSelectionChange2() {
    this.selectedValueChange1.emit(this.selectedValue2);   
  }
  onSelectionChange3() {
    this.selectedValueChange1.emit(this.selectedValue3);   
    
  }
  onSelectionChange4() {
    this.selectedValueChange1.emit(this.selectedValue4);   
  }
  onSelectionChange5() {
    this.selectedValueChange1.emit(this.selectedValue5);   
  }
  onSelectionChange6() {
    this.selectedValueChange1.emit(this.selectedValue6);   
  }
  onSelectionChange7() {
    this.selectedValueChange1.emit(this.selectedValue7);   
  }
  onSelectionChange8() {
    this.selectedValueChange1.emit(this.selectedValue8);   
  }

  checkstatus(riskForm:any){
    if(riskForm.dateOfRisk.singleDate.formatted!=undefined){
      return false;
    }else{
      return true;
    }
    
  }
  getAllAffectedFunctions() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskAffectedFunctions.url;
    const method = environment.allRiskAffectedFunctions.method;    
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.affectedFunctions.push(
          {
            id: item.functionName,
            name: item.functionId,
          });
      }
      
    },
      (error: any) => {
        this.affectedFunctions = [];
        this.errorMessage = 'apiResponseError';
      });
  }
  getAllriskCategory() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskCategory.url;
    const method = environment.allRiskCategory.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskCategory.push(
          {
            id: item.category,
            name: item.categoryId,
          });
      }
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskCategory = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }  
    
  getAllriskTreatment() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskTreatment.url;
    const method = environment.allRiskTreatment.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskTreatment.push(
          {
            id: item.riskTreatment,
            name: item.riskTreatmentId,
          });
      }    
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskTreatment = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  getAllstatus() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allstatus.url;
    const method = environment.allstatus.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.status.push(
          {
            id: item.mitigationStatus,
            name: item.mitigationId,
          });
      }    
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskTreatment = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  getAllmitigationstatus() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allmitigationstatus.url;
    const method = environment.allmitigationstatus.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskMitigationStatus.push(
          {
            id: item.mitigationStatus,
            name: item.mitigationId,
          });
      }    
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskTreatment = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  getAllriskOwner() {
    // this.rulePolicyLoader = true;
    // this.contentHidden = true;
    // this.rulePolicyLoaderFailure = false;
    const url = environment.allRiskOwner.url;
    const method = environment.allRiskOwner.method;
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((reponse: any) => {
      for (const item of reponse[0]) {
        this.riskOwner.push(
          {
            id: item.ownerName,
            name: item.id,
          });
      }
      // this.riskOwner = reponse[0];
    },
      (error: any) => {
        // this.contentHidden = true;
        // this.rulePolicyLoader = false;
        // this.rulePolicyLoaderFailure = true;
        this.riskOwner = [];
        this.errorMessage = 'apiResponseError';
        // this.showLoader = false;
      });
  }   
  navigateBack2() {
    this.router.navigate(['pl/grc/risk-management']);   
  }
  navigateBack() {    
    try {
      this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    } catch (error) {
      this.logger.log('error', error);
    }
  }
  expiryDate: any;
  getDateData(date: any): any {    
    this.expiryDate = date.singleDate.formatted;
    
  }
  getDateData2(date: any): any {    
    this.isRiskValid=true;
    this.expiryDate = date.singleDate.formatted;
    
  }  
  calculateProduct() {
    if (this.selectedValue1 !== undefined && this.selectedValue2 !== undefined) {
      this.riskValue = this.selectedValue1 * this.selectedValue2;
    } else {
      this.riskValue = 0; // Handle cases where values are not selected
    }
  }
  calculateProduct2() {
    if (this.selectedValue4 !== undefined && this.selectedValue5 !== undefined) {
      this.residualRiskRating = this.selectedValue4 * this.selectedValue5;
    } else {
      this.residualRiskRating = 0; // Handle cases where values are not selected
    }
  }
  closeErrorMessage() {
    if (this.failedTitle === 'Loading Failed') {
      // this.getDomainDetails(this.domainName);
    } else {
      this.hideContent = false;
    }
    this.isRiskCreationFailed = false;
    this.isRiskCreationFailed = false;
  }
}
