
import { Component, ViewChild, OnInit, OnDestroy,Input} from '@angular/core';
import { LoggerService } from '../../../../shared/services/logger.service';
import { ErrorHandlingService } from '../../../../shared/services/error-handling.service';
import { AdminService } from 'src/app/pacman-features/services/admin.service';
import { environment } from './../../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { UtilsService } from '../../../../shared/services/utils.service';
import { WorkflowService } from '../../../../core/services/workflow.service';
import { RouterUtilityService } from '../../../../shared/services/router-utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-create-domain',
  templateUrl: './create-domain.component.html',
  styleUrls: ['./create-domain.component.css'],
  providers: [
    LoggerService,
    ErrorHandlingService,
    AdminService
  ]
})
export class CreateDomainComponent implements OnInit {

  breadcrumbArray: any = ['Admin', 'domain'];
  breadcrumbLinks: any = ['domain', 'create-domain'];
  breadcrumbPresent: any = 'Create domain'; 
  pageTitle:string = '';
  outerArr: any = [];
  filters: any = [];
  domainName = '';
  isCreate = false;
  successTitle = '';
  failedTitle = '';
  successSubTitle = '';
  isDomainCreationUpdationFailed = false;
  isDomainCreationUpdationSuccess = false;
  loadingContent = '';
  domainId = '';
  activatedRoute: ActivatedRoute | null | undefined;
  isDomainNameValid: any = -1;
  paginatorSize = 25;
  isLastPage: boolean =false;
  isFirstPage: boolean =false;
  totalPages: number =0;
  pageNumber = 0;
  showLoader = true;
  domainLoader = false;
  errorMessage: any;

  domain: any = {
    name: '',
    desc: '',
    config: ''
  };

  hideContent = false;

  filterText: any = {};
  errorValue = 0;

  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  urlToRedirect: any = '';
  mandatory: any;

  public labels: any;
  private previousUrl: any = '';
  private pageLevel = 0;
  public backButtonRequired :any;
   private routeSubscription: Subscription|any;
   private getKeywords: Subscription |any;
   private previousUrlSubscription: Subscription |any;
   private downloadSubscription: Subscription |any;

  constructor(
    private router: Router,
    private utils: UtilsService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private workflowService: WorkflowService,
    private routerUtilityService: RouterUtilityService,
    private adminService: AdminService,
    private breadCurmbService: BreadcrumbService,
  ) {

    this.routerParam();
    this.updateComponent();
  }

  ngOnInit() {
    this.urlToRedirect = this.router.routerState.snapshot.url;
    this.backButtonRequired = this.workflowService.checkIfFlowExistsCurrently(
      this.pageLevel
    );
    this.breadCurmbService.changeBreadCrumbData(this.breadcrumbArray, this.breadcrumbLinks, this.breadcrumbPresent, true)
  }

  nextPage() {
    try {
      if (!this.isLastPage) {
        this.pageNumber++;
        this.showLoader = true;
        // this.getPolicyDetails();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  prevPage() {
    try {
      if (!this.isFirstPage) {
        this.pageNumber--;
        this.showLoader = true;
        // this.getPolicyDetails();
      }

    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  createDomain(domain:any) {
    domain.name = domain.name.trim();
    domain.desc = domain.desc.trim();
    domain.config = domain.config.trim();

    this.loadingContent = 'creating';
    this.hideContent = true;
    this.domainLoader = true;
    this.isDomainCreationUpdationFailed = false;
    this.isDomainCreationUpdationSuccess = false;
    const url = environment.createDomain.url;
    const method = environment.createDomain.method;
    this.domainName = domain.name;
    this.adminService.executeHttpAction(url, method, domain, {}).subscribe(reponse => {
      this.successTitle = 'Domain Created';
      this.isDomainCreationUpdationSuccess = true;
      this.domainLoader = false;
      this.domain.name = '';
      this.domain.desc = '';
      this.domain.config = '';
    },
      error => {
        this.failedTitle = 'Creation Failed';
        this.domainLoader = false;
        this.isDomainCreationUpdationFailed = true;
      });
  }

  updateDomain(domain:any) {
    domain.name = domain.name.trim();
    domain.desc = domain.desc.trim();
    domain.config = domain.config.trim();
    this.loadingContent = 'updating';
    this.hideContent = true;
    this.domainLoader = true;
    this.isDomainCreationUpdationFailed = false;
    this.isDomainCreationUpdationSuccess = false;
    const url = environment.updateDomain.url;
    const method = environment.updateDomain.method;
    this.domainName = domain.name;
    this.adminService.executeHttpAction(url, method, domain, {}).subscribe(reponse => {
      this.successTitle = 'Domain Updated';
      this.isDomainCreationUpdationSuccess = true;
      this.domainLoader = false;
      this.domain.name = '';
      this.domain.desc = '';
      this.domain.config = '';
    },
      error => {
        this.failedTitle = 'Updation Failed';
        this.domainLoader = false;
        this.isDomainCreationUpdationFailed = true;
      });
  }

  closeErrorMessage() {
    if (this.failedTitle === 'Loading Failed') {
      this.getDomainDetails(this.domainName);
    } else {
      this.hideContent = false;
    }
    this.isDomainCreationUpdationFailed = false;
    this.isDomainCreationUpdationSuccess = false;
  }
  /*
    * This function gets the urlparameter and queryObj 
    *based on that different apis are being hit with different queryparams
    */
  routerParam() {
    try {
      // this.filterText saves the queryparam
      const currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;
        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        this.domainName = this.queryParamsWithoutFilter.domainName;
        delete this.queryParamsWithoutFilter['filter'];
        if (this.domainName) {
          this.pageTitle = 'Edit Domain';
          this.breadcrumbPresent = 'Edit Domain';
          this.isCreate = false;
          this.getDomainDetails(this.domainName);
        } else {
          this.pageTitle = 'Create New Domain';
          this.breadcrumbPresent = 'Create Domain';
          this.isCreate = true;
          this.getAllDomainNames();
        }

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        // check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  domainNames: any = [];
  isDomainNameAvailable(domainNameKeyword:any) {
    if (domainNameKeyword.trim().length == 0) {
      this.isDomainNameValid = -1;
    } else {
        let isKeywordExits = this.domainNames.findIndex((item: string)=> domainNameKeyword.trim().toLowerCase() === item.trim().toLowerCase());
        if (isKeywordExits === -1) {
          this.isDomainNameValid = 1;
        } else {
          this.isDomainNameValid = 0;
        }
    }
  }

  getAllDomainNames() {
    this.hideContent = true;
    this.domainLoader = true;
    this.loadingContent = 'loading';
    this.isDomainCreationUpdationFailed = false;
    this.isDomainCreationUpdationSuccess= false;
    const url = environment.getAllDomainNames.url;
    const method = environment.getAllDomainNames.method;
    this.adminService.executeHttpAction(url, method, {}, {domainName: this.domainName}).subscribe(reponse => {
      this.domainLoader = false;
      this.hideContent = false;
      this.domainNames =  reponse[0];
    },
      error => {
        this.loadingContent = 'loading';
        this.failedTitle = 'Loading Failed';
        this.domainLoader = false;
        this.isDomainCreationUpdationFailed = true;
      })
  }

  getDomainDetails(domainName:any) {
    this.hideContent = true;
    this.domainLoader = true;
    this.loadingContent = 'loading';
    this.isDomainCreationUpdationFailed = false;
    this.isDomainCreationUpdationSuccess= false;
    const url = environment.domainDetailsByName.url;
    const method = environment.domainDetailsByName.method;
    this.adminService.executeHttpAction(url, method, {}, {domainName: this.domainName}).subscribe(reponse => {
      this.domainLoader = false;
      this.hideContent = false;
      const domainDetails =  reponse[0];
      this.domain.name = domainDetails.domainName;
      this.domain.desc = domainDetails.domainDesc;
      this.domain.config = domainDetails.config;
    },
      error => {
        this.loadingContent = 'loading';
        this.failedTitle = 'Loading Failed';
        this.domainLoader = false;
        this.isDomainCreationUpdationFailed = true;
      })
  }

  /**
   * This function get calls the keyword service before initializing
   * the filter array ,so that filter keynames are changed
   */

  updateComponent() {
    this.outerArr = [];
    this.showLoader = true;
    this.errorValue = 0;
  }

  // navigateBack() {
  //   // try {
  //   //   this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
  //   // } catch (error) {
  //   //   this.logger.log('error', error);
  //   // }
  //   try {
  //     this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
  //       this.router.navigate(['../pl/admin/admin-domains'], {
  //         relativeTo: this.activatedRoute,
  //         queryParamsHandling: 'merge',
  //         queryParams: {
  //         }
  //       });
  //     } catch (error) {
  //       this.errorMessage = this.errorHandling.handleJavascriptError(error);
  //       this.logger.log('error', error);
  //     }
  // }
  navigateBack() {
    try {
      this.workflowService.goBackToLastOpenedPageAndUpdateLevel(this.router.routerState.snapshot.root);
    } catch (error) {
      this.logger.log('error', error);
    }
  }


  ngOnDestroy () {
    try {
      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }
      if (this.previousUrlSubscription) {
        this.previousUrlSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger.log('error', '--- Error while unsubscribing ---');
    }
  }
}
