  <!-- <div class="container-fluid"> -->
    <!-- <div class="row"> -->
      <div *ngIf="!contValue" class="loader-container">
        <div class="loader">
          <span></span>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="card tab-box" *ngIf='contValue'>
          <div class="container-fluid mt-2 p-1 policy-violation dataTable-section-height">
            <h6>Open Risks</h6>
            <div>
            <table datatable [dtOptions]="dtOptions" 
            *ngIf="filteredColumns.length > 0 && sortArr.length > 0" 
            id="open-risk-table" class="table nowrap table-hover" width="100%" #datatable>
              <thead>
                <tr>
                  <th (click)="sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header)"
                  *ngFor="let header of filteredColumns; let i = index">
                    {{ header }}
                    <span [class.up-arr-shown]="sortArr[i].showUp == true"
                      [class.down-arr-shown]="sortArr[i].showUp == false" class="list-sortable-arrow"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let row of currentTableData
                      | searchFilter : searchPassed
                      | orderBy
                        : {
                            property: headerColName,
                            direction: direction,
                            childProperty: 'valText'
                          };
                    let i = index
                  ">
                  <td  [ngClass]="{
                      sorting_1: row[column]?.colName === sortSelectedColomn
                    }" *ngFor="let column of filteredColumns">
                    <!-- <a *ngIf='row[column]?.link && row[column]?.text'
                      (click)='currentRowData = row;goToDetails(currentRowData, column);(row[column].text === "Edit" || row[column].text === "Disable") ? $event.stopPropagation() :""'
                      class="table-trucate edit-link" data-bs-toggle="tooltip" title="{{ row[column]?.text }}"
                      [ngClass]="{ low: row[column]?.colName == 'Status' }">
                      <span [ngClass]="{
                          'low-dot':
                            row[column]?.text === 'medium' ||
                            row[column]?.text === 'low',
                          'high-dot':
                            row[column]?.text === 'high' ||
                            row[column]?.text === 'critical'
                        }" *ngIf="row[column]?.colName == 'Severity'">
                        <i class="bi bi-circle-fill"></i>
                      </span>
                      {{ row[column]?.text || "No Data" }}
                    </a>
                    <a *ngIf='!row[column]?.link' class="table-trucate"  title="{{ row[column]?.text }}"
                      [ngClass]="{ low: row[column]?.colName == 'Status' }">
                      <span [ngClass]="{
                          'low-dot':
                            row[column]?.text === 'medium' ||
                            row[column]?.text === 'low',
                          'high-dot':
                            row[column]?.text === 'high' ||
                            row[column]?.text === 'critical'
                        }" *ngIf="row[column]?.colName == 'Severity'">
                        <i class="bi bi-circle-fill"></i>
                      </span>
                      {{ row[column]?.text || "No Data" }}
                    </a> -->
                    <a *ngIf="row[column].colName==='Risk Reference Id'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" (click)='currentRowData = row;tableRowClicked(row, i); goToDetails(currentRowData, column)'
                        [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}" data-bs-toggle="modal" data-bs-target="#modalForm">{{
                        row[column].text }}</a>
                      <a *ngIf="row[column].colName==='Actions'" class='table-trucate edit-link' data-bs-toggle="tooltip" title="{{ row[column].text }}" (click)='currentRowData = row; goToUpdateRisk(currentRowData, column)'
                      [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}">{{
                      row[column].text }}</a>
                      <a *ngIf="row[column].colName!='Risk Reference Id' && row[column].colName!='Actions'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}" (click)='currentRowData = row; goToDetails(currentRowData, column)'
                      [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}">{{
                      row[column].text }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <!-- Table Pagination Strip -->
            <!-- <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
              <div class='checked-list' *ngIf='checkBox'>Selected: <span
                  class='checked-list-length'>{{checkedList.length}}</span>
              </div>
              <div class='pagination-strip'>
                <div class='me-3'> Showing {{firstPaginator}} to <span
                    *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                    entries</span>
                </div>
                <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                    class="bi bi-chevron-left"></i>
                </div>
                <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                    [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    <!-- </div> -->
  <!-- </div> -->