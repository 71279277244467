<!-- Overall Compliance chart -->
<section *ngIf='graphSeriesData'>
    <div class="container-fluid my-3">
        <div class="row">
            <div class="col-12">
                <div class="card chart-card2">
                    <div class="card-body">
                        <h6 class="card-title">{{subHeadTitle}}
                        </h6>

                        <!-- {{ graphSeriesData | json }} -->
                        <div class="mb-4">
                            <apx-chart id="{{id}}" [stroke]="complianceChartOptions.stroke"
                                [series]="complianceChartOptions.series" [legend]="complianceChartOptions.legend" [chart]="complianceChartOptions.chart"
                                [dataLabels]="complianceChartOptions.dataLabels" [fill]="complianceChartOptions.fill"
                                [colors]="complianceChartOptions.colors" [yaxis]="complianceChartOptions.yaxis"
                                [xaxis]="complianceChartOptions.xaxis" [tooltip]="complianceChartOptions.tooltip"
                                [autoUpdateSeries]="true"></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>