         <!-- Profile Nav -->
          <li class="nav-item dropdown pe-3">

            <!-- Profile Dropdown -->
           <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow  profile">
               <li>
                <a class="dropdown-item d-flex align-items-center" href="#">
                  <i class="bi bi-question-circle"></i>
                  <span class="ps-1"> Know Your Application</span>
                </a>
              </li>
              <li (click)='logout()'>
                <a class="dropdown-item d-flex align-items-center" href="#">
                  <i class="bi bi-box-arrow-right"></i>
                  <span class="ps-1"> Log Out</span>
                </a>
              </li>  
            </ul>

           <!--Profile Icon -->
            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
              <span class="d-none d-md-block dropdown-toggle pe-2">{{firstName}}</span>              
              <img src="/assets/icons/user-circle-fill.svg" alt="Profile" class="rounded-circle">
            </a><!-- End Profile Icon -->
  
            
          </li><!-- End Profile Nav -->