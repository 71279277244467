import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuService {
    private togglClass = new Subject();
    currentMessage = this.togglClass.asObservable();
  
    constructor() { }
  
    changeMessage(message: boolean) {
      this.togglClass.next(message)
    }
}