import { Observable } from 'rxjs/Rx';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';

@Injectable()
export class CpuUtilizationService {

    values: any = [];
    dataArray: any = [];
    constructor(
                private httpService: HttpService,
                private errorHandling: ErrorHandlingService) { }

    // @ts-ignore
  getData(Url :string, Method :any): Observable<any> {
        const url = Url;
        const method = Method;
        const payload = {};
        const queryParams = {};
        try {
            return this.httpService.getHttpResponse(url, method, payload, queryParams)
                    .map(response => {
                        try {
                            this.dataCheck(response);
                            return this.massageData(response);
                        } catch (error) {
                            this.errorHandling.handleJavascriptError(error);
                        }
                    });
        } catch (error) {
            this.errorHandling.handleJavascriptError(error);
        }
    }

    dataCheck(data :any) {
        const APIStatus = this.errorHandling.checkAPIResponseStatus(data);
        if (!APIStatus.dataAvailble) {
            throw new Error('noDataAvailable');
        }
    }

    massageData(data :any): any {

        this.dataArray = [];
        this.values = [];
        for (let i = 0; i < data.response.length; i++) {
            let obj = {};
            let dateValue = data.response[i].date;
            const value = data.response[i][`cpu-utilization`];
            const fixedValue = value.toFixed(2);
            const numValue = Number(fixedValue);
            dateValue = new Date(dateValue);
            data.response[i].date = dateValue;
            obj = {
                'value': numValue,
                'keys': ['cpu-utilization'],
                'legends': ['CPU'],
                'date': data.response[i].date
            };
            this.values.push(obj);
        }
        this.dataArray = [
            {
              'values': this.values
            }
        ];
        return this.dataArray;
    }
}
