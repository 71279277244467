<!-- 

 <div class="data-table-tabs flex flex-between flex-align-center" *ngIf="tabsData">
    <div class="tabs-header flex flex-align-center">
        <div class="individual-tag-header filter-by">Filter by:</div>
        <div (click)="selectTab(null, 0)" [class.selected]="!selected" class="individual-tag-header">
            <span>All</span>
        </div>
        <div class="individual-tag-header" *ngFor="let tab of tabsData; let i = index "
             [class.selected]="tabSelected(tab)" (click)="selectTab(tab, i + 1)">
            <span>{{tab['displayName']}}</span>
        </div>
    </div>
    <div class="policies-count">
        <ng-content selector="[message]"></ng-content>
    </div>
</div> -->




  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" *ngIf="tabsData">
    <li class="nav-item" role="presentation">
      <button (click)="selectTab(null, 0)" class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-1"
        type="button" role="tab" aria-controls="pills-home" aria-selected="true">
        All
      </button>
    </li>
    <li class="nav-item" role="presentation" *ngFor="let tab of tabsData; let i = index ">
      <button (click)="selectTab(tab, i + 1)" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-2"
        type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
        {{tab['displayName']}}
      </button>
    </li>
  </ul>

