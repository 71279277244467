/*
 *Copyright 2018 T Mobile, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); You may not use
 * this file except in compliance with the License. A copy of the License is located at
 * 
 * http://www.apache.org/licenses/LICENSE-2.0
 * 
 * or in the "license" file accompanying this file. This file is distributed on
 * an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or
 * implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-content-slider',
  templateUrl: './content-slider.component.html',
  styleUrls: ['./content-slider.component.css']
})
export class ContentSliderComponent implements OnInit {

  public isClose = true;
  @Input() title = 'this is title';
  @Input() titleDesc = 'this is subtitle';
  @Output() navigatePage: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.uppercasefirst(this.title);
    this.uppercasefirst(this.titleDesc);
  }
  openSlider() {
    this.isClose = !this.isClose;
  }
  uppercasefirst(value:any) {
    if (!value) {
      return 'Not assigned';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  instructParentToNavigate () {
    this.navigatePage.emit();
  }
}
