<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <div class="card tab-box">
                    <div class="container-fluid mt-2 p-1">
                        <div class="row">
                            <div class="col-md-3 mb-4">
                                <h6 class="card-title">Rule Details</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11 mx-auto">
                                <form (ngSubmit)="createNewRule(ruleForm)" #ruleForm="ngForm" [hidden]="hideContent">
                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Rule ID*
                                                <span *ngIf="isRuleIdValid==0" class="not-available">(Not
                                                    Available)</span>
                                                <span *ngIf="isRuleIdValid==1" class="available">(Available)</span>
                                            </div>
                                            <input [ngClass]="{'not-available': isRuleIdValid==0}" disabled
                                                class="form-control input-field border-0" type="text" name="query"
                                                title=""
                                                value="{{selectedPolicyId+'_'}}{{ruleForm.value.ruleName}}{{'_'+selectedTargetType}}" />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Policy ID* </div>
                                            <ngx-select name="policyId" ngModel (select)='onSelectPolicyId($event)'
                                                [allowClear]="true" [items]="allPolicyIds"
                                                placeholder="Select Policy ID" required></ngx-select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Rule Name*</div>
                                            <input type="text" autocomplete="off" name="ruleName"
                                                [(ngModel)]="selectedRuleName"
                                                (input)="isRuleIdAvailable(selectedPolicyId + '_' + $any($event).target.value + '_' + selectedTargetType)"
                                                class="form-control input-field" placeholder="Enter Rule Name" required>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Data Source</div>
                                            <ngx-select name="dataSource" ngModel (select)='onSelectDatasource($event)'
                                                [allowClear]="true" [items]="datasourceDetails"
                                                placeholder="Select Datasource">
                                            </ngx-select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Severity *</div>
                                            <ngx-select name="ruleSeverity" ngModel [allowClear]="true"
                                                [items]="ruleSeverities" placeholder="Select Rule Severity"
                                                #ruleSeverity required>
                                            </ngx-select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Target Type</div>
                                            <ngx-select name="targetType" ngModel (select)='onSelectTargetType($event)'
                                                [allowClear]="true" [items]="targetTypesNames"
                                                placeholder="Select Target Type" #targetType >
                                            </ngx-select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Rule Frequency*</div>
                                            <ngx-select name="ruleFrequency" ngModel
                                                (select)='onSelectFrequency($event)' [allowClear]="true"
                                                [items]="allFrequencies" placeholder="Select Frequency" required>
                                            </ngx-select>
                                            <div id="other-div" class="frequency-div"
                                                *ngIf="selectedFrequency==='Daily'">
                                                <label class="border-line">Every
                                                    <input type="text" autocomplete="off" name="ruleFrequencyModeValue"
                                                        ngModel class="period" appOnlyNumber required /> Day(s)
                                                </label>
                                            </div>

                                            <div id="other-div2" class="frequency-div"
                                                *ngIf="selectedFrequency==='Hourly'">
                                                <label class="border-line">Every
                                                    <input type="text" autocomplete="off" name="ruleFrequencyModeValue"
                                                        ngModel class="period" [maxlength]="2" appOnlyNumber required />
                                                    Hour(s)
                                                </label>
                                            </div>
                                            <div id="other-div3" class="frequency-div"
                                                *ngIf="selectedFrequency==='Minutes'">
                                                <label class="border-line">Every
                                                    <input type="text" autocomplete="off" name="ruleFrequencyModeValue"
                                                        ngModel class="period" [maxlength]="2" appOnlyNumber required />
                                                    Minute(s)
                                                </label>
                                            </div>
                                            <div id="other-div4" class="frequency-div" style="width:20px;"
                                                *ngIf="selectedFrequency==='Monthly'">
                                                <label class="border-line">
                                                    <table style="width:200px;">
                                                        <tr>
                                                            <td>Day <input type="text" autocomplete="off"
                                                                    name="ruleFrequencyDays" ngModel class="period"
                                                                    id="other-input4" appOnlyNumber required /> of

                                                                every </td>
                                                            <td> <input type="text" autocomplete="off"
                                                                    name="ruleFrequencyMonths" ngModel class="period"
                                                                    id="other-input44" appOnlyNumber required />
                                                                month(s)

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </label>
                                            </div>
                                            <div id="other-div5" class="frequency-div" style="width:90%;"
                                                *ngIf="selectedFrequency==='Weekly'">
                                                <label>
                                                    <div class="d-inline">
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="mon" value="MON" name="weekName"
                                                                class="form-check-input" checked="checked" ngModel
                                                                required />
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">Mon</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="tue" value="TUE" name="weekName"
                                                                class="form-check-input" ngModel required />
                                                            <label class="form-check-label"
                                                                for="inlineRadio2">Tue</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="wed" value="WED" name="weekName"
                                                                class="form-check-input" ngModel required />
                                                            <label class="form-check-label"
                                                                for="inlineRadio3">Wed</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="thu" value="THU" name="weekName"
                                                                class="form-check-input" ngModel required />
                                                            <label class="form-check-label"
                                                                for="inlineRadio4">Thu</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="fri" value="FRI" name="weekName"
                                                                class="form-check-input" ngModel required>
                                                            <label class="form-check-label"
                                                                for="inlineRadio5">Fri</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="sat" value="SAT" name="weekName"
                                                                class="form-check-input" ngModel required />
                                                            <label class="form-check-label"
                                                                for="inlineRadio6">Sat</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" id="sun" value="SUN" name="weekName"
                                                                class="form-check-input" ngModel required />
                                                            <label class="form-check-label"
                                                                for="inlineRadio7">Sun</label>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div id="other-div6" class="frequency-div"
                                                *ngIf="selectedFrequency==='Yearly'">
                                                <label class="border-line">Every
                                                        <ngx-select name="ruleFrequencyMonth" ngModel class="p-1"
                                                            (select)='onSelectFrequencyMonth($event)'
                                                            [allowClear]="true" [items]="allMonths"
                                                            placeholder="Select Month" required>
                                                        </ngx-select>

                                                        <ngx-select name="ruleFrequencyDay" ngModel class="w-25 p-1"
                                                            id="other-input66" [allowClear]="true"
                                                            [items]="allMonthDays" placeholder="Select Day"
                                                            #ruleFrequencyMonthDay required>
                                                        </ngx-select>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Would you want to scan your rule against an asset
                                                group?</div>
                                            <ngx-select name="assetGroup" ngModel [allowClear]="true"
                                                [items]="assetGroupNames" placeholder="Select Asset Group">
                                            </ngx-select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Category *</div>
                                            <ngx-select name="ruleCategory" ngModel [allowClear]="true"
                                                [items]="ruleCategories" placeholder="Select Rule Category"
                                                #ruleCategory required>
                                            </ngx-select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Rule Display Name*</div>
                                            <input type="text" autocomplete="off" name="ruleDisplayName"
                                                [(ngModel)]="ruleDisplayName" class="form-control input-field"
                                                placeholder="Enter Rule Display Name" required>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="heading-gray">Would you want to set a keyword?*
                                                <span *ngIf="isAlexaKeywordValid==0" class="not-available">(Not
                                                    Available)</span>
                                                <span *ngIf="isAlexaKeywordValid==1"
                                                    class="available">(Available)</span>
                                            </label>
                                            <input type="text" autocomplete="off" name="alexaKeywords" ngModel
                                                class="form-control input-field"
                                                (input)="isAlexaKeywordAvailable($any($event).target.value)"
                                                placeholder="Enter keyword" required>
                                            <div class="w-75 lh-12 heading-gray px-1">
                                                        <!-- <small><em>Enter a unique
                                                        keyword.You
                                                        can say to Alexa: Alexa, ask PacMan to Scan "Select an Asset
                                                        Group"
                                                        for ""</em></small> -->
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-6 mb-4">
                                        <div class="form-check pt-4">
                                            <input type="checkbox" class="form-check-input" id="test5"
                                                name="isAutofixEnabled" [checked]="true"
                                                [(ngModel)]="isAutofixEnabled">
                                            <label class="form-check-label" for="defaultCheck1">
                                                Enable Autofix
                                            </label>
                                        </div>
                                    </div>
                                        <!-- <div class="col-md-6 mb-4" style="height:120px;">
                                            <div class="heading-gray">Rule Type</div>
                                            <div class="w-75">
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" id="federated-rule" value="Classic"
                                                        name="ruleType" class="form-check-input" checked="checked"
                                                        [(ngModel)]="ruleType">
                                                    <label class="form-check-label" for="inlineRadio1">Federated
                                                        Rule</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" id="manage-rule" value="ManageRule"
                                                        name="ruleType" class="form-check-input" [(ngModel)]="ruleType">
                                                    <label class="form-check-label" for="inlineRadio2">Manage
                                                        Rule</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" id="serverless" value="Serverless"
                                                        name="ruleType" class="form-check-input" [(ngModel)]="ruleType">
                                                    <label for="serverless" class="form-check-label">Serverless <a
                                                            target="_blank">(Create Serverless URL)</a></label>
                                                </div>
                                            </div>

                                            <div class="option1 box pt-1">
                                                <div class="col-md-6 mb-2" *ngIf="ruleType=='Classic'">Rule Executable
                                                    <br>
                                                    <div id="radio_div" class="file btn  btn-upload">
                                                        <a (click)="openJarFileBrowser($event)"
                                                            *ngIf="ruleJarFileName==''"><i class="bi bi-upload"></i>
                                                            Upload JAR File</a>
                                                        <input type="file" id="selectJarFile" class="hide"
                                                            name="ruleJarFile" (change)="onJarFileChange($event)"
                                                            [(ngModel)]="ruleJarFile" required>
                                                        <div class="selected-file">{{ruleJarFileName}} <a
                                                                (click)="removeJarFileName();"
                                                                *ngIf="ruleJarFileName!=''"><i
                                                                    class="fa fa-remove"></i></a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="option3 box pt-1" *ngIf="ruleType=='Serverless'">
                                                <div class="col-md-12 mb-4">
                                                    <div class="heading-gray">Rule Rest URL *</div>
                                                    <input type="text" autocomplete="off" name="ruleRestUrl"
                                                        class="form-control input-field"
                                                        placeholder="Enter the Rule Rest URL" ngModel required>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Rule Parameters
                                                <div class="row">
                                                    <div class="col-3 pe-1">
                                                        <input type="text" autocomplete="off"
                                                            class="form-control input-field-small"
                                                            placeholder="Enter Key" name="ruleParamKey"
                                                            [(ngModel)]="parametersInput.ruleKey">
                                                    </div>
                                                    <div class="col-3">
                                                        <input type="text" autocomplete="off"
                                                            class="form-control input-field-small"
                                                            placeholder="Enter Value" name="ruleParamValue"
                                                            [(ngModel)]="parametersInput.ruleValue">
                                                    </div>
                                                    <div class="col-2 px-0  pt-2 Encrypt">
                                                        <div class="form-check">
                                                            <input type="checkbox" #isRuleParamEncrypted
                                                                id="encryptruleparam" class="form-check-input">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                                Encrypt
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 px-0 addbutton">
                                                        <button class="btn add-btn"
                                                            [disabled]="!(parametersInput.ruleKey&&parametersInput.ruleValue&&allRuleParamKeys.indexOf(parametersInput.ruleKey.trim())==-1)"
                                                            (click)="addRuleParameters(parametersInput, isRuleParamEncrypted)"><i
                                                                class="fa fa-plus"></i>Add</button>
                                                    </div>
                                                </div>
                                                <div class="row px-2">
                                                    <div class="col-10  border rounded-2 mt-2 px-0 ms-1"
                                                        *ngIf="allRuleParams.length==0">
                                                        <div class=" text-center py-5">No Rule Parameters Added</div>
                                                    </div>
                                                    <div class="col-10  border rounded-2 mt-2 px-0 ms-1"
                                                        *ngIf="allRuleParams.length!==0">
                                                        <table class="table table-hover px-0 table-text">
                                                            <thead>
                                                                <tr>
                                                                    <th>Key</th>
                                                                    <th scope="col">Value </th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody
                                                                *ngFor="let ruleParams of allRuleParams;let ruleParamsIndex = index">
                                                                <tr>
                                                                    <td>{{ruleParams.key}}</td>
                                                                    <td *ngIf="!ruleParams.encrypt">{{ruleParams.value}}
                                                                    </td>
                                                                    <td *ngIf="ruleParams.encrypt"> XXXX <span
                                                                            class="highlight-pink">(Encrypted)</span>
                                                                    </td>
                                                                    <td><a
                                                                            (click)="removeRuleParameters(ruleParamsIndex)">X</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div class="heading-gray">Environment Parameters
                                                <div class="row">
                                                    <div class="col-3 pe-1">
                                                        <input type="text" autocomplete="off"
                                                            class="form-control input-field-small"
                                                            placeholder="Enter Key" name="envParamKey"
                                                            [(ngModel)]="parametersInput.envKey" />
                                                    </div>
                                                    <div class="col-3">
                                                        <input type="text" autocomplete="off"
                                                            class="form-control input-field-small"
                                                            placeholder="Enter Value" name="envParamValue"
                                                            [(ngModel)]="parametersInput.envValue" />
                                                    </div>
                                                    <div class="col-2 px-0  pt-2 Encrypt">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                #isEnvParamEncrypted id="encryptenvparam" />
                                                            <label class="form-check-label" for="defaultCheck1">
                                                                Encrypt 
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 px-0 addbutton">
                                                        <button class="btn add-btn"
                                                            [disabled]="!(parametersInput.envKey&&parametersInput.envValue&&allEnvParamKeys.indexOf(parametersInput.envKey.trim())==-1)"
                                                            (click)="addEnvironmentParameters(parametersInput, isEnvParamEncrypted)"><i
                                                                class="fa fa-plus"></i>Add</button>
                                                    </div>
                                                </div>
                                                <div class="row px-2">
                                                    <div class="col-10  border rounded-2 mt-2 px-0 ms-1"
                                                        *ngIf="allEnvironments.length==0">
                                                        <div class=" text-center py-5">No Environment Parameters Added
                                                        </div>
                                                    </div>
                                                    <div class="col-10  border rounded-2 mt-2 px-0 ms-1"
                                                        *ngIf="allEnvironments.length!==0">
                                                        <table class="table table-hover px-0 table-text">
                                                            <thead>
                                                                <tr>
                                                                    <th>Key</th>
                                                                    <th scope="col">Value </th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody
                                                                *ngFor="let envParams of allEnvironments; let ruleEnvParamIndex = index;">
                                                                <tr>
                                                                    <td>{{envParams.key}}</td>
                                                                    <td *ngIf="!envParams.encrypt; else elseBlock">
                                                                        {{envParams.value}}</td>
                                                                    <td>
                                                                        <ng-template #elseBlock>
                                                                            XXXX <span
                                                                                class="highlight-pink">(Encrypted)</span>
                                                                        </ng-template>
                                                                    </td>
                                                                    <td><a
                                                                            (click)="removeEnvironmentParameters(ruleEnvParamIndex)">X</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="col-md-4 mx-auto my-4 button-div createsubmit">
                                                <a class="btn btn-border me-2">
                                                    <button class="submit-btn" type="button" (click)="navigateBack()">
                                                        <i class="bi bi-x fs-5"></i>
                                                        Cancel
                                                    </button>
                                                </a>
                                                <a class="btn btn-border me-2 btn-green">
                                                    <button class="submit-btn text-white"
                                                        [disabled]="(isRuleIdValid == 0 || isRuleIdValid == -1 || ruleForm.form.invalid || isAlexaKeywordValid == 0 || isAlexaKeywordValid == -1)">
                                                        <i class="bi bi-plus fs-5"></i>
                                                        Create
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="pacman-alert" *ngIf="isRuleCreationFailed">
                                    <div class="sa-icon sa-error animateErrorIcon" style="display: block;">
                                        <span class="sa-x-mark animateXMark">
                                            <span class="sa-line sa-left"></span>
                                            <span class="sa-line sa-right"></span>
                                        </span>
                                    </div>
                                
                                    <div class="pacman-alert-title">
                                        Rule Creation Failed !!!
                                    </div>
                                    <div class="pacman-alert-button">
                                        <a class="btn btn-border me-2">
                                            <button class="submit-btn retybutton" type="button" (click)="closeErrorMessage()">
                                                <i class="bi bi-x fs-5"></i>
                                                Cancel
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div class="pacman-alert" *ngIf="isRuleCreationSuccess">
                                    <div class="sa-icon sa-success animate">
                                        <span class="sa-line sa-tip animateSuccessTip"></span>
                                        <span class="sa-line sa-long animateSuccessLong"></span>
                                        <div class="sa-placeholder"></div>
                                        <div class="sa-fix"></div>
                                    </div>
                                
                                    <div class="pacman-alert-title">
                                        Rule Created
                                    </div>
                                    <div class="pacman-alert-message">
                                        <span
                                            class="highlight-pink">{{selectedPolicyId+'_'}}{{ruleForm.value.ruleName}}{{'_'+selectedTargetType}}</span>
                                        has been successfully created !!!
                                    </div>
                                    <div class="pacman-alert-button">
                                        <a class="btn btn-border me-2">
                                            <button class="submit-btn retybutton" type="button" (click)="navigateBack()">
                                                <i class="bi bi-x fs-5"></i>
                                                OK
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div class="pacman-progress" *ngIf="ruleLoader">
                                    <div class="title">
                                        Please Wait...
                                    </div>
                                    <div class="pacman-alert-message">
                                        <span
                                            class="highlight-pink">{{selectedPolicyId+'_'}}{{ruleForm.value.ruleName}}{{'_'+selectedTargetType}}</span>
                                        is been creating...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>