
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ASSETS_ROUTES } from '../../../shared/constants/routes';

const routes: Routes = ASSETS_ROUTES;

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AssetsRoutingModule {}
