<section>
  <div class="container-fluid main-heading">
    <div class="row">
      <div class="col-md-6 col-6 text-left mt-1">
        <!-- <h6>Add Risk</h6> -->
      </div>
      <div class="col-md-6 col-6 text-end mb-2">
        <a
          class="btn page-button float-end"
          routerLink="/pl/grc/risk-management">
          <i class="bi bi-chevron-left text-white"></i>
          Back
        </a>
      </div>
    </div>
  </div>
</section>    

<section>
  <div class="container-fluid mt-1">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="card">
          <div class="card-body py-5">
            <div class="col-lg-5 col-sm-7 mx-auto pt-5  text-center addrisk">
              <a class="btn addrisk-button w-100 fs-5 mb-5" 
               (click)="routelink()">
                <img src="assets/images/fill-form.svg">
                Do you want to type in risk?
                <br>
                Yes?
            </a>
              <br>
              <a
                class="btn addrisk-button-gray w-100 fs-5"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop">
                <img src="assets/images/upload.svg">
                Do you want upload the risks using
                <br>
                an excel spreadsheet?  Yes?
              </a>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Row Ends -->
  </div>
</section>
<div
  class="modal modal2 fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog2">
    <div class="modal-content text-center">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          #closebutton
          aria-label="Close"></button>
      </div>
      <div class="modal-body text-center p-5">
        <!-- Popup  Buttons -->
        <!-- Download Template file -->
        <a
          (click)="exportAsExcel()"
          class="btn addrisk-button-gray text-green w-100 fs-5 dwnbutton">
          <img src="assets/images/download.svg">
          Download Template
        </a>
        <br>
        <br>
        <!-- Upload file -->
        <a class="btn btn-green-upload w-100 fs-5 p-15">
          <img src="assets/images/upload-white.svg">
          Upload File
          <input
            id="my-file-selector"
            type="file"
            (change)="uploadData($event)"
            name="file">
        </a>
        <!--  -->
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="hasMissingDescriptions"
  class="alert alert-danger"
  role="alert">
  Risk Description Must be Filled.
  <button
    class="close"
    (click)="closeAlert()"
    aria-label="close">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div
  *ngIf="hasDescriptions"
  class="alert alert-success"
  role="success">
  Bulk upload success
  <button
    class="close"
    (click)="closeAlert1()"
    aria-label="close">
    <span aria-hidden="true">×</span>
  </button>
</div>
