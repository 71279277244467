import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexPlotOptions, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { GrcService } from '../../services/grc.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  yaxis: ApexYAxis
};

@Component({
  selector: 'app-risk-severity',
  templateUrl: './risk-severity.component.html',
  styleUrls: ['./risk-severity.component.css']
})
export class RiskSeverityComponent implements OnInit {

  private riskDataUrl = environment.riskBySeverity.url;
  private riskDataMethod = environment.riskBySeverity.method;
  severityData: any = [];
  series: any[] = [];
  chartCategories: any[] = [];
  errorMessage: any;
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToRiskBySeverity: Subscription | any;
  contValue = false;
 
  loaded = false;
  constructor(private grcService:GrcService,
    private errorHandling: ErrorHandlingService) { 
    this.chartOptions = {
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['3 - Medium', '5 - Very High', '4 - High', '1 - Negligible', 'Blank', '2 - Low']
      },
      yaxis: {
        min: 0,
        max: 50,
        tickAmount: 2,
      },
      series: [
        {
          data:[0,0,0,0,0,0]
        },
      ],

      legend: {
        show: false,
      },

      colors: ['#DAB315', '#D9434E', '#FC6A59', '#01B27C' , '#01B27C', '#0097AC', '#3F9234' ],

      chart: {
        type: 'bar',
        height: 200,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columWidth: '70%',
          barHeight: '40%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
    };
  }

  ngOnInit() {
   this.getBySeverity();   
  }

  getBySeverity(): void {
    try {
      this.subscriptionToRiskBySeverity = this.grcService
        .executeHttpAction(          
          this.riskDataUrl,
          this.riskDataMethod, {}, {}
        )
        .subscribe(
          
          (response:any) => {
            try {
              this.severityData = response[0];
              this.series = [];
              this.chartCategories = [];
              this.contValue = true;
              this.loaded = true;            
              for (let i = 0; i < this.severityData.length; i++) {
                const severityItem = this.severityData[i];
                  this.chartCategories.push(severityItem.name);
                  this.series.push(severityItem.count);
              }
              this.chartOptions.series = [
                {
                  data: this.series,
                },
              ];

              this.chartOptions.xaxis = {
                categories: this.chartCategories,
              };
            } catch (e) {
              
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          (error) => {8
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
    }

    this.series = [];
    this.chartCategories =[];
  }
}
