import { Component, OnInit } from '@angular/core';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { Subscription } from 'rxjs';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { RefactorFieldsService } from 'src/app/shared/services/refactor-fields.service';
import { environment } from 'src/environments/environment';
import { GrcService } from '../../services/grc.service';
import * as moment from 'moment';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterUtilityService } from 'src/app/shared/services/router-utility.service';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { DomainTypeObservableService } from 'src/app/core/services/domain-type-observable.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DataCacheService } from 'src/app/core/services/data-cache.service';

@Component({
  selector: 'app-open-risk',
  templateUrl: './open-risk.component.html',
  styleUrls: ['./open-risk.component.css']
})
export class OpenRiskComponent implements OnInit {

  allOpenRisks: any = [];
  errorMessage: any;
  paginatorSize: number = 25;
  isLastPage : boolean =false; 
  isFirstPage : boolean =false; 
  totalPages : number=0;
  pageNumber : number = 0;
  searchCriteria: any;
  searchTxt: string = '';
  dataTableData: any = [];
  tableDataLoaded: boolean = false;
  errorValue: number = 0;
  outerArr: any = [];
  allColumns: any = [];
  totalRows: number = 0;
  currentBucket: any = [];
  bucketNumber: number = 0;
  firstPaginator: number = 1;
  lastPaginator: number=0;
  currentPointer: number = 0;
  seekdata: boolean = false;
  showLoader: boolean = true;
  filterText: any;
  riskListingdata: any = [];
  functionsList: any = [];
  severityList: any = [];
  ownerList: any = [];
  selectedRiskDate: any;
  showGenericMessage: boolean = false;
  date = new Date();
  dateToday = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate();
  plannedResolutionDate: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  actualDateOfResolution: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  dateOfRisk: any = {
    name: '',
    reason: '',
    expiry: this.dateToday,
    assetGroup: []
  }
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy/mm/dd',    
  };
  myDpOptions2: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy/mm/dd',    
  };
  private riskListingSubscription: Subscription |any;
  FullQueryParams: any;
  queryParamsWithoutFilter: any;
  provider:any = [];
  mandatory: any;
  urlID: string = '';
  public agAndDomain :any= {};
  private assetGroupSubscription: Subscription |any;
  private domainSubscription: Subscription |any;

  constructor(private grcService:GrcService,
    private logger: LoggerService,
    private errorHandling: ErrorHandlingService,
    private refactorFieldsService: RefactorFieldsService,
    private workflowService: WorkflowService,
    private router: Router,private activatedRoute: ActivatedRoute,
    private routerUtilityService: RouterUtilityService,
    private assetGroupObservableService: AssetGroupObservableService,
    private domainObservableService: DomainTypeObservableService,   
    private loggerService: LoggerService,
    private utils: UtilsService,    
    private dataCacheService: DataCacheService,) { }

  ngOnInit() {
    this.getFunctionDetails();
    this.getOwnerDetails();
    this.getData();
  }

  getFunctionDetails() {
    const url = environment.riskMappingFunction.url;
    const method = environment.riskMappingFunction.method;    
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((response: any) => {
      for (const item of response[0]) {
        this.functionsList.push(
          {
            id: item.functionName,
            name: item.functionId,
          });
      }
      
    },
      (error: any) => {
        this.functionsList = [];
        this.errorMessage = 'apiResponseError';
      });
  }

  getOwnerDetails() {
    const url = environment.riskMappingOwner.url;
    const method = environment.riskMappingOwner.method;    
    this.grcService.executeHttpAction(url, method, {}, {}).subscribe((response: any) => {
      for (const item of response[0]) {
        this.ownerList.push(
          {
            id: item.ownerName,
            name: item.id,
          });
      }
      
    },
      (error: any) => {
        this.ownerList = [];
        this.errorMessage = 'apiResponseError';
      });
  }

  getDateData2(date: any): any {    
    this.selectedRiskDate = date.singleDate.formatted;
  }

  onSelectFunctions(onSelectFunctions:any){
    const selectedOption = onSelectFunctions;
  }

  onSelectSeverity(onSelectSeverity:any){
    const selectedOption = onSelectSeverity;
  }

  onSelectOwner(onSelectOwner:any){
    const selectedOption = onSelectOwner;
  }

  getData() {
    try {
      if (this.riskListingSubscription) {
        this.riskListingSubscription.unsubscribe();
      }

      var queryParams:{ from: number; size: number} = {
        from: this.pageNumber, 
        size: this.paginatorSize
       };

      const riskListingUrl = environment.openRisk.url;
      const riskListingMethod = environment.openRisk.method;
      this.errorValue = 0;
      this.riskListingSubscription = this.grcService
        .executeHttpAction( riskListingUrl, riskListingMethod, {}, queryParams)
        .subscribe(
          response => {
            this.showGenericMessage = false;
            try {
              this.errorValue = 1;
              this.searchCriteria = undefined;
              this.tableDataLoaded = true;
              const data = response[0];
              this.dataTableData = data.response;
              this.showLoader = false;
              // this.dataLoaded = true;
              if (data.response.length === 0) {
                this.errorValue = -1;
                this.outerArr = [];
                this.allColumns = [];
                this.totalRows = 0;
              }
              if (data.response.length > 0) {
                this.riskListingdata = data.response;
                this.seekdata = false;
                this.totalRows = data.total;
                this.firstPaginator =
                  this.bucketNumber * this.paginatorSize + 1;
                this.lastPaginator =
                  this.bucketNumber * this.paginatorSize + this.paginatorSize;
                this.currentPointer = this.bucketNumber;
                if (this.lastPaginator > this.totalRows) {
                  this.lastPaginator = this.totalRows;
                }
                const updatedResponse = this.massageData(this.riskListingdata);
                this.currentBucket[this.bucketNumber] = updatedResponse;
                this.processData(updatedResponse);
              }
            } catch (e) {
              this.errorValue = 0;
              this.errorValue = -1;
              this.outerArr = [];
              // this.dataLoaded = true;
              this.seekdata = true;
              this.errorMessage = this.errorHandling.handleJavascriptError(e);
            }
          },
          error => {
            this.showGenericMessage = true;
            this.errorValue = -1;
            this.outerArr = [];
            // this.dataLoaded = true;
            this.seekdata = true;
            this.errorMessage = 'apiResponseError';
          }
        );
    } catch (error) {
      this.showLoader = false;
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  massageData(data :any) {
    const refactoredService = this.refactorFieldsService;
    const newData :any = [];
    data.map(function(responseData :any) {
      const KeysTobeChanged = Object.keys(responseData);
      let newObj:any = {};
      KeysTobeChanged.forEach(element => {
        
        const elementnew =
          refactoredService.getDisplayNameForAKey(
            element.toLocaleLowerCase()
          ) || element;
        newObj = Object.assign(newObj, { [elementnew]: responseData[element] });
      });
      newObj['Actions'] = '';
      newData.push(newObj);
    });
    return newData;
  }

  processData(data:any) {
    try {
      var innerArr:any = {};
      var totalVariablesObj :any= {};
      var cellObj = {};
      var magenta = '#e20074';
      var green = '#26ba9d';
      var red = '#f2425f';
      var orange = '#ffb00d';
      var yellow = 'yellow';
      this.outerArr = [];
      var getData = data;

      if (getData.length) {
        var getCols:any = Object.keys(getData[0]);
      } else {
        this.seekdata = true;
      }

      for (var row = 0; row < getData.length; row++) {
        innerArr = {};
        for (var col = 0; col < getCols.length; col++) {
          if (getCols[col].toLowerCase() == 'date of risk') {
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: moment(getCols[col]).format('YYYY/MM/DD'),
              hasPreImg: false,
              imgLink: '',
              text: moment(getData[row][getCols[col]]).format('YYYY/MM/DD'),
              valText: moment(getData[row][getCols[col]]).format('YYYY/MM/DD')
            };
          } else if (getCols[col].toLowerCase() == 'actions') {
            cellObj = {
              link: true,
              properties: {
                'text-shadow': '0.33px 0',
                'color': '#ed0295'
              },
              colName: getCols[col],
              hasPreImg: false,
              valText: 'Edit',
              imgLink: '',
              text: 'Edit',
              statusProp: {
                'color': '#ed0295'
              }
            };
          } else{
            cellObj = {
              link: '',
              properties: {
                color: ''
              },
              colName: getCols[col],
              hasPreImg: false,
              imgLink: '',
              text: getData[row][getCols[col]],
              valText: getData[row][getCols[col]]
            };
          }
          innerArr[getCols[col]] = cellObj;
          totalVariablesObj[getCols[col]] = '';
        }
        this.outerArr.push(innerArr);
      }
      if (this.outerArr.length > getData.length) {
        var halfLength = this.outerArr.length / 2;
        this.outerArr = this.outerArr.splice(halfLength);
      }
      this.allColumns = Object.keys(totalVariablesObj);
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  calculateDate(_JSDate :any ) {
    if (!_JSDate) {
            return 'No Data';
        }
    const date = new Date(_JSDate);
    const year = date.getFullYear().toString();
    const month = date.getMonth() + 1;
    let monthString;
    if (month < 10) {
      monthString = '0' + month.toString();
    } else {
      monthString = month.toString();
    }
    const day = date.getDate();
    let dayString;
    if (day < 10) {
      dayString = '0' + day.toString();
    } else {
      dayString = day.toString();
    }
    return monthString + '-' + dayString + '-' + year;
  }
  
  callNewSearch() {
    // this.cbArr = [];
    // this.cbObj = {};
    this.outerArr = [];
    this.tableDataLoaded = false;
    this.currentBucket = [];
    // this.cbModel = [];
    this.bucketNumber = 0;
    this.dataTableData = [];
    this.firstPaginator = 1;
    this.showLoader = true;
    this.currentPointer = 0;
    // this.dataLoaded = false;
    this.seekdata = false;
    this.errorValue = 0;
    this.showGenericMessage = false;
    this.getData();
  }

  nextPg() {
    try {
      if (this.currentPointer < this.bucketNumber) {
        this.currentPointer++;
        this.processData(this.currentBucket[this.currentPointer]);
        this.firstPaginator = this.currentPointer * this.paginatorSize + 1;
        this.lastPaginator =
          this.currentPointer * this.paginatorSize + this.paginatorSize;
        if (this.lastPaginator > this.totalRows) {
          this.lastPaginator = this.totalRows;
        }
      } else {
        this.bucketNumber++;
        this.getData();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  prevPg() {
    try {
      this.currentPointer--;
      this.processData(this.currentBucket[this.currentPointer]);
      this.firstPaginator = this.currentPointer * this.paginatorSize + 1;
      this.lastPaginator =
        this.currentPointer * this.paginatorSize + this.paginatorSize;
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  searchCalled(search :any ) {
    this.searchTxt = search;
  }

  nextPage() {
    try {
      if (!this.isLastPage) {
        this.pageNumber++;
        this.showLoader = true;
        this.getData();
      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }

  goToDetails(row:any) {
    if(row.col === 'Actions') {
      try {
        this.workflowService.addRouterSnapshotToLevel(this.router.routerState.snapshot.root);
        this.router.navigate(['../update-risk'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: {
            riskReferenceId : row.row['Risk Reference Id'].text
          }
        });
      } catch (error) {
        this.errorMessage = this.errorHandling.handleJavascriptError(error);
        this.logger.log('error', error);
      }
    }
    
  }

  prevPage() {
    try {
      if (!this.isFirstPage) {
        this.pageNumber--;
        this.showLoader = true;
        this.getData();
      }

    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
  getProvider() {
    /* Store the recently viewed asset list in stringify format */
    try {
    let recentList = '';
    recentList = this.dataCacheService.getRecentlyViewedAssetGroups();
    if (recentList) {
      const currentAGDetails = JSON.parse(recentList).filter((element:any) => element.ag === this.agAndDomain['ag']);
      this.provider = this.fetchprovider(currentAGDetails);
     }
    } catch (error) {
      this.loggerService.log('error', error);
    }
  }
   fetchprovider(assetGroupObject :any) {
    const provider :any = [];
    if (assetGroupObject.length && assetGroupObject[0].providers) {
      assetGroupObject[0].providers.forEach((element:any) => {
        provider.push(element.provider);
      });
    }
    return provider;
}
  subscribeToAgAndDomainChange() {
    this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(assetGroup => {
      this.agAndDomain['ag'] = assetGroup;
    });
    this.domainSubscription = this.domainObservableService.getDomainType().subscribe(domain => {
      this.agAndDomain['domain'] = domain;
      this.getProvider();
    });
  }
  routerParam() {
    try {
      // this.filterText saves the queryparam
      let currentQueryParams = this.routerUtilityService.getQueryParametersFromSnapshot(this.router.routerState.snapshot.root);
      if (currentQueryParams) {

        this.FullQueryParams = currentQueryParams;

        this.queryParamsWithoutFilter = JSON.parse(JSON.stringify(this.FullQueryParams));
        delete this.queryParamsWithoutFilter['filter'];

        /**
         * The below code is added to get URLparameter and queryparameter
         * when the page loads ,only then this function runs and hits the api with the
         * filterText obj processed through processFilterObj function
         */
        this.filterText = this.utils.processFilterObj(
          this.FullQueryParams
        );

        this.urlID = this.FullQueryParams.TypeAsset;
        //check for mandatory filters.
        if (this.FullQueryParams.mandatory) {
          this.mandatory = this.FullQueryParams.mandatory;
        }

      }
    } catch (error) {
      this.errorMessage = this.errorHandling.handleJavascriptError(error);
      this.logger.log('error', error);
    }
  }
}
