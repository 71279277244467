import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { ComplianceOverviewService } from '../../services/compliance-overview.service';
import { AutorefreshService } from '../../services/autorefresh.service';
import { Subscription } from 'rxjs/Subscription';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { AssetGroupObservableService } from 'src/app/core/services/asset-group-observable.service';
import { SelectComplianceDropdown } from '../../services/select-compliance-dropdown.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import {environment} from "../../../../environments/environment";
import { DomainTypeObservableService } from '../../../core/services/domain-type-observable.service';
@Component({
  selector: 'app-vulnerabilities-compliance-trend',
  templateUrl: './vulnerabilities-compliance-trend.component.html',
  styleUrls: ['./vulnerabilities-compliance-trend.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ ComplianceOverviewService, AutorefreshService ],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class VulnerabilitiesComplianceTrendComponent implements OnInit,OnDestroy {

  @ViewChild('vulnerabilitiesComplianceOverviewContainer') widgetContainer: ElementRef |any;

  private assetGroupSubscription: Subscription;
  private complianceDropdownSubscription: Subscription;
  private issuesSubscription: Subscription|any;
  private selectedAssetGroup: any = 'rebellion';

    graphWidth: any;
  graphData: any;
  dataLoaded: any = false;
  error: any = false;
  loading: any = false;
   errorMessage: any = 'apiResponseError';
   distributedFiltersObject: any = {};

  // Graph customization variables
  yAxisLabel = 'Compliance %';
  showGraphLegend = true;
  showArea = false;
  singlePercentLine = true;

  private autorefreshInterval :any;

  durationParams: any;
  autoRefresh: boolean;

  constructor(private complianceOverviewService: ComplianceOverviewService,
              private assetGroupObservableService: AssetGroupObservableService,
              private selectComplianceDropdown: SelectComplianceDropdown,
              private autorefreshService: AutorefreshService,
              private logger: LoggerService, private errorHandling: ErrorHandlingService) {

        // Get latest asset group selected and re-plot the graph
        this.assetGroupSubscription = this.assetGroupObservableService.getAssetGroup().subscribe(
            assetGroupName => {
                this.selectedAssetGroup = assetGroupName;
                this.init();
            });

        // Get latest targetType/Application/Environment
        this.complianceDropdownSubscription = this.selectComplianceDropdown.getCompliance().subscribe(
            distributedFiltersObject => {
                this.distributedFiltersObject = distributedFiltersObject;
            });

            this.durationParams = this.autorefreshService.getDuration();
            this.durationParams = parseInt(this.durationParams, 10);
            this.autoRefresh = this.autorefreshService.autoRefresh;
  }

  onResize() {
      const element = document.getElementById('vulnerabilitiesComplianceOverview');
      if (element) {
          this.graphWidth = parseInt((window.getComputedStyle(element, null).getPropertyValue('width')).split('px')[0], 10);
      }
  }


  getOverview() {
      try {

          if (this.issuesSubscription) { this.issuesSubscription.unsubscribe(); }

          const complianceOverviewUrl = environment.vulnerabilityComplianceTrend.url;
          const method = environment.vulnerabilityComplianceTrend.method;

          const prevDate = new Date();
          prevDate.setMonth(prevDate.getMonth() - 1);
          let fromDay;
          fromDay = prevDate.toISOString().split('T')[0];

          const queryParameters = {
              'ag': this.selectedAssetGroup,
              'from': fromDay,
              'filters': {}
          };

          this.issuesSubscription = this.complianceOverviewService.getWeeklyData(complianceOverviewUrl, method, queryParameters).subscribe(
              response => {
                  try {
                    if (response.length) {
                      response.forEach((type: { key: any; }) => {
                        const key = type.key.toLowerCase();
                        if (key === 'compliance_percent') {
                            this.graphData = [type];
                        }
                      });
                      this.setDataLoaded();
                    } else {
                       this.setError('noDataAvailable');
                    }

                  } catch (error) {
                      this.setError('jsError');
                  }
              },
              error => {
                this.setError(error.message || 'apiResponseError');
              }
          );
      } catch (error) {
          this.setError('jsError');
      }
  }

  getData() {
      this.getOverview();
  }

  init() {
      this.setDataLoading();
      this.getData();
  }

  setDataLoaded() {
      this.dataLoaded = true;
      this.error = false;
      this.loading = false;
  }

  setDataLoading() {
      this.dataLoaded = false;
      this.error = false;
      this.loading = true;
  }

  setError(message?: any) {
      this.dataLoaded = false;
      this.error = true;
      this.loading = false;
      if (message) {
          this.errorMessage = message;
      }
  }

  ngOnInit() {

      this.durationParams = this.autorefreshService.getDuration();
      this.durationParams = parseInt(this.durationParams, 10);
      this.autoRefresh = this.autorefreshService.autoRefresh;

      const afterLoad = this;
        if (this.autoRefresh !== undefined) {
          if ((this.autoRefresh === true ) || (this.autoRefresh.toString() === 'true')) {

            this.autorefreshInterval = setInterval(function() {
              afterLoad.getData();
            }, this.durationParams);
          }
        }

      try {
          this.graphWidth = parseInt(window.getComputedStyle(this.widgetContainer.nativeElement, null).getPropertyValue('width'), 10);
      } catch (error) {
          this.errorMessage = this.errorHandling.handleJavascriptError(error);
          this.setError(error);
      }
  }

  ngOnDestroy() {
      try {
          this.issuesSubscription.unsubscribe();
          this.assetGroupSubscription.unsubscribe();
          this.complianceDropdownSubscription.unsubscribe();
          clearInterval(this.autorefreshInterval);
      } catch (error) {
          this.logger.log('error', '--- Error while unsubscribing ---');
      }
  }
}
