
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-filter-info',
  templateUrl: './filter-info.component.html',
  styleUrls: ['./filter-info.component.css']
})
export class FilterInfoComponent implements OnInit {

  @Input() tags: any;
  @Input() header: any;
  @Input() avoidTags = false;

  showHeader= false;
  tagsFilter: any= {};
  tagsArray  :Array<any> = [];

  constructor() { }

  ngOnInit() {
    this.tagsArray= [];
    const keys = Object.keys(this.tags);
    let filterdTags:any = [];
    
    keys.forEach(element => {
      if (this.checkElement(element, keys)) {
        filterdTags.push(element);
      }
    });

    if (this.avoidTags) {
      filterdTags = keys;
    }

    for (let i = 0; i < filterdTags.length; i++) {
      const splittedName = filterdTags[i].split('.');
      splittedName[1] = splittedName[1] || filterdTags[i];
      let obj = {};
      obj = {
        'name': splittedName[1],
        'value': this.tags[filterdTags[i]]
      };
      this.tagsArray.push(obj);
    }
    
    if (this.header !== undefined) {
      this.showHeader = true;
    } else {
      this.showHeader = false;
    }
  }

   checkElement(element: any, keys: any) {
    for (const key of keys) {
      if (element.indexOf(key) > -1) {
        return true; // Element contains at least one of the keys
      }
    }
    return false; // Element doesn't contain any of the keys
  }
}
