<section>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card tab-box">
          <div class="container-fluid mt-2 p-1">
            <div class="row">
              <div class="col-md-3 mb-4">
                <h6 class="card-title">Rule Details</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11 mx-auto">
                <form
                  (ngSubmit)="updateRule(ruleForm)"
                  #ruleForm="ngForm" [hidden]="hideContent">
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Rule ID*</div>
                      <input
                        class="form-control input-field"
                        disabled
                        type="text"
                        name="query"
                        title
                        value="{{ruleDetails.ruleId}}">
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Policy ID*</div>
                      <input
                        class="form-control input-field"
                        disabled
                        type="text"
                        name="query"
                        title
                        value="{{ruleDetails.policyId}}">
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Rule Name*</div>
                      <input
                        class="form-control input-field"
                        disabled
                        type="text"
                        name="query"
                        title
                        value="{{ruleDetails.ruleName}}">
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Data Source</div>
                      <input
                        class="form-control input-field"
                        disabled
                        type="text"
                        name="query"
                        title
                        value="{{ruleDetails.dataSource}}">
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Severity *</div>
                      <ngx-select
                        name="selectedSeverity"
                        [(ngModel)]="selectedSeverity"
                        (select)="onSelectSeverity($event)"
                        [allowClear]="true"
                        [items]="ruleSeverities"
                        placeholder="Select Rule Severity"
                        required>
                      </ngx-select>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Target Type</div>
                      <input
                        class="form-control input-field"
                        disabled
                        type="text"
                        name="query"
                        title
                        value="{{ruleDetails.targetType}}">
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Rule Frequency*</div>
                      <ngx-select
                        name="ruleFrequency"
                        [(ngModel)]="ruleFrequency"
                        (select)="onSelectFrequency($event)"
                        [allowClear]="true"
                        [items]="allFrequencies"
                        placeholder="Select Frequency"
                        required>
                      </ngx-select>
                      <div
                        id="other-div"
                        class="frequency-div"
                        *ngIf="selectedFrequency==='Daily'">
                        <label class="border-line">
                          Every
                          <input
                            type="text"
                            autocomplete="off"
                            name="ruleFrequencyModeValue"
                            [(ngModel)]="ruleFrequencyModeValue"
                            class="period"
                            appOnlyNumber
                            required>
                          Day(s)
                        </label>
                      </div>
                      <div
                        id="other-div2"
                        class="frequency-div"
                        *ngIf="selectedFrequency==='Hourly'">
                        <label class="border-line">
                          Every
                          <input
                            type="text"
                            autocomplete="off"
                            name="ruleFrequencyModeValue"
                            [(ngModel)]="ruleFrequencyModeValue"
                            class="period"
                            [maxlength]="2"
                            appOnlyNumber
                            required>
                          Hour(s)
                        </label>
                      </div>
                      <div
                        id="other-div3"
                        class="frequency-div"
                        *ngIf="selectedFrequency==='Minutes'">
                        <label class="border-line">
                          Every
                          <input
                            type="text"
                            class="period"
                            autocomplete="off"
                            name="ruleFrequencyModeValue"
                            [(ngModel)]="ruleFrequencyModeValue"
                            [maxlength]="2"
                            appOnlyNumber
                            required>
                          Minute(s)
                        </label>
                      </div>
                      <div
                        id="other-div4"
                        class="frequency-div"
                        *ngIf="selectedFrequency==='Monthly'">
                        <label class="border-line">
                          <table style="width:200px;">
                            <tbody>
                              <tr>
                                <td>
                                  Day
                                  <input
                                    type="text"
                                    class="period"
                                    autocomplete="off"
                                    name="ruleFrequencyDays"
                                    [(ngModel)]="ruleFrequencyDays"
                                    appOnlyNumber
                                    required>
                                  of
                                                            Every
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="period"
                                    autocomplete="off"
                                    name="ruleFrequencyMonths"
                                    [(ngModel)]="ruleFrequencyMonths"
                                    appOnlyNumber
                                    required>
                                  Month(s)
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </label>
                      </div>
                      <div
                        id="other-div5"
                        class="frequency-div"
                        style="width:90%;"
                        *ngIf="selectedFrequency==='Weekly'">
                        <label>
                          <div class="d-inline">
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="mon"
                                value="MON"
                                name="weekName"
                                class="form-check-input"
                                checked="checked"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio1">
                                Mon
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="tue"
                                value="TUE"
                                name="weekName"
                                class="form-check-input"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio2">
                                Tue
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="wed"
                                value="WED"
                                name="weekName"
                                class="form-check-input"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio3">
                                Wed
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="thu"
                                value="THU"
                                name="weekName"
                                class="form-check-input"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio4">
                                Thu
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="fri"
                                value="FRI"
                                name="weekName"
                                class="form-check-input"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio5">
                                Fri
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="sat"
                                value="SAT"
                                name="weekName"
                                class="form-check-input"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio6">
                                Sat
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                type="radio"
                                id="sun"
                                value="SUN"
                                name="weekName"
                                class="form-check-input"
                                [(ngModel)]="weekName"
                                required>
                              <label
                                class="form-check-label"
                                for="inlineRadio7">
                                Sun
                              </label>
                            </div>

                          </div>
                        </label>
                      </div>
                      <div
                        id="other-div6"
                        class="frequency-div"
                        *ngIf="selectedFrequency==='Yearly'">
                        <label class="border-line">
                          Every
                          <div class="dates">
                            <ngx-select
                              name="ruleFrequencyMonth"
                              class="p-1"
                              [(ngModel)]="ruleFrequencyMonth"
                              (select)="onSelectFrequencyMonth($event)"
                              [allowClear]="true"
                              [items]="allMonths"
                              placeholder="Select Month"
                              required>
                            </ngx-select>
                            <label>{{ruleFrequencyMonth|json}}</label>

                            <ngx-select
                              name="ruleFrequencyDay"
                              class="w-25 p-1"
                              [(ngModel)]="ruleFrequencyDay"
                              [allowClear]="true"
                              [items]="allMonthDays"
                              placeholder="Select Day"
                              required>
                            </ngx-select>
                            <label>{{ruleFrequencyDay|json}}</label>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">
                        Would you want to scan your rule against an asset
                                            group?
                      </div>
                      <ngx-select
                        name="assetGroup"
                        [(ngModel)]="assetGroup"
                        [allowClear]="true"
                        [items]="assetGroupNames"
                        placeholder="Select Asset Group">
                      </ngx-select>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Category *</div>
                      <ngx-select
                        name="selectedCategory"
                        [(ngModel)]="selectedCategory"
                        (select)="onSelectCategory($event)"
                        [allowClear]="true"
                        [items]="ruleCategories"
                        placeholder="Select Rule Category"
                        required>
                      </ngx-select>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="heading-gray">Rule Display Name*</div>
                      <input
                        type="text"
                        autocomplete="off"
                        name="ruleDisplayName"
                        [(ngModel)]="ruleDisplayName"
                        class="form-control input-field"
                        placeholder="Enter Rule Display Name"
                        required>
                    </div>
                    <div class="col-md-6 mb-4">
                      <label class="heading-gray">
                        Would you want to set a PacMan Alexa skill keyword?*
                        <span
                          *ngIf="isAlexaKeywordValid==0"
                          class="not-available">(Not Available)</span>
                        <span
                          *ngIf="isAlexaKeywordValid==1"
                          class="available">(Available)</span>
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        name="alexaKeywords"
                        [(ngModel)]="alexaKeywords"
                        class="form-control input-field"
                        (input)="isAlexaKeywordAvailable($any($event).target.value)"
                        placeholder="Enter Alexa skill keyword"
                        required>
                      <div class="w-75 lh-12 heading-gray px-1">
                        <small>
                          <em>Enter a unique keyword.You
                                                    can say to Alexa: Alexa, ask PacMan to Scan "Select an Asset Group"
                                                    for ""</em>
                        </small>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 pt-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="test5"
                          name="isAutofixEnabled"
                          [checked]="true"
                          [(ngModel)]="isAutofixEnabled">
                        <label
                          class="form-check-label"
                          for="defaultCheck1">
                          Enable Autofix
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-6 mb-4">
                                        <div class="heading-gray">Rule Type</div>
                                        <div class="w-75">
                                            <div class="form-check form-check-inline">
                                                <input type="radio" id="federated-rule" value="Classic" name="ruleType" class="form-check-input" checked="checked" [(ngModel)]="ruleType">
                                                <label class="form-check-label" for="inlineRadio1">Federated
                                                    Rule</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                    <input type="radio" id="manage-rule" value="ManageRule" name="ruleType" class="form-check-input" [(ngModel)]="ruleType">
                                                <label class="form-check-label" for="inlineRadio2">Manage Rule</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" id="serverless" value="Serverless" name="ruleType" class="form-check-input" [(ngModel)]="ruleType">                                                    
                                                <label class="form-check-label" for="inlineRadio3">Serverless(Create
                                                    Serverless URL)</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 heading-gray upload-file" *ngIf="ruleType=='Classic'">Rule Executable <br>
                                            <div class="file btn  btn-upload">
                                                <a (click)="openJarFileBrowser($event)" *ngIf="ruleJarFileName==''"><i class="bi bi-upload"></i> Upload JAR file</a>
                                                <input type="file" id="selectJarFile" class="hide" (change)="onJarFileChange($event)" name="ruleJarFile" [(ngModel)]="ruleJarFile" *ngIf="ruleJarFileName==''" required>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4" *ngIf="ruleType=='Serverless'">Rule Rest URL *<br>
                                            <input type="text" autocomplete="off" name="ruleRestUrl" class="form-control input-field" placeholder="Enter the Rule Rest URL" [(ngModel)]="ruleRestUrl" required>
                                        </div>
                                    </div> -->

                  <div class="col-md-6 mb-4 mt-4">
                    <div class="heading-gray parameters-div">
                      Rule Parameters
                      <span
                        *ngIf="allRuleParamKeys.indexOf(parametersInput.ruleKey.trim())!=-1"
                        class="not-available">(Key already added)</span>
                      <div class="row">
                        <div class="col-3 pe-1">
                          <input
                            type="text"
                            autocomplete="off"
                            class="form-control input-field-small"
                            placeholder="Enter Key"
                            name="ruleParamKey"
                            [(ngModel)]="parametersInput.ruleKey">
                        </div>
                        <div class="col-3">
                          <input
                            type="text"
                            autocomplete="off"
                            class="form-control input-field-small"
                            placeholder="Enter Value"
                            name="ruleParamValue"
                            [(ngModel)]="parametersInput.ruleValue">
                        </div>
                        <div class="col-2 px-0  pt-2">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              #isRuleParamEncrypted
                              id="encryptruleparam"
                              class="form-check-input">
                            <label
                              class="form-check-label"
                              for="defaultCheck1">
                              Encrypt
                            </label>
                          </div>
                        </div>
                        <div class="col-2 px-0">
                          <button
                            class="btn add-btn"
                            [disabled]="!(parametersInput.ruleKey&&parametersInput.ruleValue&&allRuleParamKeys.indexOf(parametersInput.ruleKey.trim())==-1)"
                            (click)="addRuleParameters(parametersInput, isRuleParamEncrypted)">
                            <i class="fa fa-plus"></i>
                            + Add
                          </button>
                        </div>
                      </div>
                      <div
                        class="row px-2"
                        *ngIf="allRuleParams.length==0">
                        <div class="col-10  border rounded-2 mt-2 px-0 ms-1">
                          <div class="text-center py-5">No Rule Parameters Added</div>
                        </div>
                      </div>
                      <div
                        class="col-10  border rounded-2 mt-2 px-0 ms-1"
                        *ngIf="allRuleParams.length!==0">
                        <table class="table table-hover px-0 table-text">
                          <thead>
                            <tr>
                              <th>Key</th>
                              <th scope="col">Value</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody *ngFor="let ruleParams of allRuleParams;let ruleParamsIndex = index">
                            <tr>
                              <td>{{ruleParams.key}}</td>
                              <td *ngIf="!ruleParams.encrypt">{{ruleParams.value}}</td>
                              <td *ngIf="ruleParams.encrypt">
                                XXXX
                                <span class="highlight-pink">(Encrypted)</span>
                              </td>
                              <td>
                                <a (click)="removeRuleParameters(ruleParamsIndex)">X</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4 mt-4">
                    <div class="heading-gray parameters-div">
                      Environment Parameters
                      <div class="row">
                        <div class="col-3 pe-1">
                          <input
                            type="text"
                            autocomplete="off"
                            class="form-control input-field-small"
                            placeholder="Enter Key"
                            name="envParamKey"
                            [(ngModel)]="parametersInput.envKey">
                        </div>
                        <div class="col-3">
                          <input
                            type="text"
                            autocomplete="off"
                            class="form-control input-field-small"
                            placeholder="Enter Value"
                            name="envParamValue"
                            [(ngModel)]="parametersInput.envValue">
                        </div>
                        <div class="col-2 px-0  pt-2">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              #isEnvParamEncrypted
                              id="encryptenvparam">
                            <label
                              class="form-check-label"
                              for="defaultCheck1">
                              Encrypt
                            </label>
                          </div>
                        </div>
                        <div class="col-2 px-0">
                          <button
                            class="btn add-btn"
                            [disabled]="!(parametersInput.envKey&&parametersInput.envValue&&allEnvParamKeys.indexOf(parametersInput.envKey.trim())==-1)"
                            (click)="addEnvironmentParameters(parametersInput, isEnvParamEncrypted)">
                            + Add
                          </button>
                        </div>
                      </div>
                      <div class="row px-2">
                        <div
                          class="col-10  border rounded-2 mt-2 px-0 ms-1"
                          *ngIf="allEnvironments.length==0">
                          <div class="text-center py-5">No Environment Parameters Added</div>
                        </div>
                      </div>
                      <div
                        class="col-10  border rounded-2 mt-2 px-0 ms-1"
                        *ngIf="allEnvironments.length!==0">
                        <table class="table table-hover px-0 table-text">
                          <thead>
                            <tr>
                              <th>Key</th>
                              <th scope="col">Value</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody *ngFor="let envParams of allEnvironments; let ruleEnvParamIndex = index;">
                            <tr>
                              <td>{{envParams.key}}</td>
                              <td *ngIf="!envParams.encrypt; else elseBlock">{{envParams.value}}</td>
                              <td>
                                <ng-template #elseBlock>
                                  XXXX
                                  <span class="highlight-pink">(Encrypted)</span>
                                </ng-template>
                              </td>
                              <td>
                                <a (click)="removeEnvironmentParameters(ruleEnvParamIndex)">X</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="col-md-4 mx-auto my-4 button-div createsubmit">
                      <a class="btn btn-border me-2">
                        <button
                          class="submit-btn"
                          type="button"
                          (click)="navigateBack()">
                          <i class="bi bi-x fs-5"></i>
                          Cancel
                        </button>
                      </a>
                      <a class="btn btn-border me-2 btn-green">
                        <button class="submit-btn text-white">
                          <i class="bi bi-check fs-5"></i>
                          Update
                        </button>
                      </a>
                    </div>
                  </div>
                </form>
                <div
                  class="pacman-alert"
                  *ngIf="isRuleUpdationFailed">
                  <div
                    class="sa-icon sa-error animateErrorIcon"
                    style="display: block;">
                    <span class="sa-x-mark animateXMark">
                                        
                      
                      <span class="sa-line sa-left"></span>
                                    
                      
                      <span class="sa-line sa-right"></span>
                                    </span>
                  </div>
                  <div class="pacman-alert-title">Rule Updation Failed</div>
                  <div class="pacman-alert-message">
                    <span class="highlight-pink">{{ruleDetails.ruleId}}</span>
                    has been failed to update !!!
                  </div>
                  <div class="pacman-alert-button">
                    <a class="btn btn-border me-2">
                      <button
                        class="submit-btn retybutton cancelbut"
                        type="button"
                        (click)="closeErrorMessage()">
                        <i class="bi bi-x fs-5"></i>
                        Cancel
                      </button>
                    </a>
                  </div>
                </div>
                <div
                  class="pacman-alert"
                  *ngIf="isRuleUpdationSuccess">
                  <div class="sa-icon sa-success animate">
                    <span class="sa-line sa-tip animateSuccessTip"></span>
                    <span class="sa-line sa-long animateSuccessLong"></span>
                    <div class="sa-placeholder"></div>
                    <div class="sa-fix"></div>
                  </div>
                  <div class="pacman-alert-title">Rule Updated</div>
                  <div class="pacman-alert-message">
                    <span class="highlight-pink">{{ruleDetails.ruleId}}</span>
                    has been successfully updated !!!
                  </div>
                  <div class="pacman-alert-button">
                    <a class="btn btn-border me-2">
                      <button
                        class="submit-btn cancelbut"
                        type="button"
                        (click)="navigateBack()">
                        <i class="bi bi-x fs-5"></i>
                        Cancel
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
