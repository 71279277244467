
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class AutorefreshService {

    durationParams: any;
    autoRefresh: boolean =false;

    // @ts-ignore
  getDuration(): Promise<AutorefreshService[]> {
        const absUrl = window.location.href;
        const sURLVariables = absUrl.split('?');
        const urlObj :any = {};

        if (sURLVariables[1] !== undefined) {
        const valuesParams = sURLVariables[1].split('&');

        for (let i = 0; i < valuesParams.length; i++) {
            const paramsName = valuesParams[i].split('=');
            urlObj[paramsName[0]] = paramsName[1];
        }

        if (urlObj.hasOwnProperty('autorefresh') ) {
            if (urlObj['autorefresh'] === true || urlObj['autorefresh'] === 'true' || urlObj['autorefresh'] === '' || urlObj['autorefresh'] == null || urlObj['autorefresh'] === ' ') {
                this.autoRefresh = true;
            }
        }

        if (urlObj.hasOwnProperty('interval') ) {
            if (urlObj['interval'] === '' || urlObj['interval'] == null || urlObj['interval'] === undefined || urlObj['interval'] === ' ') {
                urlObj['interval'] = 1;
            }
        } else {
            urlObj['interval'] = 1;
        }
        this.durationParams = urlObj['interval'] * 60000;
        return this.durationParams;
    }
  }
}
