

import { Component, OnInit, Input, NgZone, ViewChild, ElementRef, OnChanges } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexLegend,
} from 'ng-apexcharts';

export type coloumnChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  legend: ApexLegend;
};


@Component({
  selector: 'app-multiline-chart',
  templateUrl: './multiline-chart.component.html',
  styleUrls: ['./multiline-chart.component.css'],
})

export class MultilineChartComponent {

  @Input() id: any;
  @Input() graphWidth: any;
  @Input() graphHeight: any = 280;
  @Input() graphLinesData: any;
  @Input() yAxisLabel = '';
  @Input() xAxisLabel = '';
  @Input() showLegend = true;
  @Input() showArea = false;
  @Input() singlePercentLine = false;
  @Input() hoverActive = true;
  @Input() subHeadTitle = '';

  graphSeriesData: any = {};
  public complianceChartOptions: Partial<coloumnChartOptions> | any;

  constructor() {
    this.complianceChartOptions = {
      series: [],

      chart: {
        height: 350,
        type: 'area',
      },

      colors: ['#9fcb8d', '#277e71', '#a3748f', '#b6c3a3', '#DC8580'],

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },

      xaxis: {
        type: 'datetime',
        categories: [],
      },

      legend: {
        show: true,
      },

      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },

      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0, 80],
        },
      },
    };
  }
  ngOnInit(): void {
    this.getPolicyViolationsHistoryData(this.graphLinesData);
  }

  getPolicyViolationsHistoryData(graphData: any) {
    const date:any = [];
    const min: any = [];
    const max: any = [];

    let complianceGraphSeriesData;

    graphData.forEach((data: any, i: any) => {
      date.push(this.convertTime(data.date || 0));
      min.push(data.min || 0);
      max.push(data.max || 0);
    });

    complianceGraphSeriesData = {
      date: date,
      min: min,
      max: max,
    };

    let yAxisHighst = [...min, ...max];
    yAxisHighst.sort(function (a, b) {
      return a - b;
    });

    let yAxisMax = Math.ceil(yAxisHighst.slice(-1)[0] / 100) * 100;

    this.complianceChartOptions.xaxis = {
      categories: complianceGraphSeriesData.date,
    };

    this.complianceChartOptions.series = [
      {
        name: 'Min',
        data: complianceGraphSeriesData['min'],
      },
      {
        name: 'Max',
        data: complianceGraphSeriesData['max'],
      },
    ];

    this.complianceChartOptions.yaxis = {
      min: 0,
      max: yAxisMax,
      tickAmount: 4,
    };


  }


  convertTime(dateTime: any) {
    let date =
      new Date(dateTime).toString().split(' ')[1] +
      ' ' +
      new Date(dateTime).toString().split(' ')[2];
    return date;
  }
}
