
 <div class='relative searchable-dropdown-wrapper flex flex-col flex-center pe-4'>
    <div class='items-wrapper sidehistory' *ngIf="errorValue == 1">
        <div class="modal-header border-0 ">
            <h6 class="modal-title text-center p-2 " id="staticBackdropLabel">History List</h6>
            <button type="button" class="close" (click)='closeBox();'><i class="bi bi-x fs-5"></i></button>            
        </div>
        <div class="scrollbar" id="style-16">
        <div class='each-tile' *ngFor='let tile of tiles; let i = index;' [class.active]= 'activeTile == i' [class.hover-tile]='activeTile == -1' (click)= 'activeTile = i; selected(tile, i)'>
            <div class='flex flex-col each-tile-wrapper'>
                <div class='flex flex-between each-tile-row firstrow'>
                    <div>Modified by: <span class='owner'>{{tile.modifiedBy}}</span></div>
                    <div class='timestamp'>{{tile.auditTimeStamp}}</div>
                </div>
                <div class='each-tile-row message' *ngIf='tile.userMessage'>User remarks: <span>{{tile.userMessage}}</span></div>
                <div class='each-tile-row message' *ngIf='tile.systemMessage'>System remarks: <span>{{tile.systemMessage}}</span></div>
                <div class='flex flex-col flex-wrap'>
                    <div class='application-name flex flex-row flex-wrap' *ngFor='let property of tile.configPropertyChangeList; let i = index;'>
                            <span>{{property.application}}: </span>
                        <div class='property' *ngFor='let configObj of property.configProperties; let j = index;'>
                            <span  *ngIf= 'j < 2'>{{configObj.configKey}}</span>
                            <span  *ngIf= 'j == 2'>+{{property.configProperties.length - 2}} more</span>
                            <span class='property-border'></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="error-msg-block center" *ngIf="errorValue == -1">
        <app-error-message [selectedValue]="errorMessage"></app-error-message>
    </div>
</div>