

import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-list-table',
    templateUrl: './list-table.component.html',
    styleUrls: ['./list-table.component.css']
})
export class ListTableComponent implements OnInit, OnChanges {
    @Input() tableData: any;
    @Input() tableHeaderData: any;
    @Input() searchText: any = '';
    @Input() orderByConfig :any;
    @Input() orderByProperty :any;
    @Output() error: EventEmitter<any> = new EventEmitter();
    @Output() navigatePage: EventEmitter<any> = new EventEmitter();
    public tableBodyData: any;
    @Input() colorData:any = [];
    @Input() colorTransData: any;
    @Input() donutData: any;
    @Input() overallPercentage: any;
    @Input() loaded: any;
    result: any;
    dataResult: any;

    constructor() {
    }

    ngOnInit() {
        this.finalTableData();
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
          const graphDataChange = changes['tableData'];
          if (graphDataChange) {
            const cur  = JSON.stringify(graphDataChange.currentValue);
            const prev = JSON.stringify(graphDataChange.previousValue);
            if ((cur !== prev) && (this.tableData)) {
                this.finalTableData();
            }
          }
        } catch (error) {
          this.error.emit('jsError');
        }
      }

    finalTableData() {
        this.tableBodyData = this.tableData;
        this.result = this.tableBodyData[0].tagged+this.tableBodyData[0].untagged;
        this.dataResult = this.tableBodyData[1].tagged+this.tableBodyData[1].untagged;
    }

    addTest(object :any) :any {
        if (object) {
            let count = 0;
            for (const i of object.severityinfo) {
                count += object.severityinfo[i].count;
            }
            return count;
        }
    }
    instructParentToNavigate (appName :any, colName :any ) {
        const emitData = {
            'Rowname': appName,
            'Colname': colName
        };
        this.navigatePage.emit(emitData);
    }
}
