

import { Injectable } from '@angular/core';
// @ts-ignore
import { Observable } from 'rxjs/Observable';
// @ts-ignore
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { DataCacheService } from './data-cache.service';

@Injectable()

export class DomainTypeObservableService {
    private subject = new ReplaySubject<string>(0);
    // listOfDomains
    private domainListSubject = new ReplaySubject<string>(0);
    constructor(private dataCache: DataCacheService) {}
    // This function updates the domain type on selection of asset group
    updateDomainType(domainName: string, assetGroupNameAsSessionStorageKey: string) {
        if (domainName) {
            this.dataCache.setCurrentSelectedDomain(domainName, assetGroupNameAsSessionStorageKey);
            this.subject.next(domainName);
        }
    }

    // This function returns the updated domain type
    getDomainType(): Observable<any> {
        return this.subject.asObservable();
    }

    updateListOfDomains(domainList: string) {
        if (domainList) {
            this.dataCache.setCurrentSelectedDomainList(domainList);
            this.domainListSubject.next(domainList);
        }
    }

    getDomainListForAAssetGroup(): Observable<any> {
        return this.domainListSubject.asObservable();
    }
}
