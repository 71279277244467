import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexLegend,
} from 'ng-apexcharts';
import { Subscription } from 'rxjs';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  labels: any;
  legend: ApexLegend;
  colors: string[];
};

@Component({
  selector: 'app-riskrate',
  templateUrl: './riskrate.component.html',
  styleUrls: ['./riskrate.component.css']
})
export class RiskrateComponent implements OnInit {
  public chartOptions: Partial<ChartOptions> | any;
  subscriptionToAging: Subscription | any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    //apexcharts-pie
    this.chartOptions = {
      series: [40, 20, 10, 30],
      chart: {
        height: 500,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50px',
            labels: {
              show: true,
              name: {
                show: false
              },
              value: {
                show: true,
                formatter: (val:any) => {
                  return val + ' tCO2e';
                }
              },
              total: {
                show: true,
                showAlways: false,
                formatter: (w:any) => {
                  return w.globals.seriesTotals.reduce((a:any, b:any) => {
                    return a + b
                  }, 0) + ' tCO2e'
                }
              }
            }
          }
        }
      },
      stroke: {
        width: 0,
        colors: ["#fff"]
      },
      colors: [
        '#fc6a59', 
        '#dab315',
        '#008699',
        '#d9434e',                         
      ],
      labels: ['Corporate','Tagging','Vulnerabilities ',' Physical Security'],
      dataLabels: {
        enabled: true,
        horizontalAlign: "left",
      },
      legend: {
        position: "left",
        horizontalAlign: "left",
        offsetX: 20
      },
    };
  }

}
