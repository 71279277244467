import { Component, OnDestroy, OnInit } from '@angular/core';


import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { LoggerService } from '../../../shared/services/logger.service';
import { WorkflowService } from '../../../core/services/workflow.service';
import { DomainMappingService } from '../../../core/services/domain-mapping.service';
import { DomainTypeObservableService } from '../../../core/services/domain-type-observable.service';
import {RouterUtilityService} from "../../../shared/services/router-utility.service";

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.css'],
  providers: []
})

export class ComplianceComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }
  
}