<section class="createrisk">
  <div class="container-fluid main-heading py-1 main-div">
    <div class="row">
      <div class="col-md-3 mb-4">
        <h6 class="card-title"></h6>
      </div>
    </div>
    <div class="floating-widgets-container">
      <div>
        <div class="widget-wrapper relative">
          <div class="data-table-wrap relative">
            <div class="sub-head">
              <div class="flex flex-between flex-align-center p-25">
                <div class="row">
                  <div class="col-md-11 mx-auto">                  
                    <form
                      (ngSubmit)="createNewRisk(riskForm)"
                      #riskForm="ngForm">
                      <div [hidden]="hideContent">
                        <div class="row">
                          <div class="riskdetails">
                            <div class="pt-10">
                              <span>Risk ID: </span>
                              <span> {{riskId}}</span>
                            </div>
                            <div class="d-flex riskdate">
                              <div class="pt-10">Date of Risk:</div>
                              <div>
                                <input
                                  class="mydatepicker"
                                  (ngModelChange)="getDateData2($event)"
                                  angular-mydatepicker
                                  name="dateOfRisk"
                                  [(ngModel)]="dateOfRisk"
                                  [options]="myDpOptions2"
                                  (click)="dp.toggleCalendar()"
                                  #dp="angular-mydatepicker" aria-required="true" required>
                                <span class="input-group-addon">
                                
                                  <span class="input-group-text d-block border-0 calander">
                                  <i class="bi bi-calendar"></i>
                                </span>
                              </span>
                              <!-- <input class="mydatepicker" [ngModel]="dateOfRisk | date:'yyyy-MM-dd'" 
                                  (ngModelChange)="dateOfRisk = $event" type="date" name="dateOfRisk"/> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3 mb-4">
                            <div class>Select Business Function</div>
                            <ngx-select
                              ngModel
                              name="affectedFunctions"
                              [allowClear]="true"
                              [items]="affectedFunctions"
                              (select)="onSelectaffectedFunctions($event)"
                              placeholder="Select Business Function"
                              required></ngx-select>
                          </div>
                          <div class="col-md-3 mb-4">
                            <div class>Department</div>
                            <input
                              type="text"
                              autocomplete="off"
                              name="department"
                              [(ngModel)]="department"
                              class="form-control input-field"
                              placeholder="Enter Department"
                              required>
                          </div>
                          <div class="col-md-3 mb-4">
                            <div class>Service/Application</div>
                            <input
                              type="text"
                              autocomplete="off"
                              name="service"
                              [(ngModel)]="service"
                              class="form-control input-field"
                              placeholder="Enter Application"
                              required>
                          </div>
                          <div class="col-md-3 mb-4">
                            <div class>Risk Category</div>
                            <ngx-select
                              ngModel
                              name="riskCategory"
                              [allowClear]="true"
                              [items]="riskCategory"
                              (select)="onSelectriskCategory($event)"
                              placeholder="Select Risk Category"
                              required></ngx-select>
                          </div>

                        </div>
                        <div class="row secondrow">
                          <div class="col-md-2 mb-4">
                            <div class>Risk Probability/Likelihood</div>
                            <select
                              [(ngModel)]="selectedValue1"
                              (change)="calculateProduct()"
                              name="riskProbability"
                              class="numbervalue" required>
                              <option
                                *ngFor="let option of options1"
                                placeholder="Select Business Function"
                                [value]="option">
                                {{ option }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-2 mb-4">
                            <div class>Risk Impact</div>
                            <select
                              [(ngModel)]="selectedValue2"
                              (change)="calculateProduct()"
                              name="riskImpact"
                              class="numbervalue" required>
                              <option
                                *ngFor="let option of options2"
                                [value]="option">
                                {{ option }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-2 mb-4">  

                            <div class>Risk Rating</div>
                            <input
                              type="text"
                              autocomplete="off"
                              name="riskValue"
                              [(ngModel)]="riskValue"
                              class="form-control input-field"
                              placeholder="Enter Application"
                              readonly
                              >
                            <!-- <div class="product">{{ product }}</div> -->
                            <!-- <select
                            [(ngModel)]="selectedValue3"
                            name="riskValue"
                            class="numbervalue">
                            <option
                              *ngFor="let option of options"
                              [value]="option">
                              {{ option }}
                            </option>
                          </select> -->
                          </div>
                          <div class="col-md-6">
                            <div class>Risk Description</div>
                            <textarea
                              name="riskDescription"
                              rows="4"
                              class="input-field textarea"
                              placeholder="Enter Description"
                              [(ngModel)]="riskDescription" required></textarea>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3 mb-4">
                            <div class>Risk Treatment</div>
                            <ngx-select
                              ngModel
                              name="riskTreatment"
                              [allowClear]="true"
                              [items]="riskTreatment"
                              (select)="onSelectriskTreatment($event)"
                              placeholder="Select Risk Treatment"
                              required></ngx-select>
                          </div>
                          <div class="col-md-3 mb-4">
                            <div class>Status</div>                            
                            <ngx-select
                              ngModel
                              name="status"
                              [allowClear]="true"
                              [items]="status"
                              (select)="onSelectstatus($event)"
                              placeholder="Select Status"
                              required></ngx-select>
                          </div>
                        </div>
                        <div
                          class="lastbox"
                          *ngIf="riskTreatmentValue">
                          <div class="row">
                            <div class="col-md-4 mb-4">
                              <div class>Mitigation Plan</div>
                              <input
                                type="text"
                                autocomplete="off"
                                name="mitigationPlan"
                                [(ngModel)]="mitigationPlan"
                                class="form-control input-field"
                                placeholder="Enter Mitigation Plan"
                                required>
                            </div>
                            <div class="col-md-4 mb-4 planneddate">
                              <div class>Plan Mitigation Date</div>
                              <span class="input-group-addon">
                            
                                <span class="input-group-text d-block border-0 calander">
                              <i class="bi bi-calendar"></i>
                            </span>
                          <input
                                  class="mydatepicker"
                                  angular-mydatepicker
                                  name="plannedResolutionDate"
                                  (ngModelChange)="getDateData($event)"
                                  [(ngModel)]="plannedResolutionDate"
                                  [options]="myDpOptions"
                                  (click)="dp1.toggleCalendar()"
                                  #dp1="angular-mydatepicker"></span>
                            </div>
                            <div class="col-md-4 mb-4">
                              <div class>Risk Owner</div>
                              <ngx-select
                                ngModel
                                name="riskOwner"
                                [allowClear]="true"
                                [items]="riskOwner"
                                (select)="onSelectriskOwner($event)"
                                placeholder="Select Risk Owner"
                                required></ngx-select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 mb-4">
                              <div class>Risk Mitigation Status</div>                              
                              <!-- <select
                              [(ngModel)]="selectedValue7"
                              name="riskMitigationStatus"
                              class="numbervalue" required>
                              <option
                                *ngFor="let statusvalue of statusvalues2"
                                [value]="statusvalue">
                                {{ statusvalue }}
                              </option>
                              </select> -->
                              <ngx-select
                              ngModel
                              name="riskMitigationStatus"
                              [allowClear]="true"
                              [items]="riskMitigationStatus"
                              (select)="onSelectmitigationstatus($event)"
                              placeholder="Select Mitigation Status"
                              required></ngx-select>
                            </div>
                            <div class="col-md-3 mb-4">
                              <div class>Residual Likelihood</div>
                              <select
                                [(ngModel)]="selectedValue4"
                                (change)="calculateProduct2()"
                                name="residualRiskLikelihood"
                                class="numbervalue" required>
                                <option
                                  *ngFor="let option of options"
                                  [value]="option">
                                  {{ option }}
                                </option>
                              </select>                             
                            </div>
                            <div class="col-md-3 mb-4">
                              <div class>Residual Impact</div>
                              <select
                                [(ngModel)]="selectedValue5"
                                (change)="calculateProduct2()"
                                name="residualImpact"
                                class="numbervalue" required>
                                <option
                                  *ngFor="let option of options"
                                  [value]="option">
                                  {{ option }}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-3 mb-4">
                              <div class>Residual Risk Rating</div>
                              <input
                              type="text"
                              autocomplete="off"
                              name="residualRiskRating"
                              [(ngModel)]="residualRiskRating"
                              class="form-control input-field"
                              placeholder="Enter Application"
                              readonly required
                              >
                              <!-- <select
                                [(ngModel)]="selectedValue6"
                                name="residualRiskRating"
                                class="numbervalue">
                                <option
                                  *ngFor="let option of options"
                                  [value]="option">
                                  {{ option }}
                                </option>
                              </select> -->
                            </div>
                            <!-- <div class="col-md-3 mb-4">
                          <div class>Actual Date of Resolution</div>
                          <input class="mydatepicker" (ngModelChange)='getDateData2($event)' angular-mydatepicker name="actualDateOfResolution"  [(ngModel)]="actualDateOfResolution" [options]="myDpOptions2" (click)="dp.toggleCalendar()"  #dp="angular-mydatepicker"/>
                        </div>
                        <div class="col-md-3 mb-4">
                          <div class>Remarks</div>
                          <input
                            type="text"
                            autocomplete="off"
                            name="remarks"
                            [(ngModel)]="remarks"
                            class="form-control input-field"
                            placeholder="Enter remarks"
                            required>
                        </div> -->
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mx-auto my-4 button-div createsubmit">
                            <a class="btn btn-border me-2">
                              <button
                                class="submit-btn"
                                type="button"
                                (click)="navigateBack()">
                                <i class="bi bi-x fs-5"></i>
                                Cancel
                              </button>
                            </a>
                            <a class="btn btn-border me-2 btn-green">
                              <button [disabled]="riskForm.form.invalid || !isRiskValid" class="submit-btn text-white">
                                <i class="bi bi-plus fs-5"></i>
                                Create
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class="pacman-alert"
                        *ngIf="isRiskCreationSuccess">
                        <div class="sa-icon sa-success animate">
                          <span class="sa-line sa-tip animateSuccessTip"></span>
                          <span class="sa-line sa-long animateSuccessLong"></span>
                          <div class="sa-placeholder"></div>
                          <div class="sa-fix"></div>
                        </div>

                        <div class="pacman-alert-title">{{successTitle}}</div>
                        <div class="pacman-alert-message">
                          Risk
                          <span class="highlight-pink">{{highlightName}}</span>
                          has been successfully
                          <span>Created</span>
                          !!!
                        </div>
                        <div class="pacman-alert-button createsubmit">
                          <a class="btn btn-border me-2 cancelbutton">
                            <button
                              class="submit-btn"
                              type="button"
                              (click)="navigateBack2()">
                              <i class="bi bi-x fs-5"></i>
                              Close
                            </button>
                          </a>
                        </div>
                      </div>
                      <div
                class="pacman-alert"
                *ngIf="isRiskCreationFailed">
                <div
                  class="sa-icon sa-error animateErrorIcon"
                  style="display: block;">
                  <span class="sa-x-mark animateXMark">                                          
                    <span class="sa-line sa-left"></span>                                    
                    <span class="sa-line sa-right"></span>
                    </span>
                </div>
                <div class="pacman-alert-title">{{failedTitle}}</div>
                <div class="pacman-alert-message">
                  Risk
                  <span class="highlight-pink">{{highlightName}}</span>
                  {{loadingContent}} failed!!!
                </div>
                <div class="pacman-alert-button">
                    <a class="btn btn-border">
                  <button
                    class="submit-btn"
                    type="button"
                    (click)="closeErrorMessage()">
                    <span *ngIf="failedTitle!=='Loading Failed'"><i class="bi bi-x fs-5"></i> Close</span>
                    <span *ngIf="failedTitle==='Loading Failed'"><i class="bi bi-check fs-5"></i> Retry</span>
                  </button>
                </a>
                </div>
              </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
