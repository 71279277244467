<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12 px-1">
                <div *ngIf="!dataLoaded || !datacoming" class="card dataTable-section-height">
                    <div *ngIf="!dataLoaded" class="loader-container">
                        <div class="loader">
                            <span></span>
                        </div>
                    </div>

                    <div *ngIf="!datacoming" class="d-flex align-items-center" style="min-height:100vh">
                        <div class="w-100 text-center">
                            <div>
                                <div>
                                    <app-error-message *ngIf="seekdata == true"
                                        [selectedValue]="errorMessage"></app-error-message>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card tab-box" *ngIf="dataLoaded || datacoming">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" *ngIf="tabName">
                        <li class="nav-item" role="presentation" *ngFor="let tab of tabName | orderBy : { property: 'order', direction: 1}">
                            <button (click)='selectedTabName = tab; navigateToTab(selectedTabName.category);' class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-2"
                              type="button" role="tab" aria-controls="pills-profile" aria-selected="false" [class.loaded]='errorVal.summaryStatus === 1' [class.active]='tab == selectedTabName'>
                              {{tab.displayName}}
                              <span *ngIf='tab.displayName!=="Summary"'>({{tab.recommendations}})</span>
                            </button>
                          </li>
                      </ul>
                <!-- </div>
            </div> -->
        <!-- </div> -->
        <div class='tab-content flex flex-col'>
            <div class='pk-main-content'>
                <ng-container *ngIf='errorVal.summaryStatus === 1'>
                    <div class='flex flex-col height-100' *ngIf='selectedTabName && selectedTabName.category === "summary"'>
                        <div class='tabs-container tabs-wrapper flex flex-center'>
                            <ng-container *ngFor="let tabs of tabName | orderBy : { property: 'order', direction: 1}">
                                <div class='pk-tabs each-tab capitalize flex flex-col flex-evenly flex-align-center'
                                    (click)="getApplicationTableData(tabs)" [class.no-event]='general'
                                    [class.tab-hover]='!general'
                                    [class.active]='tabs.category == summaryActiveTab.category && !general'
                                    *ngIf='tabs.displayName !== "Summary"'>
                                    <div class='tabs-nav each-summary'>{{tabs.displayName}}</div>
                                    <div class='each-summary'>
                                        <img *ngIf='tabs.icon' alt='' src={{tabs.icon}}>
                                    </div>
                                    <div class='each-summary' [ngStyle]="{'color': tabs.color }">
                                        <div class='cost-val'>
                                            <span *ngIf='tabs.category === "cost_optimizing" && tabs.potentialMonthlySavings'>$
                                                {{tabs.potentialMonthlySavings | number : '1.0-2'}}</span>
                                            <span
                                                *ngIf='tabs.category !== "cost_optimizing" || (tabs.category === "cost_optimizing" && !tabs.potentialMonthlySavings)'>{{tabs.recommendations
                                                | number : '1.0-2'}}</span>
                                        </div>
                                        <div class='summary-text'>
                                            <span
                                                *ngIf='tabs.category === "cost_optimizing" && tabs.potentialMonthlySavings'>Potential
                                                Monthly Savings</span>
                                            <span
                                                *ngIf='tabs.category !== "cost_optimizing" || (tabs.category === "cost_optimizing" && !tabs.potentialMonthlySavings)'>Recommendations</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class='table-wrapper relative' *ngIf='!general'>
                            <app-table-list *ngIf='errorVal.tableStatus === 1 && tableListData && tableListData.header && tableListData.tableData'  
                                [DataObject]= "tableListData" 
                                [errorValue]="errorVal.tableStatus" 
                                (firstRowClick)="navigateTo(summaryActiveTab.category, $event)" 
                                [errorMessage]="errorMessage" 
                                [clickableKeys]="['Application']" 
                                [keysToSkip]="['selectedKey']">
                            </app-table-list>
                        </div>
                    </div>
                    <div class='flex flex-col height-100' *ngIf= 'selectedTabName.category !== "summary"'>
                        <div class='total-cost-wrapper flex flex-center' *ngIf='selectedTabName.category === "cost_optimizing"'>
                            <div class='total-cost flex flex-align-center flex-between' *ngIf='errorVal.costStatus === 1 && errorVal.savingsStatus === 1'><span title="{{selectedApplication}}" class='total-cost-app'>{{selectedApplication}} </span><span> Monthly Cost - $ {{totalCost | number : '1.0-2'}}</span></div>
                            <div class='total-cost savings flex flex-align-center flex-between p-1' *ngIf='errorVal.savingsStatus === 1 && monthlySavingsTotal'><span>Potential Monthly Savings - $ {{monthlySavingsTotal | number : '1.0-2'}}</span></div>
                        </div>
                        <div class='cost-table-wrapper'>
                            <app-recommand-category [selectedTab]='selectedTabName' [general]='general' (monthlySavings)='getmonthlySavings($event)' [filters]='this.appliedFilters.pageLevelAppliedFilters'></app-recommand-category>
                        </div>
                      </div>
                </ng-container>
            </div>
            </div>
            </div>
        </div>
        </div>
        </div>
        </section>