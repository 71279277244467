import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class BreadcrumbService {
    private breadCrumbData = new Subject<any>();

    changeBreadCrumbData(breadcrumbArray: any, breadcrumbLinks:any, breadcrumbPresent: any, asset: boolean) {
        this.breadCrumbData.next({breadcrumbArray, breadcrumbLinks, breadcrumbPresent, asset})
      }

    getBreadCrumbData() {
        return this.breadCrumbData.asObservable();
    }
}