
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {UtilsService} from './utils.service';
import {DataCacheService} from '../../core/services/data-cache.service';
import {ErrorHandlingService} from './error-handling.service';
import {environment} from '../../../environments/environment';
import {CONFIGURATIONS} from '../../../config/configurations';
import {HttpResponse} from '../models/http-response';


@Injectable()
export class HttpService {

  called: any = 0;
  formBody: any = [];
  timeSet: any = 0;
  envName: string;
  baseUrl: string;
  cloudBaseUrl: string;

  constructor(private http: HttpClient,
              private utilityService: UtilsService,
              private errorHandling: ErrorHandlingService,
              private dataStore: DataCacheService) {

    this.envName = environment.envName;
    if (this.envName === 'dev') {
      this.baseUrl = CONFIGURATIONS.required.domains.DEV_BASE_URL;
    } else if (this.envName === 'stg') {
      this.baseUrl = CONFIGURATIONS.required.domains.STG_BASE_URL;
    } else {
      this.baseUrl = CONFIGURATIONS.required.domains.PROD_BASE_URL;
    }
    this.cloudBaseUrl = CONFIGURATIONS.required.domains.CLOUD_BASE_URL;
  }

  getBlobResponse(url: any, method: any, payload = {responseType: 'blob'}, queryParams = {}): any {
    if (method.toUpperCase() === 'GET') {
      try {
        let updatedUrl = url;
        updatedUrl = updatedUrl.replace('{{baseUrl}}', this.baseUrl);
        updatedUrl = updatedUrl.replace('{{cloudBaseUrl}}', this.cloudBaseUrl);
        if (url.indexOf('/api/') !== 0) {
          updatedUrl += this.convertQueryParametersToString(queryParams);
        }
        const headers = {headers: new HttpHeaders({})};
        return this.getData(updatedUrl, headers);

      } catch (error) {
        this.errorHandling.handleJavascriptError(error);
      }
    } else if (method.toUpperCase() === 'POST') {
      try {

        let updatedUrl = url;
        updatedUrl = updatedUrl.replace('{{baseUrl}}', this.baseUrl);
        updatedUrl = updatedUrl.replace('{{cloudBaseUrl}}', this.cloudBaseUrl);

        if (url.indexOf('/api/') !== 0 && Object.keys(queryParams).length !== 0) {
          updatedUrl += this.convertQueryParametersToString(queryParams);
        }
        return this.postBlobData(updatedUrl, payload);
      } catch (error) {
        this.errorHandling.handleJavascriptError(error);
      }
    }
  }

  // headers is kept as an optional parameter....
  // @ts-ignore
  getHttpResponse(url: any, method: any, payload = {}, queryParams = {}, headers = {}): Observable<HttpResponse[]> {

    if (method.toUpperCase() === 'GET') {
      try {
        let updatedUrl = url;
        updatedUrl = updatedUrl.replace('{{baseUrl}}', this.baseUrl);
        updatedUrl = updatedUrl.replace('{{cloudBaseUrl}}', this.cloudBaseUrl);

        if (url.indexOf('/api/') !== 0) {
          updatedUrl += this.convertQueryParametersToString(queryParams);
        }
        return this.getData(updatedUrl, headers);

      } catch (error) {
        this.errorHandling.handleJavascriptError(error);
      }
    } else if (method.toUpperCase() === 'POST') {
      try {

        let updatedUrl = url;
        updatedUrl = updatedUrl.replace('{{baseUrl}}', this.baseUrl);
        updatedUrl = updatedUrl.replace('{{cloudBaseUrl}}', this.cloudBaseUrl);
        if (url.indexOf('/api/') !== 0 && Object.keys(queryParams).length !== 0) {
          updatedUrl += this.convertQueryParametersToString(queryParams);
        }

        return this.postData(updatedUrl, payload, headers);

      } catch (error) {
        this.errorHandling.handleJavascriptError(error);
      }
    } else if (method.toUpperCase() === 'DELETE') {
      try {
        let updatedUrl = url;
        updatedUrl = updatedUrl.replace('{{baseUrl}}', this.baseUrl);
        updatedUrl = updatedUrl.replace('{{cloudBaseUrl}}', this.cloudBaseUrl);
        if (url.indexOf('/api/') !== 0 && Object.keys(queryParams).length !== 0) {
          updatedUrl += this.convertQueryParametersToString(queryParams);
        }

        return this.deleteData(updatedUrl, headers);

      } catch (error) {
        this.errorHandling.handleJavascriptError(error);
      }
    } else if (method.toUpperCase() === 'PUT') {
      try {

        let updatedUrl = url;
        updatedUrl = updatedUrl.replace('{{baseUrl}}', this.baseUrl);
        updatedUrl = updatedUrl.replace('{{cloudBaseUrl}}', this.cloudBaseUrl);
        if (url.indexOf('/api/') !== 0 && Object.keys(queryParams).length !== 0) {
          updatedUrl += this.convertQueryParametersToString(queryParams);
        }

        return this.putData(updatedUrl, payload, headers);

      } catch (error) {
        this.errorHandling.handleJavascriptError(error);
      }
    }
  }

  convertQueryParametersToString(queryParams: any) {
    let queryParamString = '';
    if (!this.utilityService.isObjectEmpty(queryParams)) {
      queryParamString += '?';
      Object.keys(queryParams).forEach((param) => {
        queryParamString += (queryParams[param] !== '' && queryParams[param] !== undefined) ? param + '=' + encodeURIComponent(queryParams[param]) + '&' : '';
      });
      if (queryParamString[queryParamString.length - 1] === '&') {
        queryParamString = queryParamString.substr(0, queryParamString.length - 1);
      }
    }
    return queryParamString;
  }

  getData(url: any, headers: any) {

    const httpObservable: any = this.http.get(url, headers).pipe(
      map((response: any) => {
        if (url.match('logout-session') || url.match('ustpace.com')) {
          return response;
        } else {
          return response['data'];
        }
      }),
      catchError((error: any) => this.errorHandling.handleAPIError(error))
    )
      // .map((response: any) => {
      //   if (url.match('logout-session')) {
      //     return response;
      //   } else {
      //     return response['data'];
      //   }
      // })
      // .catch((error: any) => this.errorHandling.handleAPIError(error));

    return httpObservable;

  }

  postData(url: any, payload: any, headers: any) {

    const httpObservable: any = this.http.post(url, payload, headers).pipe(
      map((response: any) => {
            if (!response) {
              return response;
            }
            return response['_body'] ? JSON.parse(response['_body']) : response;
          }), catchError((error: any) => this.errorHandling.handleAPIError(error))
    )
    //   .map((response: any) => {
    //     if (!response) {
    //       return response;
    //     }
    //     return response['_body'] ? JSON.parse(response['_body']) : response;
    //   })
    //   .catch((error: any) => this.errorHandling.handleAPIError(error));

     return httpObservable;

  }

  putData(url: any, payload: any, headers: any) {

    const httpObservable: any = this.http.put(url, payload, headers).pipe(
      map((response: any) => {
        if (!response) {
          return response;
        }
        return response['_body'] ? JSON.parse(response['_body']) : response;
      }),
      catchError((error: any) => this.errorHandling.handleAPIError(error))
    )
      // .map((response: any) => {
      //   if (!response) {
      //     return response;
      //   }
      //   return response['_body'] ? JSON.parse(response['_body']) : response;
      // })
      // .catch((error: any) => this.errorHandling.handleAPIError(error));

    return httpObservable;

  }


  deleteData(url: any, headers: any) {
    const httpObservable: any = this.http.delete(url, headers).pipe(
      map((response: any) => {
        return response['_body'] ? JSON.parse(response['_body']) : response;
      }),
      catchError((error: any) => this.errorHandling.handleAPIError(error))
    )
      // .map((response: any) => {
      //   return response['_body'] ? JSON.parse(response['_body']) : response;
      // })
      // .catch((error: any) => this.errorHandling.handleAPIError(error));

    return httpObservable;
  }

  postBlobData(url: any, payload: any) {
    const httpObservable: any = this.http.post(url, payload, {
      responseType: 'blob'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => this.errorHandling.handleAPIError(error))
    )
      // .map((response: any) => {
      //   return response;
      // })
      // .catch((error: any) => this.errorHandling.handleAPIError(error));

    return httpObservable;
  }

}
