export const ICONS: any = {
  'awsResources': {
    'ec2': 'assets/aws-icons/Compute/ec2.svg',
    'asgpolicy': 'assets/aws-icons/Compute/asgpolicy.svg',
    'nat': 'assets/aws-icons/Compute/nat.svg',
    'appelb': 'assets/aws-icons/Compute/appelb.svg',
    'asg': 'assets/aws-icons/Compute/asg.svg',
    'eni': 'assets/aws-icons/Compute/eni.svg',
    'lambda': 'assets/aws-icons/Compute/lambda.svg',
    'snapshot': 'assets/aws-icons/Compute/snapshot.svg',
    'targetgroup': 'assets/aws-icons/Compute/targetgroup.svg',
    'classicelb': 'assets/aws-icons/Compute/classicelb.svg',
    'vpc': 'assets/aws-icons/Compute/vpc.svg',
    'subnet': 'assets/aws-icons/Compute/subnet.svg',
    'sg': 'assets/aws-icons/Compute/sg.svg',
    'launchconfig': 'assets/aws-icons/Compute/launchconfig.svg',
    's3': 'assets/aws-icons/Storage/s3.svg',
    'efs': 'assets/aws-icons/Storage/efs.svg',
    'volume': 'assets/aws-icons/Storage/volume.svg',
    'emr': 'assets/aws-icons/Analytics/emr.svg',
    'elasticsearch': 'assets/aws-icons/Analytics/elasticsearch.svg',
    'api': 'assets/aws-icons/Application Service/api.svg',
    'redshift': 'assets/aws-icons/Database/redshift.svg',
    'rdscluster': 'assets/aws-icons/Database/rdscluster.svg',
    'rdsdb': 'assets/aws-icons/Database/rdsdb.svg',
    'rdssnapshot': 'assets/aws-icons/Database/rdssnapshot.svg',
    'dynamodb': 'assets/aws-icons/Database/dynamodb.svg',
    'stack': 'assets/aws-icons/Management Tools/stack.svg',
    'iamuser': 'assets/aws-icons/Identity/iamuser.svg',
    'iamrole': 'assets/aws-icons/Identity/iamrole.svg',
    'kms': 'assets/aws-icons/Identity/kms.svg',
    'routetable': 'assets/aws-icons/Networking & Content Delivery/routetable.svg',
    'networkacl': 'assets/aws-icons/Networking & Content Delivery/networkacl.svg',
    'cloudfront': 'assets/aws-icons/Networking & Content Delivery/cloudfront.svg',
    'internetgateway': 'assets/aws-icons/Networking & Content Delivery/internetgateway.svg',
    'vpngateway': 'assets/aws-icons/Networking & Content Delivery/vpngateway.svg',
    'customergateway': 'assets/aws-icons/Networking & Content Delivery/customergateway.svg',
    'dhcpoption': 'assets/aws-icons/Networking & Content Delivery/dhcpoption.svg',
    'directconnect': 'assets/aws-icons/Networking & Content Delivery/directconnect.svg',
    'elasticip': 'assets/aws-icons/Networking & Content Delivery/elasticip.svg',
    'peeringconnection': 'assets/aws-icons/Networking & Content Delivery/peeringconnection.svg',
    'virtualinterface': 'assets/aws-icons/Networking & Content Delivery/virtualinterface.svg',
    'vpnconnection': 'assets/aws-icons/Networking & Content Delivery/vpnconnection.svg',
    'unknown': 'assets/aws-icons/Extra/Extra.svg'
  },
  'categories': {
    'Analytics': '#F75C03',
    'Application Service': '#645EC5',
    'Compute': '#289CF7',
    'Database': '#F2425F',
    'Identity': '#26BA9D',
    'Management Tools': '#00B946',
    'Networking & Content Delivery': '#FFB00D',
    'Storage': '#1C5066',
    'Extra': '#33BFCD'
  },
  'path': '/assets/aws-icons/'
};
