 <section class="access-groups" *ngIf="resource">
	<div class="access-groups-wrapper">
		<h6>Access Groups</h6>
		<div class="access-groups-list link-text pointer" *ngFor="let data of resource">
			{{data.name | uppercase }}
			<!-- <div class="resource-div"> -->
			<!-- <span><a href="{{config.optional.general.ACCESS_MANAGEMENT_PORTAL_URL}}/web/guest/ad-groups">
				{{data.name | uppercase }}
			</a></span> -->
		<!-- </div> -->
		</div>
	</div>
</section>