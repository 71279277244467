<section>
    <div class="container-fluid py-1 main-div">
      <div class="row">
        <div class="col-md-4 ">
            <app-tagging-summary [pageLevel]="pageLevel"></app-tagging-summary>
        </div>
        <div class="col-md-8 ">
          <app-total-tag-compliance [pageLevel]="pageLevel"></app-total-tag-compliance>
        </div>
      </div>
    </div>
  </section>

  <app-tagging-compliance-trend></app-tagging-compliance-trend>

  <app-tagging-across-target-type [pageLevel]="pageLevel"></app-tagging-across-target-type>

  <app-tagging-instances-table></app-tagging-instances-table>
  
  <app-tagging-assets-trend></app-tagging-assets-trend>