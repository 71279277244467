<!-- Policy Knowledgebase Section Start -->
<section>
    <div class="container-fluid py-1">
        <div class="row">
            <div class="col-12">
                <!-- Loader -->
                <div *ngIf="!dataLoaded || !datacoming" class="card dataTable-section-height">
                    <div *ngIf="!dataLoaded" class="loader-container">
                        <div class="loader">
                            <span></span>
                        </div>
                    </div>

                    <div *ngIf="!datacoming" class="d-flex align-items-center" style="min-height:100vh">
                        <div class="w-100 text-center">
                            <div>
                                <div>
                                    <app-error-message *ngIf="seekdata == true"
                                        [selectedValue]="errorMessage"></app-error-message>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="dataLoaded && datacoming" class="card tab-box ">
                    <!--Table Container start here -->
                    <div class="container-fluid mt-2 p-1  policy-knowledgebase">
                        <div class="row">
                            <div class="col-md-5">
                                <h4>Policy Knowledgebase</h4>
                                <div class="policy-count">Total of {{knowledgebaseData.length}} Policies</div>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4"> </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <form class="search-form d-flex align-items-center">
                                                <input type="text" placeholder="Search" title="Enter search keyword"
                                                    [(ngModel)]="searchTxt">
                                                <button title="Search"><i class="bi bi-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tab Content Start -->
                    <ul class="nav nav-pills mb-3" role="tablist">
                        <li *ngFor="let tabs of tabName " class="nav-item" role="presentation">
                            <button (click)='selectedTabName = tabs;' [class.active]='tabs == selectedTabName'
                                class="nav-link" data-bs-toggle="pill" data-bs-target="#pills-1" type="button"
                                role="tab" aria-controls="pills-home" aria-selected="true">
                                {{tabs}}
                                <span *ngIf='typeObj'>({{typeObj[tabs]}})</span>
                            </button>
                        </li>
                    </ul>

                    <div>
                        <div class="row">
                            <ng-container
                                *ngFor="let cards of ( knowledgebaseData | searchFilter: searchTxt ) | orderBy : { property: 'name', direction: 1 }">
                                <div *ngIf="(selectedTabName == 'All' || selectedTabName == cards.severity || selectedTabName == cards.ruleCategory || (selectedTabName == 'Auto Fix' && cards.autoFixEnabled) && dataLoaded)"
                                    class="mb-4 col-4 " (click)="gotoNextPage(cards)">
                                    <div>
                                        <div class="knowledge-box">
                                            <div class="row">
                                                <div class="col-md-3 pt-2"><img
                                                        src="../../../../assets/images/{{cards.provider}}-color.svg"
                                                        width="55px">
                                                </div>
                                                <div class="col-md-9"> {{cards.name}} </div>
                                                <div class="col"><span [ngClass]="{
                                                    'low':
                                                    cards.severity === 'low',
                                                    'high':
                                                      cards.severity === 'high' || cards.severity === 'critical',
                                                      'medium': cards.severity === 'medium'
                                                  }" class="high float-end">{{cards.severity}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>