<div class="flex flex-between">
  <div class="floating-widgets-section-full flex flex-col compliance-dashboard-wrapper">
    <div class="container no-shrink">
      <div class="system-container flex-center flex flex-col flex-align-center">
        <div class='loader' *ngIf='showPageLoader==1'></div>
        <app-error-message class='error_msg' [selectedValue]="errorMessage" *ngIf='showPageLoader == -1'></app-error-message>
        <div class="flex flex-col flex-align-center outer-box w-100 " *ngIf='showPageLoader==0'>
          <div class="row flex each-item flex-between no-shrink">
            <div class="col-9 rules">
              <div class="left-header">Rules</div>
              <div class="left-element">This action will stop PacBot compliance evaluation for all cloud resources. </div>
            </div>
            <div class="col-3 toggle">
              <label class="switch">
                <input [(ngModel)]="isCheckedRules" (click)="ontoggleAccess($event, 'rule');" class="switch-input" type="checkbox" />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle" [innerHtml]="isCheckedRules? 'Off': 'On'"></span>
               </label>
            </div>
          </div>
          <div class="row flex each-item flex-between no-shrink">
            <div class="col-9 rules">
              <div class="left-header">Jobs</div>
              <div class="left-element">This action will stop all inventory collection by PacBot. </div>
            </div>
            <div class="col-3 toggle">
              <label class="switch">
                <input [(ngModel)]="isCheckedJobs" (click)="ontoggleAccess($event, 'job')" class="switch-input" type="checkbox" />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle" [innerHtml]="isCheckedJobs? 'Off': 'On'"></span>
               </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-generic-modal [title]="modalTitle" *ngIf="OpenModal" (emitClose)='closeModal()'>
  <div class="flex flex-col flex-align-center container">
    <div  *ngIf="selectedValue === 'rule' && !showLoader && errorVal == 0" class="action-text text-center">This action will {{isCheckedRules ? 'start' : 'stop'}} PacBot compliance evaluation for all cloud resources ( Rules ). </div>
    <div *ngIf="selectedValue === 'job' && !showLoader && errorVal == 0" class="action-text text-center">This action will {{isCheckedJobs ? 'start' : 'stop'}} all inventory collection by PacBot (jobs). </div>
    <div *ngIf='!showLoader && errorVal == 0' class="input-box text-center">
      <input type="text" [(ngModel)]="inputValue"  class="confirm-box" placeholder="Type 'Confirm' to authorize" autofocus>
    </div>
    <div class='confirmation-txt' *ngIf='errorVal == -1'>{{errorMsg}}</div>
    <div class='confirmation-txt' *ngIf='errorVal == 1'>{{errorMsg}}</div>
  </div>
  <div *ngIf='errorVal == 0'>
    <div class="text-center">
    <a class="btn btn-border me-2" (click)='closeModal()' [class.disabled]="showLoader">Cancel</a>
    <button class="btn btn-green ms-2" (click)= "submitToCheckConfirm()"  [disabled]="showLoader || (inputValue && inputValue.toLowerCase() === 'confirm'? false : true)" >Submit</button>
  </div>
  </div>
  <div *ngIf='errorVal == 1 || errorVal == -1 ' class="closebutton">
    <button class="btn btn-green ms-2" (click)= "closeModal()" >Close</button>
  </div>
  <div class='loader' *ngIf='showLoader'></div>
</app-generic-modal>
