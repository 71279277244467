import {Injectable} from '@angular/core';
import {LoggerService} from './logger.service';
import {UtilsService} from './utils.service';
import {Observable, throwError} from 'rxjs';
import {EXPECTATION_FAILED, NOT_FOUND, SERVICE_UNAVAILABLE} from "http-status-codes";

@Injectable()
export class ErrorHandlingService {

  constructor(private loggerService: LoggerService, private util: UtilsService) {
  }

  /* To check the status of API response */

  /* To deprecate this function */
  checkAPIResponseStatus(responseData: any) {
    const APIStatus: any = {
      'dataAvailble': !this.util.checkIfAPIReturnedDataIsEmpty(responseData)
    };
    return APIStatus;
  }

  handleAPIError(error: any): Observable<any> {
    /* This fucntion is to handle errors generated by API. */
    // Report it to logger
    this.loggerService.log('info', '***Inside handleAPIError API error, error is:***');
    this.loggerService.log('error', error);

    let errorMessage: string | undefined;
    const httpStatusCode = error.status;

    this.loggerService.log('error', 'http status code - ' + httpStatusCode);

    switch (httpStatusCode) {
      case EXPECTATION_FAILED:
        errorMessage = 'apiResponseError';
        break;
      case SERVICE_UNAVAILABLE:
        errorMessage = 'apiResponseError';
        break;
      case NOT_FOUND:
        errorMessage = 'noDataAvailable';
        break;
      case 0:
        // Show user a way to handle certificate issue
        errorMessage = 'apiResponseError';
        this.loggerService.log('error', 'Certificate issue has occured');
        break;
      default:
        errorMessage = 'apiResponseError';
    }

    // return Observable.throw(errorMessage);
    return throwError(() => new Error(errorMessage))
    // return  errorMessage;

  }

  handleJavascriptError(error: any) {
    /* This fucntion is to handle erros generated by Javascript */
    // Report it to logger
    this.loggerService.log('info', '***Inside javascript error, error is: ***');
    this.loggerService.log('error', error);
    return 'jsError';
  }

  throwError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
