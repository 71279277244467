<section id="{{id}}">
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <div class="card tab-box dataTable-section-height">
          <div *ngIf='errorValue == 0' class="loader-container">
            <div class="loader">
              <span></span>
            </div>
          </div>
          <div class='error_handling' *ngIf='errorValue == -1 && errorMessage'>
            <div class="error_msg">
              <app-error-message *ngIf='errorValue == -1 && errorMessage'
                [selectedValue]="errorMessage"></app-error-message>
            </div>
          </div>
          <div class="container-fluid mt-2 p-1 severity-status">
            <!-- <div class="policy-count" *ngIf="currentTableData.length">
              Total of {{ currentTableData.length }} Policies
            </div> -->
            <div class="row">
              <div class="col-md-3" *ngIf="!tableTitle && !targetType && !assetGroupTableHeader"> <h6 class="card-title">All Rules</h6> </div> 
              <div class="col-md-3" *ngIf="tableTitle"> <h6 class="card-title">{{dataHead}}</h6> </div>  
              <div class="col-md-3" *ngIf="targetType"> <h6 class="card-title">{{dataHead}}</h6> </div>  
              <div class="col-md-3" *ngIf="assetGroupTableHeader"><h6 class="card-title">{{dataHead}}</h6> </div>  
              <div class="col-md-6"></div>  
              <div class="col-md-3  text-end crtbutoon"  *ngIf="!tableTitle && !targetType && !assetGroupTableHeader"><a class="btn page-button" (click)='goToCreateRules()'><i class="bi bi-plus text-white"></i>Create Rule</a></div>  
              <div class="col-md-3  text-end crtbutoon" *ngIf="tableTitle"><a class="btn page-button" (click)="goToCreateJobExecutionManager()"><i class="bi bi-plus text-white"></i>Schedule Job</a></div>
              <div class="col-md-3  text-end crtbutoon" *ngIf="targetType"><a class="btn page-button" (click)="goToCreateUpdateDomain()"><i class="bi bi-plus text-white"></i>Create Target Type</a></div>
              <div class="col-md-3  text-end crtbutoon" *ngIf="assetGroupTableHeader"><a class="btn page-button" (click)="goToCreateAssetGroup()"><i class="bi bi-plus text-white"></i>Create Asset Group</a></div>                   
           </div>
            <table datatable [dtOptions]="dtOptions" *ngIf="
                  errorValue > 0 &&
                  loaded &&
                  filteredColumns.length > 0 &&
                  sortArr.length > 0
                " id="datatable" class="table nowrap policy-table table-hover" width="100%" #datatable>
              <thead width="100%">
                <tr>
                  <th (click)='sortArr[i].showUp = !sortArr[i].showUp; headerClicked(i, header);'
                    *ngFor="let header of filteredColumns; let i = index">
                    {{ header }}
                    <span [class.up-arr-shown]='sortArr[i].showUp == true'
                      [class.down-arr-shown]='sortArr[i].showUp == false' class="list-sortable-arrow relative"></span>
                  </th>
                </tr>
              </thead>
              <tbody class="rules-table">
                <tr *ngFor="let row of currentTableData |searchFilter:searchPassed |orderBy :
                  { property: headerColName, direction: direction , childProperty: 'valText'}; let i = index; ">
                  <td [ngClass]="{'sorting_1': row[column].colName === sortSelectedColomn}"
                    *ngFor="let column of filteredColumns">
                    <a *ngIf="row[column].colName ==='Actions'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}"
                      (click)='currentRowData = row; goToUpdate(currentRowData, column);'
                      [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}" data-bs-toggle="modal" data-bs-target="#modalForm">{{
                      row[column].text }}</a>
                      <a *ngIf="row[column].colName !='Actions'" class='table-trucate' data-bs-toggle="tooltip" title="{{ row[column].text }}"
                      (click)='currentRowData = row; goToDetails(currentRowData, column); tableRowClicked(currentRowData, column);'
                      [ngClass]="{'high': (row[column].text === 'high' || row[column].text === 'critical') , 'low': row[column].text === 'low', 'medium': row[column].text === 'medium'}" data-bs-toggle="modal" data-bs-target="#modalForm">{{
                      row[column].text }}</a>
                    <div *ngIf='(row[column].dropDownEnabled)' (click)='$event.stopPropagation();' class="dropdown-row">
                      <select name="selectOptionValue"
                        (change)='currentRowData = row; goToDetails(currentRowData, $any($event).target.value)'>
                        <option value="-1"></option>
                        <option *ngFor="let item of row[column].dropDownItems" [value]="item">
                          <div class="flex flex-align-center each-cell-list-row">
                            <div> {{item}}</div>
                          </div>
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf='!paginatorAbsent && outerArr && outerArr.length > 0 && errorValue > 0'>
              <div class='checked-list' *ngIf='checkBox'>Selected: <span
                  class='checked-list-length'>{{checkedList.length}}</span>
              </div>
              <div class='pagination-strip'>
                <div class='me-3'> Showing {{firstPaginator}} to <span
                    *ngIf='lastPaginator > 1'>{{lastPaginator}}</span> of <span class='total-rows'>{{totalRows}}
                    entries</span>
                </div>
                <div class='paginator-arrow' (click)='prevPage()'><i [class.arrowfade]='currentPointer==0'
                    class="bi bi-chevron-left"></i>
                </div>
                <div class='paginator-arrow rotate180' (click)='nextPage()'><i
                    [class.arrowfade]='lastPaginator==totalRows' class="bi bi-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-dark text-white">
          <h5 class="modal-title" id="exampleModalLabel">Additional Details</h5>
          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">
            <i class="bi bi-x-lg text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3 pt-2">
              <!-- {{ rowObj | json}} -->
              <table width="100%" *ngIf="rowObj">
                <tr *ngFor="let header of filteredColumns">
                  <td>{{header}} : </td>
                  <td >{{ rowObj[header].text }}</td>
                  <!-- <td *ngFor="let row of rowObj">{{ row["Rule Name"].text || null }}</td> -->
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section>
  <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-dark text-white">
          <h5 class="modal-title" id="exampleModalLabel">Additional Details</h5>
          <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close">
            <i class="bi bi-x-lg text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3 pt-2">
              <table width="100%">
                <tr>
                  <td class="w-30">Rule ID :</td>
                  <td class="text-green">{{ rowObj["Rule Id"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-30"> Rule UID :</td>
                  <td>{{ rowObj["Rule UID"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-30">Policy Id:</td>
                  <td>{{ rowObj["Policy Id"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-30">Rule Name :</td>
                  <td>{{ rowObj["Rule Name"]?.text || null }}</td>
                </tr>                
                <tr>
                  <td class="w-30">Asset Type :</td>
                  <td>{{ rowObj["Asset Type"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-30">Asset Group :</td>
                  <td>{{ rowObj["Asset Group"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-30">Alexa Keyword :</td>
                  <td>{{ rowObj["Alexa Keyword"]?.text || null }}</td>
                </tr>
                <tr>
                  <td class="w-30">Rule Params:</td>
                  <td>{{ rowObj["Rule Params"]?.text || null }}</td>
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section> -->