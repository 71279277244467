import { Component, OnInit, Input } from '@angular/core';
import { CONFIGURATIONS } from '../../../../config/configurations';

@Component({
  selector: 'app-access-groups',
  templateUrl: './access-groups.component.html',
  styleUrls: ['./access-groups.component.css']
})

export class AccessGroupsComponent implements OnInit {

  @Input() resource: any;
  public config;

  constructor() {
    this.config = CONFIGURATIONS;
  }

  ngOnInit() {
  }

}
