<!-- Overall Compliance chart -->
<section *ngIf='graphSeriesData'>
    <div class="container-fluid my-3">
        <div class="row">
            <div class="col-12">
                <div class="card chart-card2">
                    <div class="card-body">
                        <h6 class="card-title">{{subHeadTitle}}
                        </h6>

                        <!-- Line Chart -->
                        <div class="div-scroll2 mb-4">
                            <apx-chart id="{{id}}" [stroke]="complianceChartOptions.stroke"
                                [series]="complianceChartOptions.series" [chart]="complianceChartOptions.chart"
                                [dataLabels]="complianceChartOptions.dataLabels" [fill]="complianceChartOptions.fill"
                                [colors]="complianceChartOptions.colors" [yaxis]="complianceChartOptions.yaxis"
                                [xaxis]="complianceChartOptions.xaxis" [tooltip]="complianceChartOptions.tooltip"
                                [autoUpdateSeries]="true"></apx-chart>
                        </div>

                        <!-- <div class="row">
                            <div class="col-md-10 mx-auto text-center chartx-label">
                                <div class="geeen2-badge me-1 "></div> <span> Governance</span>
                                <div class="geeen-badge me-1"></div> <span> CostOptimization</span>
                                <div class="violet-badge me-1"></div> <span> Security</span>
                                <div class="geeen3-badge me-1"></div> <span> Tagged</span>
                                <div class="orange-badge me-1"></div> <span> Overfall</span>
                            </div>

                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>