<!-- TODO: Disabled for now need to confirm with Ajit Raj, Nisha and Team -->
<!-- <select [disabled]="true" class="form-select platform-select" aria-label="Default select example">
  <option *ngFor="let platform of dropdownData"
  [attr.selected]="platform === selectedDomain">Infra & Platforms</option>
</select> -->

<div class="btn-group">
  <ngx-select [disabled]="butDisabled" name="selectedDomain" [(ngModel)]="selectedDomain" [allowClear]="true" [items]="dropdownData"
    placeholder="Select Domain" class="domain-option" (select)="updateDropdownSelection($event)" required>
  </ngx-select>
</div>
