<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card tab-box py-1">
          <div class="container-fluid mt-2 py-4 mb-1 relative">                     
            <div class="row"  [hidden]="hideContent">
                <div class="col-md-10 mx-auto mt-4">
                    <div class="row">
                        <div class="col-md-12 pt-4 ">
                            <div class="text-bold text-center">
                                <img class="my-4" src="assets/images/info-icon.svg">
                                <h5> {{pageTitle}}</h5>
                            </div>
                        </div>
                        <div class="col-md-10 py-4 my-4 text-center mx-auto">
                            <div class="fs-6  mx-auto">
                                Are you sure to <span class="lowercase">{{pageTitle}}</span> - <span class="highlight-pink">{{exceptionName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-md-4 mx-auto my-4 button-div">
                            <button class="submit-btn btn-border me-2 cancelbut" type="button" (click)="navigateBack()"><i class="bi bi-x fs-5"></i>
                                Cancel</button>
                            <button class="submit-btn btn-green ms-2 greenbut" type="submit" (click)="deleteException()">
                                <i class="bi bi-plus fs-5"></i> Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div
              class="pacman-alert"
              *ngIf="isExceptionDeletionFailed">
              <div
                class="sa-icon sa-error animateErrorIcon"
                style="display: block;">
                <span class="sa-x-mark animateXMark">
                                    
                  
                  <span class="sa-line sa-left"></span>
                                
                  
                  <span class="sa-line sa-right"></span>
                                </span>
              </div>
              <div class="pacman-alert-message">
                Failed in deleting exception
                <span class="highlight-pink"><b class="exception-deletion">{{exceptionName}}</b></span>
                !!!
              </div>
              <div class="pacman-alert-button">
                <a class="btn btn-border">
                  <button
                    class="submit-btn cancelbutr"
                    type="button"
                    (click)="closeErrorMessage()">
                    <i class="bi bi-x"></i>
                    Close
                  </button>
                </a>
              </div>
            </div>
            <div
              class="pacman-alert"
              *ngIf="isExceptionDeletionSuccess">
              <div class="sa-icon sa-success animate">
                <span class="sa-line sa-tip animateSuccessTip"></span>
                <span class="sa-line sa-long animateSuccessLong"></span>
                <div class="sa-placeholder"></div>
                <div class="sa-fix"></div>
              </div>
              <div class="pacman-alert-message">
                Sticky Exception
                <span class="highlight-pink"><b class="exception-deletion">{{exceptionName}}</b></span>
                successfully deleted !!!
              </div>
              <div class="pacman-alert-button">
                <a class="btn btn-border">
                  <button
                    class="submit-btn cancelbutr"
                    type="button"
                    (click)="navigateBack()">
                    <i class="bi bi-x"></i>
                    Close
                  </button>
                </a>
              </div>
            </div>
            <div
              class="pacman-progress"
              *ngIf="exceptionLoader">
              <!-- <app-pacman-loader></app-pacman-loader> -->
              <div class="title">Please Wait...</div>
              <div class="pacman-alert-message">
                Deleting Exception
                <span class="highlight-pink">{{exceptionName}}</span>
                is in progress...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
