
import { ComplianceDashboardComponent } from '../../pacman-features/modules/compliance/compliance-dashboard/compliance-dashboard.component';
import {AuthGuardService} from '../services/auth-guard.service';
import {VulnerabilitiesComplianceComponent} from '../../pacman-features/modules/compliance/vulnerabilities-compliance/vulnerabilities-compliance.component';
import {IssueListingComponent} from '../../pacman-features/modules/compliance/issue-listing/issue-listing.component';
import {PolicyKnowledgebaseComponent} from '../../pacman-features/modules/compliance/policy-knowledgebase/policy-knowledgebase.component';

import { RecommendationsComponent} from '../../pacman-features/modules/compliance/recommendations/recommendations.component';
import { TaggingComplianceComponent } from '../../pacman-features/modules/compliance/tagging-compliance/tagging-compliance.component';
import { CloudNotificationsComponent } from '../../pacman-features/modules/compliance/cloud-notifications/cloud-notifications.component';
import { AssetDashboardComponent } from 'src/app/pacman-features/modules/assets/asset-dashboard/asset-dashboard.component';
import { AssetListComponent } from 'src/app/pacman-features/modules/assets/asset-list/asset-list.component';
import { PolicyDetailsComponent } from 'src/app/pacman-features/modules/compliance/policy-details/policy-details.component';
import { PolicyKnowledgebaseDetailsComponent } from 'src/app/pacman-features/modules/compliance/policy-knowledgebase-details/policy-knowledgebase-details.component';
import { IssueDetailsComponent } from 'src/app/pacman-features/modules/compliance/issue-details/issue-details.component';
import { AssetDetailsComponent } from 'src/app/pacman-features/modules/assets/asset-details/asset-details.component';
import { PoliciesComponent } from 'src/app/pacman-features/modules/admin/policies/policies.component';
import { RulesComponent } from 'src/app/pacman-features/modules/admin/rules/rules.component';
import { CreateRuleComponent } from 'src/app/pacman-features/modules/admin/create-rule/create-rule.component';
import { EnableDisableRuleComponent } from 'src/app/pacman-features/modules/admin/enable-disable-rule/enable-disable-rule.component';
import { UpdateRuleComponent } from 'src/app/pacman-features/modules/admin/update-rule/update-rule.component';
import { InvokeRuleComponent } from 'src/app/pacman-features/modules/admin/invoke-rule/invoke-rule.component';
import { JobExecutionManagerComponent } from 'src/app/pacman-features/modules/admin/job-execution-manager/job-execution-manager.component';
import { CreateJobExecutionManagerComponent } from 'src/app/pacman-features/modules/admin/job-execution-manager/create-job-execution-manager/create-job-execution-manager.component';
import { UpdateJobExecutionManagerComponent } from 'src/app/pacman-features/modules/admin/job-execution-manager/update-job-execution-manager/update-job-execution-manager.component';
import { TargetTypesComponent } from 'src/app/pacman-features/modules/admin/asset-groups/target-types/target-types.component';
import { CreateUpdateTargetTypesComponent } from 'src/app/pacman-features/modules/admin/asset-groups/target-types/create-update-target-types/create-update-target-types.component';
import { CreatePolicyComponent } from 'src/app/pacman-features/modules/admin/create-policy/create-policy.component';
import { DomainComponent } from 'src/app/pacman-features/modules/admin/domain/domain.component';
import { CreateDomainComponent } from 'src/app/pacman-features/modules/admin/create-domain/create-domain.component';
import { PermissionGuardService } from 'src/app/core/services/permission-guard.service';
import { AssetGroupsComponent } from 'src/app/pacman-features/modules/admin/asset-groups/asset-groups.component';
import { CreateAssetGroupsComponent } from 'src/app/pacman-features/modules/admin/asset-groups/create-asset-groups/create-asset-groups.component';
import { DeleteAssetGroupsComponent } from 'src/app/pacman-features/modules/admin/asset-groups/delete-asset-groups/delete-asset-groups.component';
import { RolesComponent } from 'src/app/pacman-features/modules/admin/roles/roles.component';
import { CreateUpdateRolesComponent } from 'src/app/pacman-features/modules/admin/roles/create-update-roles/create-update-roles.component';
import { ConfigUsersComponent } from 'src/app/pacman-features/modules/admin/roles/config-users/config-users.component';
import { RolesAllocationComponent } from 'src/app/pacman-features/modules/admin/roles-allocation/roles-allocation.component';
import { StickyExceptionsComponent } from 'src/app/pacman-features/modules/admin/sticky-exceptions/sticky-exceptions.component';
import { DeleteStickyExceptionsComponent } from 'src/app/pacman-features/modules/admin/sticky-exceptions/delete-sticky-exceptions/delete-sticky-exceptions.component';
import { CreateStickyExceptionsComponent } from 'src/app/pacman-features/modules/admin/sticky-exceptions/create-sticky-exceptions/create-sticky-exceptions.component';
import { SystemManagementComponent } from 'src/app/pacman-features/modules/admin/system-management/system-management.component';
import { ServicenowComponent } from 'src/app/pacman-features/modules/admin/servicenow/servicenow.component';
import { ConfigManagementComponent } from 'src/app/pacman-features/modules/admin/config-management/config-management.component';
import { GrcComponent } from 'src/app/pacman-features/modules/grc/grc.component';
import { RiskManagementComponent } from 'src/app/pacman-features/modules/grc/risk-management/risk-management.component';
import { CreateRiskComponent } from 'src/app/pacman-features/modules/grc/create-risk/create-risk.component';
import { RiskcreateComponent } from 'src/app/pacman-features/modules/grc/riskcreate/riskcreate.component';
import { UpdateRiskComponent } from 'src/app/pacman-features/modules/grc/update-risk/update-risk.component';
import { SecurityPostureAssessmentComponent } from 'src/app/pacman-features/modules/grc/security-posture-assessment/security-posture-assessment.component';
import { RiskoverviewComponent } from 'src/app/pacman-features/modules/grc/riskoverview/riskoverview.component';

export const COMMON_PAGES = [
  'compliance-dashboard',
  'policy-details',
  'issue-listing',
  'issue-details',
  'policy-knowledgebase',
  'policy-knowledgebase-details',
  'asset-dashboard',
  'asset-list',
  'tools-landing',
  'omni-search-page',
  'admin-policies',
  'risk-management',
  'create-risk',
  'security-assessment',
  'riskoverview'
];

export const COMPLIANCE_ROUTES = [
  {
    path: 'compliance-dashboard',
    component: ComplianceDashboardComponent,
    data: {
      title: 'Overview'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'issue-listing',
    component: IssueListingComponent,
    data: {
      title: 'Policy Violations'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'issue-details/:issueId',
    component: IssueDetailsComponent,
    data: {
      title: 'Policy Violation Details'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'vulnerabilities-compliance',
    component: VulnerabilitiesComplianceComponent,
    data: {
      title: 'Vulnerabilities',
      tileName: 'app-overview-vulnerabilities'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'policy-knowledgebase',
    component: PolicyKnowledgebaseComponent,
    data: {
      title: 'Policy Knowledgebase'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'policy-knowledgebase-details/:ruleID/:autoFix',
    component: PolicyKnowledgebaseDetailsComponent,
    data: {
      title: 'Policy Details'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'tagging-compliance',
    component: TaggingComplianceComponent,
    data: {
      title: 'Tagging Compliance',
      tileName: 'app-overview-tagging'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'recommendations',
    component: RecommendationsComponent,
    data: {
      title: 'Recommendations',
      pageLevel: 0
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'health-notifications',
    component: CloudNotificationsComponent,
    data: {
      title: 'Health Notifications'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'policy-details/:ruleID',
    component: PolicyDetailsComponent,
    data: {
        title: 'Policy Compliance View'
    },
    canActivate: [AuthGuardService]
  },

];


export const ASSETS_ROUTES = [ 
  {
    path: 'asset-dashboard',
    component: AssetDashboardComponent,

    data: {
      title: 'Asset Dashboard'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'assets-details/:resourceType/:resourceId',
    component: AssetDetailsComponent,
    data: {
      title: 'Asset 360'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'asset-list',
    component: AssetListComponent,
    data: {
      title: 'Asset List'
    },
    canActivate: [AuthGuardService]
  }
];

export const ADMIN_ROUTES = [
  {
    path: 'admin-policies',
    component: PoliciesComponent,

    data: {
      title: 'Policies',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'create-policy',
    component: CreatePolicyComponent,

    data: {
      title: 'Create Polcies',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-rules',
    component: RulesComponent,

    data: {
      title: 'Rules',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-create-rule',
    component: CreateRuleComponent,
    data: {
      title: 'Create Rule',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-enable-disable-rule',
    component: EnableDisableRuleComponent,
    data: {
      title: 'Enable Disable Rule',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-update-rule',
    component: UpdateRuleComponent,
    data: {
      title: 'Update Rule',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-invoke-rule',
    component: InvokeRuleComponent,
    data: {
      title: 'Invoke Rule',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-job-execution-manager',
    component: JobExecutionManagerComponent,
    data: {
      title: 'Job Execution Manager',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-create-job-execution-manager',
    component: CreateJobExecutionManagerComponent,
    data: {
      title: 'Create Job Execution Manager',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-update-job-execution-manager',
    component: UpdateJobExecutionManagerComponent,
    data: {
      title: 'Update Job Execution Manager',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-target-types',
    component: TargetTypesComponent,
    data: {
      title: 'Target Types',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-create-update-target-type',
    component: CreateUpdateTargetTypesComponent,
    data: {
      title: 'Create Update Target Type',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-domains',
    component: DomainComponent,

    data: {
      title: 'Domains',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'create-domain',
    component: CreateDomainComponent,

    data: {
      title: 'Create Domain',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-asset-groups',
    component: AssetGroupsComponent,
    data: {
      title: 'Asset Groups',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-sticky-exceptions',
    component: StickyExceptionsComponent,

    data: {
      title: 'Sticky Exceptions',
    },
    canActivate: [AuthGuardService],
  }, 
  {
    path: 'create-sticky-exceptions',
    component: CreateStickyExceptionsComponent,

    data: {
      title: 'Create Sticky Exceptions',
    },
    canActivate: [AuthGuardService],
  }, 
  {
    path: 'delete-sticky-exceptions',
    component: DeleteStickyExceptionsComponent,

    data: {
      title: 'Delete Sticky Exception',
    },
    canActivate: [AuthGuardService],
  }, 
  {
    path: 'admin-roles',
    component: RolesComponent,

    data: {
      title: 'Roles',
      roles: ['ROLE_ADMIN']
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-create-asset-groups',
    component: CreateAssetGroupsComponent,
    data: {
      title: 'Create Asset Groups',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: 'admin-delete-asset-groups',
    component: DeleteAssetGroupsComponent,
    data: {
      title: 'Delete Asset Groups',
      roles: ['ROLE_ADMIN'],
    },
  },
   { path: 'create-update-roles',
    component: CreateUpdateRolesComponent,

    data: {
      title: 'Create Roles',
      roles: ['ROLE_ADMIN']
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'config-users',
    component: ConfigUsersComponent,

    data: {
      title: 'Config Users',
      roles: ['ROLE_ADMIN']
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'roles-allocation',
    component: RolesAllocationComponent,

    data: {
      title: 'User Role Allocation',
      roles: ['ROLE_ADMIN']
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin-config-management',
    component: ConfigManagementComponent,

    data: {
      title: 'Configuration Management',
    },
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-system-management',
    component: SystemManagementComponent,
    data: {
      title: 'System Management',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    path: 'admin-servicenow-policy-mapping',
    component: ServicenowComponent,

    data: {
      title: 'Servicenow Policy Mapping',
      roles: ['ROLE_ADMIN'],
    },
    canActivate: [AuthGuardService],
  },
];

export const GRC_MODULE = [ 
  // {
  //   path: 'grc',
  //   component: GrcComponent,

  //   data: {
  //     title: 'GRC'
  //   },
  //   canActivate: [AuthGuardService]
  // },
  {
    path: 'risk-management',
    component: RiskManagementComponent,
    data: {
      title: 'Risk Management'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'create-risk',
    component: CreateRiskComponent,
    data: {
      title: 'Crete-risk'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'riskcreate',
    component: RiskcreateComponent,
    data: {
      title: 'Crete-risk'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'update-risk',
    component: UpdateRiskComponent,
    data: {
      title: 'Update-risk'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'security-assessment',
    component: SecurityPostureAssessmentComponent,
    data: {
      title: 'Security-Posture-Assessment'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'riskoverview',
    component: RiskoverviewComponent,
    data: {
      title: 'Risk-Overview'
    },
    canActivate: [AuthGuardService]
  }
];