import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';
import { HttpService } from '../../shared/services/http-response.service';

@Injectable()
export class IssueFilterService {

    constructor( @Inject(HttpService) private httpService: HttpService) { }


    // @ts-ignore
  getFilters(filterParams :any, filterUrl :any, filterMethod :any): Observable<any> {

        const url = filterUrl;
        const method = filterMethod;
        const payload  :any= {};
        const queryParams  :Array<any>= filterParams;

        try {
            return Observable.combineLatest(
                this.httpService.getHttpResponse(url, method, payload, queryParams)
                    .map(response => this.massageData(response) )
                    .catch(this.handleError)
            );
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error: any): Observable<any> {
        return Observable.throw(error.message || error);
    }

    massageData(data :any): any {
        return data;
    }

}
