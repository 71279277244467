

import { Injectable } from '@angular/core';
import {UtilsService} from "../../shared/services/utils.service";
// import { Session } from "selenium-webdriver";

@Injectable()
export class DataCacheService {
  private store = sessionStorage;

  constructor(private utilsService: UtilsService) {}

  // Add an entry to the store
  public set(key: string, value: any) {
    if (key === undefined || key === null || key === '') {
      return;
    }

    // save the value locally
    this.store[key] = value;
  }

  // Get an entry from the store
  public get(key: string) {
    // get the value
    if (key === undefined || key === null || key === '') {
      return null;
    }

    return this.store[key];
  }

  // Delete an entry from the store
  public clear(key: string) {
    // clear an entry
    if (key === undefined || key === null || key === '') {
      return;
    }

    this.store[key] = undefined;
  }

  // Clear store
  public clearAll() {
    this.store.clear();
    // clear all cache
  }

  public getStorage() {
    return this.store;
  }

  public setCurrentUserLoginDetails(value :any) {
    const key = 'currentUserLoginDetails';
    this.set(key, value);
  }

  public getCurrentUserLoginDetails() {
    const key = 'currentUserLoginDetails';
    return this.get(key);
  }

  public getUserDetailsValue() :any {
    let secretData = this.getCurrentUserLoginDetails();
    if (secretData) {
      secretData = JSON.parse(secretData);
      return {
        /* Deprecated: getAuthToken - function is not used anymore */
        getAuthToken() {
          const authTokenValues :any = {
            'token_type': secretData.token_type,
            'access_token': secretData.access_token,
            'refresh_token': secretData.refresh_token,
            'expires_in': secretData.expires_in
          };
          return authTokenValues;
        },

        getRoles() {
          let roles = [];
          roles = secretData.userInfo.userRoles;
          return roles;
        },

        getEmail() {
          const email :string = secretData.userInfo.email;
          return email;
        },

        getFirstName() {
          const firstName :string = secretData.userInfo.firstName;
          return firstName;
        },

        getLastName() {
          const firstName :string = secretData.userInfo.lastName;
          return firstName;
        },

        getUserName() {
          const userName :string= secretData.userInfo.userName;
          return userName;
        },

        getUserId() {
          const userId :string = secretData.userInfo.userId;
          return userId;
        },

        /* Deprecated: getRefreshToken - function is not used anymore */
        getRefreshToken() {
          const refreshToken :string = secretData.refresh_token;
          return refreshToken;
        },

        /* Deprecated: getClientCredentials - function is not used anymore */
        getClientCredentials() {
          const clientCredentials :string = secretData.clientCredentials;
          return clientCredentials;
        },

        isAuthenticated() {
          return secretData.success;
        }
      };
    }
  }

  public getCurrentSelectedAssetGroup() {
    const key = 'currentSelectedAssetGroup';
    return this.get(key);
  }

  public setCurrentSelectedAssetGroup(assetGroup :any) {
    const key = 'currentSelectedAssetGroup';
    if (assetGroup) { this.set(key, assetGroup); }
  }

  public getCurrentSelectedDomain(assetGroupNameAsKey :any) {
    const key :string = assetGroupNameAsKey;
    return this.get(key);
  }

  public setCurrentSelectedDomain(domainName :any, assetGroupNameAsKey :any) {
    const key :string = assetGroupNameAsKey;
    if (domainName) { this.set(key, domainName); }
  }

  public setCurrentSelectedDomainList(domainList :any) {
    const key = 'domainList';
    if (domainList) { this.set(key, domainList); }
  }

  public getRecentlyViewedAssetGroups() {
    const key = 'recentlyViewedAssetGroups';
    return this.get(key);
  }
  public setRecentlyViewedAssetGroups(recentlyViewedAssetGroups :any) {
    const key = 'recentlyViewedAssetGroups';
    if (recentlyViewedAssetGroups) { this.set(key, recentlyViewedAssetGroups); }
  }


  public getCurrentSelectedDomainList() {
    const key = 'domainList';
    return this.get(key);
  }

  public setListOfAssetGroups(allAssetGroups :any) {
    const key = 'allAssetGroups';
    if (allAssetGroups) { this.set(key, allAssetGroups); }
  }

  public getListOfAssetGroups() {
    const key = 'allAssetGroups';
    return this.get(key);
  }

  public setUserDefaultAssetGroup(defaultAssetGroup :any) {
    const key = 'userDefaultAssetGroup';
    if (defaultAssetGroup) { this.set(key, defaultAssetGroup); }
  }

  public setUserCurrentAssetGroupObj(userDefaultAssetGroupObj :any) {
    const key = 'userDefaultAssetGroupObj';
    if (userDefaultAssetGroupObj) {
      this.set(key, JSON.stringify(userDefaultAssetGroupObj));
    }
  }

  public getUserDefaultAssetGroup() {
    const key = 'userDefaultAssetGroup';
    return this.get(key);
  }

  public getUserCurrentAssetGroupObj() {
    const key = 'userDefaultAssetGroupObj';
    return JSON.parse(this.get(key));
  }

  public setHashedIdOfUser(hashedKey :any) {
    const key = 'hashedKey';
    this.set(key, hashedKey);
  }

  public getHashedIdOfUser() {
    const key = 'hashedKey';
    return this.get(key);
  }

  /**
   * @author Trinanjan added on 09.04.2018
   * @func setOmniSeachData
   * @param searchTextToCompare gets the searchText based on which data will be returned
   * @param searchCategoryToCompare gets the searchCategory based on which data will be returned
   * @param ag asset group selected,
   * @param domain domain selected,
   * @param showTerminated terminated checkbox,
   * @param filterQueryToCompare applied filter
   * @param response saves the api response for the omnisearch
   * @desc stores the omni search data along with searchText and searchCategory
   */
  public setOmniSeachData(
    searchText :any,
    searchCategory :any,
    ag :any,
    domian :any,
    showTerminated :any,
    filterQuery :any,
    response :any
  ) {
    const key = 'OmniSearchData';
    let keyToBeStored;
    let finalKeyToBeStored;
    if (this.utilsService.isObjectEmpty(filterQuery)) {
      keyToBeStored = [searchText, searchCategory, ag, domian, showTerminated];
    } else {
      keyToBeStored = [
        searchText,
        searchCategory,
        ag,
        domian,
        showTerminated,
        JSON.stringify(filterQuery)
      ];
    }
    finalKeyToBeStored = keyToBeStored.join('*');
    const dataToBeStored :any = {};
    dataToBeStored[finalKeyToBeStored] = response;
    if (JSON.stringify(dataToBeStored)) {
      this.set(key, JSON.stringify(dataToBeStored));
    }
  }
  /**
   * @author Trinanjan added on 09.04.2018
   * @func getOmniSeachData
   * @param searchTextToCompare gets the searchText based on which data will be returned
   * @param searchCategoryToCompare gets the searchCategory based on which data will be returned
   * @param ag asset group selected,
   * @param domain domain selected,
   * @param showTerminated terminated checkbox,
   * @param filterQueryToCompare applied filter
   * @returns the saved data or empty data (if user logs in firstime or searchText/searchCategory isn't matching)
   */
  public getOmniSeachData(
    searchTextToCompare :any,
    searchCategoryToCompare :any,
    ag :any,
    domain :any,
    showTerminated :any,
    filterQueryToCompare :any
  ) {
    const key = 'OmniSearchData';
    let dataToBeChecked;
    let keyToBeChecked;
    let finalKeyToBeChecked;
    if (this.utilsService.isObjectEmpty(filterQueryToCompare)) {
      keyToBeChecked = [
        searchTextToCompare,
        searchCategoryToCompare,
        ag,
        domain,
        showTerminated
      ];
    } else {
      keyToBeChecked = [
        searchTextToCompare,
        searchCategoryToCompare,
        ag,
        domain,
        showTerminated,
        JSON.stringify(filterQueryToCompare)
      ];
    }
    finalKeyToBeChecked = keyToBeChecked.join('*');

    if (this.get(key)) {
      dataToBeChecked = JSON.parse(this.get(key));
    }
    if (dataToBeChecked) {
      if (finalKeyToBeChecked in dataToBeChecked) {
        return dataToBeChecked[finalKeyToBeChecked];
      } else {
        return 'no data';
      }
    } else {
      return 'no data';
    }
  }
  /**
   * @author Trinanjan added on 29.05.2018
   * @func setSearhCriteria
   * @param searchCategory gets the search category
   * @param searchText gets the searchText
   * @param checkBoxState  gets the terminated checkbox state
   * @desc saves the search Criteria
   */
  public setSearhCriteria(searchCategory :any, searchText :any, checkBoxState :any) {
    const key = 'searchCategory';
    const keyToBeStored = [searchCategory, searchText, checkBoxState];
    const finalKeyToBeStored = keyToBeStored.join('*');
    this.set(key, finalKeyToBeStored);
  }
  /**
   * @author Trinanjan added on 29.05.2018
   * @func getSearhCriteria
   * @param searchCategory gets the search category
   * @param searchText gets the searchText
   * @param checkBoxState  gets the terminated checkbox state
   * @desc checks whether search criteria is changed based on the params and returns true/false based on which search button is highlighted
   */
  public getSearhCriteria(searchCategory :any, searchText :any, checkBoxState :any) {
    const key = 'searchCategory';
    const keyToBeChecked = [searchCategory, searchText, checkBoxState];
    const finalKeyToBeChecked = keyToBeChecked.join('*');
    let oldKeyStored;
    if (this.get(key)) {
      oldKeyStored = this.get(key);
    }
    if (finalKeyToBeChecked === oldKeyStored) {
      return true;
    } else {
      return false;
    }
  }

  public setRedirectUrl(url :any) {
    const key = 'redirectUrl';
    this.set(key, url);
  }

  public getRedirectUrl() {
    const key = 'redirectUrl';
    return this.get(key);
  }

}
